import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { ButtonIcon } from '@statsbomb/kitbag-components';
import {
  DriveBody,
  PlaysList,
  PlaysListItem,
  OutcomeIndicator,
} from './PlayByPlayAccordionTileBody.styles';
import {
  getDownName,
  getYardlineName,
  getToGoName,
  getYardsWon,
} from '../../../../utils/helpers/play';
import {
  API_PLAY_TYPES,
  API_TURNOVER_TYPES,
} from '../../../../utils/constants/api';
import GoTo from '../../../../components/buttons/GoTo';
import { GET_PLAY_BY_PLAY_EVENTS } from '../GetPlayByPlayEvents';
import { getKeyEventDescriptions } from '../PlayByPlay.EventDescription';
import { useSelectedPlay } from '../SelectedPlayContext';

const playLi = function (playDatum, visPalette) {
  const { selectedPlay, setSelectedPlay } = useSelectedPlay();
  const { data: eventsData } = useQuery(GET_PLAY_BY_PLAY_EVENTS, {
    variables: { playId: playDatum.id },
  });

  const playDriveIndex = `P${playDatum.driveIndex}`;
  const yardsWonText = playDatum.yardsNet
    ? `${getYardsWon(playDatum.yardsNet)} yds`
    : '';

  let playOutcome = playDatum.success ? 'Success' : '';
  let playOutcomeColor = playDatum.success
    ? visPalette.successFail.success.main
    : visPalette.successFail.neutral.main;
  if (playDatum.downNegated) {
    playOutcome = 'Negated Down';
    playOutcomeColor = visPalette.successFail.neutral.main;
  } else if (playDatum.touchdownWon) {
    playOutcome = 'Touchdown';
    playOutcomeColor = visPalette.successFail.superSuccess.main;
  } else if (playDatum.turnoverType) {
    playOutcome = API_TURNOVER_TYPES[playDatum.turnoverType];
    playOutcomeColor = visPalette.successFail.superFail.main;
  } else if (playDatum.down && !playDatum.success) {
    playOutcome = 'Fail';
    playOutcomeColor = visPalette.successFail.fail.main;
  }

  const penaltyTexts = [];
  if (playDatum.offensePenaltyAccepted) {
    penaltyTexts.push('Penalty against defense');
  }
  if (playDatum.defensePenaltyAccepted) {
    penaltyTexts.push('Penalty against offense');
  }
  if (playDatum.penaltyOffset) {
    penaltyTexts.push('Penalties Offset');
  } else if (playDatum.penaltyYards) {
    const prefix = playDatum.penaltyYards > 0 ? '+' : '';
    penaltyTexts.push(`${prefix}${playDatum.penaltyYards.toFixed(0)} yds`);
  }
  if (playDatum.penaltyFirstDown) {
    penaltyTexts.push('New First Down');
  }
  if (playDatum.penaltyDownLoss) {
    penaltyTexts.push('Down Lost');
  }

  if (playDatum.penalties && playDatum.penalties.length > 0) {
    const penaltyNames = playDatum.penalties.map((p) => p.type).join('; ');
    penaltyTexts.push(`[${penaltyNames}]`);
  }
  const penText = penaltyTexts.join(', ');

  const href = `/game/animation/:leagues/:seasons/:teams/${playDatum.game.id}/${playDatum.drive.id}/${playDatum.id}`;

  const eventNotes =
    eventsData?.playEvents &&
    getKeyEventDescriptions(playDatum, eventsData.playEvents);

  const packageCollectedPlay =
    API_PLAY_TYPES[playDatum.type] === API_PLAY_TYPES.PASS ||
    API_PLAY_TYPES[playDatum.type] === API_PLAY_TYPES.RUSH;

  return (
    <PlaysListItem
      key={playDatum.id}
      $selected={selectedPlay?.id === playDatum?.id}
    >
      <div className="play-values">
        <span className="play-number">{playDriveIndex}</span>
        <span className="down-number">{getDownName(playDatum.down)}</span>
        <span className="yards-to-go">{getToGoName(playDatum.yardsToGo)}</span>
        <span className="yardline-of-the-snap">
          {getYardlineName(playDatum.yardLine)}
        </span>
        <span className="play-type">{API_PLAY_TYPES[playDatum.type]}</span>
        <span className="yards-gained-lost">{yardsWonText}</span>
        <span className="play-outcome">
          {playOutcome && (
            <>
              <OutcomeIndicator $colour={playOutcomeColor} />
              <span>{playOutcome}</span>
            </>
          )}
        </span>
        <span className="penalty-information" title="Penalty Information">
          {penText}
        </span>
      </div>
      <div className="play-notes" title="Description of play key events">
        {eventNotes}
      </div>

      <div className="buttons">
        {playDatum.videoTimestamp}

        {packageCollectedPlay && (
          <GoTo href={href} ghost linkName="Open in Play Animation" />
        )}

        {playDatum && (
          <ButtonIcon
            icon="Video"
            variant="ghost"
            onClick={() => {
              if (playDatum.id !== selectedPlay?.id) {
                setSelectedPlay({
                  id: playDatum.id,
                  info: { ...playDatum, eventNotes },
                });
              }
            }}
          />
        )}
      </div>
    </PlaysListItem>
  );
};

function PlayByPlayAccordianTileBody({ playsData, visPalette }) {
  if (!playsData) {
    return <></>;
  }

  return (
    <DriveBody>
      <div className="plays-list-headers">
        <span className="play-number">Play</span>
        <span className="down-number">Down</span>
        <span className="yards-to-go">Yards to go</span>
        <span className="yardline-of-the-snap">Yardline of the snap</span>
        <span className="play-type">Play type</span>
        <span className="yards-gained-lost">Yards gained/lost</span>
        <span className="play-outcome">Play outcome</span>
        <span className="penalty-information">Penalty information</span>
      </div>
      <PlaysList>{playsData.map((p) => playLi(p, visPalette))}</PlaysList>
    </DriveBody>
  );
}

PlayByPlayAccordianTileBody.propTypes = {
  playsData: PropTypes.arrayOf(PropTypes.shape({})),
  visPalette: PropTypes.shape({}),
};

PlayByPlayAccordianTileBody.defaultProps = {
  playsData: null,
  visPalette: null,
};

export default PlayByPlayAccordianTileBody;
