import React, { useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import PropTypes from 'prop-types';
import { isFunction } from 'lodash';
import { Loader } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import Tile from '../../../../components/Tile/Tile';
import SwitchVisTableButton from '../../../../components/buttons/SwitchVisTable/SwitchVisTable';
import Dimmer from '../../../../components/Dimmer/Dimmer';
import HavocChart from '../../../../visualisations/HavocChart/HavocChart';
import {
  HAVOC_AXES_PADDING,
  HAVOC_COLOR_MODE_LOCATIONS,
  HAVOC_FOCUS_MODE_BOX,
  HAVOC_TYPE_ALL,
  HAVOC_Y_MODE_SNAP,
} from '../../../../visualisations/HavocChart/HavocChart.constants';
import { ROTATIONS } from '../../../../utils/constants/charting';
import HavocChartKey from '../../../../visualisations/HavocChart/HavocChartKey/HavocChartKey';
import KeyButton from '../../../../components/buttons/DisplayKey/DisplayKey';
import {
  HavocEventsChartHolder,
  HavocSummaryKeyHolder,
} from '../TeamHavoc.styles';
import {
  HAVOC_KEY_VERTICAL_HEIGHT,
  HAVOC_SUMMARY_KEY_SIZES,
} from '../../../../visualisations/HavocChart/HavocChartKey/HavocChartKey.constants';
import { getFieldSize } from '../../../../visualisations/HavocChart/HavocChart.dataManipulation';
import ExportModal from '../../../../components/ExportModal/ExportModal';
import { getExportDetails } from '../TeamHavoc.DataManipulation';
import { mf_TeamDetails } from '../../../../apollo';
import { useExportSeasonInfo } from '../../../../utils/hooks/useExportSeasonInfo';
import useQueryString from '../../../../utils/hooks/useQueryString';

const HavocEventMapTile = ({
  havocData,
  isEventsLoading,
  selectedRotation,
  colorMode,
  fieldFocus,
  showPaths,
  relevantPlayers,
  positionFrequencies,
  tileTitle,
  havocMode,
  showDefensive,
  selectedPlayerId,
  selectedPositionCode,
  selectedEvents,
  setSelectedEvents,
  handleVisTableSwitch,
  nowPlayingPlayUUID,
}) => {
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const teamName = mf_TeamDetailsRV?.name;

  const [displayKey, setDisplayKey] = useQueryString(
    'event-map-display-key',
    true
  );
  const [chartHolderWidth, setChartHolderWidth] = useState(500);
  const [chartTileWidth, setChartTileWidth] = useState(500);

  const havocSummaryTileBodyId = 'havoc-events-tile-body';
  const chartHolderId = 'havoc-events-chart-holder';

  const windowWidth = useWindowWidth();
  useEffect(() => {
    const tileElem = document.querySelector(`#${havocSummaryTileBodyId}`);
    const chartHolderElem = document.querySelector(`#${chartHolderId}`);
    const rect = chartHolderElem?.getBoundingClientRect();
    if (rect?.width) {
      setChartHolderWidth(rect.width);
    }
    const rectTile = tileElem?.getBoundingClientRect();
    if (rectTile?.width) {
      setChartTileWidth(rectTile.width);
    }
  }, [windowWidth]);

  const fieldArea = getFieldSize(selectedRotation, true, fieldFocus);
  const havocChartViewboxWidth =
    fieldArea.width + HAVOC_AXES_PADDING.left + HAVOC_AXES_PADDING.right;
  const isFixedSize = havocChartViewboxWidth < chartHolderWidth;
  const chartWidthDisplayed = isFixedSize
    ? havocChartViewboxWidth
    : chartHolderWidth;

  /* 
  Key is vertical if it's very large or very small:
    When the tile space is less than that required for horizontal key (i.e. 2 columns & padding): use vertical
    when large it sits to the right of main vis, as long as tile has space for key and chart
  Key only shows in horizontal mode if both vertical causes are false
   */
  const isKeyVerticalSmall =
    chartTileWidth < HAVOC_SUMMARY_KEY_SIZES.HORIZONTAL_KEY_SPACE_REQUIRED;
  const isKeyVerticalLarge =
    chartTileWidth >
    chartWidthDisplayed + HAVOC_SUMMARY_KEY_SIZES.VERTICAL_KEY_SPACE_REQUIRED;
  const keyStyle = isKeyVerticalLarge
    ? {
        right: '16px',
        position: 'absolute',
        width: '250px',
      }
    : { width: '100%' };
  const chartHolderStyle = isKeyVerticalLarge
    ? { minHeight: HAVOC_KEY_VERTICAL_HEIGHT }
    : {};

  /* Exporting */
  const exportWidth = chartWidthDisplayed < 500 ? 500 : null;
  const { competitionSeason: info1, seasonName } = useExportSeasonInfo({
    asObject: true,
  });
  const { title, secondaryTitle, info2, info3, fileName } = getExportDetails(
    havocData,
    teamName,
    havocMode,
    showDefensive,
    selectedPlayerId,
    selectedPositionCode,
    relevantPlayers,
    positionFrequencies,
    seasonName
  );

  return (
    <Tile border="0" margin="0 0 0.5rem 0">
      <Tile.Header>
        <h2 title={tileTitle}>{tileTitle}</h2>
        <div className="buttons">
          {isFunction(handleVisTableSwitch) && (
            <SwitchVisTableButton
              isShowingVis={false}
              handleClick={handleVisTableSwitch}
            />
          )}
          <KeyButton
            showKey={displayKey}
            handleShowKey={() => setDisplayKey(!displayKey)}
          />
          <ExportModal
            title={title}
            secondaryTitle={secondaryTitle}
            info1={info1}
            info2={info2}
            info3={info3}
            fileName={fileName}
            customWidth={exportWidth}
            isDisabled={isEventsLoading || !havocData?.length}
          >
            <HavocChart
              id="export-havoc-chart"
              data={havocData}
              orientation={selectedRotation}
              displayYMode={HAVOC_Y_MODE_SNAP.value}
              colorMode={colorMode}
              fieldFocusMode={fieldFocus}
              players={relevantPlayers}
              positions={positionFrequencies}
              showPaths={showPaths}
              isFixedSize
            />
            {displayKey && (
              <HavocChartKey
                colorMode={colorMode}
                players={relevantPlayers}
                positions={positionFrequencies}
                isFixedSize
              />
            )}
          </ExportModal>
        </div>
      </Tile.Header>
      <Tile.Body
        $padding="0 1rem 1rem 1rem"
        data-testid="havoc-event-vis-tile"
        id={havocSummaryTileBodyId}
      >
        <HavocEventsChartHolder id={chartHolderId} style={chartHolderStyle}>
          <HavocChart
            data={havocData}
            orientation={selectedRotation}
            displayYMode={HAVOC_Y_MODE_SNAP.value}
            colorMode={colorMode}
            fieldFocusMode={fieldFocus}
            showPaths={showPaths}
            players={relevantPlayers}
            isFixedSize={isFixedSize}
            selectedEvents={selectedEvents}
            setSelectedEvents={setSelectedEvents}
            nowPlayingPlayUUID={nowPlayingPlayUUID}
          />
        </HavocEventsChartHolder>
        {displayKey && (
          <HavocSummaryKeyHolder style={keyStyle}>
            <HavocChartKey
              colorMode={colorMode}
              players={relevantPlayers}
              positions={positionFrequencies}
              isFixedSize
              vertical={isKeyVerticalSmall || isKeyVerticalLarge}
            />
          </HavocSummaryKeyHolder>
        )}
        {isEventsLoading && (
          <Dimmer
            active
            style={{
              minHeight: '30vh',
              maxWidth: '100%',
            }}
          >
            <Loader content="Loading Data" />
          </Dimmer>
        )}
      </Tile.Body>
    </Tile>
  );
};

HavocEventMapTile.propTypes = {
  havocData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isEventsLoading: PropTypes.bool,
  selectedRotation: PropTypes.string,
  colorMode: PropTypes.string,
  fieldFocus: PropTypes.string,
  showPaths: PropTypes.bool,
  relevantPlayers: PropTypes.arrayOf(
    PropTypes.shape({
      playerId: PropTypes.number,
      playerName: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  positionFrequencies: PropTypes.arrayOf(
    PropTypes.shape({
      positionCode: PropTypes.string,
      positionName: PropTypes.string,
      color: PropTypes.func,
      freq: PropTypes.number,
    })
  ),
  tileTitle: PropTypes.string.isRequired,
  havocMode: PropTypes.string,
  selectedEvents: PropTypes.arrayOf(PropTypes.string),
  setSelectedEvents: PropTypes.func,
  handleVisTableSwitch: PropTypes.func,
  showDefensive: PropTypes.bool,
  selectedPlayerId: PropTypes.number,
  selectedPositionCode: PropTypes.string,
  nowPlayingPlayUUID: PropTypes.string,
};

HavocEventMapTile.defaultProps = {
  selectedRotation: ROTATIONS.VERTICAL_DOWN,
  colorMode: HAVOC_COLOR_MODE_LOCATIONS.value,
  fieldFocus: HAVOC_FOCUS_MODE_BOX.value,
  showPaths: PropTypes.false,
  relevantPlayers: null,
  positionFrequencies: null,
  isEventsLoading: PropTypes.false,
  selectedEvents: null,
  setSelectedEvents: null,
  handleVisTableSwitch: null,
  havocMode: HAVOC_TYPE_ALL.value,
  showDefensive: true,
  selectedPlayerId: null,
  selectedPositionCode: null,
  nowPlayingPlayUUID: null,
};

export default HavocEventMapTile;
