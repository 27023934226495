import { isEmpty, join, orderBy, partition, sortBy, uniq } from 'lodash';
import {
  BIO_CATEGORY,
  BIO_STAT_HEADERS,
  COLLEGE_CLASS,
  PLAYS_STAT_KEY,
} from './ScoutPlayers.constants';
import { formatValue } from '../../../../utils/helpers/stats.dataManipulation';
import { SORT_DIRECTIONS } from '../../../../utils/constants/sortDirections';
import { ROSTER_POSITIONS } from '../../../../utils/constants/positions';
import { SCOUT_BIOMETRIC_FILTERS } from '../ScoutResults.constants';
import { toTitleCase } from '../../../../utils/helpers/general';
import {
  TABLE_NULL,
  inchesToFeetAndInches,
} from '../../../../utils/helpers/formatting';

export const getCategories = (apiStatCategories) => {
  if (isEmpty(apiStatCategories)) {
    return [BIO_CATEGORY];
  }
  return sortBy(apiStatCategories.concat(BIO_CATEGORY), 'name');
};

export const getPlayersStats = (apiPlayer) => {
  if (!isEmpty(apiPlayer.stats)) {
    const statObjects = apiPlayer.stats.map((s) => ({
      [s.key]: s.value,
    }));
    const flatStats = Object.assign({}, ...statObjects);
    return flatStats;
  }
  return {};
};

const getTeamsText = (teams) => {
  if (isEmpty(teams)) {
    return TABLE_NULL;
  }
  const teamsString = join(
    teams.map((c) => c.shortName),
    ', '
  );
  if (teamsString?.length < 24) {
    return teamsString;
  }

  const first24 = teamsString.substring(0, 24);
  const lastComma = first24.lastIndexOf(',');
  // assuming a semi reasonable last comma found, cut to that
  const trimmed = lastComma > 18 ? first24.substring(0, lastComma) : first24;
  return `${trimmed}...`;
};
const getTeamsTitle = (teams) => {
  if (isEmpty(teams)) {
    return TABLE_NULL;
  }
  const teamsString = join(
    teams.map((c) => `${c.shortName}, ${c.name}`),
    '\n'
  );
  return teamsString;
};

export const getPlayerBio = (playerInfo) => {
  const playerBasics = {
    id: playerInfo.id,
    name: playerInfo.name,
    knownName: playerInfo.knownName,
    height: inchesToFeetAndInches(playerInfo.height),
    weight: playerInfo.weight,
    collegeClass: COLLEGE_CLASS[playerInfo.collegeClass]?.name || TABLE_NULL,
    ncaaTransferPortal: playerInfo.ncaaTransferPortal,
    recruitingClass: playerInfo.recruitingClass,
    recruitingRanking247: playerInfo.recruitingRanking247,
    recruitingRankingComposite: playerInfo.recruitingRankingComposite,
    recruitingReranking: playerInfo.recruitingReranking,
    teamName: playerInfo.team?.name,
    teamShortName: playerInfo.team?.shortName,
    teamId: playerInfo.team?.id,
  };
  const htName = playerInfo.homeTown?.name || TABLE_NULL;
  const htNameNice = toTitleCase(htName);
  const homeTownName = playerInfo.homeTown?.state
    ? `${htNameNice}, ${playerInfo.homeTown.state}`
    : htNameNice;

  const hsName = playerInfo.highSchool?.name || TABLE_NULL;
  const hsNameNice = toTitleCase(hsName);
  const hsCity = playerInfo.highSchool?.city || TABLE_NULL;
  const hsCityNice = toTitleCase(hsCity);
  const hsState = playerInfo.highSchool?.state || TABLE_NULL;
  const highSchoolName =
    hsName === hsCity && hsName === hsState // if all table null
      ? hsNameNice
      : `${hsNameNice}, ${hsCityNice}, ${hsState}`;

  const collegesOfferedCount = playerInfo.collegesOffered?.length || 0;
  const collegesOfferedNames = getTeamsText(playerInfo.collegesOffered);
  const collegesOfferedTooltip = getTeamsTitle(playerInfo.collegesOffered);

  const collegesAttendedCount = playerInfo.collegesAttended?.length || 0;
  const collegesAttendedNames = getTeamsText(playerInfo.collegesAttended);
  const collegesAttendedTooltip = getTeamsTitle(playerInfo.collegesAttended);

  const currentPosition = ROSTER_POSITIONS[playerInfo?.rosteredPosition];
  const rosterPositionCode = currentPosition?.code;
  const rosterPositionName = currentPosition?.name;

  return {
    ...playerBasics,
    homeTownName,
    highSchoolName,
    collegesOfferedCount,
    collegesOfferedNames,
    collegesOfferedTooltip,
    collegesAttendedCount,
    collegesAttendedNames,
    collegesAttendedTooltip,
    rosterPositionCode,
    rosterPositionName,
  };
};

export const formatAPIScoutPlayers = (apiPlayers) => {
  if (isEmpty(apiPlayers)) {
    return [];
  }
  const nicePlayers = apiPlayers.map((apiPlayer) => {
    /* If there are stats to display: turn key value pairs into normal data */
    const playersStats = getPlayersStats(apiPlayer);
    const playerBio = getPlayerBio(apiPlayer.player);
    const player = {
      seasonName: apiPlayer.season?.name,
      ...playerBio,
      ...playersStats,
    };
    return player;
  });
  return nicePlayers;
};

export const getStatHeaders = (columnStatKeys, statDefinitions) => {
  if (isEmpty(columnStatKeys) || isEmpty(statDefinitions)) {
    return [];
  }
  const nonPlaysStats = columnStatKeys.filter((c) => c !== PLAYS_STAT_KEY);
  return nonPlaysStats.map((c) => {
    const statDef = statDefinitions.find((s) => s.name === c);
    return {
      key: c,
      label: statDef?.abbrev,
      description: statDef?.prettyName,
      units: statDef?.units,
    };
  });
};

export const getBiometricHeaders = (columnStatKeys) => {
  if (isEmpty(columnStatKeys)) {
    return {
      stickyBiometricHeaders: [],
      nonStickyBiometricHeaders: BIO_STAT_HEADERS,
    };
  }
  /*
  With biometrics ~ one stat can have multiple filter values, so have to convert from 
    keys into corresponding headers and reduce down that list
  */
  const headerStatKeys = uniq(
    columnStatKeys.map((k) => SCOUT_BIOMETRIC_FILTERS[k].headerKey)
  );
  const [stickyBiometricHeaders, nonStickyBiometricHeaders] = partition(
    BIO_STAT_HEADERS,
    (bsh) => headerStatKeys.includes(bsh.key)
  );
  return { stickyBiometricHeaders, nonStickyBiometricHeaders };
};

export const formatScoutSortableTableRows = (
  players,
  headers,
  leagueId,
  seasonId
) => {
  if (isEmpty(players)) {
    return [];
  }

  const playerRows = players?.map((row) => {
    const columns = headers.map((h) => {
      const formattedValue = formatValue(row[h.key], h.units);

      /* TODO make this more useful */
      const title = formattedValue;

      /* Basic cell object is label and title, possibly width if relevant */
      const cellObjectBase =
        h.width?.length > 0
          ? { label: formattedValue, title, width: h.width }
          : { label: formattedValue, title };

      /* Special case for first column: make a link to player overview */
      if (h.key === 'name') {
        /* TODO: team id missing from response at present */
        const teamId = row?.teamId || 0;
        const playerId = row.id;

        const metricObject = {
          [h.key]: {
            ...cellObjectBase,
            link: `/player/overview/${leagueId}/${seasonId}/${teamId}/${playerId}`,
          },
        };
        return metricObject;
      }
      if (h.key === 'teamShortName') {
        const metricObject = {
          [h.key]: { ...cellObjectBase, title: row.teamName },
        };
        return metricObject;
      }
      if (h.key === 'rosterPositionCode') {
        const metricObject = {
          [h.key]: { ...cellObjectBase, title: row.rosterPositionName },
        };
        return metricObject;
      }
      if (h.key === 'collegesOfferedNames') {
        const metricObject = {
          [h.key]: { ...cellObjectBase, title: row.collegesOfferedTooltip },
        };
        return metricObject;
      }
      if (h.key === 'collegesAttendedNames') {
        const metricObject = {
          [h.key]: {
            ...cellObjectBase,
            title: row.collegesAttendedTooltip,
          },
        };
        return metricObject;
      }
      /* No special case logic */
      const metricObject = {
        [h.key]: cellObjectBase,
      };
      return metricObject;
    });
    /* Merge object array of columns into single row object */
    return Object.assign(...columns);
  });
  return playerRows;
};

export const formatPlayersCSV = (players, headers) => {
  const csvPlayers = players?.map((row) => {
    const columns = headers.map((h) => {
      const formattedValue = formatValue(row[h.key], h.units);
      return { [h.key]: formattedValue };
    });
    /* Merge object array of columns into single row object */
    return Object.assign(...columns);
  });
  return csvPlayers;
};

export const sortPlayers = (players, sortKey, sortDirection) => {
  if (isEmpty(players)) {
    return [];
  }
  const [withValue, withoutValue] = partition(
    players,
    (o) => o[sortKey] !== null && o[sortKey] !== TABLE_NULL
  );
  const sortedWithValuePlayers = orderBy(
    withValue,
    [sortKey, 'name'],
    [sortDirection, SORT_DIRECTIONS.ASCENDING]
  );
  const sortedWithoutValuePlayers = orderBy(
    withoutValue,
    ['name'],
    [SORT_DIRECTIONS.ASCENDING]
  );
  const sortedPlayers = sortedWithValuePlayers.concat(
    sortedWithoutValuePlayers
  );
  return sortedPlayers;
};
