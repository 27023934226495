import { VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS } from '../../utils/constants/charting';

export const addCanvasCropZone = (svg, clipPathId, layout) => {
  const canvasClipPath = svg.select(`#${clipPathId}`);
  canvasClipPath.selectAll('rect').remove();
  canvasClipPath
    .append('rect')
    .attr('x', -layout.AXES.PADDING.left)
    .attr('y', -layout.AXES.PADDING.top)
    .attr(
      'width',
      layout.AXES.PADDING.left + layout.CANVAS.WIDTH + layout.AXES.PADDING.right
    )
    .attr(
      'height',
      layout.AXES.PADDING.top +
        layout.CANVAS.HEIGHT +
        layout.AXES.PADDING.bottom
    );
};

export const drawScatterData = (
  canvasG,
  scatterData,
  setup,
  xScale,
  yScale,
  rScale,
  handleBubbleClick,
  selectedBubble
) => {
  canvasG
    .selectAll('circle')
    .data(scatterData, (d) => d.id)
    .join(
      (enter) => {
        const scatterCircle = enter.append('circle');
        scatterCircle
          .append('title')
          .text((scatterDatum) => scatterDatum.title);
        scatterCircle
          .attr('cx', (d) => xScale(d.xValue))
          .attr('cy', (d) => yScale(d.yValue))
          .attr('r', (d) => setup.R.FIXED_RADIUS || rScale(d.rValue))
          .attr('fill', (d) => d.fill)
          .attr('stroke', (d) => d.stroke)
          .attr('stroke-width', 1)
          .attr('opacity', (d) =>
            selectedBubble !== null && d.id !== selectedBubble ? 0.4 : 1
          )
          .style('pointer-events', (d) =>
            d.fill === 'transparent' ? 'none' : 'auto'
          ) // if its transparent, it is layered above the filtered bubble, we want to let click through
          .attr(
            'class',
            handleBubbleClick
              ? VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS
              : null
          )
          .on('click', (_, datum) =>
            handleBubbleClick ? handleBubbleClick(datum) : null
          );
        return scatterCircle;
      },
      (update) => {
        update.selectAll('title').remove();
        update.append('title').text((scatterDatum) => scatterDatum.title);
        update.attr('opacity', (d) =>
          selectedBubble !== null && d.id !== selectedBubble ? 0.4 : 1
        );
        update.on('click', (_, datum) =>
          handleBubbleClick ? handleBubbleClick(datum) : null
        );
        return update;
      },
      (exit) => exit.remove()
    )
    .transition()
    .duration(600)
    .attr('cx', (d) => xScale(d.xValue))
    .attr('cy', (d) => yScale(d.yValue))
    .attr('r', (d) => setup.R.FIXED_RADIUS || rScale(d.rValue))
    .attr('fill', (d) => d.fill)
    .attr('stroke', (d) => d.stroke)
    .attr('stroke-width', 1);
};

export const drawAverageLines = (
  linesG,
  lineAverageData,
  layout,
  xScale,
  yScale
) => {
  if (lineAverageData?.length > 0) {
    const xMin = layout.AXES.CONSTRAIN_LINES_TO_PADDING
      ? 0
      : -layout.AXES.PADDING.left;
    const xMax = layout.AXES.CONSTRAIN_LINES_TO_PADDING
      ? layout.CANVAS.WIDTH
      : layout.AXES.PADDING.left +
        layout.CANVAS.WIDTH +
        layout.AXES.PADDING.right;
    const yMin = layout.AXES.CONSTRAIN_LINES_TO_PADDING
      ? 0
      : -layout.AXES.PADDING.top;
    const yMax = layout.AXES.CONSTRAIN_LINES_TO_PADDING
      ? layout.CANVAS.HEIGHT
      : layout.AXES.PADDING.top +
        layout.CANVAS.HEIGHT +
        layout.AXES.PADDING.bottom;

    linesG
      .selectAll('line')
      .data(lineAverageData, (d) => d.id)
      .join(
        (enter) => enter.append('line'),
        (update) => update,
        (exit) => exit.remove()
      )
      .transition()
      .duration(600)
      .attr('x1', (d) => (d.xValue !== null ? xScale(d.xValue) : xMin))
      .attr('x2', (d) => (d.xValue !== null ? xScale(d.xValue) : xMax))
      .attr('y1', (d) => (d.yValue !== null ? yScale(d.yValue) : yMin))
      .attr('y2', (d) => (d.yValue !== null ? yScale(d.yValue) : yMax))
      .attr('stroke', (d) => d.stroke)
      .attr('stroke-dasharray', (d) => d.strokeDashArray)
      .attr('stroke-width', 1)
      .attr('opacity', 1);
  } else {
    linesG.selectAll('line').transition().duration(600).attr('opacity', 0);
  }
};
