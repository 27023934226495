import { Link } from 'react-router-dom';
import isUrl from '../../utils/helpers/isUrl';

export const authorizedNavConfig = ({ user, items = [] }) =>
  items.filter(
    (section) =>
      !section.userGroup ||
      section.userGroup === user['https://statsbomb.com/accountID']
  );

export const checkIsExternalLink = (urlString) => {
  const isScript = urlString.substring(0, 10) === 'javascript';
  return isUrl(urlString) || isScript;
};

export const getComponentProps = (
  validHref,
  handleMenuClick,
  menuItemId,
  hasLinks
) => {
  const isExternal = checkIsExternalLink(validHref);
  const hrefTarget = isExternal ? '_blank' : '';
  const component = isExternal ? 'a' : Link;

  const componentProps = {
    [isExternal ? 'href' : 'to']: validHref,
    onClick: (event) => handleMenuClick(event, menuItemId, hasLinks, validHref),
    target: hrefTarget,
    rel: 'no-referrer',
  };

  return { component, componentProps };
};
