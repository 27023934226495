import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const SelectedPlayContext = createContext();

export const SelectedPlayProvider = ({ children }) => {
  const [selectedPlay, setSelectedPlay] = useState({ id: null });

  return (
    <SelectedPlayContext.Provider value={{ selectedPlay, setSelectedPlay }}>
      {children}
    </SelectedPlayContext.Provider>
  );
};
export const useSelectedPlay = () => useContext(SelectedPlayContext);

SelectedPlayProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
