import {
  csDivergent,
  csIntensity,
} from '../../../../utils/helpers/colorScales';

export const HAVOC_SUMMARY_PERCENTAGE_TYPES = {
  OF_ALL_PLAYS: {
    value: 'OF_ALL_PLAYS',
    label: 'Havoc %',
    dataKey: 'playsPercentage',
    gapDomain: [0, 0.1],
    dzDomain: [0, 0.1],
  },
  OF_HAVOC_PLAYS: {
    value: 'OF_HAVOC_PLAYS',
    label: '% of Havoc',
    dataKey: 'havocPercentage',
    gapDomain: [0, 0.3],
    dzDomain: [0, 0.4],
  },
};
export const HAVOC_SPT_KEYS = Object.keys(HAVOC_SUMMARY_PERCENTAGE_TYPES);

export const HAVOC_SUMMARY_COLORING_MDOES = {
  TEAM_INTENSITY: {
    value: 'TEAM_INTENSITY',
    label: 'Team intensity',
    coloringFn: csIntensity,
  },
  VS_LA: {
    value: 'VS_LA',
    label: 'vs League Average',
    coloringFn: csDivergent,
  },
};
export const HAVOC_SCM_KEYS = Object.keys(HAVOC_SUMMARY_COLORING_MDOES);

export const HAVOC_GAP_BARS_SIZE_LIMITS = {
  MIN_VIEWBOX_WIDTH: 328, // mobile
  MIN_FULL_TEXT_WIDTH: 820,
  MAX_VIEWBOX_WIDTH: 1190, // chosen so at 1920screen the key is to the side
  Y_AXIS_WIDTH: 50, // left axis
  X_AXIS_HEIGHT: 22, // gap names
};
