import { styled } from 'styled-components';

export const ScheduleTable = styled.div`
  thead {
    tr {
      th {
        height: 3rem;
        color: ${({ theme }) => theme.colours.ink.primary.main};
      }
    }
  }

  tbody {
    tr {
      height: 3rem;

      td {
        color: ${({ theme }) =>
          theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
      }
      // not the first two, but all others
      td:nth-child(n + 4) {
        font-weight: 600;
      }
    }
  }
`;
ScheduleTable.displayName = 'ScheduleTable';

export const GameResult = styled.span`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
`;
GameResult.displayName = 'GameResult';
