import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { iqTheme, ThemeProvider } from "@statsbomb/kitbag-components";

import ExportLogo from "../../assets/images/hudl_iq_text_logo_export.svg";

import {
  Export,
  ExportHeader,
  Title,
  SecondaryTitle,
  Info,
  ExportLogoContainer,
} from "./ExportModal.styles";
import { getEnrichedTheme } from "../../utils/visualisations/visPalettes";

/**
 * ExportTemplate
 * A component that displays a visualisation in a template as a preview before exporting
 */
const ExportTemplate = forwardRef(
  (
    {
      title,
      secondaryTitle,
      info1,
      info2,
      info3,
      width,
      withLogo,
      children,
      isDarkExport,
    },
    exportRef
  ) => {
    const localTheme = isDarkExport ? iqTheme.dark : iqTheme.light;
    const enrichedTheme = getEnrichedTheme(localTheme);

    return (
      <ThemeProvider theme={enrichedTheme} applyGlobalStyles={false}>
        <Export style={{ width }} ref={exportRef}>
          <ExportHeader>
            <div>
              <Title>{title}</Title>
              <Info>{info1}</Info>
            </div>
            <div>
              <SecondaryTitle>{secondaryTitle}</SecondaryTitle>
              <Info>{info2}</Info>
            </div>
            <Info>{info3}</Info>
          </ExportHeader>
          {children}
          {withLogo && (
            <ExportLogoContainer
              data-testid="export-logo"
              src={ExportLogo || "fallback"}
              style={{
                width: "125px",
                marginLeft: "auto",
              }}
            />
          )}
        </Export>
      </ThemeProvider>
    );
  }
);

ExportTemplate.propTypes = {
  /** The title of the exported image */
  title: PropTypes.string,
  /** The secondary title of the exported image */
  secondaryTitle: PropTypes.string,
  /** extra context about the exported image */
  info1: PropTypes.string,
  /** extra context about the exported image */
  info2: PropTypes.string,
  /** extra context about the exported image */
  info3: PropTypes.string,
  /** whether the export palette is dark */
  isDarkExport: PropTypes.bool,
  /** The width of the exported image */
  width: PropTypes.string.isRequired,
  /** Whether or not to include the exported logo in the exported image */
  withLogo: PropTypes.bool,
  /** The content to be exported */
  children: PropTypes.node,
};

ExportTemplate.defaultProps = {
  title: "",
  secondaryTitle: "",
  info1: "",
  info2: "",
  info3: "",
  isDarkExport: true,
  withLogo: false,
  children: null,
};

export default ExportTemplate;
