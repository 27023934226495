import { sumBy } from 'lodash';
import { getPlayerList } from '../lineBattles.dataManipulation';
import {
  POINT_OF_ATTACK_DOMAIN,
  RUN_TENDENCY_GAPS,
  RUN_TENDENCY_POSITION_KEYS,
} from './RunTendencies.constants';
import { proportionalDifference } from '../../../../utils/helpers/aggregation';
import { olGapPositionPlayer } from '../PassPressures/PassPressures.dataManipulation';
import {
  API_ALIGNMENT_POSITION_KEYS,
  API_STAT_UNITS,
} from '../../../../utils/constants/api';
import { formatValue } from '../../../../utils/helpers/stats.dataManipulation';

/*
In prototype mode converts CSV data into a clean shape as one would expect from API
Once API exists, output shape should remain the same
*/
export const formatAPIRunData = (apiRunData, isGaps) => {
  if (!apiRunData.length) {
    return [];
  }
  const runAttemptData = apiRunData.map((runDatum) => {
    const runGap =
      isGaps && runDatum?.runGap
        ? RUN_TENDENCY_GAPS.find(
            (gap) => gap.visible && gap.apiGaps.includes(runDatum.runGap)
          )?.name
        : null;
    const poaY = runDatum?.pointOfAttackdY;
    const leftTackle = runDatum?.leftTackle;
    const leftGuard = runDatum?.leftGuard;
    const center = runDatum?.center;
    const rightGuard = runDatum?.rightGuard;
    const rightTackle = runDatum?.rightTackle;
    const ballCarrier = runDatum?.carrier;
    const playIds = runDatum?.playIds;
    return {
      runGap,
      poaY,
      teamId: runDatum.team.id,
      teamName: runDatum.team.name,
      leftTackle,
      leftGuard,
      center,
      rightGuard,
      rightTackle,
      ballCarrier,
      playIds,
      plays: runDatum.plays,
      yardsGainedTotal: runDatum.yardsGained,
      yardsRunTotal: runDatum.yardsRun,
      playsSuccessful: runDatum.successPlays,
      allPos:
        leftTackle?.id &&
        leftGuard?.id &&
        center?.id &&
        rightGuard?.id &&
        rightTackle?.id &&
        ballCarrier?.id,
    };
  });

  return runAttemptData;
};

export const formatAPIRunLeagueAverageData = (apiLeagueAverageData, isGaps) => {
  if (!apiLeagueAverageData.length) {
    return [];
  }
  const runGapData = apiLeagueAverageData.map((runDatum) => {
    const runGap =
      isGaps && runDatum?.runGap
        ? RUN_TENDENCY_GAPS.find(
            (gap) => gap.visible && gap.apiGaps.includes(runDatum.runGap)
          )?.name
        : null;
    const poaY = runDatum?.pointOfAttackdY;
    return {
      runGap,
      poaY,
      plays: runDatum.plays,
      yardsGainedTotal: runDatum.yardsGained,
      playsSuccessful: runDatum.successPlays,
    };
  });
  return runGapData;
};

export const getPlayersLists = (teamRunData) => {
  const leftTackleList =
    teamRunData && getPlayerList(teamRunData, 'leftTackle');
  const leftGuardList = teamRunData && getPlayerList(teamRunData, 'leftGuard');
  const centerList = teamRunData && getPlayerList(teamRunData, 'center');
  const rightGuardList =
    teamRunData && getPlayerList(teamRunData, 'rightGuard');
  const rightTackleList =
    teamRunData && getPlayerList(teamRunData, 'rightTackle');
  const ballCarrierList =
    teamRunData && getPlayerList(teamRunData, 'ballCarrier');

  /* runTendencies.propTypes.playerListShape */
  return {
    [RUN_TENDENCY_POSITION_KEYS.leftTackle]: leftTackleList,
    [RUN_TENDENCY_POSITION_KEYS.leftGuard]: leftGuardList,
    [RUN_TENDENCY_POSITION_KEYS.center]: centerList,
    [RUN_TENDENCY_POSITION_KEYS.rightGuard]: rightGuardList,
    [RUN_TENDENCY_POSITION_KEYS.rightTackle]: rightTackleList,
    [RUN_TENDENCY_POSITION_KEYS.ballCarrier]: ballCarrierList,
  };
};

/* 
Expects each position to be marked up same way: 
  gap and POA formatting routines should provide same structure
*/
export const getSelectedPlayersData = (teamData, selectedPlayers) => {
  if (!teamData?.length > 0) {
    return [];
  }
  return teamData?.filter(
    (f) =>
      (selectedPlayers.leftTackle === 0 ||
        selectedPlayers.leftTackle === f?.leftTackle?.id) &&
      (selectedPlayers.leftGuard === 0 ||
        selectedPlayers.leftGuard === f?.leftGuard?.id) &&
      (selectedPlayers.center === 0 ||
        selectedPlayers.center === f?.center?.id) &&
      (selectedPlayers.rightGuard === 0 ||
        selectedPlayers.rightGuard === f?.rightGuard?.id) &&
      (selectedPlayers.rightTackle === 0 ||
        selectedPlayers.rightTackle === f?.rightTackle?.id) &&
      (selectedPlayers.ballCarrier === 0 ||
        selectedPlayers.ballCarrier === f?.ballCarrier?.id)
  );
};

const getRunAggregates = (runDatumId, runData, totalPlays, leagueDatum) => {
  const plays = sumBy(runData, 'plays');
  const playIds = runData.flatMap((r) => r.playIds);
  const yardsGainedTotal = sumBy(runData, 'yardsGainedTotal');
  const playsSuccessful = sumBy(runData, 'playsSuccessful');
  /* Aggregates */
  const playPercentage = totalPlays ? plays / totalPlays : 0;
  const yardsGained = plays ? yardsGainedTotal / plays : 0;
  const successRate = plays ? playsSuccessful / plays : 0;

  const yardsGainedOverAverage =
    leagueDatum?.yardsGained > 0 ? yardsGained - leagueDatum.yardsGained : 0;
  const successVsAverage = proportionalDifference(
    successRate,
    leagueDatum?.successRate
  );

  return {
    id: runDatumId,
    plays,
    playIds,
    playPercentage,
    yardsGained,
    successRate,
    yardsGainedOverAverage,
    successVsAverage,
  };
};
/*
Generate an array based on the gaps, then for each gap get the relevant data
Ensures a datum per gap, even if the values are all 0
*/
export const getGapData = (runData, totalPlays, leagueGapData) => {
  const gapsData = RUN_TENDENCY_GAPS.filter((g) => g.visible).map((gap) => {
    const gapRunData = runData.filter((r) => r.runGap === gap.name);
    const gapLeagueDatum = leagueGapData?.find(
      (lgDatum) => lgDatum.id === gap.name
    );

    return getRunAggregates(gap.name, gapRunData, totalPlays, gapLeagueDatum);
  });

  return gapsData;
};

/*
Generate an array based on the point of attack domain, then for each gap get the relevant data
Ensures a datum per bucket, even if the values are all 0
*/
export const getPOAData = (runData, totalPlays, leaguePOAData) => {
  const domainSize = POINT_OF_ATTACK_DOMAIN[1] - POINT_OF_ATTACK_DOMAIN[0] + 1;
  const domainBuckets = [...Array(domainSize).keys()].map(
    (j) => j + POINT_OF_ATTACK_DOMAIN[0]
  );

  const poaData = domainBuckets.map((pointOfAttack) => {
    const poaRunData = runData.filter((r) => r.poaY === pointOfAttack);
    const poaLeagueDatum = leaguePOAData?.find(
      (lgDatum) => lgDatum.id === pointOfAttack
    );

    return getRunAggregates(
      pointOfAttack,
      poaRunData,
      totalPlays,
      poaLeagueDatum
    );
  });

  return poaData;
};

/* 
Same logic as for gap totals, but ignoring gap 
Can also be used for POA distro
*/
export const getAverageTotals = (runData, leagueAverageTotals) => {
  const plays = Array.isArray(runData) ? sumBy(runData, 'plays') : 0;
  return getRunAggregates('total', runData, plays, leagueAverageTotals);
};

export const runGapTooltip = (datum, datumName) => {
  const playPercentage = formatValue(
    datum.playPercentage,
    API_STAT_UNITS.PERCENTAGE
  );
  const yardsGained = formatValue(datum.yardsGained, API_STAT_UNITS.YARDS);
  const yardsGainedOverAverage = formatValue(
    datum.yardsGainedOverAverage,
    API_STAT_UNITS.YARDS
  );
  const successRate = formatValue(datum.successRate, API_STAT_UNITS.PERCENTAGE);
  const successVsAverage = formatValue(
    datum.successVsAverage,
    API_STAT_UNITS.PERCENTAGE
  );
  return (
    `${datumName}` +
    `\n${datum.plays} plays (${playPercentage} of filtered plays)` +
    `\n${yardsGained} yards gained` +
    `\n${
      datum.yardsGainedOverAverage >= 0 ? '+' : ''
    }${yardsGainedOverAverage} yards gained ${
      datum.yardsGainedOverAverage >= 0 ? 'over' : 'under'
    } league average` +
    `\n${successRate} Success (${successVsAverage} of league average)`
  );
};

export const getFilterSelectedPlayers = (playersLists, selectedPlayers) => {
  const playerObjects = [
    olGapPositionPlayer(
      selectedPlayers[RUN_TENDENCY_POSITION_KEYS.leftTackle],
      playersLists[RUN_TENDENCY_POSITION_KEYS.leftTackle],
      API_ALIGNMENT_POSITION_KEYS.LEFT_TACKLE
    ),
    olGapPositionPlayer(
      selectedPlayers[RUN_TENDENCY_POSITION_KEYS.leftGuard],
      playersLists[RUN_TENDENCY_POSITION_KEYS.leftGuard],
      API_ALIGNMENT_POSITION_KEYS.LEFT_GUARD
    ),
    olGapPositionPlayer(
      selectedPlayers[RUN_TENDENCY_POSITION_KEYS.center],
      playersLists[RUN_TENDENCY_POSITION_KEYS.center],
      API_ALIGNMENT_POSITION_KEYS.CENTER
    ),
    olGapPositionPlayer(
      selectedPlayers[RUN_TENDENCY_POSITION_KEYS.rightGuard],
      playersLists[RUN_TENDENCY_POSITION_KEYS.rightGuard],
      API_ALIGNMENT_POSITION_KEYS.RIGHT_GUARD
    ),
    olGapPositionPlayer(
      selectedPlayers[RUN_TENDENCY_POSITION_KEYS.rightTackle],
      playersLists[RUN_TENDENCY_POSITION_KEYS.rightTackle],
      API_ALIGNMENT_POSITION_KEYS.RIGHT_TACKLE
    ),
  ].filter(Boolean);
  return playerObjects;
};

export const getRunGapPlays = (runData) => {
  const LeftC = runData
    .filter((runDatum) => runDatum.id === 'Left C')
    .flatMap((runDatum) => runDatum.playIds);
  const LeftB = runData
    .filter((runDatum) => runDatum.id === 'Left B')
    .flatMap((runDatum) => runDatum.playIds);
  const LeftA = runData
    .filter((runDatum) => runDatum.id === 'Left A')
    .flatMap((runDatum) => runDatum.playIds);
  const RightA = runData
    .filter((runDatum) => runDatum.id === 'Right A')
    .flatMap((runDatum) => runDatum.playIds);
  const RightB = runData
    .filter((runDatum) => runDatum.id === 'Right B')
    .flatMap((runDatum) => runDatum.playIds);
  const RightC = runData
    .filter((runDatum) => runDatum.id === 'Right C')
    .flatMap((runDatum) => runDatum.playIds);

  return {
    LeftC,
    LeftB,
    LeftA,
    RightA,
    RightB,
    RightC,
  };
};
