import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { select, pointers } from 'd3';
import { useTheme } from 'styled-components';
import { isEmpty, max, min } from 'lodash';
import { useD3 } from '../../utils/hooks/useD3';
import {
  addLoSField,
  addLoSFieldYRelative,
} from '../../utils/helpers/fieldVariants';
import {
  FIELD_BORDER_PADDING,
  FIELD_X_YARDS,
  FIELD_Y_YARDS,
  FIELD_Y_YARDS_RELATIVEY,
  LOS_FIELD_LOS_X,
  COMPETITION_LEVEL,
  ROTATIONS,
} from '../../utils/constants/charting';
import { PassEndsChartSVG } from './PassingChart.styles';
import {
  SVG_CLASS_NAMES,
  COLORING_OPTION_PASS_SUCCESS,
  COLORING_OPTION_MONOCHROME,
} from './PassingChart.constants';
import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../utils/helpers/field.constants';
import {
  FIELD_X_YARDS_PASSING_FRAME,
  FIELD_X_YARDS_PASSING_PRE_LOS,
} from '../../pages/player/PlayerPassing/PlayerPassing.constants';
import { getRectDimensions } from '../DragSelect/DragSelect.dataManipulation';
import {
  addSVGStructure,
  drawPassDots,
  drawPassPaths,
  renderHeatmap,
  updateSVGStructure,
} from './PassingChart.drawing';
import {
  paddingAndRotation,
  partitionData,
} from './PassingChart.dataManipulation';

const PassingChart = ({
  data,
  loading,
  handleSelectedEvent,
  handleDragSelection,
  selectedEventId,
  selectedPlays: selectedPlaysProp,
  displayHeatMap,
  displayPassPaths,
  displayYPassRelative,
  coloringOption,
  competitionLevel,
  orientation,
  fieldLengthOverride,
  marginOverride,
  isInteractive,
  redrawSelection,
  idSuffix,
}) => {
  /** Basic properties/settings */
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const horizontal = orientation === ROTATIONS.HORIZONTAL;
  const { pxPerYard } = DEFAULT_FIELD_DRAWING_SETTINGS;
  const fieldSizeY = displayYPassRelative
    ? FIELD_Y_YARDS_RELATIVEY * pxPerYard
    : (FIELD_Y_YARDS + FIELD_BORDER_PADDING * 2) * pxPerYard;
  const fieldLengthYds = fieldLengthOverride || {
    X_YARDS: FIELD_X_YARDS_PASSING_FRAME,
    LOS_X: FIELD_X_YARDS_PASSING_PRE_LOS,
  };
  const overrides = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    visPalette,
    orientation,
    horizontal: true,
    competitionLevel,
    Y_BORDERS: 3 + 1 / 3,
    ...fieldLengthYds,
  };
  const LOS_ADJUST = LOS_FIELD_LOS_X * pxPerYard;

  /* Get the selected event if there is one */
  const selectedEvent =
    selectedEventId && !isEmpty(data)
      ? data.find((evt) => evt.id === selectedEventId)
      : null;

  /**
   * Define the pitch dimensions according to mode and orientation
   * Generate all relative transforms and size info
   */
  const margin = {
    bottom: 8,
    left: 8,
    right: 8,
    top: 8,
    ...marginOverride,
  };
  const cropName = `active-field-area-${idSuffix}`;
  const heatmapCropName = `heatmap-selection-${idSuffix}`;

  const fieldSizeX = fieldLengthYds.X_YARDS * pxPerYard;
  const svgFieldContainer = {
    x: horizontal ? fieldSizeX : fieldSizeY,
    y: horizontal ? fieldSizeY : fieldSizeX,
  };

  const { axesPadding, fieldRotation } = paddingAndRotation(
    orientation,
    displayYPassRelative,
    fieldSizeX,
    fieldSizeY
  );
  svgFieldContainer.x += axesPadding.left + axesPadding.right;
  svgFieldContainer.y += axesPadding.top + axesPadding.bottom;
  const marginTransform = `translate(${margin.left},${margin.top})`;
  // eslint-disable-next-line max-len
  const rotationTransform = `rotate(${fieldRotation.angle},${fieldRotation.rotationPoint},${fieldRotation.rotationPoint})`;
  const axesPaddingTransform = `translate(${axesPadding.left},${axesPadding.top})`;
  const losTransform = `translate(${LOS_ADJUST},${
    displayYPassRelative ? 0 : FIELD_BORDER_PADDING * pxPerYard
  })`;

  const viewBox = `0 0 ${margin.left + svgFieldContainer.x + margin.right} ${
    margin.top + svgFieldContainer.y + margin.bottom
  }`;

  const heatmapArea = {
    width: FIELD_X_YARDS * pxPerYard,
    height: displayYPassRelative
      ? FIELD_Y_YARDS_RELATIVEY * pxPerYard
      : (FIELD_BORDER_PADDING * 2 + FIELD_Y_YARDS) * pxPerYard,
    borderShift: displayYPassRelative ? 0 : FIELD_BORDER_PADDING * pxPerYard,
  };

  /**
   * DRAG-ABLE SELECT STUFF
   * Feels like all of this should probably be some kind of component that exposes the
   *  functions for reset/complete/update Marquee -> next progress
   */
  const marqueeId = 'passing-chart-selection-marquee';
  const interacting = useRef(false);
  const originX = useRef(0);
  const originY = useRef(0);
  const passedX = useRef(0);
  const passedY = useRef(0);
  const savedX = useRef(0);
  const savedY = useRef(0);
  const savedWidth = useRef(0);
  const savedHeight = useRef(0);
  // multi select, selected plays
  const selectedPlays = useRef([]);

  const resetMarquee = (svg) => {
    savedWidth.current = 0;
    savedHeight.current = 0;
    savedX.current = 0;
    savedY.current = 0;
    svg
      .select(`#${marqueeId}`)
      .attr('x', savedX.current)
      .attr('y', savedY.current)
      .attr('width', savedWidth.current)
      .attr('height', savedHeight.current);
  };

  const updateMarquee = (svg, mouseEvent) => {
    const p = pointers(mouseEvent);
    const [currentX, currentY] = p[0];
    passedX.current = currentX;
    passedY.current = currentY;

    if (interacting.current) {
      const { rectX, rectY, rectWidth, rectHeight } = getRectDimensions(
        originX.current,
        originY.current,
        passedX.current,
        passedY.current
      );
      savedWidth.current = rectWidth;
      savedHeight.current = rectHeight;
      savedX.current = rectX;
      savedY.current = rectY;
      svg
        .select(`#${marqueeId}`)
        .attr('fill', theme.colours.interface.main)
        .attr('stroke', theme.colours.interface.main)
        .attr('stroke-dasharray', 'none')
        .attr('x', rectX)
        .attr('y', rectY)
        .attr('width', rectWidth)
        .attr('height', rectHeight);
    } else {
      originX.current = passedX.current;
      originY.current = passedY.current;
    }
  };

  const completeMarquee = (svg) => {
    interacting.current = false;
    svg
      .select(`#${marqueeId}`)
      .attr('fill-opacity', 0.5)
      .attr('stroke-dasharray', 3);

    const minX = min([originX.current, passedX.current]);
    const maxX = max([originX.current, passedX.current]);
    const minY = min([originY.current, passedY.current]);
    const maxY = max([originY.current, passedY.current]);

    /** The coordinates above are relative to the frame (regardless of orientation)
     * i.e. due to the pitch transforms, 0,0 for the drag-effects frame of reference is
     * either x=LoS,y=fieldTopBoundary or x=LoS,y=topOfFrame (no boundary)
     *  the coordinates of events in the first mode match (are relative to the same origin)
     *  but are transformed in relative-y mode, so the same transform is used in the filter
     *
     * Possible improvement: do the coordinate transform logic first to all data, then all
     *  drawing functions (+ this) can use a single coordinate system...
     */
    if (displayYPassRelative) {
      const selectedRelativeYPlays = data.filter((d) => {
        const eventX = d.lineOfScrimmageEndX;
        const eventY = d.passDeltaY + (FIELD_Y_YARDS_RELATIVEY / 2) * pxPerYard;
        return (
          eventX >= minX && eventX <= maxX && eventY >= minY && eventY <= maxY
        );
      });
      selectedPlays.current = selectedRelativeYPlays;
      handleDragSelection(selectedPlays.current);
    } else {
      const pitchYPlays = data.filter((d) => {
        const eventX = d.lineOfScrimmageEndX;
        const eventY = d.endY;
        return (
          eventX >= minX && eventX <= maxX && eventY >= minY && eventY <= maxY
        );
      });
      selectedPlays.current = pitchYPlays;
      handleDragSelection(selectedPlays.current);
    }
  };

  /** *******************************
   * RENDER THE CHART
   */

  /** Setup the shell */
  const ref = useD3((svg) => {
    // reset svg
    svg.selectAll('*').remove();
    addSVGStructure(
      svg,
      visPalette,
      marginTransform,
      axesPaddingTransform,
      rotationTransform,
      cropName
    );
  }, []);

  /** Tweak colors based on palette */
  useEffect(() => {
    const svg = select(ref.current);
    svg
      .select(`.${SVG_CLASS_NAMES.BACKGROUND_RECT}`)
      .attr('fill', visPalette?.background.main);

    svg.select(`.${SVG_CLASS_NAMES.FIELD}`).selectAll('g').remove();
    if (displayYPassRelative) {
      svg
        .select(`.${SVG_CLASS_NAMES.FIELD}`)
        .call(addLoSFieldYRelative, overrides);
    } else {
      svg.select(`.${SVG_CLASS_NAMES.FIELD}`).call(addLoSField, overrides);
    }
  }, [theme.isDark]);

  /* Update clip paths and field properties */
  useEffect(
    () => {
      const svg = select(ref.current);
      svg.selectAll('defs').remove();
      const svgDefs = svg.append('defs');
      const clippy = svgDefs.append('clipPath').attr('id', cropName);
      const heatmapClip = svgDefs
        .append('clipPath')
        .attr('id', heatmapCropName);
      clippy
        .append('rect')
        .attr('x', 0)
        .attr('y', 0.5)
        .attr('width', fieldSizeX - 1)
        .attr('height', fieldSizeY - 1);
      heatmapClip
        .append('rect')
        .attr('x', savedX.current)
        .attr('y', savedY.current)
        .attr('width', savedWidth.current)
        .attr('height', savedHeight.current)
        .attr(
          'transform',
          `translate(${LOS_ADJUST},${
            displayYPassRelative ? 0 : FIELD_BORDER_PADDING * pxPerYard - 32
          })`
        );

      /* update any positions if relevant */
      updateSVGStructure(
        svg,
        axesPaddingTransform,
        rotationTransform,
        losTransform
      );
      if (displayYPassRelative) {
        svg
          .select(`.${SVG_CLASS_NAMES.FIELD}`)
          .call(addLoSFieldYRelative, overrides);
      } else {
        svg.select(`.${SVG_CLASS_NAMES.FIELD}`).call(addLoSField, overrides);
      }
    },
    /* has to rely on data else it doesn't draw the heatmap crop correctly */
    [fieldLengthOverride, displayYPassRelative, data, orientation]
  );

  useEffect(() => {
    if (!isEmpty(data)) {
      const svg = select(ref.current);

      /* 
      Reset the chart 
      TODO: add these groups earlier in tree. Instead of wiping can then make them data responsive (animatable)
      */
      const playerDotsZone = svg.select(`.${SVG_CLASS_NAMES.PLAYER_DOTS}`);
      playerDotsZone.selectAll('g').remove();
      const markerZone = playerDotsZone
        .append('g')
        .attr('transform', losTransform);
      const lineOfScrimmageZoneUnselected = playerDotsZone
        .append('g')
        .attr('transform', losTransform)
        .attr('opacity', displayHeatMap ? 0 : 0.2); // hide or fade
      const lineOfScrimmageZone = playerDotsZone
        .append('g')
        .attr('transform', losTransform);

      // wipe any existing heatmap
      svg.select(`.${SVG_CLASS_NAMES.UNDERFIELD}`).selectAll('*').remove();
      if (displayHeatMap) {
        renderHeatmap({
          svg,
          heatmapArea,
          losAdjust: LOS_ADJUST,
          displayYPassRelative,
          data,
          selectedPlays,
          heatmapCropName,
          isDark: theme.isDark,
        });
      }
      /** override dot color in heatmap mode */
      const dotColorOption = displayHeatMap
        ? COLORING_OPTION_MONOCHROME
        : coloringOption;

      /** Partition the data if there are selected events */
      const { focusData, backgroundData } = partitionData(
        data,
        selectedPlaysProp,
        selectedEventId,
        displayHeatMap
      );

      const passDots = drawPassDots({
        eventsG: lineOfScrimmageZone,
        displayYPassRelative,
        data: focusData,
        overrides,
        dotColorOption,
        visPalette,
        radius: displayHeatMap ? 2 : 3,
      });
      const backgroundPassDots = drawPassDots({
        eventsG: lineOfScrimmageZoneUnselected,
        displayYPassRelative,
        data: backgroundData,
        overrides,
        dotColorOption,
        visPalette,
        radius: displayHeatMap ? 2 : 3,
      });
      /** No dot interaction in heatmap mode */
      if (!displayHeatMap && isInteractive) {
        passDots
          .attr('class', isInteractive ? 'dot' : '')
          .on('click', (_, datum) => {
            handleSelectedEvent(datum.id);
            resetMarquee(svg);
          })
          .on('mouseup', () => {
            if (interacting.current) {
              completeMarquee(svg);
            }
          });
        backgroundPassDots
          .attr('class', isInteractive ? 'dot' : '')
          .on('click', (_, datum) => {
            handleSelectedEvent(datum.id);
            resetMarquee(svg);
          })
          .on('mouseup', () => {
            if (interacting.current) {
              completeMarquee(svg);
            }
          });
      }

      if (displayPassPaths && !displayHeatMap) {
        drawPassPaths({
          eventsG: lineOfScrimmageZone,
          displayYPassRelative,
          data: focusData,
          overrides,
          coloringOption,
          visPalette,
        });
        drawPassPaths({
          eventsG: lineOfScrimmageZoneUnselected,
          displayYPassRelative,
          data: backgroundData,
          overrides,
          coloringOption,
          visPalette,
        });
      }

      if (selectedEvent) {
        markerZone
          .append('circle')
          .attr('cx', selectedEvent?.lineOfScrimmageEndX)
          .attr(
            'cy',
            displayYPassRelative
              ? selectedEvent?.passDeltaY +
                  (FIELD_Y_YARDS_RELATIVEY / 2) * overrides.pxPerYard
              : selectedEvent?.endY
          )
          .attr('r', 8)
          .attr('fill', 'transparent')
          .attr('stroke', visPalette.selectedObject)
          .attr('stroke-width', 2);
      }

      const clickableFieldZone = svg.select(
        `.${SVG_CLASS_NAMES.CLICKABLE_FIELD}`
      );
      clickableFieldZone.selectAll('*').remove();
      // add a rect under the dots you can click to remove selection
      clickableFieldZone.append('rect').attr('id', marqueeId);
      svg
        .select(`#${marqueeId}`)
        .attr('fill-opacity', 0.4)
        .attr('fill', 'transparent')
        .attr('stroke', visPalette.contrast)
        .attr('stroke-dasharray', '5 6')
        .attr('stroke-width', 2)
        .attr('x', redrawSelection ? savedX.current : 0)
        .attr('y', redrawSelection ? savedY.current : 0)
        .attr('width', redrawSelection ? savedWidth.current : 0)
        .attr('height', redrawSelection ? savedHeight.current : 0);

      clickableFieldZone
        .append('rect')
        .attr('x', -LOS_FIELD_LOS_X * pxPerYard)
        .attr('y', displayYPassRelative ? 0 : -FIELD_BORDER_PADDING * pxPerYard)
        .attr('width', FIELD_X_YARDS * pxPerYard)
        .attr(
          'height',
          displayYPassRelative
            ? FIELD_Y_YARDS_RELATIVEY * pxPerYard
            : (FIELD_BORDER_PADDING * 2 + FIELD_Y_YARDS) * pxPerYard
        )
        .attr('fill', 'transparent')
        .attr('stroke', 'none')
        .attr('class', isInteractive ? 'drawable' : '')
        .on('click', () => {
          if (isInteractive && !interacting.current) {
            handleSelectedEvent(null);
          }
        })
        .on('mousedown', () => {
          // allow drag if chart is interactive
          if (isInteractive) {
            interacting.current = true;
            handleSelectedEvent(null);
            resetMarquee(svg);
          }
        })
        .on('mousemove', (mouseEvent) => {
          updateMarquee(svg, mouseEvent);
        })
        .on('mouseup', (e) => {
          e.stopPropagation();
          completeMarquee(svg);
        });
    }
  }, [
    data,
    displayHeatMap,
    displayPassPaths,
    displayYPassRelative,
    coloringOption,
    loading,
    orientation,
    theme.isDark,
    selectedEventId,
  ]);

  useEffect(() => {
    selectedPlays.current = selectedPlaysProp;
  }, [selectedPlaysProp]);

  return (
    <PassEndsChartSVG
      $displayHeatMap={displayHeatMap}
      ref={ref}
      viewBox={viewBox}
    />
  );
};

PassingChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  handleSelectedEvent: PropTypes.func,
  handleDragSelection: PropTypes.func,
  selectedEventId: PropTypes.string,
  selectedPlays: PropTypes.arrayOf(PropTypes.string),
  displayHeatMap: PropTypes.bool,
  displayPassPaths: PropTypes.bool,
  displayYPassRelative: PropTypes.bool,
  coloringOption: PropTypes.string,
  competitionLevel: PropTypes.string,
  orientation: PropTypes.string,
  fieldLengthOverride: PropTypes.shape({
    X_YARDS: PropTypes.number,
    LOS_X: PropTypes.number,
  }),
  marginOverride: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    right: PropTypes.number,
    left: PropTypes.number,
  }),
  isInteractive: PropTypes.bool,
  redrawSelection: PropTypes.bool,
  // id to be added to clipMasks etc. to avoid conflict
  idSuffix: PropTypes.string,
};

PassingChart.defaultProps = {
  data: undefined,
  loading: false,
  handleSelectedEvent: () => {},
  handleDragSelection: () => {},
  selectedEventId: null,
  selectedPlays: [],
  displayHeatMap: false,
  displayPassPaths: false,
  displayYPassRelative: false,
  coloringOption: COLORING_OPTION_PASS_SUCCESS.value,
  competitionLevel: COMPETITION_LEVEL.NCAA,
  orientation: ROTATIONS.HORIZONTAL,
  fieldLengthOverride: null,
  marginOverride: {},
  isInteractive: true,
  redrawSelection: true,
  idSuffix: 'passing-chart',
};

export default PassingChart;
