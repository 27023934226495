import { isEmpty, sortBy, sumBy } from 'lodash';
import {
  csDivergent,
  csIntensity,
} from '../../../../utils/helpers/colorScales';
import { HAVOC_TABLE_AGGREGATION_MODE_TYPE } from '../HavocTable.jsx/HavocTable.constants';
import { HAVOC_DEFENDER_ZONES } from '../../../../visualisations/HavocSummary/HavocSummary.constants';
import { safeDivide } from '../../../../utils/helpers/maths';
import { HAVOC_SUMMARY_COLORING_MDOES } from './HavocBars.constants';
import {
  getNormalisedIntensityScaler,
  leagueAverageDivergenceScaler,
} from '../../../../utils/visualisations/gradients';
import { havocEventsToGroupedHavocPlays } from '../HavocPlays.dataManipulation';

/** Turns data from general format (table ready) to shape expected for defender zone chart
 * Color mode needs League Average values (as does title)
 */
export const prepDefenderZoneSummary = ({
  zoneData,
  zoneAverages,
  percentageSetup,
  colorMode,
  isDark,
}) => {
  /* When a zone is selected, what fraction of the total (for zone) is this gap (vs other gaps) */
  const totValue = sumBy(zoneData, percentageSetup.dataKey);

  /* Get normaliser functions for relative intensity, and vs league average */
  const valueTeamFractionScaler = getNormalisedIntensityScaler({
    data: zoneData,
    dataKey: percentageSetup.dataKey,
  });
  const laValueScaler = leagueAverageDivergenceScaler;

  const zonesRaw = sortBy(
    Object.values(HAVOC_DEFENDER_ZONES),
    (s) => s.dotPosition.trunkX
  );
  const zones = zonesRaw.map((dZone, i) => {
    const zoneDatum = zoneData?.find((d) => d?.key === dZone.key);
    const zoneValue = zoneDatum?.[percentageSetup.dataKey] || 0;
    /* Corresponding LA info */
    const laDatum = zoneAverages?.find((g) => g.defenderZoneCode === dZone.key);
    const valueLA = laDatum?.[percentageSetup.dataKey] || 0;
    const vsLAValue = safeDivide(zoneValue, valueLA);

    const title =
      `${dZone.name}` +
      `\n${percentageSetup.label}: ${(zoneValue * 100)?.toFixed(1)}` +
      `\nLeague Average: ${(valueLA * 100)?.toFixed(1)}`;

    const fill =
      colorMode === HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY.value
        ? csIntensity(valueTeamFractionScaler(zoneValue), isDark)
        : csDivergent(laValueScaler(vsLAValue), isDark);

    const valFrac = safeDivide(zoneValue, totValue); // used for Sankey pipes later
    return {
      ...dZone,
      zoneIndex: i,
      rValue: zoneValue,
      title,
      fill,
      stroke: 'transparent',
      valFrac,
      value: zoneValue,
      valueLA,
    };
  });

  return zones;
};
/*
  Converts events into grouped data, and then re-formats that data for bubble chart (scales/colors it)
  If a gaps selection is made, the bubbles have background-mode circles that display the unfiltered values
*/
export const getZoneBubbleData = ({
  havocData,
  zoneAverages,
  playSummary,
  selectedGaps,
  percentageSetup, // HAVOC_SUMMARY_PERCENTAGE_TYPES
  colorMode, // HAVOC_SUMMARY_COLORING_MODES
  isDark,
}) => {
  /* Always have the basic gap data */

  const { groupData: zoneData } = havocEventsToGroupedHavocPlays({
    havocData,
    aggregationMode: HAVOC_TABLE_AGGREGATION_MODE_TYPE.ZONE,
    playSummary,
  });

  const rawBubbleData = prepDefenderZoneSummary({
    zoneData,
    zoneAverages,
    percentageSetup,
    colorMode,
    isDark,
  });

  /* repeat for just the relevant filtered info if selection has been made via defender zone */
  if (!isEmpty(selectedGaps)) {
    const selectedGapData = havocData.filter((d) =>
      selectedGaps.includes(d.exploitedGapAPICode)
    );

    const { groupData: filteredZoneData } = havocEventsToGroupedHavocPlays({
      havocData: selectedGapData,
      aggregationMode: HAVOC_TABLE_AGGREGATION_MODE_TYPE.ZONE,
      playSummary,
    });
    const filteredBubbleData = prepDefenderZoneSummary({
      zoneData: filteredZoneData,
      zoneAverages,
      percentageSetup,
      colorMode,
      isDark,
    }).map((fz) => {
      /* Text under reference bubble needs to be sized to whichever bubble is larger */
      const unfilteredZone = rawBubbleData.find((b) => b.key === fz.key);
      return { ...fz, unfilteredValue: unfilteredZone.value };
    });

    return {
      bubbleData: filteredBubbleData,
      unfilteredBubbleData: rawBubbleData,
    };
  }
  return { bubbleData: rawBubbleData, unfilteredBubbleData: null };
};
