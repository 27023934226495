import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { select } from 'd3';
import { useReactiveVar } from '@apollo/client';
import { useTheme } from 'styled-components';
import { useD3 } from '../../utils/hooks/useD3';
import {
  ROUTE_TREE_CLASSES,
  ROUTE_TREE_FIELD_OVERRIDES,
} from './RouteTree.constants';
import { ClickableSVG } from '../visualisation.styles';
import { RouteSummaryPropType } from './RouteTree.propTypes';
import { addLoSFieldYRelative } from '../../utils/helpers/fieldVariants';
import { mf_LeagueLevel } from '../../apollo';
import { ROTATIONS } from '../../utils/constants/charting';
import {
  addRouteTreeBranches,
  addRouteTreeFruit,
  addRouteTreeLabels,
  addRouteTreeLayers,
  addRouteTreeNodes,
} from './RouteTree.drawing';
import {
  getTreeBranches,
  getTreeFruit,
  getTreeLabels,
} from './RouteTree.dataManipulation';

const RouteTree = ({
  id,
  routesSummary,
  orientation,
  isAnimated,
  handleRouteTreeFruitClick,
  selectedRouteType,
}) => {
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const isDarkMode = theme.isDark;
  const competitionLevel = useReactiveVar(mf_LeagueLevel);

  const isHorizontal = orientation === ROTATIONS.HORIZONTAL;
  const fieldOverrides = {
    ...ROUTE_TREE_FIELD_OVERRIDES,
    visPalette,
    competitionLevel,
    orientation,
  };

  const fieldX = fieldOverrides.X_YARDS * fieldOverrides.pxPerYard;
  const fieldY = fieldOverrides.Y_YARDS * fieldOverrides.pxPerYard;
  const vbWidth = isHorizontal ? fieldX : fieldY;
  const vbHeight = isHorizontal ? fieldY : fieldX;
  const viewBox = `0 0 ${vbWidth} ${vbHeight}`;

  const snapTransform = `translate(${
    fieldOverrides.LOS_X * fieldOverrides.pxPerYard
  },${fieldY / 2})`;

  const onRouteTreeFruitClick = (route) => {
    handleRouteTreeFruitClick(route);
  };

  const ref = useD3((svg) => {
    svg.attr('width', '100%');
    svg.attr('id', id);
    svg.selectAll('*').remove();

    // BACKING RECT FOR THE SVG
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', ROUTE_TREE_CLASSES.BACKGROUND)
      .attr('fill', visPalette.background.main);

    svg.append('g').attr('class', ROUTE_TREE_CLASSES.MAIN);
  }, []);

  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    const backingRect = svg.select(`.${ROUTE_TREE_CLASSES.BACKGROUND}`);
    backingRect.attr('fill', visPalette.background.main).on('click', () => {
      onRouteTreeFruitClick(null);
    });

    const mainG = svg.select(`.${ROUTE_TREE_CLASSES.MAIN}`);
    mainG.selectAll('*').remove();
    /* Setup orientation and zoom stuff */
    addRouteTreeLayers(mainG, { orientation, fieldX, fieldY });

    const fieldMarkingsG = svg.select(`.${ROUTE_TREE_CLASSES.FIELD_GUIDES}`);
    addLoSFieldYRelative(fieldMarkingsG, fieldOverrides);
    fieldMarkingsG.on('click', () => {
      handleRouteTreeFruitClick(null);
    });

    const treeG = svg.select(`.${ROUTE_TREE_CLASSES.TREE}`);
    treeG.attr('transform', snapTransform);
  }, [visPalette, competitionLevel, orientation]);

  useEffect(() => {
    const svg = select(ref.current);

    const treeTrunkG = svg.select(`.${ROUTE_TREE_CLASSES.TREE_TRUNK}`);
    addRouteTreeNodes(treeTrunkG, visPalette, selectedRouteType);

    const leafLabels = getTreeLabels(visPalette, orientation, routesSummary);
    const treeLeafLabelG = svg.select(`.${ROUTE_TREE_CLASSES.TREE_INFO}`);
    addRouteTreeLabels(
      treeLeafLabelG,
      leafLabels,
      visPalette,
      selectedRouteType
    );

    const routeBranchData = getTreeBranches(routesSummary, visPalette);
    const treeBranchG = svg.select(`.${ROUTE_TREE_CLASSES.TREE_BRANCHES}`);
    addRouteTreeBranches(
      treeBranchG,
      routeBranchData,
      isAnimated,
      selectedRouteType
    );

    const routeFruitData = getTreeFruit(routesSummary, visPalette, isDarkMode);
    const treeFruitG = svg.select(`.${ROUTE_TREE_CLASSES.TREE_FRUIT}`);
    addRouteTreeFruit(
      treeFruitG,
      routeFruitData,
      isAnimated,
      onRouteTreeFruitClick,
      selectedRouteType
    );
  }, [isDarkMode, visPalette, orientation, routesSummary, selectedRouteType]);

  return <ClickableSVG ref={ref} />;
};

RouteTree.propTypes = {
  id: PropTypes.string,
  routesSummary: RouteSummaryPropType.isRequired,
  orientation: PropTypes.oneOf(Object.values(ROTATIONS)),
  isAnimated: PropTypes.bool,
  handleRouteTreeFruitClick: PropTypes.func,
  selectedRouteType: PropTypes.string,
};

RouteTree.defaultProps = {
  id: 'route-tree',
  orientation: ROTATIONS.VERTICAL_UP,
  isAnimated: true,
  handleRouteTreeFruitClick: () => {},
  selectedRouteType: null,
};

export default RouteTree;
