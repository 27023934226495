import { styled } from 'styled-components';
import { Search } from 'semantic-ui-react';

export const StyledGlobalSearch = styled.div`
  margin: 0.3125rem 3.25rem 0 auto;

  .search .icon {
    height: 30px;
  }

  .ui {
    top: 2.5px;
    margin-left: auto;

    .input > input {
      min-width: 300px;
      border-radius: 0.125rem;
      background-color: ${({ theme }) =>
        theme.colours.canvas.secondary.main} !important;
      padding-top: 0.3125rem;
    }

    span + span {
      margin-left: 0.5rem;
    }
  }
`;
StyledGlobalSearch.displayName = 'StyledGlobalSearch';

export const StyledSearch = styled(Search)`
  margin-bottom: 0.9375rem;

  input.prompt {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    border: none;
    background-color: ${({ theme }) =>
      theme.colours.layering[0].main} !important;
    color: ${({ theme }) => theme.colours.ink.primary.main} !important;

    :focus {
      background-color: ${({ theme }) => theme.colours.layering[0].main};
    }
  }

  input ~ i.icon {
    color: ${({ theme }) => theme.colours.ink.primary.main};
  }

  .results {
    background-color: ${({ theme }) =>
      theme.colours.canvas.tertiary.main} !important;
    box-shadow: ${({ theme }) =>
      theme.isDark ? 'none' : theme.effects.dropShadow.regular};
    overflow-y: scroll;
    max-height: 300px;
    border: 0 !important;
    border-radius: 0 !important;

    .result {
      height: 1.875rem;
      line-height: 2;
      padding: 0 !important;
      border: 0 !important;
      border-radius: 0 !important;

      &:hover,
      &.active {
        background: ${({ theme }) =>
          theme.colours.canvas.secondary.strong} !important;
        color: ${({ theme }) => theme.colours.ink.primary.main} !important;
      }

      a {
        background: none;
        justify-content: flex-start;
        padding: 0.125rem 0.75rem;
        color: ${({ theme }) => theme.colours.ink.primary.main} !important;
        text-decoration: none;

        &:hover {
          color: ${({ theme }) => theme.colours.ink.primary.main} !important;
        }
      }
    }
  }
`;
StyledSearch.displayName = 'StyledSearch';
