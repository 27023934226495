import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { select } from 'd3';
import { merge, cloneDeep } from 'lodash';
import { useD3 } from '../../utils/hooks/useD3';
import { DEFAULT_VISUALISATION_MARGIN } from '../../utils/constants/charting';
import { marginPropType } from '../../utils/constants/propTypes';
import { ClickableSVG } from '../visualisation.styles';
import { SCATTER_CLASSES, SCATTER_AXES_SETUP } from './ScatterChart.constants';
import { makeScatterDataSafe } from './ScatterChart.dataManipulation';
import {
  addChartCoreLayout,
  applyCoreLayoutTransforms,
  getChartViewbox,
  addExtendedAxes,
  addAxes,
  drawGuides,
} from '../BasicChart/BasicChart.drawing';
import {
  addCanvasCropZone,
  drawScatterData,
  drawAverageLines,
} from './ScatterChart.drawing';
import {
  scatterAverageLine,
  scatterDatumPropTypes,
  scatterSetupPropType,
} from './ScatterChart.propTypes';
import {
  CHART_LAYOUT,
  CHART_LAYOUT_CLASSES,
} from '../BasicChart/BasicChart.constants';
import { getRange, getScale } from '../BasicChart/BasicChart.dataManipulation';
import { basicChartLayoutPropType } from '../BasicChart/BasicChart.propTypes';

const ScatterChart = ({
  chartId,
  scatterData,
  secondSeriesData,
  averageLines,
  margin,
  layoutOverride,
  setupOverride,
  handleBubbleClick,
  selectedBubble,
}) => {
  const { colours, isDark } = useTheme();
  const visPalette = colours.visualisations;
  /* Need deep merging of objects hence not using spreading for overrides */
  const layout = merge(cloneDeep(CHART_LAYOUT), layoutOverride);
  const setup = merge(cloneDeep(SCATTER_AXES_SETUP), setupOverride);
  const viewBox = getChartViewbox(layout, margin);

  const safeScatterData = makeScatterDataSafe(scatterData, visPalette);

  const xRange = getRange(setup.X, layout.CANVAS.WIDTH);
  const xScale = getScale(setup.X, xRange, safeScatterData, 'xValue');

  const yRange = getRange(setup.Y, layout.CANVAS.HEIGHT);
  const yScale = getScale(setup.Y, yRange, safeScatterData, 'yValue');

  const rRange = getRange(setup.R, 10, 1); // 1-10px radius fallback values
  const rScale = getScale(setup.R, rRange, safeScatterData, 'rValue');

  const canvasClipPathId = `${chartId}-canvas-clip-path`;

  /* 
  Permanent elements 
    Create the structure of all parts, then useEffect() based on relevant 
    parameters to update those parts/their children as required
  */
  const ref = useD3((svg) => {
    svg.attr('id', chartId);
    svg.selectAll('*').remove();

    /* Add all elements from basic chart setup */
    addChartCoreLayout(svg, margin);

    /* Add clip path to defs for canvas zone */
    const defs = svg.select(`.${CHART_LAYOUT_CLASSES.DEFS}`);
    defs.append('clipPath').attr('id', canvasClipPathId);

    /* Add Scatter specific data aspects to the canvas */
    const canvasG = svg.select(`.${CHART_LAYOUT_CLASSES.CANVAS}`);
    canvasG
      .append('g')
      .attr('class', SCATTER_CLASSES.AVERAGE_LINES)
      .attr('data-testid', SCATTER_CLASSES.AVERAGE_LINES);
    canvasG
      .append('g')
      .attr('class', SCATTER_CLASSES.SCATTER_DATA)
      .attr('data-testid', SCATTER_CLASSES.SCATTER_DATA);
    canvasG
      .append('g')
      .attr('class', SCATTER_CLASSES.SCATTER_DATA_SECOND_SERIES)
      .attr('data-testid', SCATTER_CLASSES.SCATTER_DATA_SECOND_SERIES);
  }, []);

  /* Shell elements - affected by theme, size and overrides */
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    svg
      .select(`.${CHART_LAYOUT_CLASSES.BACKGROUND}`)
      .attr('fill', visPalette.background.main);

    /* Adjust all axes by relevant transforms */
    applyCoreLayoutTransforms(svg, layout);

    /* If bars are longer than ticks area, add those lines */
    addExtendedAxes(svg, layout, visPalette);

    /* setup the canvas zone */
    addCanvasCropZone(svg, canvasClipPathId, layout);
    svg
      .select(`.${SCATTER_CLASSES.SCATTER_DATA}`)
      .attr('clip-path', `url(#${canvasClipPathId})`);
    svg
      .select(`.${SCATTER_CLASSES.SCATTER_DATA_SECOND_SERIES}`)
      .attr('clip-path', `url(#${canvasClipPathId})`);
  }, [layoutOverride, colours, isDark]);

  /* Changes dependant upon the data */
  useEffect(() => {
    const svg = select(ref.current);

    /* For any axes with labels, render them */
    addAxes(svg, visPalette, layout, setup, xScale, yScale);

    /* If drawing guide lines, add them here */
    drawGuides(svg, layout, setup, xScale, yScale, visPalette);

    /* Draw any league average lines */
    const linesG = svg.select(`.${SCATTER_CLASSES.AVERAGE_LINES}`);
    drawAverageLines(linesG, averageLines, layout, xScale, yScale);

    /* Draw scatter data */
    const canvasG = svg.select(`.${SCATTER_CLASSES.SCATTER_DATA}`);
    drawScatterData(
      canvasG,
      safeScatterData,
      setup,
      xScale,
      yScale,
      rScale,
      handleBubbleClick,
      selectedBubble
    );

    if (secondSeriesData?.length > 0) {
      const safeSecondSeriesData = makeScatterDataSafe(
        secondSeriesData,
        visPalette
      );
      const canvas2G = svg.select(
        `.${SCATTER_CLASSES.SCATTER_DATA_SECOND_SERIES}`
      );
      drawScatterData(
        canvas2G,
        safeSecondSeriesData,
        setup,
        xScale,
        yScale,
        rScale
      );
    }
  }, [
    scatterData,
    secondSeriesData,
    averageLines,
    colours,
    isDark,
    layoutOverride,
    setupOverride,
    selectedBubble,
  ]);

  return <ClickableSVG ref={ref} />;
};

ScatterChart.propTypes = {
  chartId: PropTypes.string,
  scatterData: PropTypes.arrayOf(scatterDatumPropTypes).isRequired,
  secondSeriesData: PropTypes.arrayOf(scatterDatumPropTypes),
  averageLines: PropTypes.arrayOf(scatterAverageLine),
  margin: marginPropType,
  layoutOverride: basicChartLayoutPropType,
  setupOverride: scatterSetupPropType,
  /* Function to handle clicks on the bars */
  handleBubbleClick: PropTypes.func,
  /* Selected bar id key */
  selectedBubble: PropTypes.string,
};

ScatterChart.defaultProps = {
  chartId: 'scatter-chart',
  secondSeriesData: null,
  averageLines: null,
  margin: DEFAULT_VISUALISATION_MARGIN,
  layoutOverride: undefined,
  setupOverride: undefined,
  handleBubbleClick: () => {},
  selectedBubble: null,
};

export default ScatterChart;
