import { gql } from '@apollo/client';

export const GET_GAME_PLAYS = gql`
  query GetGamePlays($competitionId: Int!, $gameId: [Int!]) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedGameId @client @export(as: "gameId")

    plays(
      first: 1000
      filters: {
        competitionId: $competitionId
        gameIds: $gameId
        includeNegated: true
      }
    ) {
      edges {
        node {
          id
          name
          down
          quarter
          driveIndex
          gameIndex
          yardsToGo
          yardLine
          type
          yardsNet
          success
          firstDownWon
          touchdownWon
          pointsWon
          qbHit
          qbSacked
          passYardsAir
          yardsRun
          offensePenaltyAccepted
          defensePenaltyAccepted
          penalties {
            type
            name
          }
          penaltyOffset
          penaltyYards
          penaltyFirstDown
          penaltyDownLoss
          downNegated
          turnoverType
          offensivePersonnel
          defensivePersonnel
          homeScore
          awayScore
          drive {
            id
            name
            offensePoints
            defensePoints
            gameIndex
            playCount
            startQuarter
            quarterClockRemaining
            team {
              id
              name
            }
            startingYards
            yardsNet
          }
          offenseTeam {
            name
          }
          defenseTeam {
            name
          }
          game {
            id
            date
            roundShortName
            homeTeam {
              id
              name
              colorPrimary
              colorSecondary
              colorTertiary
            }
            awayTeam {
              id
              name
              colorPrimary
              colorSecondary
              colorTertiary
            }
            awayScore
            homeScore
          }
        }
      }
    }
  }
`;
