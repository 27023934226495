import React, { useState, useEffect } from 'react';
import { useQuery, gql, useReactiveVar } from '@apollo/client';
import {
  Pagination,
  Slider,
  Toggle,
  Dropdown,
  TextField,
  Grid,
} from '@statsbomb/kitbag-components';
import { mf_Leagues, mf_Seasons } from '../../../apollo';
import { PaginationContainer } from '../League.styles';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  API_PLAYER_AGGREGATE_MODES,
  API_PLAYER_GROUP_BY,
} from '../../../utils/constants/api';
import {
  PLAYER_AGGREGATE_OPTIONS,
  PLAYER_GROUP_BY_OPTIONS,
  PLAYERS_PER_PAGE,
  PLAYER_GROUP_BY_HEADERS_CONFIG,
  PERMANENT_HEADERS_CONFIG,
  PLAYER_GROUP_BY_STICKY_COLUMNS_WIDTH,
  CATEGORY_GENERAL_POSITION_MAP,
  SPECIAL_TEAMS_POSITION_MAP,
  NO_TEAMS_OPTION,
  DEFAULT_SORT_BY,
  DEFAULT_HIGHLIGHTED_COLUMN,
  PHYSICAL_METRICS_MAP,
} from './PlayerStats.constants';
import { ALIGNMENT } from '../../../utils/constants/alignment';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import DownloadCsvAsync from '../../../components/buttons/DownloadCsvAsync/DownloadCsvAsync';
import TabbedTable from '../../../components/Tables/TabbedTable/TabbedTable';
import { getPlayersStats } from './getPlayersStats';
import {
  getStatHeaders,
  formatStatValues,
  orderStats,
  getDownloadColumns,
} from '../../../utils/helpers/stats.dataManipulation';
import Tile from '../../../components/Tile/Tile';
import { addPlayerGroupColumns } from './PlayerStats.dataManipulation';
import useTypingState from '../../../utils/hooks/useTypingState';
import { useGetFullResults } from '../../../utils/hooks/useGetFullResults';
import {
  useGetPlayerStatDefinitions,
  useGetPlayerStatsTeams,
} from './PlayerStats.hooks';
import { useIsNcaa } from '../../../utils/hooks/useIsNcaa';
import { useGetPlayerStatsCategoryDefinitions } from '../../../utils/hooks/useGetPlayerStatCategories';
import PositionFilter from '../../../components/PositionFilter/PositionFilter';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { KitbagPageGridHolder } from '../../../components/Layout/Layout.styles';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';

const { ASCENDING, DESCENDING } = SORT_DIRECTIONS;
const { TEAM, PLAYER } = API_PLAYER_GROUP_BY;

const PlayerStats = () => {
  const leagueId = useReactiveVar(mf_Leagues);
  const seasonId = useReactiveVar(mf_Seasons);
  const isNcaa = useIsNcaa();
  const [aggMode, setAggMode] = useQueryString(
    'agg',
    PLAYER_AGGREGATE_OPTIONS[1].value
  );
  const { seasonName } = useExportSeasonInfo({
    asObject: true,
  });
  const [groupByModes, setGroupByModes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useQueryString(
    'category',
    ''
  );
  const [selectedSubcategory, setSelectedSubcategory] = useQueryString(
    'subcategory',
    ''
  );
  const [onlyTransferPortal, setOnlyTransferPortal] = useQueryString(
    'portal',
    false
  );
  // TODO: make a function that sets the default min snaps dynamically depending on season progress
  const [minPlays, setMinPlays] = useQueryString('snaps', 0);
  const [selectedTeam, setSelectedTeam] = useQueryString(
    'team',
    NO_TEAMS_OPTION.value
  );
  const [minimumSnaps, setMinimumSnaps] = useState(minPlays);
  const [isTyping, setIsTyping, searchValue, setSearchValue] = useTypingState();
  const [sortDirection, setSortDirection] = useState(DESCENDING);
  const [sortBy, setSortBy] = useState(DEFAULT_SORT_BY);
  const [highlightedColumn, setHighlightedColumn] = useState(
    DEFAULT_HIGHLIGHTED_COLUMN
  );
  const [activePage, setActivePage] = useQueryString('page', 1);
  const [selectedRosterPositions, setSelectedRosterPositions] = useState([]);

  const { categoryDefinitions, isLoading: isLoadingCategoryDefinitions } =
    useGetPlayerStatsCategoryDefinitions();
  const { teamsLoading, teamsData } = useGetPlayerStatsTeams();

  // get all unique categories
  const categories =
    categoryDefinitions?.map((category) => category.name) || [];

  const selectedCategoryDefinition = categoryDefinitions?.find(
    (category) => category.name === selectedCategory
  );

  // get all subcategories of the selected category
  const subcategories = selectedCategoryDefinition?.subcategories.map(
    (subcategory) => subcategory.name
  );

  // get an ordered list of stats of the selected category/subcategory combination
  const orderedSubcategoryStats =
    selectedCategoryDefinition?.subcategories.find(
      (subcategory) => subcategory.name === selectedSubcategory
    )?.stats;

  const isRateMode = aggMode === API_PLAYER_AGGREGATE_MODES.RATE;
  const { statDefinitions, isLoadingStatDefinitions } =
    useGetPlayerStatDefinitions(orderedSubcategoryStats, isRateMode);

  // set a default category if none is selected yet
  useEffect(() => {
    if (!selectedCategory && categories.length) {
      setSelectedCategory(categories[0]);
    }
  }, [isLoadingCategoryDefinitions]);

  const applyRosterPosition = (positions) => {
    setSelectedRosterPositions(positions);
    setActivePage(1);
  };

  // set a default roster position filter based on selected category
  // in the case of special teams, default roster position is based on selected subcategory
  const isSpecialTeams = selectedCategory === 'Special Teams';
  const isPhysical = selectedCategory === 'Physical';
  useEffect(() => {
    if (selectedCategory && selectedSubcategory) {
      if (
        isSpecialTeams &&
        Object.keys(SPECIAL_TEAMS_POSITION_MAP).includes(selectedSubcategory)
      ) {
        const subcatDefaultRosterPositions =
          SPECIAL_TEAMS_POSITION_MAP[selectedSubcategory];
        applyRosterPosition(subcatDefaultRosterPositions);
      } else if (
        isPhysical &&
        Object.keys(PHYSICAL_METRICS_MAP).includes(selectedSubcategory)
      ) {
        const subcatDefaultRosterPositions =
          PHYSICAL_METRICS_MAP[selectedSubcategory];
        applyRosterPosition(subcatDefaultRosterPositions);
      } else {
        const catDefaultRosterPositions =
          CATEGORY_GENERAL_POSITION_MAP[selectedCategory];
        applyRosterPosition(catDefaultRosterPositions);
      }
    }
  }, [selectedCategory, selectedSubcategory]);

  // set default subcategory once the category has been set
  useEffect(() => {
    if (subcategories?.length) {
      setSelectedSubcategory(subcategories[0]);
    }
  }, [selectedCategory]);

  const handleSearch = (e) => {
    if (activePage !== 1) {
      setActivePage(1);
    }
    setSearchValue(e.target.value);
    setIsTyping(true);
  };

  const groupByMode = [PLAYER, TEAM, ...groupByModes.map((mode) => mode.value)];
  const teamIds = selectedTeam === NO_TEAMS_OPTION.value ? [] : [selectedTeam];

  const sharedVariables = {
    aggMode,
    groupByMode,
    playerName: searchValue,
    minPlays,
    // apply transfer portal flag only when on NCAA
    ncaaTransferPortal: isNcaa && onlyTransferPortal,
    rosterPositions: selectedRosterPositions,
    teamIds,
    // first order by selected stat then by player name
    orderBy: [
      {
        name: sortBy,
        descending: sortDirection === DESCENDING,
      },
      {
        name: 'playerName',
        descending: false,
      },
    ],
  };

  const playerStatsQuery = getPlayersStats(statDefinitions);

  // query to get paginated data
  const { data: playerStatsData, loading: isLoadingPlayerStats } = useQuery(
    gql(playerStatsQuery),
    {
      skip: !statDefinitions?.length || !sortBy || isTyping,
      variables: {
        ...sharedVariables,
        // pagination related query variables
        limit: PLAYERS_PER_PAGE,
        offset: (activePage - 1) * PLAYERS_PER_PAGE,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const playerStats = addPlayerGroupColumns(playerStatsData?.playerStats);

  // insert additional group by column if selected
  const groupByColumns = groupByModes.length
    ? groupByModes.map((mode) => PLAYER_GROUP_BY_HEADERS_CONFIG[mode.value])
    : [];

  const headers = [
    ...PERMANENT_HEADERS_CONFIG,
    ...groupByColumns,
    ...getStatHeaders(statDefinitions),
  ];

  const headerIds = headers.map((header) => header.id);

  const formattedStats = formatStatValues(
    playerStats,
    headerIds,
    statDefinitions,
    aggMode
  );

  const orderedStats = orderStats(formattedStats, headerIds);

  const { totalCount } = playerStatsData?.playerStats || {};

  const { data, loading, start, reset } = useGetFullResults(
    gql(playerStatsQuery),
    sharedVariables,
    totalCount,
    'data.playerStats.items'
  );

  const getOverviewLink = (groups) => {
    const teamId = groups.find(({ key }) => key === TEAM).value;
    const playerId = groups.find(({ key }) => key === PLAYER).value;
    return `/player/overview/${leagueId}/${seasonId}/${teamId}/${playerId}`;
  };

  // convert the stats to objects so we can pass extra context like "title" and "link"
  const enrichedStats = orderedStats?.map((stat, index) =>
    Object.keys(stat).reduce(
      (acc, key) => ({
        ...acc,
        [key]: {
          label: stat[key],
          title: key === 'teamShortName' ? playerStats[index].teamName : '',
          link:
            key === 'playerName'
              ? getOverviewLink(playerStats[index].groups)
              : '',
        },
      }),
      {}
    )
  );

  const stickyColumns = [
    { index: 1, width: 2 },
    { index: 2, width: 8.125 },
    { index: 3, width: 3.75 },
    { index: 4, width: 3.75 },
    { index: 5, width: 3.75 },
    ...groupByModes.map((mode, index) => ({
      index: index + 1 + PERMANENT_HEADERS_CONFIG.length,
      width: PLAYER_GROUP_BY_STICKY_COLUMNS_WIDTH[mode.value],
    })),
  ];

  const playerStatsConfig = categories?.map((category) => ({
    id: category,
    label: category,
    tableConfig: {
      headers,
      rows: enrichedStats,
      alignment: (index) =>
        stickyColumns.length > index && index !== 0 && index !== 4
          ? ALIGNMENT.LEFT
          : ALIGNMENT.RIGHT,
      stickyColumns,
    },
  }));

  const downloadColumns = getDownloadColumns(headers);

  const handleSort = (statName) => {
    if (statName === sortBy) {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      setSortDirection(DESCENDING);
      setSortBy(statName);
      setHighlightedColumn(headerIds.indexOf(statName) + 1);
    }
    setActivePage(1);
  };

  const handleTabChange = (e, tab, isSubTab) => {
    const setTab = isSubTab ? setSelectedSubcategory : setSelectedCategory;
    setTab(tab?.label);
    setSortBy(DEFAULT_SORT_BY);
    setActivePage(1);
    setHighlightedColumn(DEFAULT_HIGHLIGHTED_COLUMN);

    if (!isSubTab) {
      setSelectedSubcategory('');
    }

    // set minimum snaps filter to 0 when the Special Teams category is selected
    if (!isSubTab && tab.label !== selectedCategory) {
      setMinPlays(tab.label === 'Special Teams' ? 0 : 100);
      setMinimumSnaps(tab.label === 'Special Teams' ? 0 : 100);
    }
  };

  const handleAggModeChange = (selectedOption) => {
    setAggMode(selectedOption.value);
    setSortBy(DEFAULT_SORT_BY);
    setHighlightedColumn(DEFAULT_HIGHLIGHTED_COLUMN);
  };

  const isLoading =
    isLoadingPlayerStats ||
    isLoadingStatDefinitions ||
    isLoadingCategoryDefinitions ||
    isTyping;

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={10}>
          <PageHeader
            href="/league/stats/players/:leagues/:seasons/"
            rootPage="League"
            activePage="Player Stats"
            showPlayerName={false}
            showTeamName={false}
          >
            <div className="buttons">
              <TextField
                id="player-stats-search-filter"
                labelPosition="none"
                value={searchValue}
                onChange={handleSearch}
                adornmentIcon="Search"
                adornmentOnClick={() => {}}
                placeholder="Search players"
                size="small"
              />
              <DownloadCsvAsync
                onClick={start}
                reset={reset}
                data={data}
                headers={downloadColumns}
                fileName={`Player Stats ${seasonName}.csv`}
                disabled={isLoadingPlayerStats}
                loading={loading}
              />
            </div>
          </PageHeader>
          <Tile border="0" margin="0.5rem 0">
            <Tile.Body $padding="0">
              {selectedSubcategory && (
                <TabbedTable
                  tableTabsConfig={playerStatsConfig}
                  isLoading={isLoading}
                  onTabChange={(e, tab) => handleTabChange(e, tab, false)}
                  subTabs={subcategories}
                  selectedSubTab={selectedSubcategory}
                  handleSort={handleSort}
                  onSubTabChange={(e, tab) => handleTabChange(e, tab, true)}
                  sortDirection={sortDirection}
                  sortBy={sortBy || statDefinitions?.[0]?.name}
                  withBorder
                  isHeadSticky
                  highlightedColumn={highlightedColumn}
                  nonTableHeight="21rem"
                />
              )}
              {enrichedStats ? (
                <PaginationContainer>
                  <Pagination
                    activePage={activePage}
                    onChange={({ pageSelected }) => setActivePage(pageSelected)}
                    itemsPerPage={PLAYERS_PER_PAGE}
                    dataLength={totalCount}
                    hasEdgePageButtons
                  />
                </PaginationContainer>
              ) : null}
            </Tile.Body>
          </Tile>
        </Grid>
        <Grid item xs={2}>
          <Tile border="0" margin="0 0 0.5rem 0">
            <Tile.Body>
              <Dropdown
                options={teamsData}
                label="Filter by team"
                isLoading={teamsLoading}
                value={teamsData?.find((t) => t.value === selectedTeam)}
                id="player-stats-team-filter"
                onChange={(selectedOption) =>
                  setSelectedTeam(selectedOption.value)
                }
                isDisabled={teamsLoading}
              />
              <Dropdown
                options={PLAYER_AGGREGATE_OPTIONS}
                label="View values aggregated"
                value={PLAYER_AGGREGATE_OPTIONS.find(
                  (f) => f.value === aggMode
                )}
                id="player-stats-aggregate-filter"
                onChange={handleAggModeChange}
              />
              <Dropdown
                options={PLAYER_GROUP_BY_OPTIONS}
                label="Break player stats down by"
                value={groupByModes}
                id="player-stats-group-by-filter"
                onChange={(selectedOptions) => setGroupByModes(selectedOptions)}
                isMulti
              />
              {isNcaa && (
                <Toggle
                  onChange={() => setOnlyTransferPortal(!onlyTransferPortal)}
                  checked={onlyTransferPortal}
                  label="NCAA Transfer Portal"
                  id="player-stats-transfer-portal-filter"
                />
              )}
            </Tile.Body>
          </Tile>
          <Tile border="0" margin="0 0 0.5rem 0">
            <Tile.Body>
              <div>
                <Slider
                  ariaLabel="minimum-snaps-filter"
                  max={200}
                  min={0}
                  step={10}
                  title="Minimum Total Plays"
                  value={minimumSnaps}
                  onAfterChange={setMinPlays}
                  onChange={setMinimumSnaps}
                  reverse
                />
              </div>
            </Tile.Body>
          </Tile>
          <Tile border="0" margin="0">
            <Tile.Body>
              <PositionFilter
                selectedPositions={selectedRosterPositions}
                setSelectedPositions={applyRosterPosition}
                isRosterPositions
              />
            </Tile.Body>
          </Tile>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default PlayerStats;
