import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { useReactiveVar } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { Dropdown, Toggle, Grid, Button } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { isEmpty } from 'lodash';
import BannerError from '../../../components/Banners/Banner.Error';
import {
  mf_LeagueLevel,
  mf_PlayerDetails,
  mf_TeamDetails,
} from '../../../apollo';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  COLORING_OPTIONS_TEAM,
  COLORING_OPTION_PASS_SUCCESS,
  GRAPH_OPTIONS,
  GRAPH_OPTION_HEATMAP,
  GRAPH_OPTION_PASS_PATHS,
  INFO_DISPLAY_TABLE,
  INFO_DISPLAY_VIS,
} from '../../../visualisations/PassingChart/PassingChart.constants';
import Tile from '../../../components/Tile/Tile';
import {
  getPassers,
  getPassOutcomeFilter,
  getTargets,
  getTargetsFilter,
  getPassersFilter,
  getCountTotal,
  getTargetAlignmentFilter,
  addColorToPlayer,
} from './DataManipulation';
import { ROTATIONS, ROTATION_OPTIONS } from '../../../utils/constants/charting';
import { getSelectedEventDetails, passInfoItems } from './PassInfo';
import TableTile from '../../../components/TableTile/TableTile';
import KeyButton from '../../../components/buttons/DisplayKey/DisplayKey';
import Dimmer from '../../../components/Dimmer/Dimmer';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import {
  FixedAside,
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import { MemoizedVideoTile } from '../../../components/VideoTile/VideoTile';
import { USER_ROLES, VIDEO_JWT_CLAIMS } from '../../../utils/constants/auth';
import PassingKey from './PassingKey/PassingKey';
import {
  PASS_OUTCOME_OPTIONS,
  PASS_OUTCOME_UNSELECTED,
  TARGET_ALIGNMENT_OPTIONS,
  TARGET_ANY_ALIGNMENT,
} from './PlayerPassing.constants';
import PlaylistButton from '../../../components/buttons/Playlist/Playlist';
import {
  PASS_COUNT_HEADERS_SELECTED,
  PASS_COUNT_HEADERS_UNSELECTED,
  PLAYER_SELECTION_HEADERS,
} from './PassEndsPage.constants';
import {
  dataTransformFunction,
  formatPassingVideoData,
  getCurrentPlayData,
} from './PlayerPassing.dataManipulation';
import PassSelectedEvent from './PassSelectedEvent';
import PassPlayerTable from './PassPlayerTable';
import PassingChart from '../../../visualisations/PassingChart/PassingChart';

const PassEndsPage = ({
  formattedData,
  isError: hasError,
  loading: isLoading,
  downloadNameRoot,
  dataFilters,
  breadcrumb,
  showPasses,
}) => {
  // resize layout
  const windowWidth = useWindowWidth();
  const { pathname } = useLocation();
  const hasLoaded = useRef(false);
  const isPlayerChart = pathname.includes('player');
  const { competitionSeason: seasonInfo, seasonName } = useExportSeasonInfo({
    asObject: true,
  });
  const competitionLevel = useReactiveVar(mf_LeagueLevel);
  const player = useReactiveVar(mf_PlayerDetails);
  const team = useReactiveVar(mf_TeamDetails);
  const { user } = useKitbagAuth();
  const title = isPlayerChart ? player?.name : team?.name;
  const info1 = isPlayerChart ? team?.name : seasonInfo;
  const info2 = isPlayerChart ? seasonInfo : '';
  const [displayYPassRelative, setDisplayYPassRelative] = useQueryString(
    'yPass',
    false
  );
  const [graphOption, setGraphOption] = useQueryString(
    'graph',
    GRAPH_OPTIONS[0].value
  );
  const [coloringOption, setColoringOption] = useQueryString(
    'coloring',
    COLORING_OPTION_PASS_SUCCESS.value
  );
  // video Auth
  const hasVideoAuth = user[VIDEO_JWT_CLAIMS]?.roles.includes(
    USER_ROLES.VIDEO_CUSTOMER
  );
  // player colours for highlighting
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  /* 
  TODO: have killed the control but some logic for presenting in the chart still exists
  */
  const infoDisplayOption = INFO_DISPLAY_TABLE.value;
  const [selectedRotation, setSelectedRotation] = useQueryString(
    'rotation',
    ROTATIONS.HORIZONTAL
  );
  const [keyFilter, setKeyFilter] = useQueryString(
    'keyFilter',
    PASS_OUTCOME_UNSELECTED.value
  );
  const [displayKey, setDisplayKey] = useQueryString('key', true);
  // single play/event
  const [selectedEventId, setSelectedEventId] = useQueryString('event', null);
  // multiple plays/events
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [videoOpen, setVideoOpen] = useState(false);
  const [sidebarWidthCalc, setSidebarWidthCalc] = useState();
  const videoActive = useRef(false);
  // table headers
  const [passCountHeaders, setPassCountHeaders] = useState(
    PASS_COUNT_HEADERS_UNSELECTED
  );
  // targets table
  const [targetData, setTargetData] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState([]);
  // passer table
  const [passerData, setPasserData] = useState([]);
  const [selectedPassers, setSelectedPassers] = useState([]);
  // target alignment
  const [targetAlignment, setTargetAlignment] = useQueryString(
    'talign',
    TARGET_ANY_ALIGNMENT
  );

  const handleYPassRelativePress = () => {
    setDisplayYPassRelative(!displayYPassRelative);
  };

  const handleDisplayKey = () => {
    setDisplayKey(!displayKey);
  };

  const passers = formattedData && getPassers(formattedData);
  const targets = formattedData && getTargets(formattedData);

  const chartData =
    formattedData &&
    formattedData.filter(
      (f) =>
        getPassOutcomeFilter(f, keyFilter) &&
        getTargetsFilter(f, selectedTargets) &&
        getTargetAlignmentFilter(f, targetAlignment) &&
        getPassersFilter(f, selectedPassers)
    );
  const colouredChartData = addColorToPlayer(
    chartData,
    coloringOption,
    passers,
    targets,
    visPalette
  );
  const passCountData =
    chartData &&
    passInfoItems(
      chartData,
      selectedEvents.length > 0 ? selectedEvents : null,
      COLORING_OPTION_PASS_SUCCESS.value,
      null,
      passers,
      true,
      false,
      false,
      false
    );

  const passAveragesData =
    chartData &&
    passInfoItems(
      chartData,
      selectedEvents.length > 0 ? selectedEvents : null,
      COLORING_OPTION_PASS_SUCCESS.value,
      null,
      passers,
      false,
      true,
      false,
      false
    );

  const playCountData =
    chartData &&
    passInfoItems(
      chartData,
      selectedEvents.length > 0 ? selectedEvents : null,
      COLORING_OPTION_PASS_SUCCESS.value,
      null,
      passers,
      false,
      false,
      true,
      false
    );

  // on load: set the data for the tables
  useEffect(() => {
    if (chartData && !hasLoaded.current) {
      hasLoaded.current = true;

      if (targetData.length === 0) {
        setTargetData(
          passInfoItems(
            chartData,
            null,
            COLORING_OPTION_PASS_SUCCESS.value,
            null,
            targets,
            false,
            false,
            false,
            true
          )
        );
      }
      if (passerData.length === 0) {
        setPasserData(
          passInfoItems(
            chartData,
            null,
            COLORING_OPTION_PASS_SUCCESS.value,
            null,
            passers,
            false,
            false,
            false,
            true
          )
        );
      }
    }
  }, [chartData]);

  useEffect(() => {
    setSelectedTargets(() =>
      targetData.filter((f) => f.checked).map((m) => m.id)
    );
  }, [targetData]);

  useEffect(() => {
    setSelectedPassers(() =>
      passerData.filter((f) => f.checked).map((m) => m.id)
    );
  }, [passerData]);

  useEffect(() => {
    setPasserData(
      passInfoItems(
        chartData,
        null,
        COLORING_OPTION_PASS_SUCCESS.value,
        null,
        passers,
        false,
        false,
        false,
        true
      )
    );
    setTargetData(
      passInfoItems(
        chartData,
        null,
        COLORING_OPTION_PASS_SUCCESS.value,
        null,
        targets,
        false,
        false,
        false,
        true
      )
    );
  }, [showPasses]);

  // update tables
  useEffect(() => {
    if (chartData) {
      if (selectedEvents.length > 0) {
        const newHeaders = PASS_COUNT_HEADERS_SELECTED;
        newHeaders[1].label = `Selection (${selectedEvents.length})`;
        setPassCountHeaders(newHeaders);
      } else {
        setPassCountHeaders(PASS_COUNT_HEADERS_UNSELECTED);
      }
    }
  }, [selectedEvents]);

  const exportMargin = { top: 0, bottom: 0, right: 0, left: 0 };
  const selectedEventObj =
    chartData && chartData.find((f) => f.id === selectedEventId);
  const isVideoVisible = (() => !!selectedEventObj || videoOpen)();
  const isDragActive = selectedEvents.length > 0;
  const expansionPercentage = hasVideoAuth ? 0.5 : 0.7;

  useEffect(() => {
    // set sidebar:
    // 50% if video tile is visible with video Auth
    // 70% if item selected (video tile visible) without video Auth
    // 80% if video tile is not visible
    setSidebarWidthCalc(
      windowWidth - (isVideoVisible ? expansionPercentage : 0.8) * windowWidth
    );
  }, [windowWidth, isVideoVisible]);

  // passers table callback
  const onSelectedPassersRow = (rows) => {
    setSelectedPassers(rows.filter((f) => f.checked).map((m) => m.id));
    setPasserData(rows);
    // any interaction clear selection marquee
    setSelectedEvents([]);
  };

  // targets table callback
  const onSelectedTargetRow = (rows) => {
    setSelectedTargets(rows.filter((f) => f.checked).map((m) => m.id));
    setTargetData(rows);
    // any interaction clear selection marquee
    setSelectedEvents([]);
  };

  const selectedPassersPasses = isEmpty(passerData)
    ? 0
    : getCountTotal(passerData);
  const selectedTargetsPasses = isEmpty(targetData)
    ? 0
    : getCountTotal(targetData);
  const allPasses = formattedData?.length || 0;

  // multiple selection callback
  const onChartDrag = (eventsData) => {
    // if player is open and we have no events (empty part of chart clicked), close the player
    if (eventsData.length === 0 && videoOpen) {
      setVideoOpen(false);
    } else if (eventsData.length >= 1) {
      // user drags over one or multiple events
      setSelectedEvents(eventsData);
    }
  };

  // single selection callback
  const onSelectedEvent = (evtId) => {
    if (evtId) {
      setSelectedEventId(evtId);
      // clear multiple selects
      setSelectedEvents([]);
      setVideoOpen(true);
    } else {
      setSelectedEventId(null);
      setSelectedEvents([]);
      setVideoOpen(false);
    }
  };

  // clear single and multiple selections, close the video panel
  const clearSelection = () => {
    setSelectedEvents([]);
    setSelectedEventId(null);
  };

  // callback from video player playlist hook
  const onPlaylistChange = (currentlyPlayingPlay) => {
    // if multiple select state
    if (hasVideoAuth && selectedEvents.length > 0) {
      setSelectedEventId(
        chartData.find((event) => event.play.id === currentlyPlayingPlay?.id)
          ?.id
      );
    }
  };

  // clear single and multiple selections, close the video panel
  const clearSelectionCloseVideo = () => {
    videoActive.current = false;
    clearSelection();
    setVideoOpen(false);
  };

  // returns array of event objects
  const getVideoTileData = () => {
    // if the user has a individual selected event
    if (selectedEventObj?.play?.id && selectedEvents.length === 0) {
      return formatPassingVideoData([selectedEventObj], visPalette);
    }
    // if the users clicks play all (typically on first load) without any active drag or selection
    if (
      !selectedEventObj &&
      selectedEvents.length === 0 &&
      videoActive.current
    ) {
      return formatPassingVideoData(chartData, visPalette);
    }
    // return the events within a marquee selection
    if (selectedEvents.length > 0) {
      return formatPassingVideoData(selectedEvents, visPalette);
    }

    return [];
  };

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <SidebarRightLayout
            $sidebarWidth={`${sidebarWidthCalc}px`}
            $inlineSize={`${isVideoVisible ? 40 : 16}%`}
            $gap="0.5rem"
            $padding="0 0.25rem 0 0"
          >
            <div>
              {breadcrumb}
              <Tile border="0" margin="0.5rem 0 0 0">
                <Tile.Header style={{ padding: '1rem', minHeight: '3.5rem' }}>
                  <div className="buttons">
                    {isDragActive && selectedEvents.length > 1 && (
                      <Button
                        onClick={clearSelectionCloseVideo}
                        variant="secondary"
                        size="small"
                        title="Clear Selection"
                      >
                        Clear Selection
                      </Button>
                    )}
                    <PlaylistButton
                      handleClick={() => {
                        if (selectedEvents.length === 0) {
                          videoActive.current = true;
                        }
                        setVideoOpen(true);
                      }}
                      numberOfPlays={
                        selectedEvents.length > 0
                          ? selectedEvents.length
                          : chartData?.length
                      }
                      isDisabled={
                        chartData?.length === 0 || isLoading || isVideoVisible
                      }
                      variant={isDragActive ? 'primary' : 'secondary'}
                    />

                    <KeyButton
                      showKey={displayKey}
                      handleShowKey={handleDisplayKey}
                    />
                    <ExportModal
                      title={title}
                      secondaryTitle="Passing"
                      info1={info1}
                      info2={info2}
                      fileName={`${downloadNameRoot} Pass Completion Chart ${seasonName}`}
                      isDisabled={isLoading}
                    >
                      <PassingChart
                        data={colouredChartData}
                        loading={isLoading}
                        summaryMode={false}
                        showInfo={infoDisplayOption === INFO_DISPLAY_VIS.value}
                        displayHeatMap={graphOption === GRAPH_OPTION_HEATMAP}
                        displayPassPaths={
                          graphOption === GRAPH_OPTION_PASS_PATHS
                        }
                        displayYPassRelative={displayYPassRelative}
                        coloringOption={coloringOption}
                        competitionLevel={competitionLevel}
                        orientation={selectedRotation}
                        marginOverride={exportMargin}
                        redrawSelection={false}
                        idSuffix="export"
                      />
                      {displayKey && (
                        <PassingKey
                          coloringOption={coloringOption}
                          displayHeatMap={graphOption === GRAPH_OPTION_HEATMAP}
                          chartId="export-passing-key"
                          passers={passers}
                          targets={targets}
                        />
                      )}
                    </ExportModal>
                  </div>
                </Tile.Header>
                <Tile.Body
                  $padding={
                    isVideoVisible ? '0 0.5rem 1rem 1rem' : '0 1.5rem 1rem 1rem'
                  }
                >
                  <Grid>
                    <Grid item lg={isVideoVisible ? 12 : 9}>
                      {hasError && !isLoading && chartData?.length === 0 && (
                        <Dimmer active>
                          <BannerError message=" There has been an error. Please contact support." />
                        </Dimmer>
                      )}
                      {isLoading && (
                        <Dimmer active style={{ minHeight: '30vh' }}>
                          <Loader content="Loading Data" />
                        </Dimmer>
                      )}
                      {!hasError && !isLoading && chartData?.length === 0 && (
                        <Dimmer style={{ height: '90%' }}>
                          <p>No Data available</p>
                        </Dimmer>
                      )}
                      <PassingChart
                        data={colouredChartData}
                        loading={isLoading}
                        summaryMode={false}
                        showInfo={infoDisplayOption === INFO_DISPLAY_VIS.value}
                        handleSelectedEvent={onSelectedEvent}
                        handleDragSelection={onChartDrag}
                        selectedPlays={selectedEvents?.map((e) => e?.play?.id)}
                        selectedEventId={selectedEventId}
                        displayHeatMap={graphOption === GRAPH_OPTION_HEATMAP}
                        displayPassPaths={
                          graphOption === GRAPH_OPTION_PASS_PATHS
                        }
                        displayYPassRelative={displayYPassRelative}
                        coloringOption={coloringOption}
                        competitionLevel={competitionLevel}
                        orientation={selectedRotation}
                        marginOverride={{
                          top: 0,
                          left: 0,
                          right: 8,
                          bottom: 8,
                        }}
                        redrawSelection={isDragActive}
                        idSuffix="main"
                      />
                      {displayKey && (
                        <div
                          style={{
                            padding: isVideoVisible ? '0 0 1rem 0' : '0',
                          }}
                        >
                          <PassingKey
                            coloringOption={coloringOption}
                            displayHeatMap={
                              graphOption === GRAPH_OPTION_HEATMAP
                            }
                            passers={passers}
                            targets={targets}
                          />
                        </div>
                      )}
                    </Grid>
                    {!isLoading &&
                      infoDisplayOption === INFO_DISPLAY_TABLE.value && (
                        <Grid
                          item={!isVideoVisible}
                          lg={isVideoVisible ? 12 : 3}
                          xs={12}
                        >
                          <Grid
                            item={isVideoVisible}
                            xs={isVideoVisible ? 6 : 12}
                          >
                            <TableTile
                              data={passCountData?.filter(
                                (evt) => evt.label !== 'Total Passes'
                              )}
                              tileTitle="Pass Completion Summary"
                              columnHeaders={passCountHeaders}
                              showColumnHeader
                              error={hasError}
                              loading={isLoading}
                              fileName={`${downloadNameRoot} Pass Completion Summary ${seasonName}.csv`}
                              withBorder
                              padding={isVideoVisible ? '0 0.4rem 0 0' : '0'}
                              margin={isVideoVisible ? '0' : '0 0 1.25rem 0'}
                              highlightedColumn={
                                selectedEvents.length > 0 ? 2 : 0
                              }
                              footer={passCountData?.filter(
                                (evt) => evt.label === 'Total Passes'
                              )}
                            />
                          </Grid>

                          <Grid
                            item={isVideoVisible}
                            xs={isVideoVisible ? 6 : 12}
                          >
                            <TableTile
                              data={passAveragesData}
                              tileTitle="Pass Distance and Speed"
                              columnHeaders={passCountHeaders}
                              showColumnHeader
                              error={hasError}
                              loading={isLoading}
                              fileName={`${downloadNameRoot} Pass Distance and Speed ${seasonName}.csv`}
                              withBorder
                              padding={
                                isVideoVisible ? '0 1.6rem 0 0.4rem' : '0'
                              }
                              margin={isVideoVisible ? '0' : '0 0 1.25rem 0'}
                              highlightedColumn={
                                selectedEvents.length > 0 ? 2 : 0
                              }
                            />
                          </Grid>
                          <Grid
                            item={isVideoVisible}
                            xs={isVideoVisible ? 6 : 12}
                          >
                            <TableTile
                              data={playCountData}
                              tileTitle="Play Summary"
                              columnHeaders={passCountHeaders}
                              showColumnHeader
                              error={hasError}
                              loading={isLoading}
                              fileName={`${downloadNameRoot} Play Summary ${seasonName}.csv`}
                              withBorder
                              padding={isVideoVisible ? '0 0 0 0.4rem' : '0'}
                              margin={isVideoVisible ? '0' : '0 0 1.2rem 0'}
                              highlightedColumn={
                                selectedEvents.length > 0 ? 2 : 0
                              }
                            />
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                </Tile.Body>
              </Tile>
            </div>
            <div>
              <FixedAside
                $width={`${sidebarWidthCalc + 16}px`}
                style={{ marginLeft: 0, paddingLeft: 0 }}
              >
                {!hasError && !isLoading && (
                  <>
                    {isVideoVisible && (
                      <>
                        <MemoizedVideoTile
                          title={
                            getCurrentPlayData(
                              getSelectedEventDetails(selectedEventObj)
                            )?.title
                          }
                          subTitle={
                            getCurrentPlayData(
                              getSelectedEventDetails(selectedEventObj)
                            )?.game
                          }
                          data={getVideoTileData()}
                          // eslint-disable-next-line max-len
                          gotoLink={`/game/animation/:leagues/:seasons/:teams/${selectedEventObj?.game?.id}/${selectedEventObj?.play?.drive?.id}/${selectedEventObj?.play?.id}`}
                          isGotoDisabled={
                            !selectedEventObj?.game?.id ||
                            !selectedEventObj?.play?.drive?.id ||
                            !selectedEventObj?.play?.id
                          }
                          handlePlaylistChange={onPlaylistChange}
                          fileName={`${downloadNameRoot} Pass Completion Chart`}
                          selectedPlayId={selectedEventObj?.play?.id}
                          handleClose={clearSelectionCloseVideo}
                          dataTransformFunction={dataTransformFunction}
                        >
                          <PassSelectedEvent
                            data={getCurrentPlayData(
                              getSelectedEventDetails(selectedEventObj)
                            )}
                          />
                        </MemoizedVideoTile>
                      </>
                    )}

                    {!isVideoVisible && (
                      <>
                        <div style={{ marginBottom: '0.5625rem' }}>
                          <AccordionTile
                            id="settings-accordion"
                            header={
                              <Tile.AccordionHeader>
                                Settings
                              </Tile.AccordionHeader>
                            }
                            body={
                              <Tile.AccordionBody
                                $padding="0.5rem 0.5rem 1rem 1rem"
                                style={{ gap: '0.5rem' }}
                              >
                                <Grid item xs={12}>
                                  {dataFilters}
                                </Grid>
                                <Grid item xs={12}>
                                  <Toggle
                                    id="display-y-pass-relative-toggle"
                                    label="Display Relative to Pass Origin"
                                    onChange={handleYPassRelativePress}
                                    checked={displayYPassRelative}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Dropdown
                                    id="info-display-dropdown"
                                    options={GRAPH_OPTIONS}
                                    label="Graph Options"
                                    onChange={(selectedOption) => {
                                      setGraphOption(selectedOption.value);
                                      clearSelectionCloseVideo();
                                    }}
                                    value={GRAPH_OPTIONS.find(
                                      (item) => item.value === graphOption
                                    )}
                                    isSearchable={false}
                                    menuPosition="static"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Dropdown
                                    id="coloring-dropdown"
                                    options={COLORING_OPTIONS_TEAM}
                                    label="Coloring"
                                    onChange={(selectedOption) => {
                                      setColoringOption(selectedOption.value);
                                      clearSelectionCloseVideo();
                                    }}
                                    value={COLORING_OPTIONS_TEAM.find(
                                      (item) => item.value === coloringOption
                                    )}
                                    isSearchable={false}
                                    menuPosition="static"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Dropdown
                                    id="field-rotation-dropdown"
                                    options={ROTATION_OPTIONS}
                                    label="Field Rotation"
                                    onChange={(selectedOption) =>
                                      setSelectedRotation(selectedOption.value)
                                    }
                                    value={ROTATION_OPTIONS.find(
                                      (f) => f.value === selectedRotation
                                    )}
                                    isSearchable={false}
                                    menuPosition="static"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Dropdown
                                    id="pass-attribute-dropdown"
                                    options={PASS_OUTCOME_OPTIONS}
                                    label="Pass Outcome Displayed"
                                    onChange={(selectedOption) => {
                                      const newValue =
                                        keyFilter === selectedOption.value
                                          ? ''
                                          : selectedOption.value;
                                      setKeyFilter(newValue);
                                      clearSelectionCloseVideo();
                                    }}
                                    value={PASS_OUTCOME_OPTIONS.find(
                                      (item) => item.value === keyFilter
                                    )}
                                    isSearchable={false}
                                    menuPosition="static"
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Dropdown
                                    id="target-alignment-dropdown"
                                    options={TARGET_ALIGNMENT_OPTIONS}
                                    label="Target Alignment Position"
                                    onChange={(selectedOption) => {
                                      setTargetAlignment(selectedOption.value);
                                    }}
                                    value={TARGET_ALIGNMENT_OPTIONS.find(
                                      (item) => item.value === targetAlignment
                                    )}
                                    isSearchable={false}
                                    menuPosition="static"
                                  />
                                </Grid>
                              </Tile.AccordionBody>
                            }
                            isExpandedDefault
                            saveState
                          />
                        </div>

                        <AccordionTile
                          id="player-accordion"
                          header={
                            <Tile.AccordionHeader>
                              Player Selection
                            </Tile.AccordionHeader>
                          }
                          body={
                            <Tile.AccordionBody $padding="0.5rem 1rem 1rem 1rem">
                              <PassPlayerTable
                                data={passerData}
                                title={`Passers (${selectedPassersPasses} / ${allPasses} selected)`}
                                columnHeaders={PLAYER_SELECTION_HEADERS}
                                hasError={hasError}
                                isLoading={isLoading}
                                fileName={`${downloadNameRoot} Passer Count Summary ${seasonName}.csv`}
                                onSelected={onSelectedPassersRow}
                              />

                              <PassPlayerTable
                                data={targetData}
                                title={`Targets (${selectedTargetsPasses} / ${allPasses} selected)`}
                                columnHeaders={PLAYER_SELECTION_HEADERS}
                                hasError={hasError}
                                isLoading={isLoading}
                                fileName={`${downloadNameRoot} Target Count Summary ${seasonName}.csv`}
                                onSelected={onSelectedTargetRow}
                              />
                            </Tile.AccordionBody>
                          }
                          isExpandedDefault
                          saveState
                        />
                      </>
                    )}
                  </>
                )}
              </FixedAside>
            </div>
          </SidebarRightLayout>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

PassEndsPage.propTypes = {
  formattedData: PropTypes.arrayOf(PropTypes.shape({})),
  isError: PropTypes.bool,
  loading: PropTypes.bool,
  downloadNameRoot: PropTypes.string,
  dataFilters: PropTypes.element,
  breadcrumb: PropTypes.element,
  showPasses: PropTypes.bool,
};

PassEndsPage.defaultProps = {
  formattedData: null,
  isError: false,
  loading: false,
  downloadNameRoot: 'download of passing vis',
  dataFilters: <></>,
  breadcrumb: <></>,
  showPasses: {},
};

export default PassEndsPage;
