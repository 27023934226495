import { styled } from 'styled-components';
import { HAVOC_GAP_BARS_SIZE_LIMITS } from './HavocBars/HavocBars.constants';

export const HavocMainPage = styled.div`
  display: block;

  .havoc-no-video,
  .havoc-non-video {
    display: block;
    width: 100%;
  }
  .havoc-non-video {
    @media (min-width: 1028px) {
      width: 65%;
      float: right;
    }

    @media (min-width: 1200px) {
      width: 55%;
      float: right;
    }

    @media (min-width: 1440px) {
      width: 50%;
      float: right;
    }
  }
`;
HavocMainPage.displayName = 'HavocMainPage';

export const HavocSummaryChartHolder = styled.div`
  min-width: ${HAVOC_GAP_BARS_SIZE_LIMITS.MIN_VIEWBOX_WIDTH}px;
  max-width: ${HAVOC_GAP_BARS_SIZE_LIMITS.MAX_VIEWBOX_WIDTH}px;
  svg {
    margin-bottom: 0.25rem;
  }
`;
HavocSummaryChartHolder.displayName = 'HavocSummaryChartHolder';

export const HavocSummaryKeyHolder = styled.div`
  width: 250px;
`;
HavocSummaryKeyHolder.displayName = 'HavocSummaryKeyHolder';

export const HavocEventsChartHolder = styled.div`
  min-width: ${HAVOC_GAP_BARS_SIZE_LIMITS.MIN_VIEWBOX_WIDTH}px;
  max-width: ${HAVOC_GAP_BARS_SIZE_LIMITS.MAX_VIEWBOX_WIDTH}px;
  svg {
    margin-bottom: 0.25rem;
  }
`;
HavocEventsChartHolder.displayName = 'HavocEventsChartHolder';
