import { HEADER_HEIGHT } from '../Header/Header.styles';

export const PRODUCTION = 'production';

export const PAGE = {
  DEFAULT: {
    top: `${HEADER_HEIGHT}`,
  },
  WITH_CONTEXT_FILTERS: {
    top: '6.45rem',
  },
};
