import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { contourDensity, geoPath, scaleLinear, select } from 'd3';
import { useReactiveVar } from '@apollo/client';
import { isEmpty, maxBy, partition } from 'lodash';
import { useTheme } from 'styled-components';
import { useD3 } from '../../utils/hooks/useD3';
import {
  addLoSField,
  addLoSFieldYRelative,
} from '../../utils/helpers/fieldVariants';
import {
  FIELD_BORDER_PADDING,
  FIELD_Y_YARDS_RELATIVEY,
  ROTATIONS,
  FIELD_Y_YARDS,
  VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS,
} from '../../utils/constants/charting';

import { DEFAULT_FIELD_DRAWING_SETTINGS } from '../../utils/helpers/field.constants';
import { mf_LeagueLevel } from '../../apollo';
import { rotateScaleZoom } from '../../utils/visualisations/rotateScaleZoom';
import {
  HAVOC_AXES_PADDING,
  HAVOC_CLASSES,
  HAVOC_COLOR_MODE_HEATMAP,
  HAVOC_COLOR_MODE_LOCATIONS,
  HAVOC_FOCUS_MODE_BOX,
  HAVOC_Y_MODE_FIELD,
} from './HavocChart.constants';
import {
  getFieldSize,
  getRotationSettings,
  prepHavocData,
  formatHeatmapData,
  addPlayerColor,
} from './HavocChart.dataManipulation';
import { drawHavocEvents } from './HavocChart.drawing';
import { csHeatmap } from '../../utils/helpers/colorScales';
import { ClickableSVG } from '../visualisation.styles';
import { havocChartDataPropType } from './HavocChart.propTypes';
import { getSelectionLayerBits } from '../../utils/visualisations/AreaSelect';

/*  
  svg
    |-within margins
      |-field zone (right of / under distros)
        |-field axes
        |-field area
      |-key zone (under all the rest)
  */
const HavocChart = ({
  id,
  data,
  orientation,
  displayYMode,
  colorMode,
  fieldFocusMode,
  players,
  showPaths,
  selectedEvents,
  setSelectedEvents,
  isFixedSize,
  isInteractive,
  nowPlayingPlayUUID,
}) => {
  const isFieldYRelative = displayYMode !== HAVOC_Y_MODE_FIELD.value;
  const { pxPerYard } = DEFAULT_FIELD_DRAWING_SETTINGS;

  const fieldArea = getFieldSize(orientation, isFieldYRelative, fieldFocusMode);
  const vbWidth =
    fieldArea.width + HAVOC_AXES_PADDING.left + HAVOC_AXES_PADDING.right;
  const vbHeight =
    fieldArea.height + HAVOC_AXES_PADDING.top + HAVOC_AXES_PADDING.bottom;
  const viewBox = `0 0 ${vbWidth} ${vbHeight}`;
  const svgWidth = isFixedSize ? vbWidth : '100%';

  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const isDarkMode = theme.isDark;
  const competitionLevel = useReactiveVar(mf_LeagueLevel);

  const playersWithColors = addPlayerColor(players, visPalette);

  const overrides = {
    ...DEFAULT_FIELD_DRAWING_SETTINGS,
    visPalette,
    orientation,
    competitionLevel,
    Y_BORDERS: 3 + 1 / 3,
    X_YARDS: fieldArea.fieldXYds,
    LOS_X: fieldArea.fieldLoS,
    hideYGuides: false, // displayYMode === TACKLE_LOCATION_Y_MODE_DY.value,
  };

  const fieldDataClipPath = `${id}-active-field-area`;
  const marqueeId = 'havoc-chart-selection-marquee';

  const havocData = prepHavocData(data, displayYMode);
  const [bufferMarquee, setBufferMarquee] = useState(false);
  const handleDragSelection = (minX, maxX, minY, maxY) => {
    const selectedData = havocData.filter(
      (d) => d.x >= minX && d.x <= maxX && d.y >= minY && d.y <= maxY
    );
    const selectedEventUUIDs = selectedData?.map((d) => d.eventUUID);
    setBufferMarquee(true);
    setSelectedEvents(selectedEventUUIDs);
  };

  const {
    resetMarquee,
    updateMarquee,
    completeMarquee,
    drawCompleteMarquee,
    deselectObject,
    beginDrag,
  } = getSelectionLayerBits(marqueeId, handleDragSelection, setSelectedEvents);

  const ref = useD3((svg) => {
    svg.selectAll('g').remove();
    svg.selectAll('rect').remove();

    // BACKING RECT FOR THE SVG
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', HAVOC_CLASSES.BACKGROUND)
      .attr('fill', visPalette.background.main);

    svg.append('g').attr('class', HAVOC_CLASSES.IN_MARGINS); // margin fixed 0 so no transform

    /* Add logic so clicking anywhere deselects */
    svg.on('click', (e) => {
      if (
        e.target.classList.value !== VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS
      ) {
        setSelectedEvents([]);
      }
    });
  }, []);

  useEffect(() => {
    const svg = select(ref.current);
    const backingRect = svg.select(`.${HAVOC_CLASSES.BACKGROUND}`);
    backingRect.attr('fill', visPalette.background.main);
  }, [visPalette]);

  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);
    svg.attr('width', svgWidth);

    /* Define based on size of field a clip-path for the data */
    svg.selectAll('defs').remove();
    const svgDefs = svg.append('defs');
    svgDefs
      .append('clipPath')
      .attr('id', fieldDataClipPath)
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', fieldArea.fieldSizeX - 1)
      .attr('height', fieldArea.fieldSizeY - 1);

    /* Primary G just sits in the margins */
    const withinMargingsG = svg.select(`.${HAVOC_CLASSES.IN_MARGINS}`);
    withinMargingsG.selectAll('g').remove();
    withinMargingsG.selectAll('rect').remove();

    const fieldZoneG = withinMargingsG
      .append('g')
      .attr('class', HAVOC_CLASSES.FIELD_ZONE);

    /* 
      Adjust for Axes depending on orientation
      LoS field expects to put numbers (axes) outside the field proper
    */
    const axesTransform = `translate(${HAVOC_AXES_PADDING.left},${HAVOC_AXES_PADDING.top})`;
    const mainFieldG = fieldZoneG
      .append('g')
      .attr('class', HAVOC_CLASSES.FIELD_IN_AXES)
      .attr('transform', axesTransform);

    /* Setup orientation and zoom stuff */
    const rszSettings = {
      baseG: mainFieldG,
      ...getRotationSettings(
        fieldArea.fieldSizeX,
        fieldArea.fieldSizeY,
        orientation
      ),
      cropToViewport: false,
    };
    const rszG = rotateScaleZoom(rszSettings);

    /* Add the relevant field & markings */
    const fieldMarkingsG = rszG
      .append('g')
      .attr('class', HAVOC_CLASSES.FIELD_MARKINGS);
    if (isFieldYRelative) {
      fieldMarkingsG.call(addLoSFieldYRelative, overrides);
    } else {
      fieldMarkingsG.call(addLoSField, overrides);
    }
    /* Layer for the data */
    rszG.append('g').attr('class', HAVOC_CLASSES.FIELD_DATA);
  }, [orientation, displayYMode, fieldFocusMode, visPalette, isFixedSize]);

  useEffect(() => {
    const svg = select(ref.current);
    if (bufferMarquee) {
      setBufferMarquee(false);
    } else {
      resetMarquee(svg);
    }
  }, [selectedEvents]);

  useEffect(() => {
    const svg = select(ref.current);
    const dataG = svg.select(`.${HAVOC_CLASSES.FIELD_DATA}`);
    dataG.selectAll('g').remove();

    if (!isEmpty(havocData)) {
      /* Field Stuff */
      // const allPlayers = (ballCarriers || []).concat(tacklers || []);
      // const playersWithColor = addPlayerColor(allPlayers, visPalette);

      const clippedDataG = dataG
        .append('g')
        .attr('clip-path', `url(#${fieldDataClipPath})`);

      /* 
      Origin depends on coordinate system
      In Snap relative-y, it's the mid point, in real-field you just shift for the border
      */
      const heatmapG = clippedDataG.append('g');
      const tackleDataTransform = `translate(${
        fieldArea.fieldLoS * pxPerYard
      },${
        isFieldYRelative
          ? (FIELD_Y_YARDS_RELATIVEY * pxPerYard) / 2
          : FIELD_BORDER_PADDING * pxPerYard
      })`;
      const tackleDataG = clippedDataG
        .append('g')
        .attr('transform', tackleDataTransform);
      const draggableAreaG = tackleDataG.append('g');
      const havocDataAreaNonSelectedG = tackleDataG
        .append('g')
        .attr('opacity', 0.4);
      const havocDataAreaG = tackleDataG.append('g');

      if (colorMode !== HAVOC_COLOR_MODE_HEATMAP.value) {
        const [selectedHavoc, unselectedHavoc] = partition(
          havocData,
          (hEvent) =>
            isEmpty(selectedEvents) || selectedEvents.includes(hEvent.eventUUID)
        );

        if (!isEmpty(unselectedHavoc)) {
          drawHavocEvents({
            svgG: havocDataAreaNonSelectedG,
            havocData: unselectedHavoc,
            orientation,
            visPalette,
            colorMode,
            playersWithColors,
            showPaths,
            setSelectedEvents,
            isInteractive,
            resetMarquee: () => resetMarquee(svg),
            completeMarquee: () => completeMarquee(svg),
          });
        }

        const nowPlayingData = selectedHavoc?.filter((h) =>
          nowPlayingPlayUUID?.includes(h.playUUID)
        );
        drawHavocEvents({
          svgG: havocDataAreaG,
          havocData: selectedHavoc,
          orientation,
          visPalette,
          colorMode,
          playersWithColors,
          showPaths,
          setSelectedEvents,
          isInteractive,
          resetMarquee: () => resetMarquee(svg),
          completeMarquee: () => completeMarquee(svg),
          nowPlayingData,
        });

        if (isInteractive) {
          /** draggable stuff
           * marquee rect shows current selection
           * other rect sits under the dots and registers drawing
           */
          draggableAreaG.append('rect').attr('id', marqueeId);
          drawCompleteMarquee(svg);
          draggableAreaG
            .append('rect')
            .attr('x', -fieldArea.fieldLoS * pxPerYard)
            .attr('y', (fieldArea.fieldSizeY / -2) * pxPerYard)
            .attr('width', fieldArea.fieldSizeX * pxPerYard)
            .attr('height', fieldArea.fieldSizeY * pxPerYard)
            .attr('fill', 'transparent')
            .attr('stroke', 'none')
            .attr('class', isInteractive ? 'drawable' : '')
            .on('click', () => {
              deselectObject();
            })
            .on('mousedown', () => {
              beginDrag(svg);
            })
            .on('mousemove', (mouseEvent) => {
              updateMarquee(svg, mouseEvent);
            })
            .on('mouseup', (e) => {
              e.stopPropagation();
              completeMarquee(svg);
            });
        }
      } else {
        /* Heatmap has 3 area: background, the actual heatmap, then redraw guides atop that */
        const heatmapBackgroundG = heatmapG.append('g');
        const heatmapAreaG = heatmapG.append('g');
        const heatmapGuidesG = heatmapG.append('g');

        heatmapBackgroundG
          .append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('width', fieldArea.fieldSizeX)
          .attr('height', fieldArea.fieldSizeY)
          .attr('fill', csHeatmap(0, isDarkMode));

        if (displayYMode === HAVOC_Y_MODE_FIELD.value) {
          heatmapAreaG.attr(
            'transform',
            `translate(0,${FIELD_BORDER_PADDING * pxPerYard})`
          );
        }
        const heatmapData = formatHeatmapData(
          havocData,
          displayYMode,
          fieldArea
        );
        const xDomain = [0, fieldArea.fieldXYds];
        const xRange = [0, fieldArea.fieldSizeX];
        const yDomain =
          displayYMode === HAVOC_Y_MODE_FIELD.value
            ? [0, FIELD_Y_YARDS]
            : [0, FIELD_Y_YARDS_RELATIVEY];
        const yRange = [0, yDomain[1] * pxPerYard];
        /* compute the density data */
        const heatmapXScale = scaleLinear().domain(xDomain).range(xRange);
        const heatmapYScale = scaleLinear().domain(yDomain).range(yRange);
        const densityData = contourDensity()
          .x(({ heatmapX }) => heatmapXScale(heatmapX))
          .y(({ heatmapY }) => heatmapYScale(heatmapY))
          .size([xRange[1], yRange[1]])
          .bandwidth(30)(heatmapData);
        const maxDensity = (maxBy(densityData, 'value') || {}).value;
        heatmapAreaG
          .selectAll('path')
          .data(densityData)
          .enter()
          .append('path')
          .attr('d', geoPath())
          .attr('fill', ({ value }) =>
            csHeatmap(value / maxDensity, isDarkMode)
          ); // magma colors

        /* Heatmap sits on top of all guides, so re-layer them in */
        const heatmapVisPalette = { ...visPalette };
        heatmapVisPalette.zones.default = 'transparent';
        heatmapVisPalette.zones.alternate = 'transparent';
        const heatmapOverrides = {
          ...overrides,
          visPalette: heatmapVisPalette,
        };
        if (isFieldYRelative) {
          heatmapGuidesG.call(addLoSFieldYRelative, heatmapOverrides);
        } else {
          heatmapGuidesG.call(addLoSField, heatmapOverrides);
        }
      }
    }
  }, [
    data,
    colorMode,
    displayYMode,
    visPalette,
    fieldFocusMode,
    players,
    isFixedSize,
  ]);

  return <ClickableSVG ref={ref} id={id} />;
};

HavocChart.propTypes = {
  id: PropTypes.string,
  data: havocChartDataPropType,
  orientation: PropTypes.string,
  displayYMode: PropTypes.string,
  colorMode: PropTypes.string,
  fieldFocusMode: PropTypes.string,
  players: PropTypes.arrayOf(
    PropTypes.shape({
      playerId: PropTypes.number,
      playerName: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  showPaths: PropTypes.bool,
  selectedEvents: PropTypes.arrayOf(PropTypes.string),
  setSelectedEvents: PropTypes.func,
  // in export mode, fix the size of the field vis independently of the key
  isFixedSize: PropTypes.bool,
  // isInteractive: can you click on anything?
  isInteractive: PropTypes.bool,
  nowPlayingPlayUUID: PropTypes.string,
};

HavocChart.defaultProps = {
  id: 'havoc-chart',
  data: undefined,
  orientation: ROTATIONS.VERTICAL_UP,
  displayYMode: HAVOC_Y_MODE_FIELD.value,
  colorMode: HAVOC_COLOR_MODE_LOCATIONS.value,
  fieldFocusMode: HAVOC_FOCUS_MODE_BOX.value,
  players: null,
  showPaths: PropTypes.false,
  selectedEvents: null,
  setSelectedEvents: () => {},
  isFixedSize: false,
  isInteractive: true,
  nowPlayingPlayUUID: null,
};

export default HavocChart;
