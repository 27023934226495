import React, { useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';
import { Message, Loader } from 'semantic-ui-react';
import {
  Dropdown,
  Toggle,
  ButtonIcon,
  ButtonGroup,
  Grid,
} from '@statsbomb/kitbag-components';
import Tile from '../../../components/Tile/Tile';
import useQueryString from '../../../utils/hooks/useQueryString';
import { GET_PASS_PLACEMENT_EVENTS } from './getPassPlacementEvents';
import { GET_CATCH_PLACEMENT_EVENTS } from './getCatchPlacementEvents';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { mf_PlayerDetails, mf_TeamDetails } from '../../../apollo';
import {
  StyledPlayerPassPlacement,
  PassPlacementChartContainer,
} from './PlayerPassPlacement.styles';
import PassPlacement from '../../../visualisations/PassPlacement/PassPlacement';
import FieldEvent from '../../../visualisations/FieldEvent/FieldEvent';
import {
  PASS_PLACEMENT_COLOR_OPTIONS,
  PASS_PLACEMENT_COLOR_MODES,
  PASS_PLACEMENT_SCALES,
  PASS_PLACEMENT_SCALE_DEFAULTS,
  PASS_PLACEMENT_SCALE_OPTIONS,
} from '../../../visualisations/PassPlacement/PassPlacement.constants';
import KeyButton from '../../../components/buttons/DisplayKey/DisplayKey';
import {
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import PageHeader from '../../../components/PageHeader/PageHeader';

const PlayerPassPlacement = () => {
  const [showCatcher, setShowCatcher] = useQueryString('catcher', true);
  const [showFailsRing, setShowFailsRing] = useQueryString('fails', true);
  const [showBallSize, setShowBallSize] = useQueryString('ball', true);
  const [aspectRatio, setAspectRatio] = useQueryString(
    'aspect',
    PASS_PLACEMENT_SCALE_DEFAULTS.ASPECT_RATIO
  );
  const [xZoomIndex, setXZoomIndex] = useQueryString(
    'xzoom',
    PASS_PLACEMENT_SCALE_DEFAULTS.DEFAULT_PLUS_MINUS_X_INDEX
  );
  const [displayKey, setDisplayKey] = useQueryString('key', true);
  const [selectedPlay, setSelectedPlay] = useState(null);

  const [colorMode, setColorMode] = useQueryString(
    'mode',
    PASS_PLACEMENT_COLOR_MODES.PROXIMITY
  );
  const mf_PlayerDetailsRV = useReactiveVar(mf_PlayerDetails);
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const [showPasses, setShowPasses] = useQueryString(
    'show',
    mf_PlayerDetailsRV?.mostCommonPosition?.code === undefined ||
      mf_PlayerDetailsRV?.mostCommonPosition?.code === 'QB'
  );

  const {
    loading: loadingPasses,
    error: errorPasses,
    data: dataPasses,
  } = useQuery(GET_PASS_PLACEMENT_EVENTS);
  const {
    loading: loadingCatches,
    error: errorCatches,
    data: dataCatches,
  } = useQuery(GET_CATCH_PLACEMENT_EVENTS);

  const loading = showPasses ? loadingPasses : loadingCatches;
  const error = showPasses ? errorPasses : errorCatches;
  const data = showPasses ? dataPasses : dataCatches;

  const playerName = mf_PlayerDetailsRV?.name;
  const teamName = mf_TeamDetailsRV?.name;
  const {competitionSeason:info2, seasonName} = useExportSeasonInfo({ asObject: true });

  const handleShowCatcher = () => {
    setShowCatcher(!showCatcher);
  };

  const handleShowFailsRing = () => {
    setShowFailsRing(!showFailsRing);
  };

  const handleBallSize = () => {
    setShowBallSize(!showBallSize);
  };

  const resetZoom = () => {
    setXZoomIndex(
      PASS_PLACEMENT_SCALES.find((r) => r.ASPECT_RATIO === aspectRatio)
        .DEFAULT_PLUS_MINUS_X_INDEX
    );
  };

  const handleAspectRatioChange = (option) => {
    setAspectRatio(option.value);
    setXZoomIndex(
      PASS_PLACEMENT_SCALES.find((r) => r.ASPECT_RATIO === option.value)
        .DEFAULT_PLUS_MINUS_X_INDEX
    );
  };

  const selectedAspectRatio = PASS_PLACEMENT_SCALES.find(
    (r) => r.ASPECT_RATIO === aspectRatio
  );

  const decreaseScale = () => {
    if (xZoomIndex < selectedAspectRatio.PLUS_MINUS_X_OPTIONS.length - 1) {
      setXZoomIndex(xZoomIndex + 1);
    }
  };
  const increaseScale = () => {
    if (xZoomIndex > 0) {
      setXZoomIndex(xZoomIndex - 1);
    }
  };

  const handleDisplayKey = () => {
    setDisplayKey(!displayKey);
  };

  const plusMinusX = selectedAspectRatio.PLUS_MINUS_X_OPTIONS[xZoomIndex];
  return (
    <StyledPlayerPassPlacement>
      <KitbagPageGridHolder>
        <Grid container={false} page>
          <Grid item xs={12}>
            <SidebarRightLayout
              $sidebarWidth="300px"
              $gap="0.5rem"
              $padding="0"
            >
              <div>
                <PageHeader
                  href="/player/overview/:leagues/:seasons/:teams/:players"
                  rootPage="Player"
                  activePage="Pass Placement"
                />
                <Tile border="0" margin="0.5rem 0 0 0">
                  <Tile.Header>
                    <div className="buttons">
                      <ButtonGroup>
                        <ButtonIcon
                          id="zoom-button-out"
                          onClick={decreaseScale}
                          variant="secondary"
                          icon="ZoomOut"
                          size="small"
                          title="zoom out"
                        />
                        <ButtonIcon
                          id="zoom-button-in"
                          onClick={increaseScale}
                          variant="secondary"
                          icon="ZoomIn"
                          size="small"
                          title="zoom in"
                        />
                        <ButtonIcon
                          id="zoom-button-reset"
                          onClick={resetZoom}
                          variant="secondary"
                          icon="ResetZoom"
                          size="small"
                          title="reset zoom"
                        />
                      </ButtonGroup>
                      <KeyButton
                        showKey={displayKey}
                        handleShowKey={handleDisplayKey}
                      />
                      <ExportModal
                        title={playerName}
                        secondaryTitle="Pass Placement"
                        info1={teamName}
                        info2={info2}
                        fileName={`${playerName} Pass Placement Chart ${seasonName}`}
                        isDisabled={
                          loading || error || !data?.passEvents?.edges.length
                        }
                      >
                        <div>
                          <PassPlacement
                            data={data}
                            showCatcher={showCatcher}
                            showFailsRing={showFailsRing}
                            showBallSize={showBallSize}
                            colorMode={colorMode}
                            selectedPlay={selectedPlay}
                            aspectRatioName={aspectRatio}
                            plusMinusX={plusMinusX}
                            showKey={displayKey}
                          />
                          {selectedPlay && (
                            <FieldEvent selectedPlay={selectedPlay} />
                          )}
                        </div>
                      </ExportModal>
                    </div>
                  </Tile.Header>
                  <Tile.Body>
                    {error && !loading && (
                      <Message negative>
                        There has been an error. Please contact support.
                      </Message>
                    )}
                    {loading && (
                      <Dimmer active>
                        <Loader content="Loading" />
                      </Dimmer>
                    )}
                    {data?.passEvents?.edges?.length === 0 && !loading && (
                      <Dimmer>
                        <p>No Data available</p>
                      </Dimmer>
                    )}
                    <PassPlacementChartContainer $maxWidth="1100px">
                      <PassPlacement
                        data={data}
                        showCatcher={showCatcher}
                        showFailsRing={showFailsRing}
                        showBallSize={showBallSize}
                        colorMode={colorMode}
                        selectedPlay={selectedPlay}
                        setSelectedPlay={setSelectedPlay}
                        aspectRatioName={aspectRatio}
                        plusMinusX={plusMinusX}
                        showKey={displayKey}
                      />
                      <FieldEvent selectedPlay={selectedPlay} />
                    </PassPlacementChartContainer>
                  </Tile.Body>
                </Tile>
              </div>
              <div>
                <Tile border="0" margin="0">
                  <Tile.Body>
                    <Toggle
                      id="show-passes-toggle"
                      label={
                        showPasses
                          ? `Passes from ${playerName}`
                          : `Passes to ${playerName}`
                      }
                      onChange={() => setShowPasses(!showPasses)}
                      checked={showPasses}
                    />
                    <Toggle
                      id="show-catcher-toggle"
                      label="Show catcher"
                      onChange={handleShowCatcher}
                      checked={showCatcher}
                    />
                    <Toggle
                      id="show-fail-rings-toggle"
                      label="Show incomplete passes as rings"
                      onChange={handleShowFailsRing}
                      checked={showFailsRing}
                    />
                    <Toggle
                      id="show-ball-size-toggle"
                      label="Approximate ball size"
                      onChange={handleBallSize}
                      checked={showBallSize}
                    />

                    <Dropdown
                      id="color-mode-dropdown"
                      options={PASS_PLACEMENT_COLOR_OPTIONS}
                      label="Color Mode"
                      onChange={(selectedOption) =>
                        setColorMode(selectedOption.value)
                      }
                      value={PASS_PLACEMENT_COLOR_OPTIONS.find(
                        (item) => item.value === colorMode
                      )}
                      isSearchable={false}
                    />
                    <Dropdown
                      id="aspect-ratio-dropdown"
                      options={PASS_PLACEMENT_SCALE_OPTIONS}
                      label="Chart Area Display"
                      onChange={handleAspectRatioChange}
                      value={PASS_PLACEMENT_SCALE_OPTIONS.find(
                        (item) => item.value === aspectRatio
                      )}
                      isSearchable={false}
                    />
                  </Tile.Body>
                </Tile>
              </div>
            </SidebarRightLayout>
          </Grid>
        </Grid>
      </KitbagPageGridHolder>
    </StyledPlayerPassPlacement>
  );
};

export default PlayerPassPlacement;
