import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { useQuery, useReactiveVar } from '@apollo/client';
import uiStructure from '../../apollo/uiStructure';
import ContextFilters from '../ContextFilters/ContextFilters';
import Header from '../Header/Header';
import {
  ui_isDark,
  mf_Leagues,
  mf_Games,
  mf_Seasons,
  mf_Teams,
  pf_Drive,
  pf_TeamPlay,
  pf_TeamPlayEvent,
  mf_Players,
  mf_LeagueLevel,
  cf_GameIds,
  mf_TeamDetails,
  mf_PlayerDetails,
  mf_GameDetails,
  mf_AllSeasons,
} from '../../apollo';
import { StyledLayout, Main } from './Layout.styles';
import { GET_NAVIGATION } from '../Header/getNavigationData';
import { ERROR_CODES } from '../../utils/errorCodes/errorCodes';
import { replaceTokens } from '../../utils/helpers/general';
import { isFalsyParam } from './Layout.helper';
import { GET_GAMES } from '../Header/getGames';
import { pitchCompetitionLevel } from '../../utils/helpers/field.constants';
import SideNav from '../SideNavigation';

/* 
We don't have fully automated tests for this component, but we can test it manually.
Test Notes:
  - Change the league, this will invalidate the team and player. 
  - Check on any given page that the charts reflect this change. NCAA Division III is troublesome.
  - Change the season, it should maintain the team
  - Check initial load, open a new tab and paste in the URL (with an empty URL) 
    -- account defaults i.e. Baltimore should load
  - Check configured a URL, open a new tab and paste in the URL (http://localhost:3000/1446/1034/1001437) - the league/season/team should load
 - Make a change that will lead to a null in any of the given league/season/team
 - Where league is set make sure league level is set correctly.
    -- All non-NFL leagues are "NCAA" in terms of hash / pitch markings 
      ~ values from the API of "NCAA_DIVISION_II" are not supported/useful so ignore that
    -- live: go to a pitch vis (snap distro, animation for example). Change comp -> pitch doesn't change.
    -- here: same thing the pitch should redraw hashes appropriately.

  -Play Animation
    select different drives and plays
    copy the URL and paste into a new browser window
    hard refresh the current page
    Test Drives play changes in conjunction with:
      Snap Formations
      Play Events
    -Goto the homepage, hard refresh (to reset all internal vars) and navigate to Play Animation
*/
const Layout = () => {
  const navigate = useNavigate();
  const {
    seasons: seasonsParam,
    leagues: leaguesParam,
    teams: teamsParam,
    players: playersParam,
    games: gamesParam,
  } = useParams();
  const ui_isDarkRV = useReactiveVar(ui_isDark);
  const [pageInfo, setPageInfo] = useState();

  const location = useLocation();
  // trim leading slash
  let currentLocation = location.pathname.slice(1);
  // trim trailing slash if present
  currentLocation = currentLocation.endsWith('/')
    ? currentLocation.slice(0, -1)
    : currentLocation;

  // navigation query to populate: league / season / team / player data
  const {
    data: seasonLeagueTeamPlayersData,
    loading: isLoading,
    error: hasError,
  } = useQuery(GET_NAVIGATION);
  if (hasError) {
    console.error(ERROR_CODES.GET_NAVIGATION_DATA, hasError);
  }
  // navigation data
  const { competitions, seasons, teams, players } =
    seasonLeagueTeamPlayersData?.navigationData || {};

  // games
  const {
    data: gamesData,
    loading: isLoadingGamesData,
    error: gamesError,
  } = useQuery(GET_GAMES);
  if (gamesError) {
    console.error(ERROR_CODES.GET_GAMES, gamesError);
  }

  useLayoutEffect(() => {
    uiStructure.pages.forEach((page) => {
      page.path.forEach((url) => {
        if (new RegExp(url, 'g').test(currentLocation)) {
          if (page !== pageInfo) {
            setPageInfo(page);
          }
        }
      });
    });
  }, [location]);

  const useFilter = pageInfo?.contextFilters;
  const { user } = useKitbagAuth();

  useEffect(() => {
    if (window.heap) {
      window.heap.addUserProperties({
        Name: user.name,
        Organisation: user['https://statsbomb.com/accountID'],
      });
    }
  }, [user]);

  // give precedence to url params so they persist on refresh and url sharing
  useEffect(() => {
    if (leaguesParam && !isFalsyParam(leaguesParam)) {
      const leagueId = parseInt(leaguesParam, 10);

      mf_Leagues(leagueId);
      mf_LeagueLevel(pitchCompetitionLevel(leagueId));
    }
    if (seasonsParam && !isFalsyParam(seasonsParam)) {
      mf_Seasons(parseInt(seasonsParam, 10));
    }
    if (teamsParam && !isFalsyParam(teamsParam)) {
      mf_Teams(parseInt(teamsParam, 10));
    }
    if (playersParam && !isFalsyParam(playersParam)) {
      mf_Players(parseInt(playersParam, 10));
    }
    if (gamesParam && !isFalsyParam(gamesParam)) {
      mf_Games(parseInt(gamesParam, 10));
    }
  }, [leaguesParam, seasonsParam, teamsParam, playersParam, gamesParam]);

  const handleChange = (selectedOption, filter) => {
    const { value } = selectedOption;

    if (filter === 'leagues') {
      mf_Leagues(value);
      // assume change in league and team will invalidate team, player, and games
      mf_Teams(null);
      mf_Players(null);
      mf_Games(null);
      mf_GameDetails(null);
      mf_LeagueLevel(pitchCompetitionLevel(value));
      cf_GameIds([]);
    } else if (filter === 'seasons') {
      pf_TeamPlay(null);
      pf_Drive(null);
      mf_Seasons(value);
      mf_Games(null);
      mf_GameDetails(null);
      // nice UX to keep the team selected
      // ...but the first player may change
      mf_Players(null);
      cf_GameIds([]);
    } else if (filter === 'teams') {
      pf_TeamPlay(null);
      pf_Drive(null);
      mf_Teams(value);
      mf_Players(null);
      mf_TeamDetails(teams?.find((t) => t?.id === value));
      mf_Games(null);
      mf_GameDetails(null);
      cf_GameIds([]);
    } else if (filter === 'players') {
      mf_Players(value);
      mf_PlayerDetails(players?.find((p) => p?.id === value));
    } else if (filter === 'games') {
      pf_TeamPlay(null);
      pf_Drive(null);
      pf_TeamPlayEvent(null);
      mf_Games(value);
      mf_GameDetails(
        gamesData?.games?.edges.find((game) => game.node.id === value)?.node
      );
    }

    const instantRV = {
      leagues: mf_Leagues(),
      players: mf_Players(),
      seasons: mf_Seasons(),
      teams: mf_Teams(),
      games: mf_Games(),
      drives: pf_Drive(),
      plays: pf_TeamPlay(),
    };

    if (pageInfo) {
      navigate(
        `${replaceTokens(window.location.pathname, pageInfo, instantRV)}${
          window.location.search
        }`,
        { replace: false }
      );
    }
  };

  if (seasonLeagueTeamPlayersData) {
    if ((isFalsyParam(teamsParam) || teamsParam === '0') && teams.length > 0) {
      // data present but not in list, select the first
      mf_Teams(teams[0]?.id || 0);
      mf_TeamDetails(teams[0] || 0);

      const instantRV = {
        leagues: mf_Leagues(),
        players: mf_Players(),
        seasons: mf_Seasons(),
        teams: teams[0]?.id || 0,
        games: mf_Games(),
        drives: pf_Drive(),
        plays: pf_TeamPlay(),
      };

      navigate(
        `${replaceTokens(window.location.pathname, pageInfo, instantRV)}${
          window.location.search
        }`,
        { replace: false }
      );
    } else {
      mf_TeamDetails(teams?.find((t) => t?.id === mf_Teams()));
    }

    if (players && isFalsyParam(playersParam)) {
      // data present but not in list, select the first
      mf_Players(players[0]?.id || 0);
      mf_PlayerDetails(players[0] || 0);

      const instantRV = {
        leagues: mf_Leagues(),
        players: players[0]?.id || 0,
        seasons: mf_Seasons(),
        teams: mf_Teams(),
        games: mf_Games(),
        drives: pf_Drive(),
        plays: pf_TeamPlay(),
      };

      navigate(
        `${replaceTokens(window.location.pathname, pageInfo, instantRV)}${
          window.location.search
        }`,
        { replace: false }
      );
    } else {
      mf_PlayerDetails(players?.find((p) => p?.id === mf_Players()));
    }

    // add params to URL if empty
    if (currentLocation.length < 1) {
      const instantRV = {
        leagues: mf_Leagues(),
        players: mf_Players(),
        seasons: mf_Seasons(),
        teams: mf_Teams(),
        games: mf_Games(),
        drives: pf_Drive(),
        plays: pf_TeamPlay(),
      };

      navigate(
        `${replaceTokens(window.location.pathname, pageInfo, instantRV)}${
          window.location.search
        }`,
        { replace: false }
      );
    }

    if (gamesData) {
      if (isFalsyParam(gamesParam) || !gamesParam) {
        const firstGame = gamesData?.games?.edges[0]?.node;
        mf_Games(firstGame?.id);
        mf_GameDetails(
          gamesData?.games?.edges.find((game) => game.node.id === firstGame?.id)
            ?.node
        );
      } else {
        mf_GameDetails(
          gamesData?.games?.edges.find((game) => game.node.id === mf_Games())
            ?.node
        );
      }
    }
    mf_AllSeasons(seasons);
  }

  // TODO: remove this useEffect once we removed all style references to .dark class
  useEffect(() => {
    document.body.className = ui_isDarkRV ? 'dark' : '';
  }, []);

  return (
    <StyledLayout $withFilters={useFilter}>
      <Header
        pageInfo={pageInfo}
        navigationData={seasonLeagueTeamPlayersData?.navigationData}
        isLoadingNavigationData={isLoading || isLoadingGamesData}
        handleChange={handleChange}
        gamesData={gamesData}
      />
      <div className="core">
        {useFilter && <ContextFilters />}
        <SideNav />
        <Main>
          <Outlet context={[pageInfo, setPageInfo]} />
        </Main>
      </div>
    </StyledLayout>
  );
};

export default Layout;
