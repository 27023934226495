import { styled } from 'styled-components';

export const HEADER_HEIGHT = '3.5rem';

export const StyledHeader = styled.header`
  position: fixed;
  z-index: 103;
  top: 0;
  width: 100vw;
  height: ${HEADER_HEIGHT};
  display: flex;
  align-items: center;
  gap: 1.25rem;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  box-shadow: ${({ theme }) => theme.effects.dropShadow.uber};
  left: 2.5rem;
  padding-left: 2rem;

  ${({ theme }) => theme.mqFrom('lg')} {
    height: ${HEADER_HEIGHT};
  }
  a {
    background-color: ${({ theme }) => theme.colours.brand.main};
    flex: 0 0 187px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // changes box shadow when sub filters are present
  &:has(+ .core > .sub-header-container) {
    box-shadow: 0px 1px 0px 0px rgb(0 0 0 / 5%);
  }
`;
