import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import {
  Dropdown,
  Toggle,
  // ButtonIcon,
} from '@statsbomb/kitbag-components';
import { mf_TeamDetails } from '../../../apollo';
import Tile from '../../../components/Tile/Tile';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import {
  HAVOC_POSITION_OPTION_ANY,
  HAVOC_TYPES,
  HAVOC_PLAYER_OPTION_ANY,
  HAVOC_PLAY_TYPES,
  HAVOC_PLAY_TRIBUTTON,
} from '../../../visualisations/HavocChart/HavocChart.constants';
import {
  formatPlayersSelect,
  formatPositionsSelect,
  formatBlitzSelect,
  formatDefensiveFrontSelect,
} from './HavocSelection.dataManipulation';
import { TriButtonToggle } from '../../../components/buttons/TriButtonToggle/TriButtonToggle';

/* This provides the accordion section for filtering havoc things */
const HavocSelection = ({
  showDefensive,
  setShowDefensive,
  havocPlayMode,
  setHavocPlayMode,
  havocMode,
  setHavocMode,
  selectedBlitzType,
  setSelectedBlitzType,
  selectedDefensiveFront,
  setSelectedDefensiveFront,
  selectedPlayerId,
  setSelectedPlayerId,
  selectedPositionCode,
  setSelectedPositionCode,
  relevantPlayers,
  positionFrequencies,
  blitzTypes,
  defensiveFronts,
}) => {
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const teamName = mf_TeamDetailsRV?.name;

  /* Define the list of players and positions to filter on */
  const playerOptions = formatPlayersSelect(relevantPlayers);
  const positionOptions = formatPositionsSelect(positionFrequencies);
  const blitzTypeOptions = formatBlitzSelect(blitzTypes);
  const defensiveFrontOptions = formatDefensiveFrontSelect(defensiveFronts);

  const availableHavocTypes = HAVOC_PLAY_TYPES[havocPlayMode].havocTypeOptions;
  const havocPlayModeChanged = (newPlayMode) => {
    const newOptionValues = HAVOC_PLAY_TYPES[newPlayMode].havocTypeOptions.map(
      (h) => h.value
    );
    if (!newOptionValues.includes(havocMode)) {
      setHavocMode(newOptionValues[0]);
    }
    setHavocPlayMode(newPlayMode);
  };

  return (
    <AccordionTile
      id="selection-accordion"
      header={<Tile.AccordionHeader>Selection Options</Tile.AccordionHeader>}
      body={
        <Tile.AccordionBody
          $padding="0.5rem 0.5rem 1rem 1rem"
          style={{ gap: '0.5rem' }}
        >
          <Toggle
            id="show-defensive-toggle"
            label={
              showDefensive
                ? `${teamName} on defense`
                : `${teamName} on offense`
            }
            onChange={() => {
              setSelectedPlayerId(HAVOC_PLAYER_OPTION_ANY.value);
              setSelectedPositionCode(HAVOC_POSITION_OPTION_ANY.value);
              setShowDefensive(!showDefensive);
            }}
            checked={showDefensive}
          />
          <TriButtonToggle
            selectionState={havocPlayMode}
            setSelectionState={havocPlayModeChanged}
            labels={HAVOC_PLAY_TRIBUTTON.labels}
            values={HAVOC_PLAY_TRIBUTTON.values}
          />
          <Dropdown
            id="havoc-type-dropdown"
            options={availableHavocTypes}
            label="Line Havoc Event Types"
            menuPosition="static"
            onChange={(selectedOption) => {
              setSelectedPlayerId(HAVOC_PLAYER_OPTION_ANY.value);
              setSelectedPositionCode(HAVOC_POSITION_OPTION_ANY.value);
              setHavocMode(selectedOption.value);
            }}
            value={HAVOC_TYPES.find((f) => f.value === havocMode)}
          />
          <Dropdown
            id="havoc-position-dropdown"
            options={positionOptions}
            label="Position"
            menuPosition="static"
            onChange={(selectedOption) => {
              setSelectedPositionCode(selectedOption.value);
            }}
            value={positionOptions.find(
              (f) => f.value === selectedPositionCode
            )}
          />
          <Dropdown
            id="havoc-blitz-dropdown"
            options={blitzTypeOptions}
            label="Blitz Type"
            menuPosition="static"
            onChange={(selectedOption) => {
              setSelectedBlitzType(selectedOption.value);
            }}
            value={blitzTypeOptions.find((f) => f.value === selectedBlitzType)}
          />
          <Dropdown
            id="havoc-defensive-front-dropdown"
            options={defensiveFrontOptions}
            label="Defensive Front"
            menuPosition="static"
            onChange={(selectedOption) => {
              setSelectedDefensiveFront(selectedOption.value);
            }}
            value={defensiveFrontOptions.find(
              (f) => f.value === selectedDefensiveFront
            )}
          />
          <Dropdown
            id="havoc-player-dropdown"
            options={playerOptions}
            label={showDefensive ? 'Defender' : 'Target'}
            menuPosition="static"
            onChange={(selectedOption) => {
              setSelectedPositionCode(HAVOC_POSITION_OPTION_ANY.value);
              setSelectedPlayerId(parseInt(selectedOption.value, 10));
            }}
            value={playerOptions.find((f) => f.value === selectedPlayerId)}
          />
        </Tile.AccordionBody>
      }
      isExpandedDefault
      saveState
    />
  );
};

HavocSelection.propTypes = {
  /** state/querystring values/setters for the options controlled in this selection
   */
  showDefensive: PropTypes.bool.isRequired,
  setShowDefensive: PropTypes.func.isRequired,
  havocPlayMode: PropTypes.string.isRequired,
  setHavocPlayMode: PropTypes.func.isRequired,
  havocMode: PropTypes.string.isRequired, // HAVOC_TYPES
  setHavocMode: PropTypes.func.isRequired,
  selectedBlitzType: PropTypes.string.isRequired,
  setSelectedBlitzType: PropTypes.func.isRequired,
  selectedDefensiveFront: PropTypes.string.isRequired,
  setSelectedDefensiveFront: PropTypes.func.isRequired,
  selectedPlayerId: PropTypes.number.isRequired,
  setSelectedPlayerId: PropTypes.func.isRequired,
  selectedPositionCode: PropTypes.string.isRequired,
  setSelectedPositionCode: PropTypes.func.isRequired,
  relevantPlayers: PropTypes.arrayOf(
    PropTypes.shape({
      playerId: PropTypes.number,
      playerName: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  positionFrequencies: PropTypes.arrayOf(
    PropTypes.shape({
      positionCode: PropTypes.string,
      positionName: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  blitzTypes: PropTypes.arrayOf(
    PropTypes.shape({
      blitzType: PropTypes.string,
      blitzTypeName: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
  defensiveFronts: PropTypes.arrayOf(
    PropTypes.shape({
      defensiveFront: PropTypes.string,
      defensiveFrontName: PropTypes.string,
      freq: PropTypes.number,
    })
  ),
};

HavocSelection.defaultProps = {
  relevantPlayers: null,
  positionFrequencies: null,
  blitzTypes: null,
  defensiveFronts: null,
};

export default HavocSelection;
