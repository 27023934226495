import { isEmpty, maxBy, sumBy, uniqBy } from 'lodash';
import {
  HAVOC_AGGREGATION_DATA_TERMS,
  HAVOC_TABLE_AGGREGATION_MODE_TYPE,
} from '../HavocTable.jsx/HavocTable.constants';
import { safeDivide } from '../../../../utils/helpers/maths';

const gapKey =
  HAVOC_AGGREGATION_DATA_TERMS[HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP_EXPLOITED]
    .keyField;
const zoneKey =
  HAVOC_AGGREGATION_DATA_TERMS[HAVOC_TABLE_AGGREGATION_MODE_TYPE.ZONE].keyField;

export const getSelectedHavocData = ({
  havocData,
  selectedGaps,
  selectedZones,
}) => {
  if (isEmpty(havocData) || (isEmpty(selectedGaps) && isEmpty(selectedZones))) {
    return [];
  }
  /* There is data and some form of selection ~ get all the relevant data */
  if (!isEmpty(selectedGaps)) {
    return havocData.filter((f) => selectedGaps.includes(f[gapKey]));
  }
  return havocData.filter((f) => selectedZones.includes(f[zoneKey]));
};
/*
  Converts events into grouped data, and then re-formats that data for bubble chart (scales/colors it)
  If a gaps selection is made, the bubbles have background-mode circles that display the unfiltered values
*/
export const getPipeGroupedPlays = ({
  relevantHavocData, // events ~ should only have those with relevant grouping attributes
}) => {
  /* 
  For each possible zone/gap work out total plays from the filtered data 
    This might correspond to multiple selection of the other kind
      e.g. selecting 3 gaps might mean the largest total item is one of those gaps, or a zone with 
      overlap across those gaps, depending on how the gaps/zones overlap
  Whatever the largest play count is amongst relevant gaps/zones is then the value pipe sizing is scaled to
    e.g. if GapB = 100 plays, then 100% of possible pipe space is used for GapB (and everything else less...)
  */
  const zoneTotals = uniqBy(relevantHavocData, zoneKey).map((z) => {
    const zoneData = relevantHavocData.filter((g) => g[zoneKey] === z[zoneKey]);
    const plays = uniqBy(zoneData, 'playUUID').length;
    return {
      zoneKey: z[zoneKey],
      plays,
    };
  });
  const gapTotals = uniqBy(relevantHavocData, gapKey).map((z) => {
    const gapHavocData = relevantHavocData.filter(
      (g) => g[gapKey] === z[gapKey]
    );
    const plays = uniqBy(gapHavocData, 'playUUID').length;
    return {
      gapKey: z[gapKey],
      plays,
      gapHavocData,
    };
  });
  const maxGapOrZonePlayCount =
    maxBy(zoneTotals.concat(gapTotals), 'plays')?.plays || 0;

  /*
  For each combination of relevant zone/gap ~ figure out pipe size (proportionally)
  find out what proportion of this zone/gap it is too (so you can later place the pipe center correctly)
  */
  const gapZoneCounts = gapTotals
    .map((gapT) => {
      const gapToMaxFraction = safeDivide(gapT.plays, maxGapOrZonePlayCount);

      const gapZones = uniqBy(gapT.gapHavocData, zoneKey).map((z) => {
        const gapZoneData = gapT.gapHavocData.filter(
          (g) => g[zoneKey] === z[zoneKey]
        );
        const zoneTotal = zoneTotals.find((t) => t.zoneKey === z[zoneKey]);
        const gapZonePlays = uniqBy(gapZoneData, 'playUUID').length;
        const pipeWidthFraction = safeDivide(
          gapZonePlays,
          maxGapOrZonePlayCount
        );
        const zoneFractionOfGap = safeDivide(gapZonePlays, gapT.plays); // this is for placement of pipe
        const gapFractionOfZone = safeDivide(gapZonePlays, zoneTotal.plays);

        return {
          gapKey: gapT.gapKey,
          zoneKey: z[zoneKey],
          gapToMaxFraction,
          /* true to whole pipe */
          gapZonePlays,
          pipeWidthFraction,
          /* top (gap) of pipe location */
          gapPlays: gapT.plays,
          zoneFractionOfGap,
          pipeFractionOfGap: zoneFractionOfGap * pipeWidthFraction,
          /* bottom (zone) of pipe location */
          zonePlays: zoneTotal.plays,
          gapFractionOfZone,
          pipeFractionOfZone: gapFractionOfZone * pipeWidthFraction,
        };
      });
      return gapZones;
    })
    .flat();

  return gapZoneCounts;
};

const colorPipeData = ({
  pipeData,
  zoneBubbleData,
  gapBarData,
  colorPipesByZone,
}) =>
  pipeData.map((pipe) => {
    const matchingZone = zoneBubbleData.find((z) => z.key === pipe.zoneKey);
    const matchingGap = gapBarData.find((g) => g.id === pipe.gapKey);
    const zoneR =
      matchingZone.unfilteredValue > matchingZone.rValue
        ? matchingZone.unfilteredValue
        : matchingZone.rValue;
    const tooltip =
      `${matchingZone.name}→${matchingGap.xValue}` +
      `\nHavoc Plays: ${pipe.gapZonePlays}` +
      `\nHavoc Plays (zone): ${pipe.zonePlays}` +
      `\nHavoc Plays (gap): ${pipe.gapPlays}`;
    const newPipe = {
      ...pipe,
      gapIndex: matchingGap.gapIndex,
      zoneIndex: matchingZone.zoneIndex,
      zoneX: matchingZone.dotPosition.trunkX,
      zoneR,
      zoneY: matchingZone.dotPosition.y,
      fill: colorPipesByZone ? matchingZone.fill : matchingGap.fill,
      tooltip,
    };
    return newPipe;
  });

/* 
  Loop back over in order to work out for each pipe, what percentage it represents of it's 
    parent gap, and parent zone, according to the total filtering that has occurred
  This is basically the logic not for the color/thickness of a pipe, but the displacement of each 
    pipe about the central point
   */
const scalePipeData = ({ coloredPipeData, colorPipesByZone }) =>
  coloredPipeData.map((pipe) => {
    /* per zone gap spacing */
    const prevGaps = coloredPipeData.filter(
      (p) => p.zoneKey === pipe.zoneKey && p.gapIndex < pipe.gapIndex
    );
    const totalPrevGapFraction = sumBy(prevGaps, 'gapFractionOfZone') || 0;
    const pipeZMidpoint = totalPrevGapFraction + pipe.gapFractionOfZone / 2; // gives value 0-1 of midpoint
    const pipeZFractionX = pipeZMidpoint - 0.5; // change to value centered around 0
    const scaledZoneMidpoint = pipeZFractionX; // * pipe.pipeWidthFraction; // adjust for relative sizing
    /* zone edge */
    const prevZones = coloredPipeData.filter(
      (p) => p.gapKey === pipe.gapKey && p.zoneIndex < pipe.zoneIndex
    );
    const totalPrevZoneFraction = sumBy(prevZones, 'zoneFractionOfGap') || 0;
    const pipeGMidpoint = totalPrevZoneFraction + pipe.zoneFractionOfGap / 2; // gives value 0-1 of midpoint
    const pipeGFractionX = pipeGMidpoint - 0.5; // change to value centered around 0
    const scaledGapMidpoint = pipeGFractionX; // adjust for relative sizing

    return {
      ...pipe,
      scaledZoneMidpoint,
      scaledGapMidpoint,
      bendFraction: colorPipesByZone ? scaledGapMidpoint : scaledZoneMidpoint,
    };
  });

export const getPipeData = ({
  havocData,
  selectedGaps,
  selectedZones,
  gapBarData,
  zoneBubbleData,
}) => {
  /* Get all the havoc data relevant to the gap[s]/zone[s] selected */
  const relevantHavocData = getSelectedHavocData({
    havocData,
    selectedGaps,
    selectedZones,
  });
  if (isEmpty(relevantHavocData)) {
    return null; // no pipes without a selection
  }

  const pipeData = getPipeGroupedPlays({
    relevantHavocData,
  });
  if (isEmpty(pipeData)) {
    return null;
  }

  /* For each pipe, match to the bubble/bar data to get derived values */
  const colorPipesByZone = !isEmpty(selectedGaps);
  const coloredPipeData = colorPipeData({
    pipeData,
    zoneBubbleData,
    gapBarData,
    colorPipesByZone,
  });

  const positionedPipeData = scalePipeData({
    coloredPipeData,
    colorPipesByZone,
  });
  return positionedPipeData;
};
