import { reverse } from 'lodash';
import { API_OL_GAPS } from '../../utils/constants/api';
import { getScale } from '../BasicChart/BasicChart.dataManipulation';
import {
  CHART_SCALE_TYPE,
  CHART_SCALE_DISPLAY_TYPE,
} from '../BasicChart/BasicChart.constants';
import { POSITIONS_FOR_GAPS } from './HavocSummary.constants';

export const getGapXs = (canvasWidth) => {
  const xRange = [0, canvasWidth];
  const setupX = {
    TYPE: CHART_SCALE_TYPE.CATEGORICAL,
    DOMAIN: null,
    PADDING: 0,
    PADDING_INNER: 0.2,
    NICE: true,
    TICKS: null,
  };

  const gapListRtL = reverse(Object.values(API_OL_GAPS)).map((g) => ({
    gap: g,
  }));
  const xScaleBar = getScale(
    setupX,
    xRange,
    gapListRtL,
    'gap',
    CHART_SCALE_DISPLAY_TYPE.BAR
  );
  const gaps = gapListRtL.map((gap) => ({
    apiCode: gap.gap,
    x: xScaleBar(gap.gap) + xScaleBar.bandwidth() / 2,
  }));
  return gaps;
};

export const getPositionXs = (gapXs) => {
  const positionsWithX = Object.values(POSITIONS_FOR_GAPS).map((pos) => {
    const leftX = gapXs.find((g) => g.apiCode === pos.GAP1).x;
    const rightX = gapXs.find((g) => g.apiCode === pos.GAP2).x;
    const posX = (leftX + rightX) / 2;
    return {
      apiCode: pos.POSITION,
      x: posX,
    };
  });
  return positionsWithX;
};

export const getInflectionPoint = (gapIndex, gapCount, inflectionRange) => {
  const rangeHeight = inflectionRange[1] - inflectionRange[0];
  if (gapCount <= 1) {
    return inflectionRange[0] + rangeHeight / 2;
  }
  if (gapCount === 2) {
    return gapIndex === 0
      ? inflectionRange[0] + rangeHeight / 3
      : inflectionRange[0] + (rangeHeight * 2) / 3;
  }
  return inflectionRange[0] + (rangeHeight * gapIndex) / (gapCount - 1);
};
