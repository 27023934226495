import { reverse } from 'd3';
import { isEmpty, sortBy } from 'lodash';
import { ALIGNMENT_POSITIONS } from '../../utils/constants/positions';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../utils/constants/visText';
import {
  DEFENDER_ZONE_AXES,
  HAVOC_SANKEY_CLASSES,
  HAVOC_SANKEY_SIZES,
} from './HavocSummary.constants';
import { fontBaselineY, fontVerticalCenterY } from '../text';
import { VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS } from '../../utils/constants/charting';

export const drawPositions = (svg, visPalette, positionsWithX, positionR) => {
  const posSymbolsG = svg.select(`.${HAVOC_SANKEY_CLASSES.POSITION_SYMBOLS}`);
  posSymbolsG.selectAll('*').remove();

  // draw OL Shapes
  posSymbolsG
    .selectAll('path')
    .data(positionsWithX)
    .enter()
    .append('path')
    .attr('d', (d) => {
      const position = ALIGNMENT_POSITIONS[d.apiCode];
      return position.shape(positionR);
    })
    .attr(
      'transform',
      (d) =>
        `translate(${d.x} ${HAVOC_SANKEY_SIZES.SYMBOLS_MID_Y}) rotate(${
          ALIGNMENT_POSITIONS[d.apiCode]?.rotation
        })`
    )
    .attr('fill', (d) => (d.isSelected ? visPalette.contrast : 'none'))
    .attr('stroke-width', 2)
    .attr('stroke', visPalette.axis);

  /* Primary Structure */
  posSymbolsG
    .selectAll('text')
    .data(positionsWithX)
    .enter()
    .append('text')
    .attr('fill', visPalette.text.label)
    .attr('x', 0)
    .attr('y', 0)
    .attr(
      'transform',
      (d) => `translate(${d.x},${HAVOC_SANKEY_SIZES.SYMBOLS_BASELINE})`
    )
    .attr('font-size', `${positionR}px`)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'middle')
    .text((d) => ALIGNMENT_POSITIONS[d.apiCode].code);
};

export const drawDefenderZoneAxes = (
  svg,
  visPalette,
  canvasWidth,
  canvasPadding
) => {
  const dzAxesG = svg.select(`.${HAVOC_SANKEY_CLASSES.DEFENDER_ZONE_AXES}`);
  dzAxesG.selectAll('*').remove();

  const dzAxes = Object.values(DEFENDER_ZONE_AXES);
  // Draw Gap Shapes
  dzAxesG
    .selectAll('line')
    .data(dzAxes)
    .enter()
    .append('line')
    .attr('x1', -canvasPadding)
    .attr('x2', canvasWidth + canvasPadding * 2)
    .attr('y1', (d) => d.y * HAVOC_SANKEY_SIZES.DEFENDER_ZONE_HEIGHT)
    .attr('y2', (d) => d.y * HAVOC_SANKEY_SIZES.DEFENDER_ZONE_HEIGHT)
    .attr('stroke', visPalette.guides)
    .attr('stroke-dasharray', '2 2')
    .attr('stroke-width', 2);

  dzAxesG
    .selectAll('text')
    .data(dzAxes)
    .enter()
    .append('text')
    .attr('fill', visPalette.text.label)
    .attr('x', -5 - canvasPadding)
    .attr(
      'y',
      (d) =>
        d.y * HAVOC_SANKEY_SIZES.DEFENDER_ZONE_HEIGHT +
        fontVerticalCenterY(VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE)
    )
    .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE}px`)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
    .attr('font-family', DEFAULT_FONT)
    .attr('text-anchor', 'end')
    .text((d) => d.text);
};

export const drawBubbles = (
  dzG,
  { visPalette, zones, bubbleScale, canvasWidth, canvasHeight, toggleZones }
) => {
  /* actual bubble */
  dzG
    .selectAll('circle')
    .data(zones, (z) => z.key)
    .join(
      (enter) => {
        const scatterCircle = enter
          .append('circle')
          .attr('cy', (d) => d.dotPosition.y * canvasHeight)
          .attr('class', VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS)
          .on('click', (_, d) => {
            toggleZones(d.key);
          });

        scatterCircle.append('title').text((dZone) => dZone.title);
        return scatterCircle;
      },
      (update) => {
        update.selectAll('title').remove();
        update.append('title').text((dZone) => dZone.title);
        update.on('click', (_, d) => {
          toggleZones(d.key);
        });
        return update;
      },
      (exit) => exit.remove()
    )
    /* note cx updates but doesn't animate ~ this is so resizing works but prevents
            bubbles from continually resetting x value to 0
     */
    .attr('cx', (d) => d.dotPosition.x * canvasWidth)
    .transition()
    .duration(600)
    .attr('r', (d) => bubbleScale(d.rValue))
    .attr('fill', (d) => d.fill)
    .attr('fill-opacity', (d) => d.opacity)
    .attr('stroke', visPalette.objects.neutral.main)
    .attr('stroke-width', (d) => d.strokeWidth)
    .attr('stroke', visPalette.objects.neutral.main);

  const yBaseline = fontBaselineY(
    VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE,
    VISUALISATION_FONT_SETUPS.AXES_VALUES.LINE_HEIGHT
  );
  /* text beneath bubble */
  dzG
    .selectAll('text')
    .data(zones, (z) => z.key)
    .join(
      (enter) => {
        const scatterCircle = enter
          .append('text')
          .attr('fill', visPalette.text.label)
          .attr('x', 0)
          .attr('y', 0)
          .attr('font-size', `${VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE}px`)
          .attr('font-weight', VISUALISATION_FONT_SETUPS.AXES_VALUES.WEIGHT)
          .attr('font-family', DEFAULT_FONT)
          .attr('text-anchor', 'middle');
        scatterCircle.append('title').text((dZone) => dZone.title);
        return scatterCircle;
      },
      (update) => {
        update.selectAll('title').remove();
        update.append('title').text((dZone) => dZone.title);
        return update;
      },
      (exit) => exit.remove()
    )
    .attr(
      'transform',
      (d) =>
        `translate(${d.dotPosition.x * canvasWidth},${
          d.dotPosition.y * canvasHeight +
          bubbleScale(
            d.unfilteredValue > d.rValue ? d.unfilteredValue : d.rValue
          ) +
          yBaseline
        })`
    )
    .transition()
    .duration(600)
    .attr('opacity', (d) => d.opacity)
    .text((d) => `${(d.rValue * 100)?.toFixed(1)}%`);
};

export const drawSankeyPipes = (sankeyG, { xValuePipes, canvasWidth }) => {
  if (!isEmpty(xValuePipes)) {
    const pipeBandwidth = canvasWidth / 20 - 16;
    const pipeBottom = HAVOC_SANKEY_SIZES.DEFENDER_ZONE_Y;
    const pipeInflectionY =
      HAVOC_SANKEY_SIZES.PIPE_Y_INFLECTION_MAX -
      HAVOC_SANKEY_SIZES.PIPE_Y_INFLECTION_MIN;
    const renderOrderPipes = reverse(sortBy(xValuePipes, 'pipeWidthFraction'));

    const pipes = sankeyG
      .selectAll('path')
      .data(renderOrderPipes)
      .enter()
      .append('path')
      .attr(
        'd',
        (d) =>
          `M${d.gapX + d.scaledGapMidpoint * pipeBandwidth},${0} L${
            d.gapX + d.scaledGapMidpoint * pipeBandwidth
          },${
            HAVOC_SANKEY_SIZES.PIPE_Y_INFLECTION_MIN +
            d.bendFraction * pipeInflectionY
          }` +
          `L${d.zoneX * canvasWidth + d.scaledZoneMidpoint * pipeBandwidth},${
            HAVOC_SANKEY_SIZES.PIPE_Y_INFLECTION_MIN +
            d.bendFraction * pipeInflectionY
          } L${d.zoneX * canvasWidth + d.scaledZoneMidpoint * pipeBandwidth},${
            pipeBottom +
            HAVOC_SANKEY_SIZES.DEFENDER_ZONE_HEIGHT * d.zoneY -
            d.bubbleR
          }`
      )
      .attr('fill', 'none')
      .attr('stroke', (d) => d.fill)
      .attr('stroke-linejoin', 'round')
      .attr('stroke-width', (d) => d.pipeWidthFraction * pipeBandwidth);
    pipes.append('title').text((d) => d.tooltip);
  }
};
