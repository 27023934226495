import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { scaleSqrt, select } from 'd3';
import { useTheme } from 'styled-components';
import { isEmpty, isFunction } from 'lodash';
import { useD3 } from '../../utils/hooks/useD3';
import { VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS } from '../../utils/constants/charting';
import { ClickableSVG } from '../visualisation.styles';
import {
  HAVOC_SANKEY_CLASSES,
  HAVOC_SANKEY_SIZES,
} from './HavocSummary.constants';
import { getGapXs, getPositionXs } from './HavocSummaryChart.utils';
import { VISUALISATION_FONT_SETUPS } from '../../utils/constants/visText';
import { CHART_LAYOUT } from '../BasicChart/BasicChart.constants';
import { HAVOC_GAP_BARS_SIZE_LIMITS } from '../../pages/team/TeamHavoc/HavocBars/HavocBars.constants';
import {
  drawBubbles,
  drawDefenderZoneAxes,
  drawPositions,
  drawSankeyPipes,
} from './HavocSummary.drawing';
import { API_DEFENDER_ZONES } from '../../utils/constants/api';

/*  
  svg
    |-within margins
      |-field zone (right of / under distros)
        |-field axes
        |-field area
      |-key zone (under all the rest)
  */
const HavocSummaryChart = ({
  id,
  zoneDomain,
  zoneData,
  zoneDataGhost,
  pipeData,
  viewboxWidth,
  selectedZones,
  setSelectedZones,
}) => {
  const { colours } = useTheme();
  const visPalette = colours.visualisations;
  const isInteractive = isFunction(setSelectedZones);

  const canvasWidth =
    viewboxWidth -
    CHART_LAYOUT.AXES.PADDING.left -
    CHART_LAYOUT.AXES.PADDING.right -
    HAVOC_GAP_BARS_SIZE_LIMITS.Y_AXIS_WIDTH;
  const viewboxHeight =
    HAVOC_SANKEY_SIZES.DEFENDER_ZONE_Y +
    HAVOC_SANKEY_SIZES.DEFENDER_ZONE_HEIGHT +
    HAVOC_SANKEY_SIZES.DEFENDER_ZONE_BOTTOM_MARGIN;

  const svgWidth = '100%';
  const viewBox = `0 0 ${viewboxWidth} ${viewboxHeight}`;
  const marginTransform = `translate(${
    HAVOC_GAP_BARS_SIZE_LIMITS.Y_AXIS_WIDTH + CHART_LAYOUT.AXES.PADDING.left
  },0)`;

  const gapsWithX = getGapXs(canvasWidth);
  const positionsWithX = getPositionXs(gapsWithX);

  const bubbleScale = scaleSqrt()
    .domain(zoneDomain)
    .range(HAVOC_SANKEY_SIZES.BUBBLE_RANGE)
    .clamp(true);

  const toggleZones = (zoneId) => {
    const existList = isEmpty(selectedZones) ? [] : selectedZones;
    if (!existList.includes(zoneId)) {
      setSelectedZones(existList.concat(zoneId));
    } else {
      const otherZones = existList.filter((z) => z !== zoneId);
      setSelectedZones(otherZones);
    }
  };

  const xValuePipes = isEmpty(pipeData)
    ? []
    : pipeData.map((p) => {
        const matchingGap = gapsWithX.find((g) => g.apiCode === p.gapKey);
        return {
          ...p,
          gapX: matchingGap.x,
          bubbleR: [
            API_DEFENDER_ZONES.MID_CENTRAL,
            API_DEFENDER_ZONES.BACK_CENTRAL,
          ].includes(p.zoneKey)
            ? 0
            : bubbleScale(p.zoneR) + 2,
        };
      });

  const ref = useD3((svg) => {
    svg.attr('width', svgWidth);
    svg.selectAll('g').remove();
    svg.selectAll('rect').remove();

    // BACKING RECT FOR THE SVG
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', HAVOC_SANKEY_CLASSES.BACKGROUND)
      .attr('fill', visPalette.background.main)
      .attr('stroke', 'none');

    const withinMargingsG = svg
      .append('g')
      .attr('transform', marginTransform)
      .attr('class', HAVOC_SANKEY_CLASSES.IN_MARGINS);

    withinMargingsG
      .append('g')
      .attr('class', HAVOC_SANKEY_CLASSES.POSITION_SYMBOLS);
    withinMargingsG
      .append('g')
      .attr('transform', `translate(0,${HAVOC_SANKEY_SIZES.DEFENDER_ZONE_Y})`)
      .attr('class', HAVOC_SANKEY_CLASSES.DEFENDER_ZONE_AXES);
    withinMargingsG
      .append('g')
      .attr('class', HAVOC_SANKEY_CLASSES.SANKEY_LINES);
    withinMargingsG
      .append('g')
      .attr('transform', `translate(0,${HAVOC_SANKEY_SIZES.DEFENDER_ZONE_Y})`)
      .attr('class', HAVOC_SANKEY_CLASSES.DEFENDER_ZONES);
    withinMargingsG
      .append('g')
      .attr('transform', `translate(0,${HAVOC_SANKEY_SIZES.DEFENDER_ZONE_Y})`)
      .attr('class', HAVOC_SANKEY_CLASSES.DEFENDER_ZONES_GHOSTS);

    if (isInteractive) {
      /* Add logic so clicking anywhere deselects */
      svg.on('click', (e) => {
        if (
          e.target.classList.value !==
          VISUALISATION_STYLE_CLICKABLE_OBJECT_CLASS
        ) {
          setSelectedZones([]);
        }
      });
    }
  }, []);

  /** Basic layout ~ top symbols and backing rect / chart size */
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    const backingRect = svg.select(`.${HAVOC_SANKEY_CLASSES.BACKGROUND}`);
    backingRect.attr('fill', visPalette.background.main);

    drawPositions(
      svg,
      visPalette,
      positionsWithX,
      VISUALISATION_FONT_SETUPS.AXES_VALUES.SIZE
    );

    drawDefenderZoneAxes(
      svg,
      visPalette,
      canvasWidth,
      CHART_LAYOUT.AXES.PADDING.left
    );
  }, [visPalette, viewboxWidth]);

  /** bubbles - size/color/shape */
  useEffect(() => {
    const svg = select(ref.current);
    /* 
    No need to partition data because bubbles can't overlap 
    can have nice/easy opacity transform
    */
    const opacityZoneData = zoneData.map((zo) => {
      const opacity =
        isEmpty(selectedZones) || selectedZones.includes(zo.key) ? 1 : 0.4;
      return { ...zo, opacity, strokeWidth: 0 };
    });
    const dzG = svg.select(`.${HAVOC_SANKEY_CLASSES.DEFENDER_ZONES}`);

    drawBubbles(dzG, {
      visPalette,
      zones: opacityZoneData,
      bubbleScale,
      canvasWidth,
      canvasHeight: HAVOC_SANKEY_SIZES.DEFENDER_ZONE_HEIGHT,
      toggleZones,
    });
    const dzGhostG = svg.select(
      `.${HAVOC_SANKEY_CLASSES.DEFENDER_ZONES_GHOSTS}`
    );
    if (!isEmpty(zoneDataGhost)) {
      const ghostZoneData = zoneDataGhost.map((zo) => ({
        ...zo,
        opacity: 0,
        strokeWidth: 2,
      }));
      drawBubbles(dzGhostG, {
        visPalette,
        zones: ghostZoneData,
        bubbleScale,
        canvasWidth,
        canvasHeight: HAVOC_SANKEY_SIZES.DEFENDER_ZONE_HEIGHT,
        toggleZones,
      });
    } else {
      dzGhostG.selectAll('*').remove();
    }
  }, [visPalette, viewboxWidth, zoneData, zoneDomain, selectedZones]);

  useEffect(() => {
    const svg = select(ref.current);
    const sankeyG = svg.select(`.${HAVOC_SANKEY_CLASSES.SANKEY_LINES}`);
    sankeyG.selectAll('*').remove();
    drawSankeyPipes(sankeyG, { xValuePipes, canvasWidth });
  }, [visPalette, viewboxWidth, pipeData]);

  return <ClickableSVG ref={ref} id={id} />;
};

HavocSummaryChart.propTypes = {
  id: PropTypes.string,
  zoneDomain: PropTypes.arrayOf(PropTypes.number),
  zoneDataGhost: PropTypes.arrayOf(PropTypes.shape({})),
  zoneData: PropTypes.arrayOf(PropTypes.shape({})),
  pipeData: PropTypes.arrayOf(PropTypes.shape({})),
  viewboxWidth: PropTypes.number,
  selectedZones: PropTypes.arrayOf(PropTypes.string),
  setSelectedZones: PropTypes.func,
};

HavocSummaryChart.defaultProps = {
  id: 'havoc-sankey-chart',
  zoneDomain: [0, 1],
  zoneDataGhost: undefined,
  zoneData: undefined,
  pipeData: undefined,
  viewboxWidth: 1000,
  selectedZones: null,
  setSelectedZones: null,
};

export default HavocSummaryChart;
