/* Takes the API data and simplifies so object keys are the same
    as those generated from the raw data 
*/
export const formatLeagueAverageData = (leagueAverages) => {
  const formattedAverages = leagueAverages.map((laDatum) => ({
    gapCode: laDatum?.gap,
    defenderZoneCode: laDatum?.zone,
    playsPercentage: laDatum?.percentageOfAllPlays,
    havocPercentage: laDatum?.percentageOfHavocPlays,
  }));
  return formattedAverages;
};
