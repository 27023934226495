import React from 'react';
import PropTypes from 'prop-types';
import {
  PlayerBioStat,
  PlayerBioStatsRow,
  PlayerBioStatWrapper,
} from '../PlayerBio.Tile.styles';
import Tooltip from '../../../../components/Tooltip/Tooltip';
import StarRating from '../../../../components/StarRating/StarRating';
import {
  inchesToFeetAndInches,
  TABLE_NULL,
} from '../../../../utils/helpers/formatting';
import { formatValue } from '../../../../utils/helpers/stats.dataManipulation';
import { API_STAT_UNITS } from '../../../../utils/constants/api';

const PlayerBioNCAA = ({ playerBioData }) => {
  if (!playerBioData) return null;
  const {
    alignmentSnaps,
    height,
    weight,
    collegeClass,
    homeTown,
    homeState,
    highSchool,
    offered,
    recruitingClass,
    recruitingReranking,
    rankingComposite,
    ranking247,
  } = playerBioData;

  return (
    <>
      <PlayerBioStatsRow>
        <PlayerBioStatWrapper>
          <h4>{`Alignment Position${
            alignmentSnaps?.length > 1 ? 's' : ''
          }`}</h4>
          <PlayerBioStat>
            <ul>
              {alignmentSnaps &&
                alignmentSnaps.map((snap) => (
                  <li
                    key={snap.position}
                  >{`${snap.position} (${snap.total})`}</li>
                ))}
              {alignmentSnaps?.length === 0 && TABLE_NULL}
            </ul>
          </PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Height</h4>
          <PlayerBioStat>
            {height ? inchesToFeetAndInches(height) : TABLE_NULL}
          </PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Weight</h4>
          <PlayerBioStat>{weight ? `${weight} lbs` : TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>College class</h4>
          <PlayerBioStat>{collegeClass || TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Home town</h4>
          <PlayerBioStat>{homeTown || TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Home state</h4>
          <PlayerBioStat>{homeState || TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>High school</h4>
          <PlayerBioStat>{highSchool || TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>
      </PlayerBioStatsRow>

      <PlayerBioStatsRow $border={1}>
        <PlayerBioStatWrapper>
          <h4>Recruiting class</h4>
          <PlayerBioStat>{recruitingClass || TABLE_NULL}</PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Recruiting 247 ranking</h4>
          <PlayerBioStat>
            <span className="stars">
              <StarRating rating={ranking247 || 0} />
            </span>
            {ranking247 && ranking247}
          </PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Recruiting composite ranking</h4>
          <PlayerBioStat>
            <span className="stars">
              <StarRating rating={(rankingComposite || 0) * 100} />
            </span>
            {rankingComposite &&
              formatValue(rankingComposite, API_STAT_UNITS.RATE)}
          </PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4>Recruiting re-ranking</h4>
          <PlayerBioStat>
            <span className="stars">
              <StarRating rating={recruitingReranking || 0} />
            </span>
            {recruitingReranking}
          </PlayerBioStat>
        </PlayerBioStatWrapper>

        <PlayerBioStatWrapper>
          <h4> {`College${offered?.length > 1 ? 's' : ''} Offered`}</h4>

          {offered?.length > 0 && (
            <Tooltip
              content={offered.map((offer) => (
                <p key={offer}>{offer}</p>
              ))}
              target={
                <PlayerBioStat>
                  {`${offered.length} offer${offered.length > 1 ? 's' : ''}`}
                </PlayerBioStat>
              }
              withScroll
            />
          )}

          {!offered && <PlayerBioStat>{TABLE_NULL}</PlayerBioStat>}
        </PlayerBioStatWrapper>
      </PlayerBioStatsRow>
    </>
  );
};

PlayerBioNCAA.propTypes = {
  playerBioData: PropTypes.shape({
    alignmentSnaps: PropTypes.arrayOf(
      PropTypes.shape({
        position: PropTypes.string,
        total: PropTypes.number,
      })
    ),
    height: PropTypes.number,
    weight: PropTypes.number,
    collegeClass: PropTypes.string,
    highSchool: PropTypes.string,
    homeTown: PropTypes.string,
    homeState: PropTypes.string,
    attended: PropTypes.arrayOf(PropTypes.string),
    offered: PropTypes.arrayOf(PropTypes.string),
    recruitingClass: PropTypes.string,
    recruitingReranking: PropTypes.number,
    rankingComposite: PropTypes.number,
    ranking247: PropTypes.number,
  }),
};

PlayerBioNCAA.defaultProps = {
  playerBioData: {
    alignmentSnaps: [],
    height: 0,
    weight: 0,
    collegeClass: '',
    highSchool: '',
    homeTown: '',
    homeState: '',
    attended: [],
    offered: [],
    recruitingClass: '',
    recruitingReranking: null,
    rankingComposite: null,
    ranking247: null,
  },
};

export default PlayerBioNCAA;
