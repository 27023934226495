import { scaleLinear } from 'd3';
import { maxBy, minBy } from 'lodash';

/* 
    Intensity scaling:
    Find max/min values to get the scale domain
    Returns a function which then takes any value and returns the normalised 0-1 value
    This can then be passed to any coloring gradient
*/
export const getNormalisedIntensityScaler = ({
  data,
  dataKey,
  zeroMin = false,
}) => {
  const maxValue = maxBy(data, dataKey)?.[dataKey] || 1;
  const minValue = zeroMin ? 0 : minBy(data, dataKey)?.[dataKey] || 0;

  const normalisedIntensityScaler = scaleLinear()
    .domain([minValue, maxValue])
    .range([0, 1])
    .clamp(true);
  return normalisedIntensityScaler;
};

/* 
    Vs League Avergae Divergent scaling:
        Expect a value which is the reference value / corresponding league average
    value will be anything from 0 to infinity, but clamp at 2 (double the LA) so divergence is
        uniform and symmetrical
*/
export const leagueAverageDivergenceScaler = scaleLinear()
  .domain([0, 2])
  .range([0, 1])
  .clamp(true);
