import { styled } from 'styled-components';

export const PlayByPlayTable = styled.div`
  min-height: 70vh;

  table {
    thead {
      tr {
        height: 2.5rem;

        th {
          width: 11%;
          text-align: left;
        }
        // Drive Column
        th:nth-child(1) {
          width: 40px;
        }
        // Team on offense
        th:nth-child(2) {
          width: 12%;
        }

        th:nth-child(10) {
          width: 10%;
          max-width: 200px;

          button {
            float: right;
          }
        }
      }
    }

    tbody {
      tr {
        height: 2.5rem;
        width: 100%;

        td {
          width: 11%;
          text-align: left;
        }
        // Drive Column
        td:nth-child(1) {
          width: 40px;
        }
        // Team on offense
        td:nth-child(2) {
          width: 12%;
        }

        td:nth-child(10) {
          width: 10%;
          max-width: 200px;

          button {
            float: right;
          }
        }
      }
    }
  }
`;

export const PlayByPlayHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2rem;

  .active.section {
    font-size: 1.8rem;
    font-weight: 700;
  }
`;

export const PlayByPlayHeader = styled.h1`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.headingRegular};
  gap: ${({ theme }) => theme.sizing.small};

  span:first-of-type {
    margin-right: 1.5rem;
  }

  svg {
    min-height: 1.5rem;
    min-width: 1.5rem;
  }

  svg:nth-of-type(1) {
    path {
      fill: ${({ $selectedTeamColor }) => $selectedTeamColor};
    }
  }

  svg:nth-of-type(2) {
    path {
      fill: ${({ $opponentTeamColor }) => $opponentTeamColor};
    }
  }
`;
