import { styled } from 'styled-components';

export const TeamPlusMinusPage = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;

  > div:last-of-type {
    max-height: 21.2rem;
  }
`;

export const TeamPlusMinusHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const TeamPlusMinusControls = styled.div`
  display: flex;
  gap: 0.5rem;
`;

TeamPlusMinusPage.displayName = 'TeamPlusMinusPage';
TeamPlusMinusHeader.displayName = 'TeamPlusMinusHeader';
TeamPlusMinusControls.displayName = 'TeamPlusMinusControls';
