import { styled } from 'styled-components';

// TO DO: Mobile version of this
export const DriveBody = styled.div`
  width: 100%;
  min-width: 700px;
  position: relative;
  text-align: left;
  min-height: 20px;
  padding: 0 0.5rem 0.5rem;

  overflow: hidden;

  .plays-list-headers {
    display: flex;
    gap: 0.5rem;
    height: 2.5rem;
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0 0.5rem;
    align-items: center;
    background: ${({ theme }) => theme.colours.canvas.tertiary.main};
    color: ${({ theme }) => theme.colours.graphics.primary.main};
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .play-number {
    width: 5%;
  }

  .down-number {
    width: 10%;
  }

  .yards-to-go {
    width: 12.5%;
  }

  .yardline-of-the-snap {
    width: 15%;
  }

  .play-type {
    width: 10%;
  }

  .yards-gained-lost {
    width: 12.5%;
  }

  .play-outcome {
    width: 12.5%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .penalty-information {
    width: 22.5%;
  }
`;

export const PlaysList = styled.ul`
  width: 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 0;
  border-top: 2px solid ${({ theme }) => theme.colours.interface.main};
  background: ${({ theme }) => theme.colours.canvas.tertiary.main};

  li:last-of-type {
    border-bottom: transparent;
  }
`;

export const PlaysListItem = styled.li`
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  padding: 0.5rem;
  gap: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.colours.canvas.secondary.main}`};
  background: ${({ theme, $selected }) =>
    $selected ? `${theme.colours.interface.main}23` : ''};

  * {
    font-size: 0.75rem;
  }

  .play-values {
    display: flex;
    gap: 0.3125rem;
    height: 0.875rem;
  }

  .play-notes {
    font-size: 0.625rem;
    font-style: italic;
    color: ${({ theme }) => theme.colours.graphics.primary.main};
  }

  &.apb-offense-package {
    right: 50px;
    text-align: right;
    width: 200px;

    span {
      position: unset;
      margin-right: 5px;
    }
  }

  &.apb-defense-package {
    right: 50px;
    text-align: right;
    top: 20px;
    width: 200px;

    span {
      position: unset;
      margin-right: 5px;
    }
  }

  .buttons {
    position: absolute;
    right: 0.3125rem;
    display: flex;
    gap: 0.5rem;

    button {
      width: 2rem;
    }
  }
`;

export const OutcomeIndicator = styled.span`
  height: ${({ $size }) => $size || '0.75rem'};
  width: ${({ $size }) => $size || '0.75rem'};
  border-radius: 9999px;
  background-color: ${({ $colour }) => $colour};
`;

DriveBody.displayName = 'DriveBody';
PlaysList.displayName = 'PlaysList';
PlaysListItem.displayName = 'PlaysListItem';
OutcomeIndicator.displayName = 'OutcomeIndicator';
