import { scaleLinear } from 'd3';
import {
  ROUTE_TREE_BRANCH_DASH,
  ROUTE_TREE_BRANCH_RANGE,
} from '../RouteTree.constants';
import {
  ICON_LABEL_Y,
  ROUTE_TREE_KEY_BRANCH_SCALE_VALUES,
  ROUTE_TREE_KEY_FRUIT_SCALE_VALUES,
  ROUTE_TREE_KEY_FRUIT_SCALE_TEXT_VALUES,
  ROUTE_TREE_KEY_CLASSES,
  ROUTE_TREE_KEY_SIZE,
  SECTION_HEAD_Y,
} from './RouteTreeKey.constants';
import { appendText } from '../../text';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../utils/constants/visText';
import { formatValue } from '../../../utils/helpers/stats.dataManipulation';

export const branchIconScaler = scaleLinear()
  .domain([0, 1])
  .range([0, ROUTE_TREE_KEY_SIZE.BRANCH_SCALE_WIDTH])
  .clamp(true);
export const fruitIconScaler = scaleLinear()
  .domain([0, 1])
  .range([0, ROUTE_TREE_KEY_SIZE.FRUIT_SCALE_WIDTH])
  .clamp(true);

export const branchThicknessScaler = scaleLinear()
  .domain([0, 1])
  .range(ROUTE_TREE_BRANCH_RANGE)
  .clamp(true);

export const keyValueAlign = (value) => {
  if (value === 0) {
    return 'start';
  }
  if (value === 1) {
    return 'end';
  }
  return 'middle';
};

export const keyValueX = (value, isBranch = true) => {
  if (value === 0) {
    return isBranch ? branchIconScaler(value) : fruitIconScaler(value);
  }
  if (value === 1) {
    return isBranch
      ? branchIconScaler(value) + ROUTE_TREE_KEY_SIZE.BRANCH_RADIUS * 2
      : fruitIconScaler(value) + ROUTE_TREE_KEY_SIZE.FRUIT_RADIUS * 2;
  }
  return isBranch
    ? branchIconScaler(value) + ROUTE_TREE_KEY_SIZE.BRANCH_RADIUS
    : fruitIconScaler(value) + ROUTE_TREE_KEY_SIZE.FRUIT_RADIUS;
};

export const drawBranchKey = (branchG, visPalette, branchConfig) => {
  branchG.selectAll('text').remove();
  appendText(branchG, visPalette, {
    message: `Branch Thickness: ${branchConfig.abbrev}`,
    y: SECTION_HEAD_Y,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
  });

  const imagesG = branchG.select(
    `.${ROUTE_TREE_KEY_CLASSES.BRANCH_ZONE_IMAGES}`
  );
  const imageLabelsG = branchG.select(
    `.${ROUTE_TREE_KEY_CLASSES.BRANCH_ZONE_TEXT}`
  );

  const branchValues = ROUTE_TREE_KEY_BRANCH_SCALE_VALUES.map((val) => ({
    value: val,
    x: branchIconScaler(val),
    strokeWidth: branchThicknessScaler(val),
    strokeDash: val === 0 ? ROUTE_TREE_BRANCH_DASH : '',
    stroke: visPalette.selectedObject,
  }));
  imagesG
    .selectAll('path')
    .data(branchValues, (d) => d.val)
    .enter()
    .append('path')
    .attr(
      'd',
      (d) =>
        `M${d.x} ${ROUTE_TREE_KEY_SIZE.ICONS_SEGMENT / 2} l${
          ROUTE_TREE_KEY_SIZE.BRANCH_RADIUS * 2
        } 0`
    )
    .attr('stroke', (d) => d.stroke)
    .attr('stroke-width', (d) => d.strokeWidth)
    .attr('stroke-dasharray', (d) => d.strokeDash);

  const branchLabelScale = scaleLinear()
    .domain([0, 1])
    .range(branchConfig.domain)
    .clamp(true);

  imageLabelsG
    .selectAll('text')
    .data(ROUTE_TREE_KEY_BRANCH_SCALE_VALUES, (d) => d)
    .enter()
    .append('text')
    .attr('x', (d) => keyValueX(d))
    .attr('y', ICON_LABEL_Y)
    .attr('font-size', `${VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE}px`)
    .attr('font-family', DEFAULT_FONT)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
    .attr('text-anchor', (d) => keyValueAlign(d))
    .attr('fill', visPalette.text.info)
    .text((d) => formatValue(branchLabelScale(d), branchConfig.units));
};

export const drawFruitKey = (fruitG, visPalette, fruitConfig, isDarkMode) => {
  fruitG.selectAll('text').remove();

  const imagesG = fruitG.select(`.${ROUTE_TREE_KEY_CLASSES.FRUIT_ZONE_IMAGES}`);

  const imageLabelsG = fruitG.select(
    `.${ROUTE_TREE_KEY_CLASSES.FRUIT_ZONE_TEXT}`
  );
  fruitG.selectAll('text').remove();
  appendText(fruitG, visPalette, {
    message: `Fruit Color: ${fruitConfig.abbrev}`,
    y: SECTION_HEAD_Y,
    fontSize: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
    fontWeight: VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT,
  });

  const circleValues = ROUTE_TREE_KEY_FRUIT_SCALE_VALUES.map((val) => ({
    value: val,
    cx: fruitIconScaler(val) + ROUTE_TREE_KEY_SIZE.FRUIT_RADIUS,
    fill: fruitConfig.colorFunction(val, isDarkMode),
  }));
  imagesG.selectAll('circle').remove();
  imagesG
    .selectAll('circle')
    .data(circleValues, (d) => d.value)
    .enter()
    .append('circle')
    .attr('cx', (d) => d.cx)
    .attr('cy', ROUTE_TREE_KEY_SIZE.ICONS_SEGMENT / 2)
    .attr('r', ROUTE_TREE_KEY_SIZE.FRUIT_RADIUS)
    .attr('stroke', 'none')
    .attr('fill', (d) => d.fill);

  const fruitLabelScale = scaleLinear()
    .domain([0, 1])
    .range(fruitConfig.domain)
    .clamp(true);

  imageLabelsG
    .selectAll('text')
    .data(ROUTE_TREE_KEY_FRUIT_SCALE_TEXT_VALUES, (d) => d)
    .enter()
    .append('text')
    .attr('x', (d) => keyValueX(d, false))
    .attr('y', ICON_LABEL_Y)
    .attr('font-size', `${VISUALISATION_FONT_SETUPS.KEY_LABEL.SIZE}px`)
    .attr('font-family', DEFAULT_FONT)
    .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_LABEL.WEIGHT)
    .attr('text-anchor', (d) => keyValueAlign(d))
    .attr('fill', visPalette.text.info)
    .text((d) => formatValue(fruitLabelScale(d), fruitConfig.units));
};
