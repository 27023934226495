import React from 'react';
import PropTypes from 'prop-types';
import { SummaryStatWrapper } from './SummaryStat.styles';
import { formatValue } from '../../utils/helpers/stats.dataManipulation';
import { API_STAT_UNITS } from '../../utils/constants/api';

export const SummaryStat = ({ label, value, width, units }) => {
  const displayValue = units ? formatValue(value, units) : value;
  return (
    <SummaryStatWrapper $width={width}>
      <h4>{label}</h4>
      <div>{displayValue}</div>
    </SummaryStatWrapper>
  );
};

SummaryStat.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  units: PropTypes.oneOf(Object.values(API_STAT_UNITS)),
};

SummaryStat.defaultProps = {
  value: undefined,
  width: '31%',
  units: null,
};
