import React from 'react';
import PropTypes from 'prop-types';
import {
  PersonnelDistributionBars,
  ScaleIndicator,
} from './TeamDepthChart.styles';
import PersonnelDistributionBar from './PersonnelDistributionBar';

const PersonalDistributionBarsWrapper = ({
  personnelDistribution,
  offense,
}) => (
  <>
    <ScaleIndicator data-max={offense ? '70%' : '50%'} />
    <PersonnelDistributionBars>
      {personnelDistribution.map((p) => (
        <PersonnelDistributionBar
          key={p.label}
          snapCount={p.snapCount}
          snapRate={p.snapRate}
          leagueAverage={p.competitionRate}
          label={p.label}
          visibleMax={offense ? 0.7 : 0.5}
        />
      ))}
    </PersonnelDistributionBars>
  </>
);

export default PersonalDistributionBarsWrapper;

PersonalDistributionBarsWrapper.propTypes = {
  personnelDistribution: PropTypes.array.isRequired,
  offense: PropTypes.bool.isRequired,
};

PersonalDistributionBarsWrapper.defaultProps = {
  personnelDistribution: [],
  offense: true,
};
