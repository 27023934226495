import { isEmpty, reverse, sumBy } from 'lodash';
import {
  csDivergent,
  csIntensity,
} from '../../../../utils/helpers/colorScales';
import { API_OL_GAPS } from '../../../../utils/constants/api';
import { LINE_GAPS } from '../../../../utils/constants/gaps';
import { safeDivide } from '../../../../utils/helpers/maths';
import {
  HAVOC_GAP_BARS_SIZE_LIMITS,
  HAVOC_SUMMARY_COLORING_MDOES,
} from './HavocBars.constants';
import { havocEventsToGroupedHavocPlays } from '../HavocPlays.dataManipulation';
import {
  getNormalisedIntensityScaler,
  leagueAverageDivergenceScaler,
} from '../../../../utils/visualisations/gradients';

/** Turns data from general format (table ready) to shape expected for a bar chart
 * colorMode needs League Average values (as does title)
 */
export const prepGapSummary = ({
  gapData,
  gapAverages,
  percentageSetup,
  colorMode,
  isDark,
}) => {
  /* When a zone is selected, what fraction of the total (for zone) is this gap (vs other gaps) */
  const totValue = sumBy(gapData, percentageSetup.dataKey);

  /* Get normaliser functions for relative intensity, and vs league average */
  const valueTeamFractionScaler = getNormalisedIntensityScaler({
    data: gapData,
    dataKey: percentageSetup.dataKey,
  });
  const laValueScaler = leagueAverageDivergenceScaler;

  /* Want all gaps always, so start from the (ordered) gaps then add data */
  const rtlGaps = reverse(Object.values(API_OL_GAPS));
  const barData = rtlGaps.map((gapCode, i) => {
    const gapDatum = gapData?.find((g) => g.key === gapCode);
    const laDatum = gapAverages?.find((g) => g.gapCode === gapCode);
    const valueLA = laDatum?.[percentageSetup.dataKey] || 0;

    const gap = LINE_GAPS[gapCode];
    const value = gapDatum?.[percentageSetup.dataKey] || 0;
    /* scale 0-1 of gap value vs team values */
    const valueTeamFraction = valueTeamFractionScaler(value);
    /* scale 0-1 of gap value vs la value for gap */
    const valueLAFraction = laValueScaler(safeDivide(value, valueLA));

    const title =
      `${gap.name}` +
      `\n${percentageSetup.label}: ${(value * 100)?.toFixed(1)}` +
      `\nLeague Average: ${(valueLA * 100)?.toFixed(1)}`;

    const fill =
      colorMode === HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY.value
        ? csIntensity(valueTeamFraction, isDark)
        : csDivergent(valueLAFraction, isDark);

    const valFrac = safeDivide(value, totValue); // used for sankey pipes later
    return {
      id: gap.apiCode[0],
      gapIndex: i,
      xValue: gap.name,
      yValue: value,
      title,
      fill,
      stroke: 'transparent',
      valFrac,
      value,
      valueLA,
    };
  });

  return barData;
};

/*
Converts events into grouped data, and then re-formats that data for bar chart (scales/colors it)
If a defender zone selection is made, the bars have background-mode bars that display the unfiltered values
*/
export const getBarData = ({
  havocData,
  gapAverages,
  playSummary,
  selectedDefenderZones,
  aggregationMode, // snap gap or exploited gap
  percentageSetup, // HAVOC_SUMMARY_PERCENTAGE_TYPES
  colorMode, // HAVOC_SUMMARY_COLORING_MDOES
  isDark,
}) => {
  /* Always have the basic gap data */
  const { groupData: gapData } = havocEventsToGroupedHavocPlays({
    havocData,
    aggregationMode,
    playSummary,
  });

  const rawBarData = prepGapSummary({
    gapData,
    gapAverages,
    percentageSetup,
    colorMode,
    isDark,
  });
  /* repeat for just the relevant filtered info if selection has been made via defender zone */
  if (!isEmpty(selectedDefenderZones)) {
    const selectedDZoneData = havocData.filter((d) =>
      selectedDefenderZones.includes(d.defenderZoneAPICode)
    );

    const { groupData: filteredGapData } = havocEventsToGroupedHavocPlays({
      havocData: selectedDZoneData,
      aggregationMode,
      playSummary,
    });
    const filteredBarData = prepGapSummary({
      gapData: filteredGapData,
      gapAverages,
      percentageSetup,
      colorMode,
      isDark,
    });

    return { barData: filteredBarData, unfilteredBarData: rawBarData };
  }
  return { barData: rawBarData, unfilteredBarData: null };
};

export const barNameUpdate = ({ barData, viewboxWidth }) => {
  if (viewboxWidth < HAVOC_GAP_BARS_SIZE_LIMITS.MIN_FULL_TEXT_WIDTH) {
    const shortNameBars = barData?.map((bar) => {
      const gap = LINE_GAPS[bar.id];
      return { ...bar, xValue: gap.code };
    });
    return shortNameBars;
  }
  return barData;
};
