import { isEmpty } from 'lodash';
import { formatValue } from '../../../utils/helpers/stats.dataManipulation';
import {
  DEFENSIVE_SCHEME_AVG_KEYS,
  DEFENSIVE_SCHEME_GROUPS,
} from './TeamDefensiveScheme.constants';

// format data for table
export const formatDefensiveScheme = (data, headers, average = false) => {
  if (!data?.length || !headers?.length) return [];

  return data.map((stat) =>
    headers.reduce(
      (row, h) => {
        let label = formatValue(stat[h.key], h.units);

        // Check if label is a positive number or contains '%' and no '-'
        // exclude playCount from this check
        const isPositiveNumber = parseFloat(label) > 0;
        const containsPercentAndNoNegative =
          typeof label === 'string' &&
          label.includes('%') &&
          !label.includes('-');

        if (
          average &&
          (isPositiveNumber || containsPercentAndNoNegative) &&
          h.key !== 'playCount'
        ) {
          label = `+${label}`;
        }

        return { ...row, [h.key]: { ...h, label } };
      },
      { id: stat.playIds }
    )
  );
};

// assumes matching keys and order between team and league data
export const versusLeagueAvg = (data, headers, group) => {
  if (!data?.length || !headers?.length) return [];

  const leagueData = data?.filter(
    (d) =>
      d.statsType === DEFENSIVE_SCHEME_GROUPS[group.toUpperCase()] &&
      d.team === DEFENSIVE_SCHEME_AVG_KEYS.LEAGUE
  );
  const teamData = data?.filter(
    (d) =>
      d.statsType === DEFENSIVE_SCHEME_GROUPS[group.toUpperCase()] &&
      d.team === DEFENSIVE_SCHEME_AVG_KEYS.TEAM
  );

  return teamData.map((team, index) => {
    const leagueAverage = leagueData[index];
    const result = {};

    Object.keys(team).forEach((key) => {
      if (
        leagueAverage[key] !== undefined &&
        typeof team[key] === 'number' &&
        key !== 'playCount'
      ) {
        result[key] = team[key] - leagueAverage[key];
      } else {
        result[key] = team[key];
      }
    });

    return result;
  });
};

export const formatDefensiveSchemeCSV = (data, headers) => {
  if (!data?.length || !headers?.length) return '';

  const csvRows = data.map((row) =>
    headers
      .map((h) => {
        const formattedValue = formatValue(row[h.key], h.units);
        return typeof formattedValue === 'object'
          ? JSON.stringify(formattedValue?.label)
          : `"${String(formattedValue).replace(/"/g, '""')}"`;
      })
      .join(',')
  );
  return [...csvRows].join('\n');
};

export const groupDataByLabel = (data) => {
  if (isEmpty(data)) return [];

  // group data by label
  const topLevelGroupings = data
    ?.filter((d) => d.category?.label === 'ALL')
    .map((t) => ({
      ...t,
      category: {
        ...t.category,
        label: t.group?.label,
      },
      children: [],
    }));

  // add children to top level groupings
  data
    ?.filter((d) => d.category?.label !== 'ALL')
    .forEach((item) => {
      const { label } = item.group;

      topLevelGroupings
        ?.find((t) => t.group.label === label)
        ?.children.push(item);
    });

  // ensure that unknown groupings are at the bottom
  topLevelGroupings?.sort((a, b) => {
    const aEmpty = Array.isArray(a.children) && a.children.length === 0;
    const bEmpty = Array.isArray(b.children) && b.children.length === 0;
    if (aEmpty && !bEmpty) return 1;
    if (!aEmpty && bEmpty) return -1;
    return 0;
  });

  return topLevelGroupings;
};
