import React from 'react';
import ParsedLink from '../ParsedLink/ParsedLink';

const LogoLink = ({ id, logo, alt }) => (
  <ParsedLink href="/">
    <img src={logo} data-testid={`logo-link-${id}`} alt={alt} />
  </ParsedLink>
);

export default LogoLink;
