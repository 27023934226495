import { useQuery } from '@apollo/client';
import { ERROR_CODES } from '../errorCodes/errorCodes';
import { GET_PLAY_PLAYERS_VIDEO } from './apiQueries/video/getPlaysPlayersVideo';
import { getRosterPositionColorByCode } from '../helpers/positions';

// given an array of plays, return all players associated with that play
export const useGetPlayPlayers = (playIds, visPalette) => {
  const { data, loading, error } = useQuery(GET_PLAY_PLAYERS_VIDEO, {
    skip: !playIds || playIds.length === 0,
    variables: {
      playIds,
    },
  });

  if (loading) {
    return { isLoading: loading };
  }

  if (error) {
    console.error(ERROR_CODES.GET_PLAY_PLAYERS, error);
    return {
      isLoading: false,
      data: error,
    };
  }

  return {
    isLoading: false,
    data: data?.playPlayersVideo?.map((play) => ({
      playId: play.playId,
      highlightPlayers: play.players.map((player) => ({
        id: player.playerId,
        name: player.name,
        number: player.jersey,
        colour: getRosterPositionColorByCode(player?.positionCode, visPalette),
      })),
    })),
  };
};
