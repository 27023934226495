import { styled } from 'styled-components';

export const SortableTableContainer = styled.div`
  overflow-x: auto;
  ${({ $isHeadSticky, $nonTableHeight }) =>
    $isHeadSticky &&
    `max-height: calc(100vh - ${$nonTableHeight || '34.5rem'});`}

  // hide corner-square when overflow-x and overflow-y being used simultaneously
    ::-webkit-scrollbar-corner {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colours.layering[0].main};
  }

  table {
    // table-layout: fixed;
    border: ${({ $withBorder }) =>
      $withBorder
        ? `1px solid ${({ theme }) => theme.colours.canvas.secondary.weak}`
        : 'none'};
  }
  tbody {
    tr td {
      height: ${({ $rowHeight }) => $rowHeight || '1.5rem'};

      &.hasChevron {
        font-weight: bold;
        text-transform: capitalize;

        button {
          position: absolute;
          top: 0.25rem;
          right: 0.25rem;
        }
      }
    }
    tr:hover td {
      background-color: ${({ theme }) => theme.colours.canvas.secondary.strong};

      .hover-svg {
        svg {
          background-color: ${({ theme }) =>
            theme.colours.canvas.secondary.strong};
        }
      }
    }
  }
  tfoot {
    position: ${({ $isHeadSticky }) => $isHeadSticky && 'sticky'};
    top: 0;
    bottom: 0;
    inset-block-start: 0;
    inset-block-end: 0;
    z-index: 2;

    tr td {
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
      color: ${({ theme }) =>
        theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
      border-top: 2px solid ${({ theme }) => theme.colours.interface.main};
      font-weight: 600;
      height: ${({ $rowHeight }) => $rowHeight || '1.5rem'};
    }
  }

  a {
    text-decoration: underline;
  }
`;

SortableTableContainer.displayName = 'SortableTableContainer';

export const HeaderWithButtons = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
    border-color: ${({ theme }) => theme.colours.canvas.primary.main};
    border-width: 1px;
    border-style: solid;
    border-radius: 0.25rem;
    width: 100%;
    top: 16px;
    padding: 4px 8px;
    min-width: 74px;
    z-index: 1;
  }

  .dropdown-content > button {
    margin-left: 8px;
    float: left;
  }
  .dropdown-content > button:first-child {
    margin-left: 0px;
  }

  &:hover .dropdown-content {
    display: block;
  }
`;
HeaderWithButtons.displayName = 'HeaderWithButtons';
