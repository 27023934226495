import React, { useEffect, useState } from 'react';
import { isEmpty, uniqBy } from 'lodash';
import { Grid } from '@statsbomb/kitbag-components';
import { useReactiveVar } from '@apollo/client';
import { mf_TeamDetails } from '../../../../apollo';
import useQueryString from '../../../../utils/hooks/useQueryString';
import {
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../../components/Layout/Layout.styles';
import { HavocMainPage } from '../TeamHavoc.styles';
import {
  HAVOC_POSITION_OPTION_ANY,
  HAVOC_TYPE_ALL,
  HAVOC_PLAYER_OPTION_ANY,
  HAVOC_PLAY_TYPES,
  HAVOC_BLITZ_OPTION_ANY,
  HAVOC_DEFENSIVE_FRONT_OPTION_ANY,
  HAVOC_COLOR_MODE_LOCATIONS_TIME,
  HAVOC_FOCUS_MODE_BOX,
  HAVOC_FOCUS_MODE_FIELD,
} from '../../../../visualisations/HavocChart/HavocChart.constants';

import {
  useGetHavocAverages,
  useGetHavocEvents,
  useGetHavocTeamStats,
} from '../TeamHavoc.hooks';
import {
  getPlayers,
  getPositions,
  getBlitzTypes,
  getDefensiveFrontTypes,
  getHavocTotals,
  filterHavocData,
  getZonedHavocData,
} from '../TeamHavoc.DataManipulation';
import HavocSelection from '../HavocSelection';
import HavocSummaryBar from '../HavocSummaryBar/HavocSummaryBar';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import HavocTableTile from '../HavocTable.jsx/HavocTableTile';
import {
  HAVOC_TABLE_AGGREGATION_MODE_TYPE,
  HAVOC_TABLE_AGGREGATION_MODES,
} from '../HavocTable.jsx/HavocTable.constants';
import HavocTableFilters from '../HavocTable.jsx/HavocTableFilters';
import HavocBarTile from '../HavocBars/HavocBarTile';
import {
  HAVOC_SUMMARY_COLORING_MDOES,
  HAVOC_SUMMARY_PERCENTAGE_TYPES,
} from '../HavocBars/HavocBars.constants';
import HavocBarFilters from '../HavocBars/HavocBarFilters';
import { ROTATIONS } from '../../../../utils/constants/charting';
import HavocEventMapTile from '../HavocEventMap/HavocEventMapTile';
import HavocEventFilters from '../HavocEventMap/HavocEventFilters';
import HavocVideoSection from '../HavocVideo/HavocVideoSection';
import { useExportSeasonInfo } from '../../../../utils/hooks/useExportSeasonInfo';

const HavocSummary = () => {
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  const teamName = mf_TeamDetailsRV?.name;

  /** selected event will open video / change layout
   * but for now in summary can just be always off
   * but included to maintain logic parity in layout elements
   */
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const { seasonName } = useExportSeasonInfo({
    asObject: true,
  });

  const [showDefensive, setShowDefensive] = useQueryString('defensive', true);
  const [havocPlayMode, setHavocPlayMode] = useQueryString(
    'havocPlayMode',
    HAVOC_PLAY_TYPES.ANY.value
  );
  const [havocMode, setHavocMode] = useQueryString(
    'havocmode',
    HAVOC_TYPE_ALL.value
  );
  const [selectedPlayerId, setSelectedPlayerId] = useQueryString(
    'player',
    HAVOC_PLAYER_OPTION_ANY.value
  );
  const [selectedPositionCode, setSelectedPositionCode] = useQueryString(
    'position',
    HAVOC_POSITION_OPTION_ANY.value
  );
  const [selectedBlitzType, setSelectedBlitzType] = useQueryString(
    'blitz',
    HAVOC_BLITZ_OPTION_ANY.value
  );
  const [selectedDefensiveFront, setSelectedDefensiveFront] = useQueryString(
    'front',
    HAVOC_DEFENSIVE_FRONT_OPTION_ANY.value
  );
  /* For tables */
  const [aggregationMode, setAggregationMode] = useQueryString(
    'aggregationmode',
    HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP
  );
  /* For Summary Chart */
  const [percentageMode, setPercentageMode] = useQueryString(
    'percentageMode',
    HAVOC_SUMMARY_PERCENTAGE_TYPES.OF_ALL_PLAYS.value
  );
  const [summaryColorMode, setSummaryColorMode] = useQueryString(
    'summaryColorMode',
    HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY.value
  );
  const [selectedGaps, setSelectedGaps] = useState([]);
  const [selectedDefenderZones, setSelectedDefenderZones] = useState([]);
  /* For events chart */
  const [selectedRotation, setSelectedRotation] = useQueryString(
    'rotation',
    ROTATIONS.VERTICAL_DOWN
  );
  const [fieldFocus, setFieldFocus] = useQueryString(
    'fieldFocused',
    HAVOC_FOCUS_MODE_BOX.value
  );
  const [colorMode, setColorMode] = useQueryString(
    'color',
    HAVOC_COLOR_MODE_LOCATIONS_TIME.value
  );
  const [showPaths, setShowPaths] = useQueryString('showpaths', false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  /* For video */
  const [nowPlayingPlayUUID, setNowPlayingPlayUUID] = useState(null);

  /* Get the data */
  const {
    loading: isLoadingHavoc,
    error: errorHavoc,
    teamHavocData,
  } = useGetHavocEvents(showDefensive, havocMode);

  const {
    loading: isLoadingPlays,
    error: errorSummary,
    playSummary,
  } = useGetHavocTeamStats(showDefensive);
  const isError = !!errorHavoc || !!errorSummary;
  const isLoadingSummary = isLoadingHavoc || isLoadingPlays;

  const { loading: isLoadingLAGap, havocAverageCounts: gapAverages } =
    useGetHavocAverages(true, havocMode, selectedPositionCode);
  /* Zone LA response checked for validity, needed for next part of vis */
  const { loading: isLoadingLAZone, havocAverageCounts: zoneAverages } =
    useGetHavocAverages(false, havocMode, selectedPositionCode);

  /* Define the list of players and positions to filter on */
  const relevantPlayers = getPlayers(teamHavocData);
  const positionFrequencies = getPositions(teamHavocData);
  const blitzTypes = getBlitzTypes(teamHavocData);
  const defensiveFronts = getDefensiveFrontTypes(teamHavocData);

  /* Get the havoc events that correspond to the filtering */
  const filteredHavocData = filterHavocData({
    teamHavocData,
    selectedPlayerId,
    selectedBlitzType,
    selectedDefensiveFront,
    selectedPositionCode,
  });

  /* Summary bar stuff ~ based on filtering */
  const havocCounts = getHavocTotals(filteredHavocData);
  const havocSummary = {
    havocPlays: havocCounts.zonedHavocPlays,
    havocEvents: havocCounts.zonedHavocEvents,
    havocEventsAll: havocCounts.allHavocEvents,
  };

  /* Summary chart stuff */
  const zonedHavocEvents = getZonedHavocData(filteredHavocData);

  /* Event chart stuff */
  const setRotation = (newRotation) => {
    if (newRotation === ROTATIONS.HORIZONTAL) {
      setFieldFocus(HAVOC_FOCUS_MODE_FIELD.value);
    } else {
      setFieldFocus(HAVOC_FOCUS_MODE_BOX.value);
    }
    setSelectedRotation(newRotation);
  };

  /** Page level display settings */
  const [displayTable, setDisplayTable] = useQueryString(
    'havoc-summary-display-table',
    false
  );
  const [displayTableEvents, setDisplayTableEvents] = useQueryString(
    'havoc-events-display-table',
    false
  );

  /* Export naming */
  const aggregateModeName = HAVOC_TABLE_AGGREGATION_MODES.find(
    (f) => f.value === aggregationMode
  ).label;

  /* 
  selection of gap / pipe resets the other & filters events
  selection of events resets gap / pipe
  */
  const gapBarsGapsSelected = (gaps) => {
    if (!isEmpty(selectedDefenderZones)) {
      setSelectedDefenderZones([]);
    }
    setSelectedGaps(gaps);
    if (isEmpty(gaps)) {
      setSelectedEvents([]);
    } else {
      const selectedGapEventUUIDs = filteredHavocData
        .filter((d) => gaps.includes(d.exploitedGapAPICode))
        .map((d) => d.eventUUID);
      setSelectedEvents(selectedGapEventUUIDs);
    }
  };
  const defenderZoneBubbleSelected = (defenderZones) => {
    if (!isEmpty(selectedGaps)) {
      setSelectedGaps([]);
    }
    setSelectedDefenderZones(defenderZones);
    if (isEmpty(defenderZones)) {
      setSelectedEvents([]);
    } else {
      const selectedZoneEventUUIDs = filteredHavocData
        .filter((d) => defenderZones.includes(d.defenderZoneAPICode))
        .map((d) => d.eventUUID);
      setSelectedEvents(selectedZoneEventUUIDs);
    }
  };
  const eventMapEventsSelected = (events) => {
    if (!isEmpty(selectedDefenderZones)) {
      setSelectedDefenderZones([]);
    }
    if (!isEmpty(selectedGaps)) {
      setSelectedGaps([]);
    }
    setSelectedEvents(events);
  };

  /* For summary/video need unique plays amongst the selected plays */
  const selectedEventData = filteredHavocData.filter((f) =>
    selectedEvents?.includes(f.eventUUID)
  );
  const selectedEventPlays = uniqBy(selectedEventData, 'playUUID');
  const havocSummarySelected = isEmpty(selectedEvents)
    ? null
    : {
        havocPlays: selectedEventPlays.length,
        havocEvents: selectedEventData.length,
      };
  useEffect(() => {
    setNowPlayingPlayUUID(null);
    if (showVideoPlayer && !isEmpty(selectedEvents)) {
      const selectedEventObj = filteredHavocData?.find(
        (e) => e.eventUUID === selectedEvents[0]
      );
      setNowPlayingPlayUUID(selectedEventObj.playUUID);
    } else if (isEmpty(selectedEvents)) {
      setShowVideoPlayer(false);
    }
  }, [selectedEvents, showVideoPlayer]);
  const onPlaylistChange = (currentlyPlayingPlay) => {
    setNowPlayingPlayUUID(currentlyPlayingPlay?.id);
  };
  const showVideoClicked = () => {
    if (isEmpty(selectedEvents)) {
      /* select all events to show video of them */
      const selectedZoneEventUUIDs = zonedHavocEvents.map((d) => d.eventUUID);
      setSelectedEvents(selectedZoneEventUUIDs);
    }
    setShowVideoPlayer(true);
  };
  const hideVideoClicked = () => {
    setShowVideoPlayer(false);
  };
  const clearSelectionClicked = () => {
    setSelectedGaps([]);
    setSelectedDefenderZones([]);
    setSelectedEvents([]);
    setShowVideoPlayer(false);
  };

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <HavocMainPage>
            {showVideoPlayer && (
              <HavocVideoSection
                havocData={filteredHavocData}
                selectedEvents={selectedEvents}
                nowPlayingPlayUUID={nowPlayingPlayUUID}
                handleClose={hideVideoClicked}
                onPlaylistChange={onPlaylistChange}
              />
            )}
            <div
              className={showVideoPlayer ? 'havoc-non-video' : 'havoc-no-video'}
            >
              <SidebarRightLayout
                $sidebarWidth={showVideoPlayer ? '0px' : '320px'}
                $gap="0.5rem"
                $padding="0"
              >
                <div>
                  <PageHeader
                    href="/team/havoc/:leagues/:seasons/:teams"
                    rootPage="Team"
                    activePage="Havoc Events"
                    showPlayerName={false}
                    showTeamName
                  />
                  <HavocSummaryBar
                    playSummary={playSummary}
                    havocSummary={havocSummary}
                    havocSummarySelected={havocSummarySelected}
                    showVideoClicked={showVideoClicked}
                    clearSelectionClicked={clearSelectionClicked}
                    isError={isError} // if a problem, just list once up top
                  />

                  {!displayTable && (
                    <HavocBarTile
                      havocData={zonedHavocEvents}
                      playSummary={playSummary}
                      isLoadingSummary={isLoadingSummary}
                      handleVisTableSwitch={() =>
                        setDisplayTable(!displayTable)
                      }
                      colorMode={summaryColorMode}
                      havocSummaryMode={percentageMode}
                      selectedGaps={selectedGaps}
                      setSelectedGaps={gapBarsGapsSelected}
                      selectedDefenderZones={selectedDefenderZones}
                      setSelectedDefenderZones={defenderZoneBubbleSelected}
                      isLoadingLA={isLoadingLAGap || isLoadingLAZone}
                      gapAverages={gapAverages}
                      zoneAverages={zoneAverages}
                    />
                  )}
                  {displayTable && (
                    <HavocTableTile
                      havocData={zonedHavocEvents}
                      aggregationMode={aggregationMode}
                      playSummary={playSummary}
                      tileTitle="Havoc Plays Summary"
                      fileName={`${teamName} ${seasonName} havoc play counts ${aggregateModeName}.csv`}
                      handleVisTableSwitch={() =>
                        setDisplayTable(!displayTable)
                      }
                      isPlaysMode
                    />
                  )}

                  {!displayTableEvents && (
                    <HavocEventMapTile
                      havocData={filteredHavocData}
                      isEventsLoading={isLoadingHavoc}
                      selectedRotation={selectedRotation}
                      colorMode={colorMode}
                      fieldFocus={fieldFocus}
                      showPaths={showPaths}
                      relevantPlayers={relevantPlayers}
                      positionFrequencies={positionFrequencies}
                      tileTitle="Havoc Events"
                      havocMode={havocMode}
                      showDefensive={showDefensive}
                      selectedPlayerId={selectedPlayerId}
                      selectedPositionCode={selectedPositionCode}
                      selectedEvents={selectedEvents}
                      setSelectedEvents={eventMapEventsSelected}
                      handleVisTableSwitch={() =>
                        setDisplayTableEvents(!displayTableEvents)
                      }
                      nowPlayingPlayUUID={nowPlayingPlayUUID}
                    />
                  )}
                  {displayTableEvents && (
                    <HavocTableTile
                      havocData={filteredHavocData}
                      aggregationMode={aggregationMode}
                      tileTitle="Havoc Events"
                      fileName={`${teamName} ${seasonName} havoc events ${aggregateModeName}.csv`}
                      handleVisTableSwitch={() =>
                        setDisplayTableEvents(!displayTableEvents)
                      }
                    />
                  )}
                </div>
                <div>
                  {!showVideoPlayer && (
                    <>
                      <HavocSelection
                        showDefensive={showDefensive}
                        setShowDefensive={setShowDefensive}
                        havocPlayMode={havocPlayMode}
                        setHavocPlayMode={setHavocPlayMode}
                        havocMode={havocMode}
                        setHavocMode={setHavocMode}
                        selectedBlitzType={selectedBlitzType}
                        setSelectedBlitzType={setSelectedBlitzType}
                        selectedDefensiveFront={selectedDefensiveFront}
                        setSelectedDefensiveFront={setSelectedDefensiveFront}
                        selectedPlayerId={selectedPlayerId}
                        setSelectedPlayerId={setSelectedPlayerId}
                        selectedPositionCode={selectedPositionCode}
                        setSelectedPositionCode={setSelectedPositionCode}
                        relevantPlayers={relevantPlayers}
                        positionFrequencies={positionFrequencies}
                        blitzTypes={blitzTypes}
                        defensiveFronts={defensiveFronts}
                      />
                      <HavocTableFilters
                        aggregationMode={aggregationMode}
                        setAggregationMode={setAggregationMode}
                      />
                      <HavocBarFilters
                        percentageMode={percentageMode}
                        setPercentageMode={setPercentageMode}
                        summaryColorMode={summaryColorMode}
                        setSummaryColorMode={setSummaryColorMode}
                      />
                      <HavocEventFilters
                        colorMode={colorMode}
                        setColorMode={setColorMode}
                        selectedRotation={selectedRotation}
                        setRotation={setRotation}
                        fieldFocus={fieldFocus}
                        setFieldFocus={setFieldFocus}
                        showPaths={showPaths}
                        setShowPaths={setShowPaths}
                      />
                    </>
                  )}
                </div>
              </SidebarRightLayout>
            </div>
          </HavocMainPage>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default HavocSummary;
