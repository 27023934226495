import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@statsbomb/kitbag-components';
import { Loader } from 'semantic-ui-react';
import { orderBy } from 'lodash';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileHeader,
} from '../../../components/Tile/TileSummary.styles';
import SelectableSortableTable from '../../../components/Tables/SortableTable/SelectableSortableTable';
import Dimmer from '../../../components/Dimmer/Dimmer';
import PlaylistButton from '../../../components/buttons/Playlist/Playlist';
import DownloadCSVButton from '../../../components/buttons/DownloadCSV';
import BannerError from '../../../components/Banners/Banner.Error';
import {
  DEFENSIVE_SCHEME_AVG_KEYS,
  DEFENSIVE_SCHEME_COLUMNS,
  DEFENSIVE_SCHEME_GROUPS,
  DEFENSIVE_SCHEME_STICKY_COLUMNS,
} from './TeamDefensiveScheme.constants';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import { useGetPlayPlayers } from '../../../utils/hooks/useGetPlaysPlayersVideo';
import {
  formatDefensiveScheme,
  formatDefensiveSchemeCSV,
  groupDataByLabel,
  versusLeagueAvg,
} from './TeamDefensiveScheme.dataManipulation';
import useQueryString from '../../../utils/hooks/useQueryString';

const DefensiveSchemeTile = ({
  title,
  data,
  isLoading,
  hasError,
  teamType,
  statsType,
  fileName,
  isLeagueAvg,
  onPlaylistClick,
  isVideoVisible,
  visPalette,
}) => {
  const { DEFAULT, DESCENDING, ASCENDING } = SORT_DIRECTIONS;
  const [schemeData, setSchemeData] = useState([]);
  const [schemeCSVData, setSchemeCSVData] = useState([]);
  const [plays, setPlays] = useState([]);
  const [sortBy, setSortBy] = useQueryString(
    `${title.replace(/ /g, '')}SB`,
    ''
  );
  const [sortDirection, setSortDirection] = useQueryString(
    `${title.replace(/ /g, '')}SD`,
    DEFAULT
  );
  const playsVideo = useGetPlayPlayers(plays, visPalette);

  useEffect(() => {
    const rawSchemeData = formatDefensiveScheme(
      data?.defensiveScheme?.filter(
        (d) => d.statsType === statsType && d.team === teamType
      ),
      DEFENSIVE_SCHEME_COLUMNS
    );

    const leagueAvgSchemeData = formatDefensiveScheme(
      versusLeagueAvg(
        data?.defensiveScheme,
        DEFENSIVE_SCHEME_COLUMNS,
        statsType
      ),
      DEFENSIVE_SCHEME_COLUMNS,
      true
    );

    const sortedSchemeData = orderBy(
      isLeagueAvg ? leagueAvgSchemeData : rawSchemeData,
      [`${sortBy}.label`],
      [sortDirection]
    );

    setSchemeData(groupDataByLabel(sortedSchemeData));
    setSchemeCSVData(sortedSchemeData);
  }, [data, statsType, teamType, sortBy, sortDirection, isLeagueAvg]);

  const schemeCSV = formatDefensiveSchemeCSV(
    schemeCSVData,
    DEFENSIVE_SCHEME_COLUMNS
  );

  const highlightCol =
    DEFENSIVE_SCHEME_COLUMNS.findIndex((h) => h.key === sortBy) > 0
      ? DEFENSIVE_SCHEME_COLUMNS.findIndex((h) => h.key === sortBy) + 1
      : null;

  const handleSort = (headerId) => {
    if (sortDirection === DEFAULT || sortBy !== headerId) {
      setSortBy(headerId);
      setSortDirection(DESCENDING);
    } else if (sortBy === headerId && sortDirection === DESCENDING) {
      setSortDirection(ASCENDING);
    } else if (sortBy === headerId && sortDirection === ASCENDING) {
      setSortDirection(DEFAULT);
      setSortBy(null);
    }
  };

  // return playIds from table row highlighting
  const getCheckedPlayIds = (arr) => {
    const extractPlayIds = (item) => {
      let playIds = item.checked ? item.id : [];

      if (item.children && item.children.length > 0) {
        item.children.forEach((child) => {
          playIds = playIds.concat(extractPlayIds(child));
        });
      }

      return playIds;
    };

    return arr.flatMap(extractPlayIds);
  };

  // table row clicks, sortableTable handles internals
  const handleRowClick = (rows) => {
    setSchemeData(rows);
    setPlays(getCheckedPlayIds(rows));
  };

  return (
    <SummaryTile>
      <SummaryTileHeader
        $borderWidth="0"
        $padding="1rem 1rem 0 1rem"
        $height="2.5rem"
      >
        <Typography variant="headingMedium">{title}</Typography>
        <div>
          <PlaylistButton
            handleClick={() => onPlaylistClick(title, playsVideo)}
            numberOfPlays={plays.length}
            isDisabled={plays.length === 0 || isLoading || isVideoVisible}
            variant={plays && plays.length > 0 ? 'primary' : 'secondary'}
            tooltip="Select plays from the table below"
          />
          <DownloadCSVButton
            data={schemeCSV}
            headers={DEFENSIVE_SCHEME_COLUMNS?.map((h) => h.label)}
            fileName={fileName}
            data-testid={`${title.replace(/ /g, '')}CSVButton`}
            isDisabled={isLoading || hasError}
          />
        </div>
      </SummaryTileHeader>
      <SummaryTileBody $minHeight={isLoading ? '26rem' : 'auto'}>
        {isLoading && (
          <Dimmer active>
            <Loader content="Loading" />
          </Dimmer>
        )}
        {hasError && !isLoading && (
          <Dimmer active>
            <BannerError message=" There has been an error. Please contact support." />
          </Dimmer>
        )}
        <SelectableSortableTable
          name={`${title.toLowerCase().replace(/ /g, '-')}-table`}
          headers={DEFENSIVE_SCHEME_COLUMNS}
          data={schemeData}
          handleSort={handleSort}
          stickyColumns={DEFENSIVE_SCHEME_STICKY_COLUMNS}
          sortDirection={sortDirection}
          sortKey={sortBy}
          handleToggle={handleRowClick}
          highlightedColumn={highlightCol}
        />
      </SummaryTileBody>
    </SummaryTile>
  );
};

DefensiveSchemeTile.propTypes = {
  title: PropTypes.string,
  data: PropTypes.shape({
    selectedCompetitionId: PropTypes.number,
    selectedSeasonId: PropTypes.number,
    selectedTeamId: PropTypes.number,
    defensiveScheme: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  teamType: PropTypes.oneOf([
    DEFENSIVE_SCHEME_AVG_KEYS.TEAM,
    DEFENSIVE_SCHEME_AVG_KEYS.LEAGUE,
  ]),
  statsType: PropTypes.oneOf([
    DEFENSIVE_SCHEME_GROUPS.FRONT,
    DEFENSIVE_SCHEME_GROUPS.BLITZ,
    DEFENSIVE_SCHEME_GROUPS.COVERAGE,
  ]),
  fileName: PropTypes.string,
  isLeagueAvg: PropTypes.bool,
  onPlaylistClick: PropTypes.func,
  isVideoVisible: PropTypes.bool,
  visPalette: PropTypes.shape({}),
};

DefensiveSchemeTile.defaultProps = {
  title: '',
  data: { defensiveScheme: [] },
  isLoading: false,
  hasError: false,
  teamType: DEFENSIVE_SCHEME_AVG_KEYS.TEAM,
  statsType: DEFENSIVE_SCHEME_GROUPS.FRONT,
  fileName: '',
  isLeagueAvg: false,
  onPlaylistClick: null,
  isVideoVisible: false,
  visPalette: {},
};

export default DefensiveSchemeTile;
