import {
  SideNavigationMenuList,
  SideNavigationItem,
} from '@statsbomb/kitbag-components';
import React from 'react';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import PropTypes from 'prop-types';
import SubMenu from './SubMenu';
import { useReactiveState } from '../../utils/hooks/useReactiveState';
import {
  mf_Teams,
  mf_Leagues,
  mf_Seasons,
  mf_Games,
  mf_Players,
  pf_Drive,
  pf_TeamPlay,
} from '../../apollo';
import { replaceHrefTokens } from '../../utils/helpers/general';
import { authorizedNavConfig, getComponentProps } from './SideNav.helpers';
import { isSectionSelected } from './SideNav.config';
import isUrl from '../../utils/helpers/isUrl';

const SideNavMenuList = ({
  menuListItems,
  isMobile,
  currentLocation,
  isOpen,
  selectedSubmenu,
  setSelectedSubmenu,
}) => {
  const { user } = useKitbagAuth();
  const handleMenuClick = (event, menuItemId, hasLinks, href) => {
    if (isMobile && hasLinks) {
      // Prevent default routing behavior
      event.preventDefault();
      // Handle submenu opening/closing logic
      if (selectedSubmenu === menuItemId) {
        setSelectedSubmenu(null);
      } else {
        setSelectedSubmenu(menuItemId);
      }
    } else if (isUrl(href)) {
      setSelectedSubmenu(null);
    } else {
      setSelectedSubmenu(menuItemId);
    }
  };

  const [leagueId] = useReactiveState(mf_Leagues);
  const [seasonId] = useReactiveState(mf_Seasons);
  const [teamId] = useReactiveState(mf_Teams);
  const [playerId] = useReactiveState(mf_Players);
  const [gameId] = useReactiveState(mf_Games);
  const [driveId] = useReactiveState(pf_Drive);
  const [playId] = useReactiveState(pf_TeamPlay);

  const urlParams = {
    leagues: leagueId,
    seasons: seasonId,
    teams: teamId,
    players: playerId,
    games: gameId,
    drives: driveId,
    plays: playId,
  };

  const menuItems = authorizedNavConfig({ items: menuListItems, user });
  return (
    <SideNavigationMenuList>
      {menuItems.map((menuItem) => {
        const validHref = replaceHrefTokens(menuItem.links[0].href, urlParams);
        const { component, componentProps } = getComponentProps(
          validHref,
          handleMenuClick,
          menuItem.id,
          !!menuItem.links
        );
        return (
          <SideNavigationItem
            isOpen={isOpen}
            version={2}
            key={menuItem.id}
            menuItemId={menuItem.id}
            icon={menuItem.icon}
            isTouch={isMobile}
            isSelected={isSectionSelected(currentLocation, menuItem)}
            selectedSubmenu={selectedSubmenu}
            componentProps={componentProps}
            component={component}
            isSubmenuOpen={selectedSubmenu === menuItem.id}
            testId={`sideNav-menu-${menuItem.id}`}
            subMenu={
              <SubMenu
                menuItems={menuItem.links}
                currentLocation={currentLocation}
                isMobile={isMobile}
              />
            }
          >
            {menuItem.name}
          </SideNavigationItem>
        );
      })}
    </SideNavigationMenuList>
  );
};
SideNavMenuList.propTypes = {
  menuListItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isMobile: PropTypes.bool.isRequired,
  currentLocation: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  selectedSubmenu: PropTypes.string,
  setSelectedSubmenu: PropTypes.func.isRequired,
};

SideNavMenuList.defaultProps = {
  selectedSubmenu: null,
};

export default SideNavMenuList;
