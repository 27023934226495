import { styled } from 'styled-components';

export const HavocVideo = styled.div`
  display: block;
  width: 100%;

  @media (min-width: 1028px) {
    width: 35%;
    float: right;
  }

  @media (min-width: 1200px) {
    width: 45%;
    float: right;
  }

  @media (min-width: 1440px) {
    width: 50%;
    float: right;
  }
`;
HavocVideo.displayName = 'HavocVideo';
