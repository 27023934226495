import { gql } from '@apollo/client';

export const GET_PLAY_PLAYERS_VIDEO = gql`
  query getPlayPlayersVideo($playIds: [String!]) {
    playPlayersVideo(playIds: $playIds) {
      playId
      players {
        playerId
        name
        positionCode
        jersey
      }
    }
  }
`;
