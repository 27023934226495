import { TABLE_CHART } from './TableChart.constants';
import TableChartEPA_Bar from './TableChartEPA_Bar';
import TableChartPlayPercBar from './TableChartPlayPercBar';

export const getTableChart = (chart, value) => {
  const { EPA, PLAY_PERCENTAGE } = TABLE_CHART;
  switch (chart) {
    case EPA:
      // eslint-disable-next-line react/jsx-pascal-case
      return TableChartEPA_Bar({ value });
    case PLAY_PERCENTAGE:
      return TableChartPlayPercBar({ value });
    default:
      return null;
  }
};
