import { styled } from 'styled-components';
import { ReactSVG } from 'react-svg';
import { VERTICAL_SPACING, PADDING } from './ExportModal.constants';
import { VISUALISATION_FONT_SETUPS } from '../../utils/constants/visText';

export const ExportContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.8rem;
`;

export const ExportSettings = styled.div`
  width: 30%;
`;

export const ExportTemplateContainer = styled.div`
  max-width: 100%;
  max-height: 60vh;
  overflow: auto;

  // hide corner-square when overflow-x and overflow-y being used simultaneously
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;

export const Export = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) =>
    theme.colours.visualisations.background.main};
  padding: ${PADDING}px;
  gap: ${VERTICAL_SPACING}px;
  font-family: 'Inter';

  // adding "font-face" ensures we get the correct export font across browsers
  @font-face {
    font-family: 'Inter';
    font-style: normal;
  }
`;

export const ExportHeader = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 8px;

  > div {
    margin: 0 8px;
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 0.8rem;
  }

  > div:last-of-type {
    justify-content: end;
  }
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colours.visualisations.text.header};
  font-size: ${VISUALISATION_FONT_SETUPS.HEADER_TITLE.SIZE}px;
  font-weight: ${VISUALISATION_FONT_SETUPS.HEADER_TITLE.WEIGHT};
  line-height: ${VISUALISATION_FONT_SETUPS.HEADER_TITLE.LINE_HEIGHT}px;
`;

export const SecondaryTitle = styled.div`
  color: ${({ theme }) => theme.colours.visualisations.text.subHeader};
  font-size: ${VISUALISATION_FONT_SETUPS.HEADER_SUB_TITLE.SIZE}px;
  font-weight: ${VISUALISATION_FONT_SETUPS.HEADER_SUB_TITLE.WEIGHT};
  line-height: ${VISUALISATION_FONT_SETUPS.HEADER_SUB_TITLE.LINE_HEIGHT}px;
`;

export const Info = styled.div`
  color: ${({ theme }) => theme.colours.visualisations.text.info};
  font-size: ${VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.SIZE}px;
  font-weight: ${VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.WEIGHT};
  line-height: ${VISUALISATION_FONT_SETUPS.HEADER_SUB_SUB_TITLE.LINE_HEIGHT}px;
  text-align: end;
`;

export const ExportLogoContainer = styled(ReactSVG)`
  svg {
    fill: ${({ theme }) => theme.colours.canvas.primary.ink} !important;
    position: relative;
    left: -3rem;
  }
`;

ExportContainer.displayName = 'ExportContainer';
ExportSettings.displayName = 'ExportSettings';
ExportTemplateContainer.displayName = 'ExportTemplateContainer';
Export.displayName = 'Export';
ExportHeader.displayName = 'ExportHeader';
Title.displayName = 'Title';
SecondaryTitle.displayName = 'SecondaryTitle';
Info.displayName = 'Info';
