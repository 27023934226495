import { getRosterPositionColorByCode } from '../../../../utils/helpers/positions';

// format data for video tile to allow player tracking and colouring
export const formatHavocVideoData = (events, visPalette) =>
  events.map((evt) => ({
    playId: evt?.playUUID,
    play: { id: evt?.playUUID },
    highlightPlayers: [
      {
        active: true,
        id: evt?.targetId,
        colour: getRosterPositionColorByCode(
          evt?.targetRosterPositionCode,
          visPalette
        ),
        name: evt?.targetName,
        number: evt?.targetJerseyNumber,
      },
      {
        active: true,
        id: evt?.playerId,
        colour: getRosterPositionColorByCode(
          evt?.defenderRosterPositionCode,
          visPalette
        ),
        name: evt?.playerName,
        number: evt?.defenderJerseyNumber,
      },
    ],
  }));
