import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@statsbomb/kitbag-components';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { IconButtonWrapper } from './Playlist.styles';
import { USER_ROLES, VIDEO_JWT_CLAIMS } from '../../../utils/constants/auth';
import Tooltip from '../../Tooltip/Tooltip';

/*
 * A Button to add plays to a playlist
 * with auth wrapper
 */
const PlaylistButton = ({
  handleClick,
  isDisabled,
  numberOfPlays,
  variant,
  tooltip,
}) => {
  const { user } = useKitbagAuth();
  const hasVideoAuth = user[VIDEO_JWT_CLAIMS]?.roles.includes(
    USER_ROLES.VIDEO_CUSTOMER
  );

  // Disable the button if the user doesn't have videoAuth
  const buttonDisabled = isDisabled || !hasVideoAuth;
  const title = hasVideoAuth
    ? 'Add plays to playlist'
    : 'No permission to watch plays';

  return (
    <IconButtonWrapper>
      <Tooltip
        content={<p>{isDisabled && tooltip}</p>}
        target={
          <Button
            onClick={hasVideoAuth ? handleClick : undefined}
            variant={variant}
            size="small"
            title={tooltip ? '' : title}
            isDisabled={buttonDisabled}
          >
            <span>
              <Icon variant="Video" size="small" />
            </span>
            Watch play{numberOfPlays !== 1 ? `s` : ''}
            <span>{numberOfPlays > 0 ? `(${numberOfPlays})` : ''}</span>
          </Button>
        }
      />
    </IconButtonWrapper>
  );
};

PlaylistButton.propTypes = {
  handleClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  numberOfPlays: PropTypes.number,
  variant: PropTypes.string,
  // optional tooltip for disabled state
  tooltip: PropTypes.string,
};

PlaylistButton.defaultProps = {
  handleClick: () => {},
  isDisabled: false,
  numberOfPlays: 0,
  variant: 'secondary',
  tooltip: '',
};

export default PlaylistButton;
