import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { BasicPageNoPadding } from './Layout.styles';

const BasicPage = ({ top }) => (
  <BasicPageNoPadding $top={top}>
    <Outlet />
  </BasicPageNoPadding>
);

BasicPage.propTypes = {
  top: PropTypes.string,
};

BasicPage.defaultProps = {
  top: '6.375rem',
};

export default BasicPage;
