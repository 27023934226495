import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Typography } from '@statsbomb/kitbag-components';
import { RunTableContainer, RunTableTitleHolder } from './RunTable.styles';
import { formatRunTableData } from './RunTable.dataManipulation';
import { RUN_TENDENCY_TABLE_MODES } from './RunTable.constants';
import { mf_TeamDetails, mf_LeagueLevel } from '../../../../../apollo';
import BasicTable from '../../../../../components/Table/BasicTable';
import DownloadCSVButton from '../../../../../components/buttons/DownloadCSV';

const RunTable = ({
  runData,
  teamRunData,
  leagueRunData,
  tableMode,
  tableName,
  csvName,
}) => {
  const columnHeaders = RUN_TENDENCY_TABLE_MODES[tableMode].tableHeaders;
  const team = useReactiveVar(mf_TeamDetails);
  const leagueName = useReactiveVar(mf_LeagueLevel);
  const fileName = `${team?.name} ${csvName}`;

  const rows = formatRunTableData(
    runData,
    teamRunData,
    leagueRunData,
    columnHeaders,
    team?.shortName,
    leagueName
  );

  const stickyColumns = [
    {
      index: 1,
      width: 12,
    },
  ];
  /* Clean up headers for prop types */
  const tableHeaders = columnHeaders.map((h) => ({
    key: h.key?.toString(),
    label: h.label?.toString(),
  }));

  return (
    <RunTableContainer>
      <RunTableTitleHolder>
        <Typography variant="headingMedium">{tableName}</Typography>
        <DownloadCSVButton
          data={rows}
          headers={tableHeaders}
          fileName={fileName}
          iconSize="small"
        />
      </RunTableTitleHolder>
      <BasicTable
        data={rows}
        headers={tableHeaders}
        stickyColumns={stickyColumns}
        showHeader
        withBorder
      />
    </RunTableContainer>
  );
};

RunTable.propTypes = {
  runData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  teamRunData: PropTypes.arrayOf(PropTypes.shape({})),
  leagueRunData: PropTypes.arrayOf(PropTypes.shape({})),
  tableMode: PropTypes.string,
  tableName: PropTypes.string,
  csvName: PropTypes.string,
};

RunTable.defaultProps = {
  teamRunData: null,
  leagueRunData: null,
  tableMode: RUN_TENDENCY_TABLE_MODES.GAP.value,
  tableName: null,
  csvName: 'table csv download',
};

export default RunTable;
