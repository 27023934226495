import React from 'react';
import { Badge } from '@statsbomb/kitbag-components';
import PropTypes from 'prop-types';
import { StyledBadge } from './SideNav.styles';

const BadgeLabel = ({ condition, text }) =>
  condition ? (
    <StyledBadge>
      <Badge>{text}</Badge>
    </StyledBadge>
  ) : (
    <></>
  );

BadgeLabel.propTypes = {
  condition: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default BadgeLabel;
