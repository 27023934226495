import { styled } from 'styled-components';

export const TableCellPadding = styled.td`
  padding: 0;
`;

export const AccordionTableRowHeader = styled.table`
  width: 100% !important;
  border: 0 !important;

  &:focus {
    outline: none;
  }

  tbody {
    tr {
      :hover {
        td {
          background-color: initial !important;
        }
      }

      td:last-child {
        button {
          transition: transform 0.3s ease-in-out;
          ${({ $isExpanded }) => $isExpanded && `transform: rotate(180deg)`};
        }
      }
    }
  }
`;
AccordionTableRowHeader.displayName = 'AccordionTableRowHeader';

export const AccordionTableRowBody = styled.div`
  width: 100%;
  transition: all 500ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  overflow: hidden;

  ${({ $isExpanded }) => `
    min-height: ${$isExpanded ? '40px' : '0'};
    max-height: ${$isExpanded ? '2500px' : '0'};
  `}
`;
AccordionTableRowBody.displayName = 'AccordionTableRowBody';
