import { isEmpty, orderBy, partition } from 'lodash';
import { TABLE_NULL, toUsDate } from '../../../utils/helpers/formatting';
import {
  getDownName,
  getYardlineName,
  getYardsWon,
} from '../../../utils/helpers/play';
import { millisecondsToTime } from '../../../utils/helpers/time';
import { formatValue } from '../../../utils/helpers/stats.dataManipulation';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import {
  API_STAT_UNITS,
  PLAY_FINDER_WIDGET_TYPES,
  normalPlayTypes,
} from '../../../utils/constants/api';

// format data for table
export const formatPlayFinderResultsTable = (data, headers) => {
  if (data.length === 0 || headers.length === 0) return [];

  const playFinderRows = data?.map((play) =>
    headers.reduce(
      (row, h) => {
        let label;
        switch (h.key) {
          case 'date':
            label = toUsDate(play.game.date);
            break;
          case 'offensiveTeam':
            label = formatValue(play.offenseTeam.name, h.units);
            break;
          case 'defensiveTeam':
            label = formatValue(play.defenseTeam.name, h.units);
            break;
          case 'down':
            label = getDownName(play.down, h.units);
            break;
          case 'yardsToGo':
            label = formatValue(play.yardsToGo, h.units);
            break;
          case 'scrimmageEpa':
            label = play?.scrimmageEpa?.toFixed(2);
            break;
          case 'yardLine':
            label = getYardlineName(play.yardLine, h.units);
            break;
          case 'time':
            label = `Q${play.quarter} - ${millisecondsToTime(
              play.quarterClockRemaining
            )}`;
            break;
          case 'yardsNet':
            label = getYardsWon(play.yardsNet, h.units);
            break;
          case 'type':
            label = normalPlayTypes[play.type];
            break;
          default:
            label = formatValue(play[h.key], h.units);
            break;
        }
        return { ...row, [h.key]: { ...h, label } };
      },
      { id: play.id }
    )
  );

  return playFinderRows;
};

// format data for CSV export
export const formatPlaysCSV = (plays, headers) => {
  const playFinderRows = plays?.map((play) =>
    headers.reduce((row, h) => {
      let formattedValue;

      if (h.key === 'offensiveTeam') {
        formattedValue = formatValue(play.offensiveTeam.label, h.units);
      } else if (h.key === 'defensiveTeam') {
        formattedValue = formatValue(play.defensiveTeam.label, h.units);
      } else {
        formattedValue = formatValue(play[h.key]?.label, h.units);
      }

      return { ...row, [h.key]: formattedValue };
    }, {})
  );

  return playFinderRows;
};

// sortable table function
export const sortPlays = (plays, sortKey, sortDirection) => {
  if (isEmpty(plays)) {
    return [];
  }
  const [withValue, withoutValue] = partition(
    plays,
    (o) => o[sortKey] !== null && o[sortKey] !== TABLE_NULL
  );
  const sortedWithValuePlays = orderBy(
    withValue,
    [sortKey],
    [sortDirection, SORT_DIRECTIONS.ASCENDING]
  );
  const sortedWithoutValuePlays = orderBy(
    withoutValue,
    ['name'],
    [SORT_DIRECTIONS.ASCENDING]
  );
  const sortedPlays = sortedWithValuePlays.concat(sortedWithoutValuePlays);
  return sortedPlays;
};

// prepare data for PlayFinder Header, ex: Chicago Bears, 2023/2024, Defense
export const getPlayFinderHeader = (team, season, isOffense) => {
  if (team && season) {
    return `${team}, ${season}, ${isOffense ? 'Offense' : 'Defense'}`;
  }
  return '';
};

// prepare data for Summary Accordion
export const formatPlayFinderSummaryData = (summaryData, headers) => {
  if (!summaryData) return [];
  const formattedSummary = summaryData.map((data) => {
    const matchedHeader = headers.find(
      (h) => h.key === data.label.replace(/\s/g, '')
    );
    return {
      ...data,
      value:
        data.label.replace(/\s/g, '') === 'Chunk%'
          ? `${data.value.toFixed(2)}%`
          : formatValue(data.value, matchedHeader?.units),
      hasColour: matchedHeader?.hasColour ?? true,
    };
  });
  return formattedSummary;
};

// format selected filters for use in GET_PLAY_FINDER_RESULTS query:
/* 
PlayFinderFilter query
  filterId: String!
  min: Float
  max: Float
  intVal: Int
  intVals: [Int!]    # in case multiple choices can be made
  boolVal: Boolean
  enumVal: String
  enumVals: [String!]   # in case multiple choices can be made

  INPUTS:
    Filter type:
      - SELECT_SINGLE
      - SELECT_MULTIPLE
      - BOOL
      - CUSTOM
      - RANGE
  
    units: 
      STRING -> enumVal
      SHORT_DECIMAL -> intVal
  
  OUTPUT:
    [
      {filterId:'id', enumVal: 'value'}, 
      {filterId:'id', intVal: 1}, 
      {filterId:'id', intVals: [1, 2, 3]}, 
      {filterId:'id', boolVal: true}, 
      {filterId:'id', min: 1, max: 2}
    ]
*/
export const formatSelectedFilters = (filterTreeData) => {
  const queryFilters = [];
  filterTreeData.forEach((filter) => {
    filter.children.forEach((child) => {
      child.children.forEach((grandchild) => {
        if (
          grandchild.widgetArgs &&
          grandchild.widgetArgs.hasOwnProperty('selected')
        ) {
          const tempFilter = {
            filterId: grandchild.filterId,
          };
          if (
            grandchild.widgetType === PLAY_FINDER_WIDGET_TYPES.SELECT_SINGLE &&
            grandchild.units === API_STAT_UNITS.STRING
          ) {
            tempFilter.enumVal = grandchild.widgetArgs.selected;
          }
          if (
            grandchild.widgetType === PLAY_FINDER_WIDGET_TYPES.SELECT_SINGLE &&
            grandchild.units === API_STAT_UNITS.SHORT_DECIMAL
          ) {
            tempFilter.intVal = parseFloat(grandchild.widgetArgs.selected);
          }
          if (
            grandchild.widgetType ===
              PLAY_FINDER_WIDGET_TYPES.SELECT_MULTIPLE &&
            grandchild.units === API_STAT_UNITS.SHORT_DECIMAL
          ) {
            tempFilter.intVals = grandchild.widgetArgs.selected.map((item) =>
              parseFloat(item)
            );
          }
          if (
            grandchild.widgetType ===
              PLAY_FINDER_WIDGET_TYPES.SELECT_MULTIPLE &&
            grandchild.units === API_STAT_UNITS.STRING
          ) {
            tempFilter.enumVals = grandchild.widgetArgs.selected;
          }
          if (grandchild.widgetType === PLAY_FINDER_WIDGET_TYPES.BOOL) {
            tempFilter.boolVal = JSON.parse(grandchild.widgetArgs.selected);
          }
          if (
            (grandchild.widgetType === PLAY_FINDER_WIDGET_TYPES.RANGE &&
              grandchild.units === API_STAT_UNITS.SHORT_DECIMAL) ||
            grandchild.units === API_STAT_UNITS.EPA
          ) {
            const [min, max] = grandchild.widgetArgs.selected;
            tempFilter.min = parseFloat(min);
            tempFilter.max = parseFloat(max);
          }
          if (
            grandchild.widgetType === PLAY_FINDER_WIDGET_TYPES.RANGE &&
            grandchild.units === API_STAT_UNITS.YARDS
          ) {
            const [min, max] = grandchild.widgetArgs.selected;
            tempFilter.min = min;
            tempFilter.max = max;
          }
          queryFilters.push(tempFilter);
        }
      });
    });
  });

  return queryFilters;
};

export const formatSelectedHeaders = (filterTreeData) => {
  const selectedCols = [];
  filterTreeData.forEach((filter) => {
    filter.children.forEach((child) => {
      child.children.forEach((grandchild) => {
        const tempColumn = {
          key: grandchild.columnId,
          label: grandchild.abbrev,
          description: grandchild.label,
          units: grandchild.units,
          filterId: grandchild.filterId,
        };
        selectedCols.push(tempColumn);
      });
    });
  });

  return selectedCols;
};

export function mergeArraysExcludeDuplicates(array1, array2, key) {
  const map = new Map();

  array1.forEach((item) => {
    map.set(item[key], item);
  });

  array2.forEach((item) => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });

  return Array.from(map.values());
}

// format data for video tile to allow player tracking and colouring
export const formatPlayFinderVideoData = (events) =>
  events.map((evt) => ({
    playId: evt.id,
  }));
