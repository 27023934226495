/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Checkbox, Table, ButtonIcon } from '@statsbomb/kitbag-components';
import { isArray } from 'lodash';
import { ALIGNMENT } from '../../../../utils/constants/alignment';
import {
  tableRowsPropTypes,
  sortableTableAlignmentPropTypes,
  sortableTableCellSize,
} from '../../Tables.propTypes';
import { getTableChart } from '../../../../visualisations/TableChart/TableChart.helper';

/**
 *  SortableTableBody component
 *  This component is responsible for rendering the data rows of the table
 */
const SortableTableBody = ({
  rows,
  name,
  alignment,
  isFooter,
  dataCellSize,
  onChange,
}) => {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRow = (rowIndex) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowIndex]: !prev[rowIndex],
    }));
  };

  const renderCell = (cell) => {
    if (cell.chart) {
      return getTableChart(cell.chart, cell.label);
    }

    if (cell?.link && cell?.hasOwnProperty('label')) {
      return <Link to={cell.link}>{cell.label}</Link>;
    }

    if (cell?.hasOwnProperty('label')) {
      return cell.label;
    }

    if (isArray(cell)) {
      return cell.map((item) => item.label);
    }

    return cell;
  };

  const renderRow = (row, rowIndex, level = 0) => (
    <React.Fragment key={`${name}-table-row-${rowIndex}`}>
      <Table.Row data-testid={`${name}-table-row-${rowIndex}`}>
        {onChange && (
          <Table.DataCell style={{ width: '32px' }}>
            <Checkbox
              checked={row?.checked}
              id={`select-row-${rowIndex}`}
              label={`select-row-${rowIndex}`}
              onChange={() => onChange(rowIndex)}
              withLabel={false}
              size="small"
            />
          </Table.DataCell>
        )}
        {Object.values(row).map((cell, cellIndex) => {
          const widthOverride =
            cell?.width?.length > 0
              ? {
                  minWidth: cell.width,
                  maxWidth: cell.width,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }
              : {};
          const colorOverride =
            cell?.color?.length > 0
              ? { backgroundColor: cell?.color, color: cell?.ink }
              : {};

          // don't render id cell if it is a selectable row
          // OR
          // if its set to be hidden
          // OR
          // if its an array
          if (
            (typeof cell !== 'object' && onChange) ||
            cell.isHidden ||
            isArray(cell)
          ) {
            return null;
          }

          const isExpandingCell = cellIndex === 2 && row.children?.length > 0;

          return (
            <Table.DataCell
              $textAlign={
                typeof alignment === 'function'
                  ? alignment(cellIndex)
                  : cell.alignment || alignment
              }
              key={`${name}-table-data-cell-${rowIndex}-${cellIndex}`}
              title={cell?.title || ''}
              $size={dataCellSize}
              style={{
                ...widthOverride,
                ...colorOverride,
                maxWidth: cell.chart === 'EPA' ? '150px' : 'auto',
                paddingLeft:
                  cellIndex === 2 && level !== 0 && `${level * 20}px`,
              }}
              className={isExpandingCell && 'hasChevron'}
            >
              {isExpandingCell && (
                <ButtonIcon
                  onClick={() => toggleRow(rowIndex)}
                  variant="ghost"
                  size="small"
                  icon={expandedRows[rowIndex] ? 'ChevronDown' : 'ChevronUp'}
                />
              )}
              {renderCell(cell)}
            </Table.DataCell>
          );
        })}
      </Table.Row>
      {expandedRows[rowIndex]
        ? expandedRows[rowIndex]
        : true &&
          row.children &&
          row.children.map((childRow, childRowIndex) =>
            renderRow(childRow, `${rowIndex}-${childRowIndex}`, level + 1)
          )}
    </React.Fragment>
  );

  const tableRows = rows.map((row, rowIndex) => renderRow(row, rowIndex));

  if (isFooter) {
    return (
      <Table.Foot data-testid={`${name}-table-foot`}>{tableRows}</Table.Foot>
    );
  }
  return (
    <Table.Body data-testid={`${name}-table-body`}>{tableRows}</Table.Body>
  );
};

SortableTableBody.propTypes = {
  // An array of objects containing the data for the table data rows
  rows: tableRowsPropTypes,
  // determines how each column should be aligned. can be a function that
  // uses the column index, or an alignment string which will apply to all columns
  alignment: sortableTableAlignmentPropTypes,
  // The name of the table - used as a prefix for keys
  name: PropTypes.string.isRequired,
  // Is this the footer section of the table
  isFooter: PropTypes.bool,
  // controls the height of the data cells
  dataCellSize: sortableTableCellSize,
  // function that is called when a row is clicked
  onChange: PropTypes.func,
};

SortableTableBody.defaultProps = {
  rows: [],
  alignment: ALIGNMENT.LEFT,
  isFooter: false,
  dataCellSize: 'small',
  onChange: null,
};

export default SortableTableBody;
