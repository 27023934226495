import { useRef } from 'react';
import { pointers } from 'd3';
import { useTheme } from 'styled-components';
import { max, min } from 'lodash';
import { getRectDimensions } from '../../visualisations/DragSelect/DragSelect.dataManipulation';

/**
 * DRAG-ABLE SELECT STUFF
 *
 * This function takes some basics and returns all the logic for doing drag select as
 * a set of 5 functions
 *
 * The base layer for this goes under the data, but so the data can also be individually selectable,
 *  those shapes also need some of these actions, and thus you can't easily just make a component that
 *  fits into a layer of the hierarchy
 * 
 * Use this stuff:
 * <svg
 *  ... //draw pitch or whatever
 *  <g id="data-layer" >  //rotation/zoom etc. do above this
 *    <g id="drag-layer" >
 *      <rect id={marqueeId} /> //just set up
 *      <rect ... //size to fill viewport, make transparent (not fill="none")
 *         onClick=deselectObject
           onMouseDown=beginDrag
           onMouseMove=updateMarquee
           onMouseUp=completeMarquee
 *      />
 *    </g> //drag layer
 *    <g id="data-objects" >
 *      [
  *      <path...
 *          onClick={ otherFuncs; resetMarquee}
 *          onMouseUp={ otherFuncs; completeMarquee} />, 
 *      ...]
 *    </g> //data object
 *  </g> //data layer
 * </svg>
 * 
 */
export const getSelectionLayerBits = (
  marqueeId,
  handleDragSelection, // function that passes back the coordinates of the drawn rect in data-layer orientation
  handleSelectedEvent // function used here with null for clearances
) => {
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;

  const interacting = useRef(false);
  const originX = useRef(0);
  const originY = useRef(0);
  const passedX = useRef(0);
  const passedY = useRef(0);
  const savedX = useRef(0);
  const savedY = useRef(0);
  const savedWidth = useRef(0);
  const savedHeight = useRef(0);

  const resetMarquee = (svg) => {
    savedWidth.current = 0;
    savedHeight.current = 0;
    savedX.current = 0;
    savedY.current = 0;
    svg
      .select(`#${marqueeId}`)
      .attr('x', savedX.current)
      .attr('y', savedY.current)
      .attr('width', savedWidth.current)
      .attr('height', savedHeight.current);
  };

  const updateMarquee = (svg, mouseEvent) => {
    const p = pointers(mouseEvent);
    const [currentX, currentY] = p[0];
    passedX.current = currentX;
    passedY.current = currentY;

    if (interacting.current) {
      const { rectX, rectY, rectWidth, rectHeight } = getRectDimensions(
        originX.current,
        originY.current,
        passedX.current,
        passedY.current
      );
      savedWidth.current = rectWidth;
      savedHeight.current = rectHeight;
      savedX.current = rectX;
      savedY.current = rectY;
      svg
        .select(`#${marqueeId}`)
        .attr('fill', theme.colours.interface.main)
        .attr('stroke', theme.colours.interface.main)
        .attr('stroke-dasharray', 'none')
        .attr('x', rectX)
        .attr('y', rectY)
        .attr('width', rectWidth)
        .attr('height', rectHeight);
    } else {
      originX.current = passedX.current;
      originY.current = passedY.current;
    }
  };
  const drawCompleteMarquee = (svg) => {
    svg
      .select(`#${marqueeId}`)
      .attr('fill-opacity', 0.4)
      .attr('fill', 'transparent')
      .attr('stroke', visPalette.contrast)
      .attr('stroke-dasharray', '5 6')
      .attr('stroke-width', 2)
      .attr('x', savedX.current)
      .attr('y', savedY.current)
      .attr('width', savedWidth.current)
      .attr('height', savedHeight.current);
  };

  const completeMarquee = (svg) => {
    if (interacting.current) {
      interacting.current = false;
      svg
        .select(`#${marqueeId}`)
        .attr('fill-opacity', 0.5)
        .attr('stroke-dasharray', 3);

      const minX = min([originX.current, passedX.current]);
      const maxX = max([originX.current, passedX.current]);
      const minY = min([originY.current, passedY.current]);
      const maxY = max([originY.current, passedY.current]);

      handleDragSelection(minX, maxX, minY, maxY);
      drawCompleteMarquee(svg);
    }
  };

  const deselectObject = () => {
    if (!interacting.current) {
      handleSelectedEvent(null);
    }
  };

  const beginDrag = (svg) => {
    interacting.current = true;
    handleSelectedEvent(null);
    resetMarquee(svg);
  };

  return {
    resetMarquee,
    updateMarquee,
    completeMarquee,
    drawCompleteMarquee,
    deselectObject,
    beginDrag,
  };
};
