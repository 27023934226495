import { EXPORT_TYPE_FUNCS } from '../../utils/constants/exporting';
import { ERROR_CODES } from '../../utils/errorCodes/errorCodes';

export const createLinkAndDownload = (dataUrl, fileName) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = dataUrl;
  link.click();
  return link;
};

export const exportVisual = async (
  exportRef,
  exportType,
  fileName = 'Hudl-IQ-Export'
) => {
  if (!exportRef.current) {
    console.error(
      ERROR_CODES.EXPORT_VISUAL,
      'Export failed - no current ref defined.'
    );
    return Promise.resolve();
  }

  return EXPORT_TYPE_FUNCS[exportType](exportRef.current, { cacheBust: true })
    .then((dataUrl) =>
      createLinkAndDownload(dataUrl, `${fileName}.${exportType.toLowerCase()}`)
    )
    .catch((error) => {
      console.error(ERROR_CODES.EXPORT_VISUAL, error);
    });
};
