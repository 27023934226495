import { ALIGNMENT } from '../../../utils/constants/alignment';
import { API_STAT_UNITS } from '../../../utils/constants/api';
import { TABLE_CHART } from '../../../visualisations/TableChart/TableChart.constants';

const { STRING, COUNT, YARDS, PERCENTAGE_PROVIDED, EPA } = API_STAT_UNITS;
const { EPA: EPA_CHART, PLAY_PERCENTAGE: PLAY_PERCENTAGE_CHART } = TABLE_CHART;

export const DEFENSIVE_SCHEME_AVG_KEYS = {
  TEAM: 'Team',
  LEAGUE: 'League',
};

export const DEFENSIVE_SCHEME_GROUPS = {
  FRONT: 'Front',
  BLITZ: 'Blitz',
  COVERAGE: 'Coverage',
};

export const DEFENSIVE_SCHEME_STICKY_COLUMNS = [
  {
    index: 1,
    width: 2,
  },
  {
    index: 2,
    width: 7,
  },
];

export const DEFENSIVE_SCHEME_COLUMNS = [
  {
    key: 'group',
    label: 'Group',
    units: STRING,
    description: 'Category grouping',
    isHidden: true,
  },
  {
    key: 'category',
    label: 'Name',
    units: STRING,
    description: 'Category of plays',
  },
  {
    id: 'playCount',
    key: 'playCount',
    label: 'Play Count',
    units: COUNT,
    description: 'Count of plays',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
  },
  {
    id: 'playPerc',
    key: 'playPerc',
    label: 'Play %',
    units: PERCENTAGE_PROVIDED,
    description: 'Percentage of plays',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
    chart: PLAY_PERCENTAGE_CHART,
  },
  {
    id: 'epaPerPlay',
    key: 'epaPerPlay',
    label: 'EPA/Play',
    units: EPA,
    description: 'EPA per play',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
    chart: EPA_CHART,
    width: '150px',
  },
  {
    id: 'ydsPerPlay',
    key: 'ydsPerPlay',
    label: 'Yds/Play',
    units: YARDS,
    description: 'Yards per play',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
  },
  {
    id: 'successPerc',
    key: 'successPerc',
    label: 'Success %',
    units: PERCENTAGE_PROVIDED,
    description: 'Percentage of successful plays',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
  },
  {
    id: 'epaRun',
    key: 'epaRun',
    label: 'EPA/Run',
    units: YARDS,
    description: 'EPA per run and yards per run',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
  },
  {
    id: 'ydsRun',
    key: 'ydsRun',
    label: 'Yds/Run',
    units: YARDS,
    description: 'EPA per run and yards per run',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
  },
  {
    id: 'successPercRun',
    key: 'successPercRun',
    label: 'Run Succ %',
    units: PERCENTAGE_PROVIDED,
    description: 'Run success percentage',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
  },
  {
    id: 'epaPass',
    key: 'epaPass',
    label: 'EPA/Pass',
    units: EPA,
    description: 'EPA per pass and yards per pass',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
  },
  {
    id: 'ydsPass',
    key: 'ydsPass',
    label: 'Yds/Pass',
    units: YARDS,
    description: 'Yards per pass',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
  },
  {
    id: 'successPercPass',
    key: 'successPercPass',
    label: 'Pass Succ %',
    units: PERCENTAGE_PROVIDED,
    description: 'Pass success percentage',
    isSortable: true,
    alignment: ALIGNMENT.RIGHT,
  },
];
