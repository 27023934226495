import PropTypes from 'prop-types';
import { ALIGNMENT } from '../../../utils/constants/alignment';

export const PLAY_BY_TABLE_HEADERS = [
  {
    label: 'Drive',
    key: 'drive',
    alignment: ALIGNMENT.RIGHT,
    width: '10px',
  },
  { label: 'Team on offense', key: 'teamOnOffense', alignment: ALIGNMENT.LEFT },
  { label: 'Clock', key: 'clock', alignment: ALIGNMENT.LEFT },
  { label: 'Plays', key: 'plays', alignment: ALIGNMENT.RIGHT },
  { label: 'Drive Result', key: 'driveResult', alignment: ALIGNMENT.LEFT },
  {
    label: 'Starting field position',
    key: 'startingFieldPosition',
    alignment: ALIGNMENT.LEFT,
  },
  { label: 'Yards gained', key: 'yardsGained', alignment: ALIGNMENT.LEFT },
  { label: '', key: 'selectedTeamScore', alignment: ALIGNMENT.LEFT },
  { label: '', key: 'opponentTeamScore', alignment: ALIGNMENT.LEFT },
  { label: '', key: 'controls', alignment: ALIGNMENT.RIGHT },
];

export const PLAY_BY_TABLE_HEADERS_PROP_TYPE = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    alignment: PropTypes.oneOf(Object.values(ALIGNMENT)).isRequired,
    width: PropTypes.string,
  })
);
