import { styled } from 'styled-components';

export const StyledBasicTable = styled.div`
  overflow: auto;
  max-height: ${({ $maxHeight }) => $maxHeight && `${$maxHeight}px`};
  max-width: ${({ $maxWidth }) => $maxWidth || '100%'};
  // hide corner-square when overflow-x and overflow-y being used simultaneously
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  table {
    width: ${({ $width }) => $width || '100%'};

    thead {
      top: 0;
    }

    tfoot {
      top: 0;
      bottom: 0;
      inset-block-start: 0;
      inset-block-end: 0;
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};

      tr {
        td {
          color: ${({ theme }) =>
            theme.applyOpacity(theme.colours.ink.primary.main, 'secondary')};
          border-top: 2px solid ${({ theme }) => theme.colours.interface.main};
          font-weight: 600;
          height: 2rem;
        }
      }
    }

    tbody {
      tr {
        cursor: ${({ $rowSelectable }) =>
          $rowSelectable ? 'pointer' : 'default'};
        td {
          font-size: 0.75rem;
          height: ${({ $bodytdHeight }) => $bodytdHeight || '1.5rem'};
        }
        &.disabled {
          td {
            color: ${({ theme }) =>
              theme.applyOpacity(theme.colours.ink.primary.main, 'disabled')};
          }
        }
      }
      tr:hover td {
        background-color: ${({ theme }) =>
          theme.colours.canvas.secondary.strong};
      }
      /* 
      Because row hover and ghost button hover are same colour, you can't see button shape 
        So override inherent ghost hover with canvas color because row always dark
      */
      tr {
        td {
          button:hover {
            background-color: ${({ theme }) =>
              theme.colours.canvas.tertiary.weak} !important;
          }
        }
      }
    }
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colours.layering[0].main} !important;
  }
`;

StyledBasicTable.displayName = 'StyledBasicTable';
