import React, { useState, useEffect } from 'react';
import {
  Button,
  Icon,
  ConfirmationDialog,
  Grid,
} from '@statsbomb/kitbag-components';
import { useKitbagAuth } from '@statsbomb/kitbag-auth';
import { StyledDataAccess, ApiKey } from './DataAccess.styles';
import DataDictionary from './DataDictionary/DataDictionary';
import { useFetch } from '../../utils/hooks/useFetch';
import { generateApiKey, deleteApiKey } from './DataAccess.helper';
import { KitbagPageGridHolder } from '../../components/Layout/Layout.styles';
import PageHeader from '../../components/PageHeader/PageHeader';

const DataAccess = () => {
  const [accessToken, setAccessToken] = useState('');
  const [alert, setAlert] = useState('');
  const [newApiKey, setNewApiKey] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const { user, getAccessTokenSilently } = useKitbagAuth();

  useEffect(async () => {
    await getAccessTokenSilently()
      .then((token) => {
        setAccessToken(token);
      })
      .catch((error) => {
        console.error(error.message);
      });
  }, [user, getAccessTokenSilently]);

  useEffect(() => {
    const clearAlert = setTimeout(() => {
      if (alert) {
        setAlert('');
      }
    }, 2000);

    return () => clearTimeout(clearAlert);
  }, [alert]);

  // check for existing keys
  const {
    data: existingKeys,
    loading: loadingExistingKeys,
    error: errorExistingKeys,
  } = useFetch(
    process.env.ACCOUNT_API_KEYS,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${accessToken}`,
      },
    },
    [accessToken],
    !accessToken
  );

  const userHasKey = !!existingKeys?.length;
  const existingKeyId = existingKeys?.[0]?.key_id;

  const getFeedback = () => {
    if (newApiKey?.key_id) {
      return (
        <>
          <Icon variant="Warning" size="small" />
          <span>
            API keys are only available at the time they are generated. Make
            sure to save your API key before navigating off this page so you
            don&apos;t lose your key!
          </span>
        </>
      );
    }

    if (!loadingExistingKeys && userHasKey) {
      return 'You already generated an API key';
    }
    // The request for existing keys will return a 403 error if the user does not have api access privileges
    if (errorExistingKeys === 403) {
      return 'Your account does not have API access privileges. Speak to a Statsbomb representative to learn more';
    }

    return '';
  };

  const message = getFeedback();

  const keyId = newApiKey?.key_id || existingKeyId;

  const generateKey = async () => {
    if (keyId) {
      await deleteApiKey(keyId, accessToken);
    }
    const key = await generateApiKey(accessToken);
    setNewApiKey(key);
    setIsOpen(false);
  };

  const handleGenerateKey = () => {
    if (keyId) {
      setIsOpen(true);
    } else {
      generateKey();
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(newApiKey?.api_key);
    setAlert('Copied to clipboard');
  };

  const disabled =
    loadingExistingKeys || !existingKeys || errorExistingKeys || !accessToken;

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <PageHeader
            customTitle="Data Access"
            showPlayerName={false}
            showTeamName={false}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledDataAccess>
            <ApiKey $withFeedback={message}>
              <header>
                <h2>API Key</h2>
                <Button onClick={handleGenerateKey} isDisabled={disabled}>
                  Generate Key
                </Button>
              </header>
              {message && <p data-testid="api-key-feedback">{message}</p>}
              <div className="api-key-container">
                {newApiKey?.api_key && (
                  <button
                    type="button"
                    data-testid="api-key"
                    onClick={handleCopyToClipboard}
                  >
                    <span>{newApiKey?.api_key}</span>
                  </button>
                )}
                <span className="clipboard-alert" data-testid="clipboard-alert">
                  {alert}
                </span>
              </div>
            </ApiKey>
            <DataDictionary />
            <ConfirmationDialog
              id="re-generate-api-key-warning"
              title="Are you sure?"
              isOpen={isOpen}
              onCancel={() => setIsOpen(false)}
              onConfirm={generateKey}
            >
              Generating a new API key will invalidate your existing API key!
              <br />
              This might affect other users in your organization.
            </ConfirmationDialog>
          </StyledDataAccess>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default DataAccess;
