import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';

export const GET_DEFENSIVE_SCHEME = gql`
  query GetDefensiveScheme(
    $teamId: Int!
    $competitionId: Int!
    $seasonId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    selectedTeamId @client @export(as: "teamId")
    ${getContextFilterClientExports()}

    defensiveScheme(
      teamId: $teamId
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        ${getContextFiltersQueryArguments()}
      }
    ) {
      statsType
      team
      group
      category
      playCount
      playPerc
      epaPerPlay
      ydsPerPlay
      successPerc
      epaRun
      ydsRun
      successPercRun
      epaPass
      ydsPass
      successPercPass
      playIds
    }
  }
`;
