import React from 'react';
import { Grid } from '@statsbomb/kitbag-components';
import Tile from '../../components/Tile/Tile';
import SafePalette from '../../visualisations/SafePalette/SafePalette';
import TeamPalette from '../../visualisations/SafePalette/TeamPalette';

/* This page is internal use only so should never appear in any menu 
    It shows the safe palette of colors against the possible theme background
    It also lists all teams in the currently selected league section, 
        and the safe colors their team colors get mapped to
*/
const SafePaletteViewer = function () {
  return (
    <Grid container={false} page>
      <Grid item xs={12}>
        <Tile>
          <Tile.Header>
            <h1>Safe Palette</h1>
          </Tile.Header>
          <div style={{ width: 1020 }}>
            <SafePalette />
          </div>
        </Tile>
        <Tile>
          <div style={{ width: 1020 }}>
            <TeamPalette />
          </div>
        </Tile>
      </Grid>
    </Grid>
  );
};

export default SafePaletteViewer;
