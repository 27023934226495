import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonIcon, Icon, Table } from '@statsbomb/kitbag-components';
import { millisecondsToTime } from '../../../../utils/helpers/time';
import { getYardlineName, getYardsWon } from '../../../../utils/helpers/play';
import { PLAY_BY_TABLE_HEADERS_PROP_TYPE } from '../PlayByPlay.constants';
import { useSelectedPlay } from '../SelectedPlayContext';
import { ChevronWrapper } from './PlayByPlayAccordionHeader.styles';

const PlayByPlayAccordionHeader = ({
  drive,
  tableHeaders,
  isHomeTeam,
  selectedTeamName,
  selectedTeamColor,
  opponentTeamColor,
}) => {
  const { setSelectedPlay } = useSelectedPlay();
  const [isOpen, setIsOpen] = useState(false);

  const obj = {
    drive: drive?.gameIndex,
    teamOnOffense: drive?.team?.name,
    clock: `Q${drive?.startQuarter} ${millisecondsToTime(
      drive?.quarterClockRemaining
    )}`,
    plays: drive.playCount,
    driveResult: drive?.name?.split('→ ')[1] || '',
    startingFieldPosition: `${getYardlineName(drive?.startingYards)}`,
    yardsGained: getYardsWon(drive?.yardsNet),
    selectedTeamScore: isHomeTeam ? drive?.homeScore : drive?.awayScore,
    opponentTeamScore: isHomeTeam ? drive?.awayScore : drive?.homeScore,
    controls: '',
  };

  // check content for exceptional cases
  const checkContent = (header, content) => {
    if (header.key === 'teamOnOffense') {
      return (
        <span style={{ display: 'flex', gap: '0.5rem' }}>
          <span
            style={{
              color:
                selectedTeamName === content
                  ? selectedTeamColor
                  : opponentTeamColor,
              gap: '0.5rem',
            }}
          >
            <Icon
              variant={
                selectedTeamName === content ? 'HalfMoonLeft' : 'HalfMoonTop'
              }
              size="small"
            />
          </span>
          <span>{content}</span>
        </span>
      );
    }
    if (header.key === 'controls') {
      return (
        <ChevronWrapper $isOpen={isOpen}>
          <ButtonIcon
            icon="ChevronDown"
            variant="ghost"
            size="small"
            onClick={() => {
              setIsOpen(!isOpen);
              setSelectedPlay(content);
            }}
          />
        </ChevronWrapper>
      );
    }
    return content;
  };

  return (
    <Table.Row key={`${drive.name}-table-${drive.name}-row-${drive.name}`}>
      {tableHeaders.map((header, headerIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <Table.DataCell key={headerIndex}>
          {checkContent(header, obj[header.key])}
        </Table.DataCell>
      ))}
    </Table.Row>
  );
};

PlayByPlayAccordionHeader.propTypes = {
  drive: PropTypes.shape({
    gameIndex: PropTypes.number.isRequired,
    team: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    startQuarter: PropTypes.number.isRequired,
    quarterClockRemaining: PropTypes.number.isRequired,
    playCount: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    startingYards: PropTypes.number.isRequired,
    yardsNet: PropTypes.number.isRequired,
    homeScore: PropTypes.number.isRequired,
    awayScore: PropTypes.number.isRequired,
  }).isRequired,
  tableHeaders: PLAY_BY_TABLE_HEADERS_PROP_TYPE.isRequired,
  isHomeTeam: PropTypes.bool.isRequired,
  selectedTeamName: PropTypes.string.isRequired,
  selectedTeamColor: PropTypes.string.isRequired,
  opponentTeamColor: PropTypes.string.isRequired,
};

export default PlayByPlayAccordionHeader;
