import { gql } from '@apollo/client';
import {
  getContextQueryVariables,
  getContextFilterClientExports,
  getContextFiltersQueryArguments,
} from '../../../utils/helpers/contextFilters';
import { HAVOC_POSITION_OPTION_ANY } from '../../../visualisations/HavocChart/HavocChart.constants';
import { getAlignmentPositionByCode } from '../../../utils/helpers/positions';

const getPositionQuery = (selectedPositionCode) => {
  if (
    !selectedPositionCode ||
    selectedPositionCode === HAVOC_POSITION_OPTION_ANY.value
  ) {
    return '';
  }
  const position = getAlignmentPositionByCode(selectedPositionCode);
  if (position.isOffense) {
    return `targetAlignmentPosition: ${position.apiCode}`;
  }
  return `defenderAlignmentPosition: ${position.apiCode}`;
};

export const getHavocAverages = (
  isGaps,
  filterHavocTypes,
  selectedPositionCode
) => {
  const groupBy = isGaps ? 'GAP' : 'ZONE';
  const positionFilter = getPositionQuery(selectedPositionCode);
  const gqlString = gql`
  query GetHavocAverages(
    $competitionId: Int!
    $seasonId: Int!
    ${getContextQueryVariables()}
  ) {
    selectedCompetitionId @client @export(as: "competitionId")
    selectedSeasonId @client @export(as: "seasonId")
    ${getContextFilterClientExports()}
    havocEventsAverage(
      playFilters: {
        competitionId: $competitionId
        seasonId: $seasonId
        ${getContextFiltersQueryArguments()}
      }
      groupBy: ${groupBy}
      havocTypes: [${filterHavocTypes}]
      ${positionFilter}
    ) {
      gap
      zone
      percentageOfAllPlays
      percentageOfHavocPlays
    }
  }
`;
  return gqlString;
};
