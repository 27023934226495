import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { uniqBy } from 'lodash';
import VideoTile from '../../../../components/VideoTile/VideoTile';
import { formatHavocVideoData } from './HavocVideo.dataManipulation';
import HavocEventTile from '../HavocEvent/HavocEventTile';
import Tile from '../../../../components/Tile/Tile';
import { HavocVideo } from './TeamVideoSection.styles';

const HavocVideoSection = ({
  children,
  havocData,
  selectedEvents,
  nowPlayingPlayUUID,
  handleClose,
  onPlaylistChange,
}) => {
  // player colors for highlighting
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;

  /* format havoc events as video playlist data */
  const selectedData = havocData.filter((h) =>
    selectedEvents?.includes(h.eventUUID)
  );
  /* Often multiple havoc events are same play ~ playlist deals with plays */
  const selectedPlays = uniqBy(selectedData, 'playUUID');
  const videoData = formatHavocVideoData(selectedPlays, visPalette);

  const eventDetails = selectedData.filter(
    (d) => d.playUUID === nowPlayingPlayUUID
  );

  /* now playing ~ play details same for all events */
  const href =
    `/game/animation/:leagues/:seasons/:teams/${eventDetails?.[0]?.game_id}` +
    `/${eventDetails?.[0]?.driveUUID}/${eventDetails?.[0]?.playUUID}`;
  const title = `${eventDetails?.[0]?.playName}${
    eventDetails?.length > 1 ? ` (${eventDetails.length} havoc events)` : ''
  }`;
  const subTitle = eventDetails?.[0]?.gameName;

  return (
    <HavocVideo>
      <Tile border="0" margin="0 0 0.5rem 0">
        <VideoTile
          title={title}
          subTitle={subTitle}
          data={videoData}
          gotoLink={href}
          isGotoDisabled={!nowPlayingPlayUUID}
          selectedPlayId={nowPlayingPlayUUID}
          handleClose={handleClose}
          handlePlaylistChange={onPlaylistChange}
        >
          {children}
        </VideoTile>
        {eventDetails.map((evt) => (
          <HavocEventTile data={evt} />
        ))}
      </Tile>
    </HavocVideo>
  );
};

HavocVideoSection.propTypes = {
  // info passed to VideoTile
  children: PropTypes.node,
  havocData: PropTypes.arrayOf(
    PropTypes.shape({
      eventUUID: PropTypes.string,
      playUUID: PropTypes.string,
      /* for href/link */
      game_id: PropTypes.number,
      driveUUID: PropTypes.string,
      gameName: PropTypes.string,
      playName: PropTypes.string,
      /* for currently playing info tile */
      postSnapSeconds: PropTypes.number,
      defenderName: PropTypes.string,
      playerId: PropTypes.number,
      snapGapName: PropTypes.string,
      targetName: PropTypes.string,
      driveName: PropTypes.string,
      xLoS: PropTypes.number,
      eventGapName: PropTypes.string,
      havocType: PropTypes.string,
      playerName: PropTypes.string,
      /* video highlighting */
      targetId: PropTypes.number,
      targetRosterPositionCode: PropTypes.string,
      targetJerseyNumber: PropTypes.string,
      defenderRosterPositionCode: PropTypes.string,
      defenderJerseyNumber: PropTypes.string,
    })
  ),
  selectedEvents: PropTypes.arrayOf(PropTypes.string),
  nowPlayingPlayUUID: PropTypes.string,
  handleClose: PropTypes.func,
  onPlaylistChange: PropTypes.func,
};

HavocVideoSection.defaultProps = {
  children: null,
  havocData: undefined,
  selectedEvents: null,
  nowPlayingPlayUUID: null,
  handleClose: () => {},
  onPlaylistChange: () => {},
};

export default HavocVideoSection;
