import { styled } from 'styled-components';

export const OverviewTileMessage = styled.div`
  padding: 1.25rem;
  color: ${({ theme }) => theme.colours.ink.primary.main};
`;

export const TileContentVerticallyCentered = styled.div`
  height: 100%;

  .chartWrapper {
    svg {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 100%;
    }
  }
`;
TileContentVerticallyCentered.displayName = 'TileContentVerticallyCentered';

export const HalfSizeIcon = styled.span`
  svg {
    transform: scale(0.6);
  }
`;
HalfSizeIcon.displayName = 'HalfSizeIcon';

export const PlayerTacklingTileContainer = styled.div`
  height: 100%;

  .chartWrapper {
    min-height: auto;
    .show {
      display: block;
    }
    .hide {
      display: none;
    }
    svg {
      max-width: ${({ $maxWidth }) => $maxWidth || '550px'};
      margin: 0 auto;
      margin-bottom: ${({ $marginBottom }) => $marginBottom || '0'};
    }
    .dist {
      display: none;

      &.show {
        display: block;
      }

      svg {
        max-width: initial;
        max-height: 640px;
        margin-bottom: 0;
      }
    }
  }
`;
PlayerTacklingTileContainer.displayName = 'PlayerTacklingTileContainer';

export const PlayerSnapTileContainer = styled.div`
  height: 100%;

  svg {
    max-width: 570px;
    margin: 0 auto;
  }
`;
PlayerSnapTileContainer.displayName = 'PlayerSnapTileContainer';

export const PlayerPassEndsTileContainer = styled.div`
  height: 100%;

  .chartWrapper {
    svg {
      max-width: ${({ $maxWidth }) => $maxWidth || '570px'};
      margin: 0 auto;
    }
  }
`;
PlayerPassEndsTileContainer.displayName = 'PlayerPassEndsTileContainer';

export const PlayerHavocTileContainer = styled.div`
  .chartWrapper {
    // havoc chart
    svg:first-child {
      margin: 0 auto;
    }
    // chart key
    svg {
      margin: 0 25px;
    }
  }
`;
PlayerHavocTileContainer.displayName = 'PlayerHavocTileContainer';

export const RouteTreeTileWrapper = styled.div`
  svg {
    max-height: 535px;
    padding: 1rem 7%;
  }

  div + div {
    svg {
      max-height: 60px;
      padding: 0 7%;
    }
  }
`;
RouteTreeTileWrapper.displayName = 'RouteTreeTileWrapper';

export const PlayerComparisonTileWrapper = styled.div`
  a {
    color: ${({ theme }) => theme.colours.ink.primary.main};
    text-decoration: underline;
  }

  a:hover {
    color: ${({ theme }) => theme.colours.ink.highlight.main};
  }

  .comparisonInfo {
    ${({ theme }) => theme.typography.headingMedium};
    margin-bottom: 0.5rem;
  }

  .infoFooter {
    ${({ theme }) => theme.typography.headingSmall};
    margin-left: 0 !important;
  }
`;
PlayerComparisonTileWrapper.displayName = 'PlayerComparisonTileWrapper';
