import { styled } from 'styled-components';

export const RunTableContainer = styled.div`
  width: 100%;
`;
RunTableContainer.displayName = 'RunTableContainer';

export const RunTableTitleHolder = styled.div`
  width: 100%;
  position: relative;

  h3 {
    display: inline-block;
    padding-bottom: 0.5rem;
  }

  button {
    float: right;
  }
`;
RunTableTitleHolder.displayName = 'RunTableTitleHolder';
