import React from 'react';
import PropTypes from 'prop-types';
import { Toggle, Dropdown } from '@statsbomb/kitbag-components';
import Tile from '../../../../components/Tile/Tile';
import AccordionTile from '../../../../components/Accordion/AccordionTile';

import {
  HAVOC_COLOR_MODES,
  HAVOC_FOCUS_MODES,
} from '../../../../visualisations/HavocChart/HavocChart.constants';
import { ROTATION_OPTIONS } from '../../../../utils/constants/charting';

/* This provides the accordion section for filtering havoc things */
const HavocEventFilters = ({
  colorMode,
  setColorMode,
  selectedRotation,
  setRotation,
  fieldFocus,
  setFieldFocus,
  showPaths,
  setShowPaths,
}) => (
  <AccordionTile
    id="field-accordion"
    header={<Tile.AccordionHeader>Event Map Options</Tile.AccordionHeader>}
    body={
      <Tile.AccordionBody>
        <Dropdown
          id="havoc-color-dropdown"
          options={HAVOC_COLOR_MODES}
          label="Display Mode"
          menuPosition="static"
          onChange={(selectedOption) => setColorMode(selectedOption.value)}
          value={HAVOC_COLOR_MODES.find((f) => f.value === colorMode)}
        />

        <Toggle
          id="show-defender-paths-toggle"
          label={showPaths ? `Defender Paths On` : `Defender Paths Off`}
          onChange={() => {
            setShowPaths(!showPaths);
          }}
          checked={showPaths}
        />

        <Dropdown
          id="havoc-field-rotation-dropdown"
          options={ROTATION_OPTIONS}
          label="Field Rotation"
          menuPosition="static"
          onChange={(selectedOption) => setRotation(selectedOption.value)}
          value={ROTATION_OPTIONS.find((f) => f.value === selectedRotation)}
        />

        <Dropdown
          id="havoc-focus-dropdown"
          options={HAVOC_FOCUS_MODES}
          label="Zoom Mode"
          menuPosition="static"
          onChange={(selectedOption) => setFieldFocus(selectedOption.value)}
          value={HAVOC_FOCUS_MODES.find((f) => f.value === fieldFocus)}
        />
      </Tile.AccordionBody>
    }
    isMount
    saveState
    isExpandedDefault
  />
);

HavocEventFilters.propTypes = {
  /** state/querystring values/setters for the options controlled in this selection
   */
  colorMode: PropTypes.string.isRequired,
  setColorMode: PropTypes.func.isRequired,
  selectedRotation: PropTypes.string.isRequired,
  setRotation: PropTypes.func.isRequired,
  fieldFocus: PropTypes.string.isRequired,
  setFieldFocus: PropTypes.func.isRequired,
  showPaths: PropTypes.bool.isRequired,
  setShowPaths: PropTypes.func.isRequired,
};

export default HavocEventFilters;
