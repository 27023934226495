/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Grid, Icon } from '@statsbomb/kitbag-components';
import { useTheme } from 'styled-components';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { GET_GAME_PLAYS } from './GetGamePlays';
import { mf_Teams } from '../../../apollo';
import { formatPlay } from './PlayByPlay.DataManipulation';
import PlayByPlayAccordion from './PlayByPlayAccordion/PlayByPlayAccordion';
import Tile from '../../../components/Tile/Tile';
import {
  PlayByPlayHeaderContainer,
  PlayByPlayTable,
} from './PlayByPlay.styles';
import { useDistinctTeamColors } from '../../../utils/hooks/useDistinctTeamColors';
import {
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { MemoizedVideoTile } from '../../../components/VideoTile/VideoTile';
import { useSelectedPlay } from './SelectedPlayContext';
import { PLAY_BY_TABLE_HEADERS } from './PlayByPlay.constants';

const PlayByPlay = () => {
  // video integration
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const { selectedPlay, setSelectedPlay } = useSelectedPlay();
  const videoOpen = selectedPlay?.id?.length > 0 || false;
  const windowWidth = useWindowWidth();
  const [sidebarWidthCalc, setSidebarWidthCalc] = useState();

  // data
  const {
    data: playsData,
    loading: playsLoading,
    error: playsError,
  } = useQuery(GET_GAME_PLAYS);

  const selectedTeam = useReactiveVar(mf_Teams);

  const formattedPlays = playsData?.plays.edges
    .map(formatPlay)
    .filter((p) => p.drive);

  const gameDetails = formattedPlays?.[0]?.game;
  // whether the currently selected team is the home team
  const isHomeTeam = gameDetails?.homeTeam.id === selectedTeam;
  // date
  const formattedDate = new Date(gameDetails?.date).toLocaleString('en-us', {
    day: 'numeric',
    month: 'numeric',
  });
  const gameDate = gameDetails
    ? `${gameDetails?.roundShortName} ${formattedDate}`
    : '';

  // names
  const selectedTeamName = isHomeTeam
    ? gameDetails?.homeTeam.name
    : gameDetails?.awayTeam.name;
  const opponentTeamName = isHomeTeam
    ? gameDetails?.awayTeam.name
    : gameDetails?.homeTeam.name;

  // scores
  const selectedTeamScore = isHomeTeam
    ? gameDetails?.homeScore
    : gameDetails?.awayScore;
  const opponentTeamScore = isHomeTeam
    ? gameDetails?.awayScore
    : gameDetails?.homeScore;

  // colors
  const homeTeamColors = {
    primary: gameDetails?.homeTeam.colorPrimary,
    secondary: gameDetails?.homeTeam.colorSecondary,
    tertiary: gameDetails?.homeTeam.colorTertiary,
  };
  const awayTeamColors = {
    primary: gameDetails?.awayTeam.colorPrimary,
    secondary: gameDetails?.awayTeam.colorSecondary,
    tertiary: gameDetails?.awayTeam.colorTertiary,
  };
  const selectedTeamColors = isHomeTeam ? homeTeamColors : awayTeamColors;
  const opponentTeamColors = isHomeTeam ? awayTeamColors : homeTeamColors;

  const { selectedTeamColor, opponentTeamColor } = useDistinctTeamColors(
    selectedTeamColors,
    opponentTeamColors
  );

  const tableHeaders = [...PLAY_BY_TABLE_HEADERS];
  tableHeaders.find((h) => h.key === 'selectedTeamScore').label =
    selectedTeamName;
  tableHeaders.find((h) => h.key === 'opponentTeamScore').label =
    opponentTeamName;

  const validScores =
    typeof selectedTeamScore === 'number' &&
    typeof opponentTeamScore === 'number';

  const onVideoClose = () => {
    setSelectedPlay(null);
  };

  useEffect(() => {
    setSidebarWidthCalc(windowWidth - 0.5 * windowWidth);
  }, [windowWidth, videoOpen]);

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <SidebarRightLayout
            $sidebarWidth={videoOpen ? `${sidebarWidthCalc}px` : '0px'}
            $inlineSize={`${videoOpen ? 40 : 0}%`}
            $gap="0"
            $padding="0"
          >
            <div>
              <PageHeader
                href="/game/byplay/:leagues/:seasons/:teams/:games"
                rootPage="Game"
                activePage="Play by Play Feed"
                showPlayerName={false}
                showTeamName={false}
                customTitle={
                  <PlayByPlayHeaderContainer>
                    <span>{gameDate}</span>
                    <span style={{ color: selectedTeamColor }}>
                      <Icon variant="HalfMoonLeft" />
                    </span>
                    <span>{selectedTeamName || 'Selected Team'}</span>
                    {` ${isHomeTeam ? 'vs' : '@'} `}
                    <span style={{ color: opponentTeamColor }}>
                      <Icon variant="HalfMoonTop" />
                    </span>
                    <span>{`${opponentTeamName || 'Opponent Team'}`}</span>
                    {validScores &&
                      `(${selectedTeamScore} - ${opponentTeamScore})`}
                  </PlayByPlayHeaderContainer>
                }
              />
              <Tile border="0" margin="0.5rem 0 0 0">
                <Tile.Header $border="1px">
                  <h1>Play by Play Feed</h1>
                </Tile.Header>
                <Tile.Body>
                  <PlayByPlayTable>
                    <PlayByPlayAccordion
                      playsData={formattedPlays}
                      isLoading={playsLoading}
                      isError={!!playsError}
                      selectedTeamColor={selectedTeamColor}
                      opponentTeamColor={opponentTeamColor}
                      visPalette={visPalette}
                      tableHeaders={tableHeaders}
                    />
                  </PlayByPlayTable>
                </Tile.Body>
              </Tile>
            </div>
            <div>
              {selectedPlay?.id?.length > 0 && (
                <div style={{ margin: '0 0 0 0.5rem' }}>
                  <MemoizedVideoTile
                    title={selectedPlay?.info?.name}
                    subTitle={selectedPlay?.info?.eventNotes}
                    data={[selectedPlay?.id]}
                    handleClose={onVideoClose}
                  />
                </div>
              )}
            </div>
          </SidebarRightLayout>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default PlayByPlay;
