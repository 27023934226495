import { styled } from 'styled-components';

export const RunTendenciesContainer = styled.div`
  display: flex;
  flex-direction: ${({ $horizontal }) => ($horizontal ? 'column' : 'row')};

  // chart
  svg:first-child {
    width: ${({ $horizontal }) => ($horizontal ? '100%' : '80%')};
  }

  // key
  svg:last-child {
    width: ${({ $horizontal }) => ($horizontal ? '100%' : '20%')};
    align-items: flex-end;
  }
`;

export const ChartButtonRibbon = styled.div`
  width: 100%;
  position: relative;
  display: table;
  height: 24px;

  button {
    float: right;
    margin-left: 8px;
  }
`;
ChartButtonRibbon.displayName = 'ChartButtonRibbon';

export const POAChartTitle = styled.div`
  margin-top: ${({ $horizontal }) => ($horizontal ? '2rem' : '1rem')};
`;
