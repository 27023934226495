import React, { useState } from 'react';
import { groupBy, orderBy, sumBy } from 'lodash';
import { useReactiveVar } from '@apollo/client';
import { Loader } from 'semantic-ui-react';
import {
  Button,
  ButtonIcon,
  ButtonGroup,
  Grid,
} from '@statsbomb/kitbag-components';
import { CSVLink } from 'react-csv';
import Dimmer from '../../../components/Dimmer/Dimmer';
import { mf_TeamDetails, mf_Teams } from '../../../apollo';
import Tile from '../../../components/Tile/Tile';
import ExportModal from '../../../components/ExportModal/ExportModal';
import {
  FormationSummaryPage,
  FormationSummaryPageHeader,
  FormationSummaryMainContent,
  FormationSummarySectionHeader,
  Controls,
  FormationSummaryChartContainer,
} from './TeamFormationSummary.styles';
import AccordionTable from '../../../components/Tables/AccordionTable/AccordionTable';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import FormationSummaryChart from '../../../visualisations/FormationSummaryChart/FormationSummaryChart';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import FormationSummaryFormationTable from './FormationSummaryFormationTable';
import {
  getStatHeaders,
  orderStats,
} from '../../../utils/helpers/stats.dataManipulation';
import {
  getFormationSummaryCsvData,
  getRowsToRender,
} from './TeamFormationSummary.dataManipulation';
import {
  useTableDataByFormation,
  useTableDataByPersonnel,
  useBarChartDataByFormation,
  useBarChartDataByPersonnel,
} from './TeamFormationSummary.hooks';
import useQueryString from '../../../utils/hooks/useQueryString';
import { useFormationSummaryStats } from './useFormationSummaryStats';
import { useTeamStatDefinitions } from './useTeamStatDefinitions';
import FormationSummaryChartKey from '../../../visualisations/FormationSummaryChart/FormationSummaryChartKey';
import { useTeamStatDistributions } from '../../../utils/hooks/useTeamStatDistributions';
import {
  PLAY_COUNT,
  FORMATION_SUMMARY_STICKY_COLUMNS,
  FORMATION_SUMMARY_STAT_DISTRIBUTION,
} from './TeamFormationSummary.constants';
import FormationSummaryTableKey from './FormationSummaryTableKey';
import { KitbagPageGridHolder } from '../../../components/Layout/Layout.styles';

const { ASCENDING, DESCENDING } = SORT_DIRECTIONS;

const TeamFormationSummary = () => {
  const teamId = useReactiveVar(mf_Teams);
  const teamDetails = useReactiveVar(mf_TeamDetails);
  const [isTeamMode, setIsTeamMode] = useQueryString('teamMode', true);
  const [isPersonnelMode, setIsPersonnelMode] = useQueryString(
    'personnelMode',
    false
  );
  const [sortBy, setSortBy] = useQueryString('sortBy', PLAY_COUNT);
  const [sortDirection, setSortDirection] = useQueryString(
    'sortDirection',
    DESCENDING
  );
  const [displayChartKey, setDisplayChartKey] = useQueryString(
    'chartKey',
    true
  );
  const [displayTableKey, setDisplayTableKey] = useQueryString(
    'tableKey',
    true
  );
  const [visibleStats, setVisibleStats] = useState({ 0: 'partial' });
  const [isExpanded, setIsExpanded] = useState(false);
  const { competitionSeason, seasonName } = useExportSeasonInfo({ asObject: true });

  const {
    data: statDistributions,
    loading: statDistributionsLoading,
    error: statDistributionsError,
  } = useTeamStatDistributions(FORMATION_SUMMARY_STAT_DISTRIBUTION);

  const {
    data: statDefinitions,
    loading: statDefinitionsLoading,
    error: statDefinitionsError,
  } = useTeamStatDefinitions(isPersonnelMode);

  const {
    data: teamStatsByFormation,
    loading: teamStatsByFormationLoading,
    error: teamStatsByFormationError,
  } = useFormationSummaryStats(
    teamId,
    ['FORMATION'],
    statDistributions,
    isTeamMode,
    isPersonnelMode
  );

  const {
    data: teamStatsByPersonnel,
    loading: teamStatsByPersonnelLoading,
    error: teamStatsByPersonnelError,
  } = useFormationSummaryStats(
    teamId,
    ['OFFENSE_PERSONNEL'],
    statDistributions,
    isTeamMode,
    !isPersonnelMode
  );

  const {
    data: teamStatsByFormationPersonnel,
    loading: teamStatsByFormationPersonnelLoading,
    error: teamStatsByFormationPersonnelError,
  } = useFormationSummaryStats(
    teamId,
    ['FORMATION', 'OFFENSE_PERSONNEL'],
    statDistributions,
    isTeamMode,
    !isPersonnelMode
  );

  const headers = getStatHeaders(statDefinitions);
  const headerIds = headers.map((h) => h.id);

  // Chart data by personnel
  const groupedTeamStatsByPersonnel = groupBy(
    teamStatsByFormationPersonnel,
    'offensePersonnel'
  );

  const orderedChartDataByPersonnel = orderBy(
    Object.values(groupedTeamStatsByPersonnel).map((group) =>
      orderBy(group, [sortBy], [sortDirection])
    ),
    [(d) => sumBy(d, PLAY_COUNT)],
    [SORT_DIRECTIONS.DESCENDING]
  );

  const chartDataByPersonnel = useBarChartDataByPersonnel(
    orderedChartDataByPersonnel,
    teamStatsByPersonnel,
    isTeamMode,
    visibleStats
  );

  // Chart data by formation
  const orderedChartDataByFormation = orderBy(
    teamStatsByFormation,
    [sortBy],
    [sortDirection]
  );

  const filteredChartDataByFormation = isExpanded
    ? orderedChartDataByFormation
    : orderedChartDataByFormation.slice(0, 10);

  const chartDataByFormation = useBarChartDataByFormation(
    filteredChartDataByFormation,
    isTeamMode
  );

  const barChartData = isPersonnelMode
    ? chartDataByPersonnel
    : chartDataByFormation;

  // table data grouped by personnel
  const formattedTableDataByPersonnel = useTableDataByPersonnel(
    teamStatsByFormationPersonnel,
    teamStatsByPersonnel,
    statDistributions,
    statDefinitions,
    isTeamMode,
    sortBy,
    sortDirection,
    headerIds
  );

  const sortedTableDataByPersonnel = orderBy(
    formattedTableDataByPersonnel,
    [(d) => d.rows[0].playCount?.value],
    [DESCENDING]
  );

  // filter rows to display depending on visibility status
  const filteredTableDataByPersonnel = sortedTableDataByPersonnel.map(
    (table, index) => ({
      ...table,
      rows: getRowsToRender(table.rows, visibleStats[index]),
      expandButtonCondition: table.rows.length > 7,
    })
  );

  // table data grouped by formation
  const formattedTableDataByFormation = useTableDataByFormation(
    teamStatsByFormation,
    statDistributions,
    statDefinitions,
    isTeamMode,
    headerIds
  );

  const sortedTableDataByFormation = orderBy(
    formattedTableDataByFormation,
    [(row) => row[sortBy]?.title],
    [sortDirection]
  );

  const orderedTableDataByFormation = orderStats(
    sortedTableDataByFormation,
    headerIds
  );

  const handleSort = (headerId) => {
    if (sortBy === headerId) {
      setSortDirection(sortDirection === ASCENDING ? DESCENDING : ASCENDING);
    } else {
      setSortDirection(DESCENDING);
      setSortBy(headerId);
    }
  };

  const handleDataVisibility = (index, status) =>
    setVisibleStats({
      ...visibleStats,
      [index]: status,
    });

  const loading =
    statDefinitionsLoading ||
    statDistributionsLoading ||
    teamStatsByFormationLoading ||
    teamStatsByPersonnelLoading ||
    teamStatsByFormationPersonnelLoading;

  const error =
    statDistributionsError ||
    statDefinitionsError ||
    teamStatsByFormationError ||
    teamStatsByPersonnelError ||
    teamStatsByFormationPersonnelError;

  const getFeedback = () => {
    if (loading) {
      return <Loader inverted content="Loading" />;
    }
    if (error) {
      return 'Data error';
    }
    if (!barChartData?.length) {
      return `No data available`;
    }
    return null;
  };
  const feedback = getFeedback();

  // csv formatting
  const stats = isTeamMode ? 'Team Specific' : 'Vs League Average';
  const grouping = isPersonnelMode ? 'Personnel' : 'Formation';
  const fileName = `Formation Summary - ${teamDetails?.name} By ${grouping}, ${stats} ${seasonName}`;
  const csvData = isPersonnelMode
    ? sortedTableDataByPersonnel
    : orderedTableDataByFormation;
  const formattedCsvData = getFormationSummaryCsvData(csvData);

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <FormationSummaryPage>
            <FormationSummaryPageHeader>
              <div className="team">{teamDetails?.name}</div>
              <div className="controls">
                <div>Stats</div>
                <ButtonGroup>
                  <Button
                    variant={isTeamMode ? 'primary' : 'secondary'}
                    onClick={() => setIsTeamMode(true)}
                    size="small"
                  >
                    Team
                  </Button>
                  <Button
                    variant={isTeamMode ? 'secondary' : 'primary'}
                    onClick={() => setIsTeamMode(false)}
                    size="small"
                  >
                    vs. League Avg
                  </Button>
                </ButtonGroup>
                <div className="divider" />
                <div>Grouping</div>
                <ButtonGroup>
                  <Button
                    variant={isPersonnelMode ? 'primary' : 'secondary'}
                    onClick={() => setIsPersonnelMode(true)}
                    size="small"
                  >
                    Personnel
                  </Button>
                  <Button
                    variant={isPersonnelMode ? 'secondary' : 'primary'}
                    onClick={() => setIsPersonnelMode(false)}
                    size="small"
                  >
                    Formation
                  </Button>
                </ButtonGroup>
              </div>
            </FormationSummaryPageHeader>
            <FormationSummaryMainContent>
              <Tile margin="0">
                <Tile.Header>
                  <FormationSummarySectionHeader>
                    <div>{`Frequency and Success : ${stats}`}</div>
                    <Controls>
                      <ButtonIcon
                        size="small"
                        variant="secondary"
                        icon="Key"
                        onClick={() => setDisplayChartKey(!displayChartKey)}
                        off={!displayChartKey}
                        title="Display chart key"
                      >
                        {`${displayChartKey ? 'hide' : 'display'} chart key`}
                      </ButtonIcon>
                      <ExportModal
                        title={teamDetails?.name}
                        secondaryTitle="Formation Summary"
                        info1={competitionSeason}
                        fileName={`Formation Summary - ${teamDetails?.name} ${seasonName}`}
                        isDisabled={!!feedback}
                      >
                        <>
                          <FormationSummaryChart
                            id="formation-summary-chart-export"
                            data={barChartData}
                            isTeamMode={isTeamMode}
                            isPersonnelMode={isPersonnelMode}
                          />
                          {displayChartKey && (
                            <FormationSummaryChartKey isTeamMode={isTeamMode} />
                          )}
                        </>
                      </ExportModal>
                    </Controls>
                  </FormationSummarySectionHeader>
                </Tile.Header>
                <Tile.Body className="section-body">
                  {feedback ? (
                    <Dimmer active inverted>
                      {feedback}
                    </Dimmer>
                  ) : (
                    <>
                      <FormationSummaryChartContainer>
                        <FormationSummaryChart
                          id="formation-summary-chart-export"
                          data={barChartData}
                          isTeamMode={isTeamMode}
                          isPersonnelMode={isPersonnelMode}
                        />
                      </FormationSummaryChartContainer>
                      {displayChartKey && (
                        <FormationSummaryChartKey isTeamMode={isTeamMode} />
                      )}
                    </>
                  )}
                </Tile.Body>
              </Tile>
              <Tile>
                <Tile.Header>
                  <FormationSummarySectionHeader>
                    <div>Key Metrics</div>
                    <Controls>
                      <ButtonIcon
                        size="small"
                        variant="secondary"
                        icon="Key"
                        onClick={() => setDisplayTableKey(!displayTableKey)}
                        off={!displayTableKey}
                        title="Display table key"
                      >
                        {`${displayTableKey ? 'hide' : 'display'} table key`}
                      </ButtonIcon>
                      <CSVLink data={formattedCsvData} filename={fileName}>
                        <ButtonIcon
                          size="small"
                          variant="secondary"
                          icon="Download"
                          disabled={loading}
                          title="Download Formation Summary Table"
                        >
                          download formation summary table
                        </ButtonIcon>
                      </CSVLink>
                    </Controls>
                  </FormationSummarySectionHeader>
                </Tile.Header>
                <Tile.Body className="section-body">
                  {feedback && (
                    <Dimmer active inverted>
                      {feedback}
                    </Dimmer>
                  )}
                  {!loading &&
                    (isPersonnelMode ? (
                      <AccordionTable
                        tables={filteredTableDataByPersonnel}
                        headers={headers}
                        handleSort={handleSort}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        handleDataVisibility={handleDataVisibility}
                        stickyColumns={FORMATION_SUMMARY_STICKY_COLUMNS}
                        expandButtonSuffix="formations"
                        visibleStats={visibleStats}
                        withAllRows
                      />
                    ) : (
                      <FormationSummaryFormationTable
                        headers={headers}
                        rows={orderedTableDataByFormation}
                        handleSort={handleSort}
                        sortBy={sortBy}
                        sortDirection={sortDirection}
                        isExpanded={isExpanded}
                        handleExpand={() => setIsExpanded(!isExpanded)}
                        displayKey={displayTableKey}
                      />
                    ))}
                  {displayTableKey && <FormationSummaryTableKey />}
                </Tile.Body>
              </Tile>
            </FormationSummaryMainContent>
          </FormationSummaryPage>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default TeamFormationSummary;
