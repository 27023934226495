import { maxBy, minBy, omit, sumBy } from 'lodash';
import { scaleLinear } from 'd3';
import { ROUTE_TYPE_API_NAMES } from './PlayerRoute.constants';
import { proportionalDifference } from '../../../utils/helpers/aggregation';
import {
  ROUTE_TREE_BRANCH_RANGE,
  ROUTE_TYPE_CONFIG,
  ROUTE_TYPE_UNCLASSIFIED,
} from '../../../visualisations/RouteTree/RouteTree.constants';
import { formatValue } from '../../../utils/helpers/stats.dataManipulation';
import { getRosterPositionColorByCode } from '../../../utils/helpers/positions';

export const formatAPIRouteData = (apiRouteData) => {
  if (!apiRouteData?.length) {
    return [];
  }
  const routesData = apiRouteData.map((routeDatum) => {
    const playerId = parseInt(
      routeDatum.groups.find((g) => g.key === 'PLAYER').value,
      10
    );
    const routeType = routeDatum?.routeName
      ? routeDatum.routeName
      : ROUTE_TYPE_UNCLASSIFIED.apiKey;
    const deGroupedRouteDatum = omit(
      routeDatum,
      'groups',
      'routeName',
      '__typename'
    );
    return {
      ...deGroupedRouteDatum,
      playerId,
      routeType,
    };
  });

  return routesData;
};

export const getRouteTotals = (routesDataIn) => {
  /* Safety valve for not yet loaded data */
  const routesData = routesDataIn?.length ? routesDataIn : [];

  /* First get the overall totals */
  const totalPlays = sumBy(routesData, 'plays');
  const totalRoutes = sumBy(routesData, 'routesRun');
  const totalTargets = sumBy(routesData, 'targets');

  /* Return all summary information */
  return {
    totalPlays,
    totalRoutes,
    totalTargets,
  };
};

/* For a subset of data do all the maths */
const routeSummaryDatum = (
  routeName,
  routeTypeDatum,
  totalRoutes,
  totalTargets
) => {
  const routesRun = routeTypeDatum?.routesRun || 0;
  const targets = routeTypeDatum?.targets || 0;

  /* These values match the route tree axes definitions */
  const routePercentage = totalRoutes ? routesRun / totalRoutes : 0;
  const targetPercentage = totalTargets ? targets / totalTargets : 0;
  const targetRouteRatio = proportionalDifference(
    targetPercentage,
    routePercentage
  );
  const playerStatValues = routeTypeDatum || {};
  return {
    ...playerStatValues,
    routeType: routeName,
    routesRun,
    targets,
    routePercentage,
    targetPercentage,
    targetRouteRatio,
  };
};

export const addRouteProportionalities = (routesData, routeTotals) => {
  /* Safety valve for not yet loaded data */
  if (!routesData?.length || routeTotals?.totalPlays <= 0) {
    return [];
  }
  /* First get the overall totals */
  const { totalRoutes, totalTargets } = routeTotals;

  /* For each named route class, work out the proportions from the totals */
  const routeTypes = ROUTE_TYPE_API_NAMES.map((routeName) => {
    const routeTypeDatum = routesData.find(
      (datum) => datum.routeType === routeName
    );
    return routeSummaryDatum(
      routeName,
      routeTypeDatum,
      totalRoutes,
      totalTargets
    );
  });
  /* Do same for anything not classified and amalgamate */
  const unclassifiedRoutesDatum = routesData.find(
    (datum) => datum.routeType === null
  );
  const unclassifiedRoutesSummary = routeSummaryDatum(
    ROUTE_TYPE_UNCLASSIFIED.apiKey,
    unclassifiedRoutesDatum,
    totalRoutes,
    totalTargets
  );
  const allRoutes = routeTypes.concat(unclassifiedRoutesSummary);
  return allRoutes;
};

export const rescaleMetricConfig = (routesData, metricName, axisConfig) => {
  if (!routesData?.length > 0) {
    return axisConfig;
  }
  /* Rescale route % to maximum */
  const maxValue = maxBy(routesData, metricName)?.[metricName] || 0;
  const minValue = minBy(routesData, metricName)?.[metricName] || 0;
  const playerDomain = [minValue, maxValue];
  return { ...axisConfig, domain: playerDomain };
};

export const objectifyRoutes = (routesData) => {
  /* Merge everything into single object */
  const routeTypeesObject = routesData.reduce(
    (obj, item) => ({
      ...obj,
      [item.routeType]: item,
    }),
    {}
  );
  return routeTypeesObject;
};

/* Convert routes data by formatting each value for table display */
export const tablefyRoutes = (routesData, statDefinitions) => {
  const tableRoutes = routesData.map((routeDatum) => {
    /* Format each value that is a table column */
    const tableRowValues = statDefinitions.map((def) => ({
      key: [def.key],
      value: formatValue(routeDatum[def.key], def.units),
    }));
    /* re-merge into an object per row */
    const tableRow = tableRowValues.reduce(
      (obj, keyValueObj) => ({
        ...obj,
        [keyValueObj.key]: keyValueObj.value,
      }),
      {}
    );
    /* Prettify the route name from api-key to nice name */
    const routeType = ROUTE_TYPE_CONFIG[routeDatum.routeType].name;
    return {
      ...tableRow,
      routeType,
    };
  });
  return tableRoutes;
};

/* Change stat defs into format for basic table headers */
export const getTableHeaders = (statDefinitions) => {
  const dataHeaders = statDefinitions.map((statDef) => ({
    key: statDef.key,
    label: statDef.abbrev,
    description: statDef.description,
  }));
  return dataHeaders;
};

/* Change stat defs into format for dropdown selection */
export const getFilterMetrics = (statDefinitions) => {
  const metricDropdownOptions = statDefinitions
    .filter((m) => m.domain !== null)
    .map((statDef) => ({
      value: statDef.key,
      label: statDef.abbrev,
    }));
  return metricDropdownOptions;
};

/* 
For each value, works out all the display information
Generates values that are RouteSummaryPropType shape
 */
export const addVisulisationInfo = (routesData, branchConfig, fruitConfig) => {
  if (!routesData?.length > 0) {
    return [];
  }

  const treeBranchScale = scaleLinear()
    .domain(branchConfig.domain)
    .range(ROUTE_TREE_BRANCH_RANGE)
    .clamp(true);

  const scaledRoutes = routesData.map((routeDatum) => {
    const branchValue = routeDatum[branchConfig.key] || 0;
    const branchValueFormatted = formatValue(branchValue, branchConfig.units);
    const branch = {
      value: branchValue,
      valueFormatted: branchValueFormatted,
      strokeWidth: treeBranchScale(branchValue),
      strokeDash: branchValue <= 0,
    };

    const fruitValue = routeDatum[fruitConfig.key];
    const fruitValueFormatted = formatValue(fruitValue, fruitConfig.units);
    const fruitScaler = scaleLinear()
      .domain(fruitConfig.domain)
      .range([0, 1])
      .clamp(true);
    const scaledFruitValue = fruitScaler(fruitValue);
    const fruit = {
      value: fruitValue,
      valueFormatted: fruitValueFormatted,
      fillValue: scaledFruitValue,
      fillFunction: fruitConfig.colorFunction,
      opacity: fruitValue ? 1 : 0.4,
    };

    const routeConfig = ROUTE_TYPE_CONFIG[routeDatum.routeType];
    const tooltip =
      /* Route type details */
      `${routeConfig.name}` +
      `\n${routeConfig.description}` +
      /* Basic counts */
      `\n${routeDatum.routesRun} Routes Run` +
      `\n${routeDatum.targets} Targets` +
      /* User selected details */
      `\n\n${branchConfig.abbrev}: ${branchValueFormatted}` +
      `\n${fruitConfig.abbrev}: ${fruitValueFormatted}`;

    /* Output here needs to match the prop type shape: routeSummaryDatumPropType */
    return {
      routeType: routeDatum.routeType,
      routes: routeDatum.routesRun,
      targets: routeDatum.targets,
      branch,
      fruit,
      tooltip,
    };
  });
  return scaledRoutes;
};

// format data for video tile to allow player tracking and colouring
export const formatRouteVideoData = (playIds, playerDetails, visPalette) =>
  playIds?.map((playId) => ({
    playId,
    highlightPlayers: [
      {
        active: true,
        id: playerDetails?.id,
        colour: getRosterPositionColorByCode(
          playerDetails?.mostCommonPosition?.code,
          visPalette
        ),
        name: playerDetails?.name,
        number: playerDetails?.mostCommonNumber,
      },
    ],
  }));
