import {
  API_PLAYER_AGGREGATE_MODES,
  API_PLAYER_GROUP_BY,
  API_ROSTER_POSITION_KEYS,
} from '../../../utils/constants/api';

export const PLAYER_AGGREGATE_OPTIONS = [
  { value: API_PLAYER_AGGREGATE_MODES.TOTAL, label: 'Total' },
  { value: API_PLAYER_AGGREGATE_MODES.RATE, label: 'Rate' },
];

export const PLAYER_GROUP_BY_OPTIONS = [
  { value: API_PLAYER_GROUP_BY.DOWN, label: 'Down' },
  { value: API_PLAYER_GROUP_BY.QUARTER, label: 'Quarter' },
  { value: API_PLAYER_GROUP_BY.PLAY_POSITION, label: 'Alignment Position' },
];

export const NO_TEAMS_OPTION = { value: 'none', label: 'None' };

export const PERMANENT_HEADERS_CONFIG = [
  {
    id: 'jerseyNumber',
    label: '#',
    isSortable: false,
    description: 'Player Jersey Number',
  },
  {
    id: 'playerName',
    label: 'Player',
    isSortable: true,
    description: 'Player Name',
  },
  {
    id: 'teamShortName',
    label: 'Team',
    isSortable: true,
    description: 'Team Short Name',
  },
  {
    id: 'rosterPosition',
    label: 'R Pos',
    isSortable: true,
    description:
      'Roster Position\nThe listed position of a player on the roster',
  },
  {
    id: 'plays',
    label: 'Plays',
    isSortable: true,
    description: '# of plays a player played',
  },
];

export const DEFAULT_SORT_BY = 'plays';
export const DEFAULT_HIGHLIGHTED_COLUMN =
  PERMANENT_HEADERS_CONFIG.findIndex((h) => h.id === DEFAULT_SORT_BY) + 1;

export const PLAYER_GROUP_BY_HEADERS_CONFIG = {
  [API_PLAYER_GROUP_BY.DOWN]: {
    id: 'down',
    label: 'Down',
    description: 'Down\nThe down metrics are aggregated for',
    isSortable: false,
  },
  [API_PLAYER_GROUP_BY.QUARTER]: {
    id: 'quarter',
    label: 'Quarter',
    description: 'Quarter\nThe quarter metrics are aggregated for',
    isSortable: false,
  },
  [API_PLAYER_GROUP_BY.PLAY_POSITION]: {
    id: 'playPosition',
    label: 'A Pos',
    description:
      'Alignment Position\nThe position a player lined up at for a play',
    isSortable: true,
  },
};

export const PLAYER_GROUP_BY_STICKY_COLUMNS_WIDTH = {
  [API_PLAYER_GROUP_BY.DOWN]: 3.75,
  [API_PLAYER_GROUP_BY.QUARTER]: 4.6875,
  [API_PLAYER_GROUP_BY.PLAY_POSITION]: 5.625,
};

export const PLAYERS_PER_PAGE = 100;

export const GENERAL_POSITION_TO_CATEGORY = {
  QUARTERBACK: 'Passing',
  RUNNING_BACK: 'Rushing',
  WIDE_RECEIVER: 'Receiving',
  TIGHT_END: 'Receiving',
  DEFENSIVE_LINEMAN: 'Defensive Line',
  OFFENSIVE_LINEMAN: 'Offensive Line',
  LINEBACKER: 'Linebackers',
  DEFENSIVE_BACK: 'Defensive Line',
  PUNTER: 'Special Teams',
  KICKER: 'Special Teams',
  LONG_SNAPPER: 'Special Teams',
};

export const GENERAL_POSITION_TO_TILE_SUB_CATEGORY = {
  QUARTERBACK: 'Primary',
  RUNNING_BACK: 'Primary',
  WIDE_RECEIVER: 'Primary',
  TIGHT_END: 'Primary',
  DEFENSIVE_LINEMAN: 'Primary',
  OFFENSIVE_LINEMAN: 'Primary',
  LINEBACKER: 'Primary',
  DEFENSIVE_BACK: 'Primary',
  PUNTER: 'Punting',
  KICKER: 'FG/XP',
  LONG_SNAPPER: 'Long Snappers',
};

const {
  DEFENSIVE_BACK,
  DEFENSIVE_LINEMAN,
  HOLDER,
  KICKER,
  KICK_RETURNER,
  LINEBACKER,
  LONG_SNAPPER,
  OFFENSIVE_LINEMAN,
  PUNTER,
  PUNT_RETURNER,
  QUARTERBACK,
  RUNNING_BACK,
  TIGHT_END,
  WIDE_RECEIVER,
} = API_ROSTER_POSITION_KEYS;

const DEFENSE = [DEFENSIVE_LINEMAN, LINEBACKER, DEFENSIVE_BACK];
const OFFENSE = [
  QUARTERBACK,
  RUNNING_BACK,
  WIDE_RECEIVER,
  TIGHT_END,
  OFFENSIVE_LINEMAN,
];

// TODO: remove this enum/map once we can get this information from the api definitions
export const CATEGORY_GENERAL_POSITION_MAP = {
  'Defensive Line': [DEFENSIVE_LINEMAN],
  'Offensive Line': [OFFENSIVE_LINEMAN],
  'Defensive Backs': [DEFENSIVE_BACK],
  Linebackers: [LINEBACKER],
  Receiving: [WIDE_RECEIVER, TIGHT_END],
  Rushing: [RUNNING_BACK],
  Passing: [QUARTERBACK],
  'Special Teams': [
    KICKER,
    PUNTER,
    HOLDER,
    LONG_SNAPPER,
    PUNT_RETURNER,
    KICK_RETURNER,
  ],
  Physical: [...DEFENSE, ...OFFENSE],
};

// TODO: remove this enum/map once we can get this information from the api definitions
const allRosterPositions = Object.values(API_ROSTER_POSITION_KEYS);
export const SPECIAL_TEAMS_POSITION_MAP = {
  'FG/XP': [KICKER],
  Punting: [PUNTER],
  'Punt Returns': allRosterPositions,
  Kickoffs: [KICKER],
  'Kick Returns': allRosterPositions,
  'Long Snappers': [LONG_SNAPPER, DEFENSIVE_LINEMAN, OFFENSIVE_LINEMAN],
  'ST Tackling': allRosterPositions,
};

// TODO: remove this enum/map once we can get this information from the api definitions
export const PHYSICAL_METRICS_MAP = {
  Primary: [...DEFENSE, ...OFFENSE],
  'Closing Speed': [DEFENSIVE_BACK],
  'Get Off': [DEFENSIVE_LINEMAN],
  'Deep Route Speed': [WIDE_RECEIVER, TIGHT_END],
};
