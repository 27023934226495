export const TABLE_OF_CONTENTS_CONFIG = [
  {
    section: 'Player',
    pages: [
      {
        label: 'Overview',
        link: '/player/overview/:leagues/:seasons/:teams/:players',
      },
      {
        label: 'Pass Placement',
        link: '/player/placement/:leagues/:seasons/:teams/:players',
      },
      {
        label: 'Player Passing',
        link: '/player/passing/:leagues/:seasons/:teams/:players',
      },
      {
        label: 'Snap Distribution',
        link: '/player/snap/:leagues/:seasons/:teams/:players',
      },
      {
        label: 'Radar',
        link: '/player/radar/:leagues/:seasons/:teams/:players',
      },
      {
        label: 'Routes Run',
        link: '/player/route/:leagues/:seasons/:teams/:players',
      },
      {
        label: 'Comparison',
        link: '/player/comparison/:leagues/:seasons/:teams/:players',
      },
    ],
  },
  {
    section: 'Team',
    pages: [
      {
        label: 'Overview',
        link: '/team/overview/:leagues/:seasons/:teams',
      },
      {
        label: 'Box Score',
        link: '/team/box/:leagues/:seasons/:teams',
      },
      {
        label: 'Passing Chart',
        link: '/team/passing/:leagues/:seasons/:teams',
      },
      {
        label: 'Line Pressure',
        link: '/team/linePressure/:leagues/:seasons/:teams',
      },
      {
        label: 'Run Tendencies',
        link: '/team/runTendencies/:leagues/:seasons/:teams',
      },
      {
        label: 'Tackling Map',
        link: '/team/tackles/:leagues/:seasons/:teams',
      },
      {
        label: 'Havoc Events',
        link: '/team/havocSummary/:leagues/:seasons/:teams',
      },
      {
        label: 'Depth Chart',
        link: '/team/depth/:leagues/:seasons/:teams',
      },
      {
        label: 'Formation Summary',
        link: '/team/summary/:leagues/:seasons/:teams',
      },
      {
        label: 'Formation Detail',
        link: '/team/detail/:leagues/:seasons/:teams',
        wip: true,
      },
      {
        label: 'Player +/-',
        link: '/team/impact/:leagues/:seasons/:teams',
        wip: true,
      },
      {
        label: 'Radar',
        link: '/team/radar/:leagues/:seasons/:teams',
      },
      {
        label: 'Schedule',
        link: '/team/schedule/:leagues/:seasons/:teams',
      },
    ],
  },
  {
    section: 'League',
    pages: [
      {
        label: 'Player Stats',
        link: '/league/stats/players/:leagues/:seasons',
      },
      {
        label: 'Team Stats',
        link: '/league/stats/teams/:leagues/:seasons',
      },
    ],
  },
  {
    section: 'Game',
    pages: [
      {
        label: 'Play Animation',
        link: '/game/animation/:leagues/:seasons/:teams/:games/:drives/:plays',
      },
      {
        label: 'Snap Formations',
        link: '/game/formations/:leagues/:seasons/:teams/:games/:drives',
      },
      {
        label: 'Play Event Tiles',
        link: '/game/event/:leagues/:seasons/:teams/:games/:drives/:plays',
      },
      {
        label: 'Play By Play Feed',
        link: '/game/byplay/:leagues/:seasons/:teams/:games',
      },
    ],
  },
  {
    section: 'Scout',
    pages: [
      {
        label: 'Player Scout',
        link: '/scout/wizard/:leagues/:seasons',
      },
    ],
  },
  {
    section: 'General',
    pages: [
      {
        label: 'Data Access',
        link: '/data-access',
      },
      {
        label: 'Release Notes',
        link: '/help/release-notes',
      },
    ],
  },
];
