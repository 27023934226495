import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
} from '@statsbomb/kitbag-components';
import { useQuery, useReactiveVar } from '@apollo/client';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import { useTheme } from 'styled-components';
import {
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { GET_DEFENSIVE_SCHEME } from './getDefensiveScheme';
import {
  DEFENSIVE_SCHEME_AVG_KEYS,
  DEFENSIVE_SCHEME_GROUPS,
} from './TeamDefensiveScheme.constants';
import useQueryString from '../../../utils/hooks/useQueryString';
import { MemoizedVideoTile } from '../../../components/VideoTile/VideoTile';
import { mf_TeamDetails } from '../../../apollo';
import DefensiveSchemeTile from './DefensiveSchemeTile';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';

const TeamDefensiveScheme = () => {
  const mf_TeamDetailsRV = useReactiveVar(mf_TeamDetails);
  // league avg or team avg
  const { TEAM, LEAGUE } = DEFENSIVE_SCHEME_AVG_KEYS;
  const [isLeagueAvg, setLeagueAvg] = useQueryString('leagueAvg', false);
  const teamType = isLeagueAvg ? LEAGUE : TEAM;
  // video
  const { colours } = useTheme();
  const visPalette = colours.visualisations;
  const windowWidth = useWindowWidth();
  const [videoOpen, setVideoOpen] = useState(false);
  const [sidebarWidthCalc, setSidebarWidthCalc] = useState();
  const [videoData, setVideoData] = useState();
  const videoActive = useRef(false);
  const isVideoVisible = (() => videoOpen)();
  const { seasonName } = useExportSeasonInfo({
    asObject: true,
  });
  const { FRONT, BLITZ, COVERAGE } = DEFENSIVE_SCHEME_GROUPS;
  const {
    loading: isLoading,
    error: hasError,
    data,
  } = useQuery(GET_DEFENSIVE_SCHEME);

  const closeVideo = () => {
    videoActive.current = false;
    setVideoOpen(false);
  };

  const onPlaylistClick = (playlistTitle, playlistData) => {
    setVideoData({
      title: playlistTitle,
      subTitle: `${playlistData.data.length} Play${
        playlistData.data.length === 1 ? '' : 's'
      }`,
      data: playlistData.data,
    });
    setVideoOpen(true);
  };

  useEffect(() => {
    // set sidebar
    setSidebarWidthCalc(windowWidth - (isVideoVisible ? 0.5 : 1) * windowWidth);
  }, [windowWidth, isVideoVisible]);

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <SidebarRightLayout
          $sidebarWidth={`${sidebarWidthCalc}px`}
          $inlineSize={`${isVideoVisible ? 40 : 0}%`}
          $gap="0"
          $padding="0"
          $closed={isVideoVisible ? '1' : '0'}
        >
          <div>
            <Grid item xs={12}>
              <PageHeader
                href="/team/defensiveScheme/:leagues/:seasons/:teams"
                rootPage="Team"
                activePage="Defensive Scheme"
                showTeamName
                showPlayerName={false}
              >
                <div style={{ display: 'inline-flex', gap: '1rem' }}>
                  <Typography variant="bodyMedium" style={{ lineHeight: 2 }}>
                    Stats
                  </Typography>
                  <ButtonGroup>
                    <Button
                      variant={isLeagueAvg ? 'secondary' : 'primary'}
                      onClick={() => setLeagueAvg(false)}
                      size="small"
                    >
                      Team
                    </Button>
                    <Button
                      variant={isLeagueAvg ? 'primary' : 'secondary'}
                      onClick={() => setLeagueAvg(true)}
                      size="small"
                    >
                      vs. League Avg
                    </Button>
                  </ButtonGroup>
                </div>
              </PageHeader>
            </Grid>
            <Grid item xs={12}>
              <DefensiveSchemeTile
                title="Defensive Fronts"
                data={data}
                isLoading={isLoading}
                hasError={hasError}
                teamType={teamType}
                statsType={FRONT}
                fileName={`${mf_TeamDetailsRV?.name?.replace(
                  / /g,
                  ''
                )}DefensiveFronts ${seasonName}.csv`}
                isLeagueAvg={isLeagueAvg}
                onPlaylistClick={onPlaylistClick}
                isVideoVisible={isVideoVisible}
                visPalette={visPalette}
              />
            </Grid>
            <Grid item xs={12}>
              <DefensiveSchemeTile
                title="Blitz Types"
                data={data}
                isLoading={isLoading}
                hasError={hasError}
                teamType={teamType}
                statsType={BLITZ}
                fileName={`${mf_TeamDetailsRV?.name?.replace(
                  / /g,
                  ''
                )}BlitzTypes ${seasonName}.csv`}
                isLeagueAvg={isLeagueAvg}
                onPlaylistClick={onPlaylistClick}
                isVideoVisible={isVideoVisible}
                visPalette={visPalette}
              />
            </Grid>
            <Grid item xs={12}>
              <DefensiveSchemeTile
                title="Coverage"
                data={data}
                isLoading={isLoading}
                hasError={hasError}
                teamType={teamType}
                statsType={COVERAGE}
                fileName={`${mf_TeamDetailsRV?.name?.replace(
                  / /g,
                  ''
                )}CoveragePlays ${seasonName}.csv`}
                isLeagueAvg={isLeagueAvg}
                onPlaylistClick={onPlaylistClick}
                isVideoVisible={isVideoVisible}
                visPalette={visPalette}
              />
            </Grid>
          </div>
          <div>
            {isVideoVisible && data && (
              <Grid item xs={12}>
                <MemoizedVideoTile
                  title={videoData?.title}
                  subTitle={videoData?.subTitle}
                  data={videoData?.data}
                  handleClose={closeVideo}
                />
              </Grid>
            )}
          </div>
        </SidebarRightLayout>
      </Grid>
    </KitbagPageGridHolder>
  );
};
export default TeamDefensiveScheme;
