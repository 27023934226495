import { styled } from 'styled-components';

export const SummaryTile = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  height: ${({ $height }) => $height || '100%'};
  overflow: hidden;
  position: relative;
  margin: ${({ $margin }) => $margin || '0'};
`;
SummaryTile.displayName = 'SummaryTile';

export const SummaryTileBody = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  padding: ${({ $padding }) =>
    // this is absolute madness it doesn't work, too much time wasted
    // returns 'undefinedrem' FYI: have tried every other combination
    // using a <br> instead
    // console.log('Padding prop:', $padding);
    `${$padding}rem` || '0'};
  margin: ${({ $margin }) => $margin || '1rem'};
  min-height: ${({ $minHeight }) => $minHeight || '25rem'};
  max-height: ${({ $maxHeight }) => $maxHeight || 'initial'};
  flex-grow: 1;
  transition: all 500ms cubic-bezier(0.465, 0.183, 0.153, 0.946);
  overflow-y: ${({ $overflowY }) => $overflowY || 'auto'};
  overflow-x: hidden;
  z-index: 2;
`;
SummaryTileBody.displayName = 'SummaryTileBody';

// Summary Tile Header and Footers support the following layout options:
// 1. <div/>
// 2. <div/> <div/>
// 3. <div/> <div/> <div/>
// in all instances the last <div/> should align the content to the right
// elements (buttons or drops etc.) will be spaced and aligned
export const SummaryTileHeader = styled.div`
  display: flex;
  padding: ${({ $padding }) => $padding || '0 1rem'};
  height: ${({ $height }) => $height || '3.25rem'};
  border-bottom: ${({ theme, $borderWidth }) =>
    `${$borderWidth || 1}px solid ${theme.colours.canvas.secondary.main}`};
  ${({ theme }) => theme.typography.headingRegular}
  align-items: center;
  z-index: 3;

  > div {
    flex: 1;
    line-height: 1;
    display: inline-flex;
    gap: 0.5rem;
  }

  > div:last-child {
    justify-content: end;

    button {
      z-index: 10;
    }
  }

  // extra spacing if element placed next to title
  h3 + div {
    margin-left: 0.5rem;
  }
`;
SummaryTileHeader.displayName = 'SummaryTileHeader';

export const SummaryTileHeaderSub = styled.span`
  color: ${({ theme }) => theme.colours.graphics.primary.main};
  margin-left: 0.1875rem;
`;

// element spacing
const EL_SPACING = '0.7rem';

export const SummaryTileFooter = styled.div`
  display: flex;
  padding: 0 1rem;
  justify-content: flex-end;
  height: 36px;
  position: absolute;
  bottom: 0;
  width: 100%;
  align-items: center;

  button {
    height: 22px;
  }

  // temp for inline svg
  svg {
    fill: ${({ theme }) => theme.colours.graphics.primary.main};
  }
  // end temp

  .ui {
    &.small.text.loader {
      background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
      padding: 0.35rem 0 0 1.75rem;
      line-height: 1.7;
      width: 1rem;
      height: 1rem;
      z-index: 50;
      font-size: 0.78125rem;

      &:before,
      &:after {
        left: 0.875rem;
        top: 0.5rem;
      }
    }
  }
`;
SummaryTileFooter.displayName = 'SummaryTileFooter';

// layout containers
export const SummaryTileCol = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colours.graphics.primary.main};
  text-align: left;

  > * {
    margin-right: ${EL_SPACING};
    margin-left: 0;
    vertical-align: middle;
  }

  // furthest right col is right aligned
  &:last-child {
    text-align: right;

    > * {
      margin-left: ${EL_SPACING};
      margin-right: ${({ $marginRight }) => $marginRight};
      flex-flow: row-reverse;
    }
  }
`;
SummaryTileCol.displayName = 'SummaryTileCol';

export const SummaryError = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.colours.messaging.error.main};
  background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  line-height: 1.5;

  svg {
    fill: ${({ theme }) => theme.colours.messaging.error.main} !important;
    float: left;
    margin-right: 0.3125rem;
    margin-top: 0.125rem;
  }
`;
SummaryError.displayName = 'Summary Error';

export const TileDimmer = styled.div`
  position: absolute;
  top: 0;
  width: calc(100% - 11px);
  height: 100%;
  left: 5px;
  background-color: ${({ theme }) =>
    theme.applyOpacity(
      theme.colours.canvas.secondary.weak,
      'overlay'
    )} !important;
  opacity: ${({ $fadeIn }) => ($fadeIn ? 0 : 1)};
  transition: opacity 0.4s ease-in;
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 1;

  div {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
  }
`;
TileDimmer.displayName = 'Tile Dimmer';
