import React, { useEffect, useState } from 'react';
import { useReactiveVar, useQuery } from '@apollo/client';
import { Message, Loader } from 'semantic-ui-react';
import { Grid, Dropdown, Toggle } from '@statsbomb/kitbag-components';
import { useWindowWidth } from '@react-hook/window-size';
import { useTheme } from 'styled-components';
import {
  OffensiveLinePositionDropdown,
  PassPressuresVisHolder,
  PassPressuresKeyHolder,
  PassPressuresDropdowns,
  PassPressuresVisWrapper,
} from './PassPressures.styles';
import {
  summarizeOLTotals,
  summarizeGapTotals,
  getGapLeagueAverages,
  getPosLeagueAverages,
  getPositionsTableData,
  getGapsTableData,
  olGapPositionPlayer,
  getPositionPlays,
  formatPressuresVideoData,
} from './PassPressures.dataManipulation';
import { OLPressure } from '../../../../visualisations/OLPressure/OLPressure';
import {
  BAR_MODE_LA_OFFSET,
  COLOR_MODES,
  PG_POS,
  PG_BOTH,
  PG_GAP,
  PG_MODES,
  COLOR_DIVERGENT_SCALING,
} from './PassPressures.constants';
import { OLPressureKey } from '../../../../visualisations/OLPressure/OLPressureKey/OLPressureKey';
import KeyButton from '../../../../components/buttons/DisplayKey/DisplayKey';
import AccordionTile from '../../../../components/Accordion/AccordionTile';
import Tile from '../../../../components/Tile/Tile';
import {
  gapsDatumToGaps,
  positionDatumToPositions,
} from '../../../../visualisations/OLPressure/OLPressure.dataManipulation';
import { GET_TEAM_PASS_PRESSURES_ALLOWED } from './getPassPressuresAllowed';
import { mf_Leagues, mf_TeamDetails } from '../../../../apollo';
import {
  FixedAside,
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../../components/Layout/Layout.styles';
import { OLGapPosition } from '../../../../visualisations/OLPressure/OLGapPosition/OLGapPosition';
import { API_ALIGNMENT_POSITION_KEYS } from '../../../../utils/constants/api';
import ExportModal from '../../../../components/ExportModal/ExportModal';
import { useExportSeasonInfo } from '../../../../utils/hooks/useExportSeasonInfo';
import { getPlayerList } from '../lineBattles.dataManipulation';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import { MemoizedVideoTile } from '../../../../components/VideoTile/VideoTile';
import TableSummaryTile from '../../../../components/TableTile/TableTile.summary';
import {
  SummaryTile,
  SummaryTileBody,
  SummaryTileHeader,
} from '../../../../components/Tile/TileSummary.styles';
import {
  OLP_VIS_GAPS,
  OLP_VIS_POS,
} from '../../../../visualisations/OLPressure/OLPressure.constants';
import Dimmer from '../../../../components/Dimmer/Dimmer';

const PassPressures = function () {
  // selected team
  const selectedTeamDetails = useReactiveVar(mf_TeamDetails);
  // specify NFL as default to make sure League Average query can't fall over
  const selectedCompetitionId = useReactiveVar(mf_Leagues) || 1409;
  const [showGapPressures, setShowGapPressures] = useState(PG_BOTH.value);
  const [displayKey, setDisplayKey] = useState(true);
  const [compareTeamValues] = useState(true);
  const [showRawDots, setShowRawDots] = useState(true);
  const [colorMode, setColorMode] = useState(COLOR_DIVERGENT_SCALING.value);
  const [barMode] = useState(BAR_MODE_LA_OFFSET.value);
  // video integration
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;
  const [selectedPlays, setSelectedPlays] = useState([]);
  const [videoOpen, setVideoOpen] = useState(false);
  const windowWidth = useWindowWidth();
  const [sidebarWidthCalc, setSidebarWidthCalc] = useState();
  const [horizontalKey, setHorizontalKey] = useState(false);
  const [selectedBarKey, setSelectedBarKey] = useState(null);

  const {
    loading: isLoading,
    error: hasError,
    data,
  } = useQuery(GET_TEAM_PASS_PRESSURES_ALLOWED, {
    context: {
      headers: {
        'Accept-Version': 'v2023q2',
      },
    },
  });

  const teamData = data && data?.offensiveLinePressure;
  const leftTacklesList = teamData && getPlayerList(teamData, 'leftTackle');
  const leftGuardsList = teamData && getPlayerList(teamData, 'leftGuard');
  const centersList = teamData && getPlayerList(teamData, 'center');
  const rightGuardsList = teamData && getPlayerList(teamData, 'rightGuard');
  const rightTacklesList = teamData && getPlayerList(teamData, 'rightTackle');

  const [selectedLT, setSelectedLT] = useState(0);
  const [selectedLG, setSelectedLG] = useState(0);
  const [selectedC, setSelectedC] = useState(0);
  const [selectedRG, setSelectedRG] = useState(0);
  const [selectedRT, setSelectedRT] = useState(0);

  const filteredData =
    teamData &&
    teamData.filter(
      (f) =>
        (selectedLT === 0 || selectedLT === f?.leftTackle?.id) &&
        (selectedLG === 0 || selectedLG === f?.leftGuard?.id) &&
        (selectedC === 0 || selectedC === f?.center?.id) &&
        (selectedRG === 0 || selectedRG === f?.rightGuard?.id) &&
        (selectedRT === 0 || selectedRT === f?.rightTackle?.id)
    );

  const selectedPlayers = [
    olGapPositionPlayer(
      selectedLT,
      leftTacklesList,
      API_ALIGNMENT_POSITION_KEYS.LEFT_TACKLE
    ),
    olGapPositionPlayer(
      selectedLG,
      leftGuardsList,
      API_ALIGNMENT_POSITION_KEYS.LEFT_GUARD
    ),
    olGapPositionPlayer(
      selectedC,
      centersList,
      API_ALIGNMENT_POSITION_KEYS.CENTER
    ),
    olGapPositionPlayer(
      selectedRG,
      rightGuardsList,
      API_ALIGNMENT_POSITION_KEYS.RIGHT_GUARD
    ),
    olGapPositionPlayer(
      selectedRT,
      rightTacklesList,
      API_ALIGNMENT_POSITION_KEYS.RIGHT_TACKLE
    ),
  ].filter(Boolean);

  const totalsLA = getPosLeagueAverages(selectedCompetitionId);
  const totalsTeam = summarizeOLTotals(teamData, selectedCompetitionId);
  const totalsOLFiltered = summarizeOLTotals(
    filteredData,
    selectedCompetitionId
  );

  const niceTableData = getPositionsTableData(
    totalsOLFiltered,
    totalsTeam,
    totalsLA
  );

  const PRESSURES_DATA_HEADERS = [
    { label: 'Metric', key: 'name' },
    { label: 'Left Tackle', key: 'lt' },
    { label: 'Left Guard', key: 'lg' },
    { label: 'Center', key: 'c' },
    { label: 'Right Guard', key: 'rg' },
    { label: 'Right Tackle', key: 'rt' },
  ];

  /* gaps data */
  const gapTotalsLA = getGapLeagueAverages(selectedCompetitionId);
  const gapTotalsTeam = summarizeGapTotals(teamData, selectedCompetitionId);
  const gapTotalsOLFiltered = summarizeGapTotals(
    filteredData,
    selectedCompetitionId
  );
  const niceGapTableData = getGapsTableData(
    gapTotalsOLFiltered,
    gapTotalsTeam,
    gapTotalsLA
  );
  const LINE_GAP_DATA_HEADERS = [
    { label: 'Metric', key: 'name' },
    { label: 'C Left', key: 'lC' },
    { label: 'B Left', key: 'lB' },
    { label: 'A Left', key: 'lA' },
    { label: 'A Right', key: 'rA' },
    { label: 'B Right', key: 'rB' },
    { label: 'C Right', key: 'rC' },
  ];

  /* Prepare Gaps and Positions data for the vis */
  const visPositionsData = positionDatumToPositions(
    totalsOLFiltered,
    totalsTeam
  );
  // extract plays for use in video
  const positionPlays = getPositionPlays(filteredData);
  const visGapsData = gapsDatumToGaps(
    gapTotalsOLFiltered,
    gapTotalsTeam,
    selectedCompetitionId
  );
  /* Can choose to view either gaps or positions or both in the vis 
    Pick relevant or combine based onn selection mode */
  const baseData = showGapPressures === PG_GAP.value ? [] : visPositionsData;
  const visData =
    showGapPressures === PG_POS.value ? baseData : baseData.concat(visGapsData);

  const { competitionSeason, seasonName } = useExportSeasonInfo({ asObject: true });

  useEffect(() => {
    setSidebarWidthCalc(windowWidth - (videoOpen ? 0.5 : 0.8) * windowWidth);
    // transition delay for closing panel
    if (!videoOpen && windowWidth > 1950) {
      setTimeout(() => {
        setHorizontalKey(false);
      }, 500); // matches SidebarRightLayout transition time
    } else {
      setHorizontalKey(windowWidth <= 1950 || videoOpen);
    }
  }, [windowWidth, videoOpen]);

  const onBarClick = (datum) => {
    if (datum && positionPlays[datum.playsKey].length > 0) {
      setSelectedBarKey(datum.key);
      setSelectedPlays(positionPlays[datum.playsKey]);
      setVideoOpen(true);
    } else {
      setSelectedBarKey(null);
      setSelectedPlays([]);
      setVideoOpen(false);
    }
  };

  return (
    <KitbagPageGridHolder>
      <Grid container={false} page>
        <Grid item xs={12}>
          <SidebarRightLayout
            $sidebarWidth={`${sidebarWidthCalc}px`}
            $inlineSize={`${videoOpen ? 40 : 16}%`}
            $gap="0.5rem"
            $padding="0 0.25rem 0 0"
          >
            <div>
              <PageHeader
                href="/team/linePressure/:leagues/:seasons/:teams/"
                rootPage="Team"
                activePage="Line Pressure"
                customTitle={
                  selectedTeamDetails?.name && `${selectedTeamDetails?.name}`
                }
                showPlayerName={false}
                showTeamName={false}
              />

              <SummaryTile $height="auto" $margin="0.5rem 0">
                <SummaryTileHeader>
                  <div>
                    <h3>{`Summary of ${totalsOLFiltered?.plays} snaps`}</h3>
                  </div>
                  <div>
                    <KeyButton
                      showKey={displayKey}
                      handleShowKey={() => setDisplayKey(!displayKey)}
                    />
                    <ExportModal
                      title={selectedTeamDetails?.name}
                      secondaryTitle="Offensive Line: Pressures on Quarterback Conceded"
                      info1={competitionSeason}
                      info2={`${totalsOLFiltered?.plays} Plays`}
                      fileName={`${selectedTeamDetails?.name} Pressures Conceded ${seasonName}`}
                      isDisabled={hasError || isLoading || !visData}
                    >
                      <OLGapPosition
                        selectedPlayers={selectedPlayers}
                        hidePlayerInfo={false}
                      />
                      <OLPressure
                        pressureData={visData}
                        barMode={barMode}
                        compareTeamValues={compareTeamValues}
                        colorMode={colorMode}
                        showRawDots={showRawDots}
                        olpId="line-battles-exporting"
                      />
                      <OLPressureKey
                        showRawDots={showRawDots}
                        compareTeamValues={compareTeamValues}
                        colorMode={colorMode}
                        horizontalKey
                      />
                    </ExportModal>
                  </div>
                </SummaryTileHeader>
                <SummaryTileBody $padding="0 1rem 1rem" $minHeight="auto">
                  {hasError && !isLoading && (
                    <Message negative>
                      There has been an error. Please contact support.
                    </Message>
                  )}

                  <PassPressuresDropdowns>
                    {teamData && (
                      <>
                        <OffensiveLinePositionDropdown>
                          <Dropdown
                            id="ol-lt-filter"
                            options={leftTacklesList}
                            label="Left Tackle"
                            onChange={(selectedOption) =>
                              setSelectedLT(selectedOption.value)
                            }
                            value={
                              leftTacklesList.filter(
                                (f) => f.value === selectedLT
                              )[0]
                            }
                          />
                        </OffensiveLinePositionDropdown>
                        <OffensiveLinePositionDropdown>
                          <Dropdown
                            id="ol-lg-filter"
                            options={leftGuardsList}
                            label="Left Guard"
                            onChange={(selectedOption) =>
                              setSelectedLG(selectedOption.value)
                            }
                            value={
                              leftGuardsList.filter(
                                (f) => f.value === selectedLG
                              )[0]
                            }
                          />
                        </OffensiveLinePositionDropdown>
                        <OffensiveLinePositionDropdown>
                          <Dropdown
                            id="ol-c-filter"
                            options={centersList}
                            label="Center"
                            onChange={(selectedOption) =>
                              setSelectedC(selectedOption.value)
                            }
                            value={
                              centersList.filter(
                                (f) => f.value === selectedC
                              )[0]
                            }
                          />
                        </OffensiveLinePositionDropdown>
                        <OffensiveLinePositionDropdown>
                          <Dropdown
                            id="ol-rg-filter"
                            options={rightGuardsList}
                            label="Right Guard"
                            onChange={(selectedOption) =>
                              setSelectedRG(selectedOption.value)
                            }
                            value={
                              rightGuardsList.filter(
                                (f) => f.value === selectedRG
                              )[0]
                            }
                          />
                        </OffensiveLinePositionDropdown>
                        <OffensiveLinePositionDropdown>
                          <Dropdown
                            id="ol-rt-filter"
                            options={rightTacklesList}
                            label="Right Tackle"
                            onChange={(selectedOption) =>
                              setSelectedRT(selectedOption.value)
                            }
                            value={
                              rightTacklesList.filter(
                                (f) => f.value === selectedRT
                              )[0]
                            }
                          />
                        </OffensiveLinePositionDropdown>
                      </>
                    )}
                  </PassPressuresDropdowns>

                  <PassPressuresVisWrapper>
                    {isLoading && (
                      <Dimmer active>
                        <Loader content="Loading" />
                      </Dimmer>
                    )}
                    <PassPressuresVisHolder>
                      <OLGapPosition selectedPlayers={selectedPlayers} />
                    </PassPressuresVisHolder>
                    <PassPressuresVisHolder>
                      <OLPressure
                        pressureData={visData}
                        barMode={barMode}
                        compareTeamValues={compareTeamValues}
                        colorMode={colorMode}
                        showRawDots={showRawDots}
                        handleBarClick={onBarClick}
                        selectedBar={selectedBarKey}
                      />
                    </PassPressuresVisHolder>
                    {displayKey && (
                      <PassPressuresKeyHolder
                        $maxWidth={horizontalKey ? '1000px' : '220px'}
                        $marginLeft={horizontalKey ? '0' : 'auto'}
                      >
                        <OLPressureKey
                          showRawDots={showRawDots}
                          compareTeamValues={compareTeamValues}
                          colorMode={colorMode}
                          horizontalKey={horizontalKey}
                        />
                      </PassPressuresKeyHolder>
                    )}
                  </PassPressuresVisWrapper>
                </SummaryTileBody>
              </SummaryTile>

              <Grid>
                <Grid item xs={videoOpen ? 12 : 6}>
                  <TableSummaryTile
                    data={niceTableData}
                    tileTitle="Offensive Lineman Position Pressures"
                    columnHeaders={PRESSURES_DATA_HEADERS}
                    showColumnHeader
                    error={hasError}
                    loading={isLoading}
                    fileName={`${selectedTeamDetails?.name} OL Position Pressures ${seasonName}.csv`}
                  />
                </Grid>
                <Grid item xs={videoOpen ? 12 : 6}>
                  <TableSummaryTile
                    data={niceGapTableData}
                    tileTitle="Offensive Line Gap Pressures"
                    columnHeaders={LINE_GAP_DATA_HEADERS}
                    showColumnHeader
                    error={hasError}
                    loading={isLoading}
                    fileName={`${selectedTeamDetails?.name} OL Gap Pressures ${seasonName}.csv`}
                  />
                </Grid>
              </Grid>
            </div>
            <div>
              <FixedAside
                $width={`${sidebarWidthCalc + 16}px`}
                style={{ marginLeft: 0, paddingLeft: 0 }}
              >
                {videoOpen && (
                  <MemoizedVideoTile
                    title={`${
                      OLP_VIS_POS.find((vis) => vis.key === selectedBarKey)
                        ?.position ||
                      OLP_VIS_GAPS.find((vis) => vis.key === selectedBarKey)
                        ?.gap
                    } Pressures`}
                    subTitle={`${selectedPlays.length} Plays`}
                    data={formatPressuresVideoData(selectedPlays, visPalette)}
                    handleClose={() => {
                      setVideoOpen(false);
                      setSelectedBarKey(null);
                    }}
                  />
                )}
                {!videoOpen && (
                  <AccordionTile
                    header={
                      <Tile.AccordionHeader>Settings</Tile.AccordionHeader>
                    }
                    body={
                      <Tile.AccordionBody>
                        <Dropdown
                          id="slot-visbility-filter"
                          options={PG_MODES}
                          label="Pressure Slot Display"
                          onChange={(selectedOption) =>
                            setShowGapPressures(selectedOption.value)
                          }
                          value={
                            PG_MODES.filter(
                              (f) => f.value === showGapPressures
                            )[0]
                          }
                        />
                        <Dropdown
                          id="color-filter"
                          options={COLOR_MODES}
                          label="Coloring Mode"
                          onChange={(selectedOption) =>
                            setColorMode(selectedOption.value)
                          }
                          value={
                            COLOR_MODES.filter((f) => f.value === colorMode)[0]
                          }
                        />
                        <Toggle
                          id="show-pressures-toggle"
                          label="Show pressures per 100 plays"
                          onChange={() => setShowRawDots(!showRawDots)}
                          checked={showRawDots}
                        />
                      </Tile.AccordionBody>
                    }
                    isExpandedDefault
                    isMount
                  />
                )}
              </FixedAside>
            </div>
          </SidebarRightLayout>
        </Grid>
      </Grid>
    </KitbagPageGridHolder>
  );
};

export default PassPressures;
