import React, { useEffect, useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import { useTheme } from 'styled-components';
import { clamp, isEmpty, maxBy } from 'lodash';
import BarChart from '../../../../visualisations/BarChart/BarChart';
import {
  HavocGroupDataProp,
  HavocLeageuAverageDataProp,
} from '../HavocTable.jsx/HavocTable.propTypes';
import Tile from '../../../../components/Tile/Tile';
import KeyButton from '../../../../components/buttons/DisplayKey/DisplayKey';
import Dimmer from '../../../../components/Dimmer/Dimmer';
import SwitchVisTableButton from '../../../../components/buttons/SwitchVisTable/SwitchVisTable';
import {
  HAVOC_GAP_BARS_SIZE_LIMITS,
  HAVOC_SCM_KEYS,
  HAVOC_SPT_KEYS,
  HAVOC_SUMMARY_COLORING_MDOES,
  HAVOC_SUMMARY_PERCENTAGE_TYPES,
} from './HavocBars.constants';
import { HAVOC_TABLE_AGGREGATION_MODE_TYPE } from '../HavocTable.jsx/HavocTable.constants';
import { barNameUpdate, getBarData } from './HavocBars.dataManipulation';
import {
  HavocSummaryChartHolder,
  HavocSummaryKeyHolder,
} from '../TeamHavoc.styles';
import { CHART_LAYOUT } from '../../../../visualisations/BasicChart/BasicChart.constants';
import HavocSummaryChart from '../../../../visualisations/HavocSummary/HavocSummaryChart';
import { getZoneBubbleData } from './HavocBubbles.dataManipulation';
import { getPipeData } from './HavocPipes.dataManipulation';
import { HAVOC_SUMMARY_KEY_SIZES } from '../../../../visualisations/HavocChart/HavocChartKey/HavocChartKey.constants';
import HavocSummaryLegend from '../HavocLegend/HavocLegend';
import useQueryString from '../../../../utils/hooks/useQueryString';

/*
This sets up all the custom settings (i.e. scales) for the scatter chart
    and converts from runGap data formatting to generic scatter formatting
 */
const HavocBarTile = ({
  isLoadingSummary,
  isLoadingLA,
  handleVisTableSwitch,
  chartId,
  havocData,
  gapAverages,
  zoneAverages,
  playSummary,
  selectedGaps,
  setSelectedGaps,
  selectedDefenderZones,
  setSelectedDefenderZones,
  havocSummaryMode,
  colorMode,
}) => {
  const [displayKey, setDisplayKey] = useQueryString(
    'havoc-bar-display-key',
    true
  );
  const [chartHolderWidth, setChartHolderWidth] = useState(500);
  const [chartTileWidth, setChartTileWidth] = useState(500);
  const { isDark } = useTheme();
  const percentageSetup = HAVOC_SUMMARY_PERCENTAGE_TYPES[havocSummaryMode];
  const havocSummaryTileBodyId = 'havoc-summary-tile-body';

  const windowWidth = useWindowWidth();
  useEffect(() => {
    const tileElem = document.querySelector(`#${havocSummaryTileBodyId}`);
    const chartHolderElem = document.querySelector(`#${chartId}`);
    const rect = chartHolderElem?.getBoundingClientRect();
    if (rect?.width) {
      setChartHolderWidth(rect.width);
    }
    const rectTile = tileElem?.getBoundingClientRect();
    if (rectTile?.width) {
      setChartTileWidth(rectTile.width);
    }
  }, [windowWidth]);
  /* 
  Key is vertical if it's very large or very small:
    When the tile space is less than that required for horizontal key (i.e. 2 columns & padding): use vertical
    when large it sits to the right of main vis, as long as tile has space for key and chart
  Key only shows in horizontal mode if both vertical causes are false
   */
  const isKeyVerticalSmall =
    chartTileWidth < HAVOC_SUMMARY_KEY_SIZES.HORIZONTAL_KEY_SPACE_REQUIRED;
  const isKeyVerticalLarge =
    chartTileWidth >
    chartHolderWidth + HAVOC_SUMMARY_KEY_SIZES.VERTICAL_KEY_SPACE_REQUIRED;
  const keyStyle = isKeyVerticalLarge
    ? {
        right: '16px',
        position: 'absolute',
        width: '250px',
      }
    : { width: '100%' };

  const { barData, unfilteredBarData } = getBarData({
    havocData,
    gapAverages,
    playSummary,
    selectedDefenderZones,
    aggregationMode: HAVOC_TABLE_AGGREGATION_MODE_TYPE.GAP_EXPLOITED,
    percentageSetup,
    colorMode,
    isDark,
  });

  const { bubbleData, unfilteredBubbleData } = getZoneBubbleData({
    havocData,
    zoneAverages,
    playSummary,
    selectedGaps,
    percentageSetup, // HAVOC_SUMMARY_PERCENTAGE_TYPES
    colorMode, // HAVOC_SUMMARY_COLORING_MODES
    isDark,
  });

  /* Pipes are generated from combination gap/zone;
  requires raw events and enriched bubble/bar data for matching color/scale outputs */
  const pipeData = getPipeData({
    havocData,
    selectedGaps,
    selectedZones: selectedDefenderZones,
    gapBarData: barData,
    zoneBubbleData: bubbleData,
  });

  /* Modifications to standard bar chart for this bar chart */
  const viewboxWidth = clamp(
    chartHolderWidth,
    HAVOC_GAP_BARS_SIZE_LIMITS.MIN_VIEWBOX_WIDTH,
    HAVOC_GAP_BARS_SIZE_LIMITS.MAX_VIEWBOX_WIDTH
  );
  const namedBarData = barNameUpdate({ barData, viewboxWidth });
  const namedGhostBarData = barNameUpdate({
    barData: unfilteredBarData,
    viewboxWidth,
  });

  const canvasWidth =
    viewboxWidth -
    CHART_LAYOUT.AXES.PADDING.left -
    CHART_LAYOUT.AXES.PADDING.right -
    HAVOC_GAP_BARS_SIZE_LIMITS.Y_AXIS_WIDTH;
  const layoutOverride = {
    CANVAS: { HEIGHT: 200, WIDTH: canvasWidth },
    AXES: {
      GUIDES: {
        VERTICAL: null,
      },
      AREA: {
        top: 0,
        right: 0,
        bottom: HAVOC_GAP_BARS_SIZE_LIMITS.X_AXIS_HEIGHT,
        left: HAVOC_GAP_BARS_SIZE_LIMITS.Y_AXIS_WIDTH,
      },
    },
  };
  const yOverrides = {
    DOMAIN: percentageSetup.gapDomain,
    NICE: true,
    TICKS: 9,
    INVERT: true,
  };
  const setupOverride = {
    Y: yOverrides,
    X: { PADDING_INNER: 0.2 },
  };

  const onBarClicked = (gapId) => {
    if (!gapId) {
      setSelectedGaps([]);
    } else {
      const existList = isEmpty(selectedGaps) ? [] : selectedGaps;
      if (!existList.includes(gapId)) {
        setSelectedGaps(existList.concat(gapId));
      } else {
        const otherZones = existList.filter((z) => z !== gapId);
        setSelectedGaps(otherZones);
      }
    }
  };

  /* For the legend */
  const barAxisMax = maxBy(barData, 'value')?.value;
  const bubbleAxisMax = maxBy(bubbleData, 'value')?.value;

  return (
    <Tile border="0" margin="0 0 0.5rem 0">
      <Tile.Header>
        <h2 title="Visualisation displaying havoc play aggregates">
          Havoc Plays Summary
        </h2>
        <div className="buttons">
          <SwitchVisTableButton
            isShowingVis
            handleClick={handleVisTableSwitch}
          />
          <KeyButton
            showKey={displayKey}
            handleShowKey={() => setDisplayKey(!displayKey)}
          />
        </div>
      </Tile.Header>
      <Tile.Body id={havocSummaryTileBodyId}>
        <HavocSummaryChartHolder>
          <BarChart
            chartId={chartId}
            barData={namedBarData}
            barDataGhost={namedGhostBarData}
            layoutOverride={layoutOverride}
            setupOverride={setupOverride}
            handleBarClick={onBarClicked}
            selectedBars={selectedGaps}
          />
          <HavocSummaryChart
            zoneData={bubbleData}
            zoneDataGhost={unfilteredBubbleData}
            zoneDomain={percentageSetup.dzDomain}
            pipeData={pipeData}
            viewboxWidth={viewboxWidth}
            selectedZones={selectedDefenderZones}
            setSelectedZones={setSelectedDefenderZones}
          />
        </HavocSummaryChartHolder>
        {isLoadingLA &&
          colorMode === HAVOC_SUMMARY_COLORING_MDOES.VS_LA.value && (
            <div>
              <span>League average data is loading...</span>
            </div>
          )}
        {displayKey && (
          <HavocSummaryKeyHolder style={keyStyle}>
            <HavocSummaryLegend
              colorMode={colorMode}
              isVertical={isKeyVerticalSmall || isKeyVerticalLarge}
              maxIntensityBar={barAxisMax}
              maxIntensityBubble={bubbleAxisMax}
            />
          </HavocSummaryKeyHolder>
        )}

        {isLoadingSummary && (
          <Dimmer
            active
            style={{
              maxWidth: '100%',
            }}
          >
            <Loader content="Loading Data" />
          </Dimmer>
        )}
      </Tile.Body>
    </Tile>
  );
};

HavocBarTile.propTypes = {
  isLoadingSummary: PropTypes.bool,
  isLoadingLA: PropTypes.bool,
  handleVisTableSwitch: PropTypes.func,
  chartId: PropTypes.string,
  havocData: HavocGroupDataProp,
  gapAverages: HavocLeageuAverageDataProp,
  zoneAverages: HavocLeageuAverageDataProp,
  playSummary: PropTypes.shape({
    playCount: PropTypes.number,
    runRate: PropTypes.number,
    passPlays: PropTypes.number,
    runPlays: PropTypes.number,
  }),
  selectedGaps: PropTypes.arrayOf(PropTypes.string),
  setSelectedGaps: PropTypes.func,
  setSelectedDefenderZones: PropTypes.func,
  selectedDefenderZones: PropTypes.arrayOf(PropTypes.string),
  havocSummaryMode: PropTypes.oneOf(HAVOC_SPT_KEYS),
  colorMode: PropTypes.oneOf(HAVOC_SCM_KEYS),
};

HavocBarTile.defaultProps = {
  isLoadingSummary: false,
  isLoadingLA: false,
  handleVisTableSwitch: null,
  chartId: 'run-point-of-attack-bars',
  havocData: undefined,
  gapAverages: undefined,
  zoneAverages: undefined,
  playSummary: { playCount: 0, runRate: 0.5, passPlays: 0, runPlays: 0 },
  selectedGaps: undefined,
  setSelectedGaps: undefined,
  setSelectedDefenderZones: undefined,
  selectedDefenderZones: undefined,
  havocSummaryMode: HAVOC_SUMMARY_PERCENTAGE_TYPES.OF_ALL_PLAYS.value,
  colorMode: HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY.value,
};

export default HavocBarTile;
