import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import { BasicPageNoPadding } from '../../components/Layout/Layout.styles';
import { PAGE } from '../../components/app/index.constants';

const Game = () => {
  const [pageInfo, setPageInfo] = useOutletContext();

  return (
    <BasicPageNoPadding $top={PAGE.WITH_CONTEXT_FILTERS.top}>
      <Outlet context={[pageInfo, setPageInfo]} />
    </BasicPageNoPadding>
  );
};

export default Game;
