import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { select } from 'd3';
import { useD3 } from '../../../../utils/hooks/useD3';
import {
  HAVOC_SUMMARY_CLASSES,
  HAVOC_SUMMARY_KEY_SIZES,
} from '../../../../visualisations/HavocChart/HavocChartKey/HavocChartKey.constants';
import { addGradientDots } from '../../../../utils/visualisations/keys';
import { fontBaselineY } from '../../../../visualisations/text';
import {
  DEFAULT_FONT,
  VISUALISATION_FONT_SETUPS,
} from '../../../../utils/constants/visText';
import { HAVOC_SUMMARY_COLORING_MDOES } from '../HavocBars/HavocBars.constants';
import { API_STAT_UNITS } from '../../../../utils/constants/api';
import { formatValue } from '../../../../utils/helpers/stats.dataManipulation';

const HavocSummaryLegend = ({
  colorMode,
  isVertical,
  maxIntensityBar,
  maxIntensityBubble,
}) => {
  const visWidth = isVertical
    ? HAVOC_SUMMARY_KEY_SIZES.VERTICAL_WIDTH
    : HAVOC_SUMMARY_KEY_SIZES.VERTICAL_WIDTH * 2;

  const vbHeight = isVertical
    ? HAVOC_SUMMARY_KEY_SIZES.SECTION.HEIGHT.TOTAL * 2
    : HAVOC_SUMMARY_KEY_SIZES.SECTION.HEIGHT.TOTAL -
      HAVOC_SUMMARY_KEY_SIZES.SECTION.HEIGHT.MARGIN; // bars and bubbles
  const viewBox = `0 0 ${visWidth} ${vbHeight}`;
  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;

  const barWidth = isVertical
    ? HAVOC_SUMMARY_KEY_SIZES.SECTION.WIDTH
    : HAVOC_SUMMARY_KEY_SIZES.SECTION.WIDTH -
      HAVOC_SUMMARY_KEY_SIZES.SECTION.PADDING;
  const bubbleTransform = isVertical
    ? `translate(0,${HAVOC_SUMMARY_KEY_SIZES.SECTION.HEIGHT.TOTAL})`
    : `translate(${HAVOC_SUMMARY_KEY_SIZES.SECTION.WIDTH},0)`;

  const coloringMode =
    HAVOC_SUMMARY_COLORING_MDOES[colorMode] ||
    HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY;
  const coloringFunction = coloringMode.coloringFn;

  const addSectionTitle = (sectionG, titleStart) => {
    sectionG
      .append('text')
      .attr('x', 0)
      .attr(
        'y',
        fontBaselineY(
          VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE,
          VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.LINE_HEIGHT
        )
      )
      .attr(
        'font-size',
        `${VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.SIZE}px`
      )
      .attr('font-weight', VISUALISATION_FONT_SETUPS.KEY_SECTION_HEADER.WEIGHT)
      .attr('font-family', DEFAULT_FONT)
      .attr('text-anchor', 'start')
      .attr('fill', visPalette.text.info)
      .text(`${titleStart}: ${coloringMode.label}`);
  };

  const axisLimits = (maxIntensity) => {
    if (colorMode === HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY.value) {
      return {
        axisMin: '0%',
        axisMax: formatValue(maxIntensity, API_STAT_UNITS.PERCENTAGE),
        axisLabel: null,
      };
    }
    return { axisMin: '-100%', axisMax: '+100%', axisLabel: 'League Average' };
  };

  const ref = useD3((svg) => {
    svg.selectAll('*').remove();

    // BACKING RECT FOR THE SVG
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('class', HAVOC_SUMMARY_CLASSES.BACKGROUND)
      .attr('fill', visPalette.background.main);

    svg.append('g').attr('class', HAVOC_SUMMARY_CLASSES.BAR_COLORS);
    svg.append('g').attr('class', HAVOC_SUMMARY_CLASSES.BUBBLE_COLORS);
  }, []);

  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);
    svg.attr('width', `${visWidth}px`);
    svg
      .select(`.${HAVOC_SUMMARY_CLASSES.BACKGROUND}`)
      .attr('fill', visPalette.background.main);
    svg
      .select(`.${HAVOC_SUMMARY_CLASSES.BUBBLE_COLORS}`)
      .attr('transform', bubbleTransform);
  }, [visPalette, isVertical]);

  useEffect(() => {
    const svg = select(ref.current);
    const barG = svg.select(`.${HAVOC_SUMMARY_CLASSES.BAR_COLORS}`);
    const bubbleG = svg.select(`.${HAVOC_SUMMARY_CLASSES.BUBBLE_COLORS}`);
    barG.selectAll('*').remove();
    bubbleG.selectAll('*').remove();

    addSectionTitle(barG, 'Exploited Gap bars');

    const dotsTransform = `translate(0,${HAVOC_SUMMARY_KEY_SIZES.SECTION.HEIGHT.TITLE})`;
    const barDotsG = barG.append('g').attr('transform', dotsTransform);
    const barAxisLimits = axisLimits(maxIntensityBar);
    addGradientDots(
      barDotsG,
      coloringFunction,
      visPalette,
      isDark,
      barWidth,
      barAxisLimits.axisMin,
      barAxisLimits.axisMax,
      barAxisLimits.axisLabel
    );

    addSectionTitle(bubbleG, 'Defensive Zone spots');
    const bubbleDotsG = bubbleG.append('g').attr('transform', dotsTransform);
    const bubbleAxisLimits = axisLimits(maxIntensityBubble);
    addGradientDots(
      bubbleDotsG,
      coloringFunction,
      visPalette,
      isDark,
      barWidth,
      bubbleAxisLimits.axisMin,
      bubbleAxisLimits.axisMax,
      bubbleAxisLimits.axisLabel
    );
  }, [colorMode, visPalette, maxIntensityBar, maxIntensityBubble, isVertical]);

  return <svg ref={ref} />;
};

HavocSummaryLegend.propTypes = {
  colorMode: PropTypes.string,
  isVertical: PropTypes.bool,
  maxIntensityBar: PropTypes.number,
  maxIntensityBubble: PropTypes.number,
};

HavocSummaryLegend.defaultProps = {
  colorMode: HAVOC_SUMMARY_COLORING_MDOES.TEAM_INTENSITY.value,
  isVertical: false,
  maxIntensityBar: 1,
  maxIntensityBubble: 1,
};

export default HavocSummaryLegend;
