import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { Table } from '@statsbomb/kitbag-components';
import { uniqBy } from 'lodash';
import { mf_Teams } from '../../../../apollo';
import { formatDriveInfo } from '../PlayByPlay.DataManipulation';
import PlayByPlayAccordionTileBody from './PlayByPlayAccordionTileBody';
import { StyledBasicTable } from '../../../../components/Table/BasicTable.styles';
import AccordionTableRow from '../../../../components/Tables/AccordionTableRow/AccordionTableRow';
import { PLAY_BY_TABLE_HEADERS_PROP_TYPE } from '../PlayByPlay.constants';
import PlayByPlayAccordionFeedback from './PlayByPlayAccordionFeedback';
import PlayByPlayAccordionHeader from './PlayByPlayAccordionHeader';

const PlayByPlayAccordion = ({
  playsData,
  isLoading,
  isError: hasError,
  selectedTeamColor,
  opponentTeamColor,
  visPalette,
  tableHeaders,
}) => {
  const selectedTeam = useReactiveVar(mf_Teams);
  const gameDetails = playsData?.[0]?.game;
  const isHomeTeam = gameDetails?.homeTeam.id === selectedTeam;
  const selectedTeamName = isHomeTeam
    ? gameDetails?.homeTeam.name
    : gameDetails?.awayTeam.name;
  const drives = uniqBy(playsData, (p) => p.drive.id);
  const formattedDrives = drives.map((p) =>
    formatDriveInfo(p.drive, playsData)
  );

  return (
    <StyledBasicTable $width="100%">
      <Table withBorder={false}>
        <Table.Head>
          <Table.Row>
            {tableHeaders.map((header, headerIndex) => (
              // eslint-disable-next-line react/no-array-index-key
              <Table.HeaderCell key={headerIndex}>
                {header.label}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <PlayByPlayAccordionFeedback
            isLoading={isLoading}
            hasError={hasError}
            playsData={playsData}
          />

          {formattedDrives.map((drive) => (
            <AccordionTableRow
              id="accord"
              colSpan={10}
              header={
                <PlayByPlayAccordionHeader
                  drive={drive}
                  tableHeaders={tableHeaders}
                  isHomeTeam={isHomeTeam}
                  selectedTeamName={selectedTeamName}
                  selectedTeamColor={selectedTeamColor}
                  opponentTeamColor={opponentTeamColor}
                />
              }
              body={
                <PlayByPlayAccordionTileBody
                  playsData={drive.playsInDrive}
                  visPalette={visPalette}
                />
              }
            />
          ))}
        </Table.Body>
      </Table>
    </StyledBasicTable>
  );
};

PlayByPlayAccordion.propTypes = {
  playsData: PropTypes.arrayOf(
    PropTypes.shape({
      game: PropTypes.shape({
        awayTeam: PropTypes.shape({ name: PropTypes.string }),
        homeTeam: PropTypes.shape({ name: PropTypes.string }),
      }),
    })
  ),
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  selectedTeamColor: PropTypes.string, // hex code
  opponentTeamColor: PropTypes.string, // hex code
  visPalette: PropTypes.shape({}),
  tableHeaders: PLAY_BY_TABLE_HEADERS_PROP_TYPE,
};

PlayByPlayAccordion.defaultProps = {
  playsData: undefined,
  isLoading: false,
  isError: false,
  selectedTeamColor: 'Red',
  opponentTeamColor: 'Blue',
  visPalette: {},
  tableHeaders: [],
};

export default PlayByPlayAccordion;
