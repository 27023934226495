import { sortBy, uniqBy, reverse, isEmpty } from 'lodash';
import { API_EVENT_TYPE_KEYS } from '../../../utils/constants/api';
import { arrowRotation } from '../../../utils/visualisations/shapes';
import { LINE_GAPS } from '../../../utils/constants/gaps';
import { ALIGNMENT_POSITIONS } from '../../../utils/constants/positions';
import {
  HAVOC_POSITION_OPTION_ANY,
  HAVOC_BLITZ_OPTION_ANY,
  HAVOC_DEFENSIVE_FRONT_OPTION_ANY,
  HAVOC_TYPES,
  HAVOC_TYPE_ALL,
  HAVOC_TYPE_TACKLE_FOR_LOSS,
} from '../../../visualisations/HavocChart/HavocChart.constants';
import { toTitleCase } from '../../../utils/helpers/general';
import { HAVOC_DEFENDER_ZONES } from '../../../visualisations/HavocSummary/HavocSummary.constants';

export const getHavocType = (apiEventTypes) => {
  if (apiEventTypes.includes(API_EVENT_TYPE_KEYS.SACK)) {
    return API_EVENT_TYPE_KEYS.SACK;
  }
  if (apiEventTypes.includes(API_EVENT_TYPE_KEYS.RUN_DISRUPTION)) {
    return API_EVENT_TYPE_KEYS.RUN_DISRUPTION;
  }
  if (apiEventTypes.includes(API_EVENT_TYPE_KEYS.PROXIMITY_PRESSURE)) {
    return API_EVENT_TYPE_KEYS.PROXIMITY_PRESSURE;
  }
  if (apiEventTypes.includes(API_EVENT_TYPE_KEYS.TACKLE)) {
    return API_EVENT_TYPE_KEYS.TACKLE;
  }
  return null;
};

/*
Input data will match items from API Endpoint havocEvents
*/
export const formatHavocData = (havocAPIData, showDefensive) => {
  if (!havocAPIData?.length) {
    return [];
  }
  const formattedData = havocAPIData.map((apiHavocDatum) => {
    const xRaw = apiHavocDatum.eventLocationX;
    const yRaw = apiHavocDatum.eventLocationY;
    const snap = {
      x: apiHavocDatum.play.yardLine,
      y: apiHavocDatum.play.snapY,
    };
    const xLoS = xRaw - snap.x;
    const yLoS = yRaw - snap.y;
    const targetXRaw = apiHavocDatum?.targetLocationX || xRaw;
    const targetYRaw = apiHavocDatum?.targetLocationY || yRaw;
    const targetXLoS = targetXRaw - snap.x;
    const targetYLoS = targetYRaw - snap.y;
    const havocType = getHavocType(apiHavocDatum.eventTypes);
    const postSnapSeconds = apiHavocDatum.timePostSnap;
    const playerId = showDefensive
      ? apiHavocDatum?.defender.id
      : apiHavocDatum?.targetPlayer.id;
    const defenderName = apiHavocDatum?.defender.name;
    const defenderJerseyNumber = apiHavocDatum?.defender.jerseyNumber;
    const targetName = apiHavocDatum?.targetPlayer.name;
    const targetJerseyNumber = apiHavocDatum?.targetPlayer.jerseyNumber;
    const targetId = apiHavocDatum?.targetPlayer.id;
    const playerName = showDefensive ? defenderName : targetName;
    const defenderPosition =
      ALIGNMENT_POSITIONS[apiHavocDatum?.defender?.alignmentPosition];
    const targetPosition =
      ALIGNMENT_POSITIONS[apiHavocDatum?.targetPlayer?.alignmentPosition];
    const targetRosterPositionCode =
      apiHavocDatum?.targetPlayer?.teamPosition?.code;
    const defenderRosterPositionCode =
      apiHavocDatum?.defender?.teamPosition?.code;
    const position = showDefensive ? defenderPosition : targetPosition;
    const positionName = position?.name;
    const positionCode = position?.code;
    const angleLoS = arrowRotation({
      x1: xLoS,
      y1: yLoS,
      x2: targetXLoS,
      y2: targetYLoS,
    });
    const defenderPath = apiHavocDatum?.defenderMovementPath?.map((point) => ({
      xLoS: point.x - snap.x,
      yLoS: point.y - snap.y,
    }));
    const snapGap = LINE_GAPS[apiHavocDatum?.defenderOrigin];
    /* For exploited gap, because currently missing on run havoc data, have a fallback to snap gap */
    const exploitedGap =
      LINE_GAPS[apiHavocDatum?.exploitedLineGap] ||
      LINE_GAPS[apiHavocDatum?.defenderOrigin];

    const defenderZone = Object.values(HAVOC_DEFENDER_ZONES).find(
      (d) =>
        d.alignmentPositions.includes(
          apiHavocDatum?.defender?.alignmentPosition
        ) && d.snapGaps.includes(apiHavocDatum?.defenderOrigin)
    );

    return {
      snap,
      xRaw,
      yRaw,
      xLoS,
      yLoS,
      targetXRaw,
      targetYRaw,
      targetXLoS,
      targetYLoS,
      havocType,
      postSnapSeconds,
      playerId,
      playerName,
      position,
      positionName,
      positionCode,
      defenderName,
      defenderJerseyNumber,
      targetName,
      targetJerseyNumber,
      defenderRosterPositionCode,
      defenderPosition,
      targetRosterPositionCode,
      targetPosition,
      targetId,
      offenseTeamId: apiHavocDatum.play.offenseTeam.id,
      defenseTeamId: apiHavocDatum.play.defenseTeam.id,
      angleLoS,
      snapGap,
      exploitedGap,
      defenderZone,
      snapGapName: snapGap?.name,
      eventGapName: exploitedGap?.name,
      defenderZoneName: defenderZone?.name,
      snapGapAPICode: snapGap?.apiCode[0],
      exploitedGapAPICode: exploitedGap?.apiCode[0],
      defenderZoneAPICode: defenderZone?.key,
      snapLinePosition: apiHavocDatum?.linePosition,
      defenderPath,
      defensiveFront: apiHavocDatum.play.defensiveFront,
      defensiveFrontName: toTitleCase(apiHavocDatum.play.defensiveFront),
      blitzType: apiHavocDatum.play.blitzType,
      blitzTypeName: toTitleCase(apiHavocDatum.play.blitzType),
      /* For selected event utility/details */
      eventUUID: apiHavocDatum.eventUUID,
      playUUID: apiHavocDatum.play.id,
      driveUUID: apiHavocDatum.play.drive.id,
      game_id: apiHavocDatum.play.game.id,
      playName: apiHavocDatum.play.name,
      driveName: apiHavocDatum.play.drive.name,
      gameName: apiHavocDatum.play.game.name,
    };
  });
  return formattedData;
};

export const getPlayers = (havocData) => {
  if (!havocData?.length) {
    return null;
  }
  const players = uniqBy(
    havocData.filter((b) => b?.playerId),
    (d) => d.playerId
  );
  const playerCounts = players.map((b) => {
    const tacklerPlays = havocData.filter((t) => t?.playerId === b.playerId);
    const freq = tacklerPlays.length;
    return {
      playerId: b.playerId,
      playerName: b.playerName,
      freq,
    };
  });
  return reverse(sortBy(playerCounts, 'freq'));
};

export const getPositions = (havocData) => {
  if (!havocData?.length) {
    return null;
  }
  const positions = uniqBy(
    havocData.filter((b) => b?.positionCode),
    (d) => d.positionCode
  );
  const positionCodes = positions.map((b) => {
    const matchingEvents = havocData.filter(
      (t) => t?.positionCode === b.positionCode
    );
    const freq = matchingEvents.length;
    return {
      positionCode: b.positionCode,
      positionName: b.positionName,
      color: b.position.color,
      freq,
    };
  });
  const sortedPositions = reverse(sortBy(positionCodes, 'freq'));
  return sortedPositions;
};

export const getExportDetails = (
  havocData,
  teamName,
  havocMode,
  showDefensive,
  selectedPlayerId,
  selectedPositionCode,
  relevantPlayers,
  positionFrequencies,
  seasonName
) => {
  const getTitleHavocType = () => {
    const havocTypeOption = HAVOC_TYPES.find((h) => h.value === havocMode);
    if (havocTypeOption.value === HAVOC_TYPE_ALL.value) {
      return showDefensive ? 'Havoc Line Events' : 'Havoc Line Event Targets';
    }
    if (havocTypeOption.value === HAVOC_TYPE_TACKLE_FOR_LOSS.value) {
      return showDefensive ? 'Tackles for Loss' : 'Tackle for Loss Targets';
    }
    return showDefensive
      ? `${havocTypeOption.label}s`
      : `${havocTypeOption.label} Targets`;
  };
  const getChartTitle = () => {
    if (selectedPlayerId) {
      const playerName = relevantPlayers?.find(
        (p) => p.playerId === selectedPlayerId
      )?.playerName;
      return playerName;
    }
    if (selectedPositionCode !== HAVOC_POSITION_OPTION_ANY.value) {
      const pos = positionFrequencies.find(
        (d) => d.positionCode === selectedPositionCode
      );
      return pos?.positionName;
    }
    return `${teamName} on ${showDefensive ? 'Defense' : 'Offense'}`;
  };
  const title = getChartTitle();
  const secondaryTitle = getTitleHavocType();
  const playCount = `${havocData?.length} events`;
  const info2 =
    selectedPlayerId || selectedPositionCode !== HAVOC_POSITION_OPTION_ANY.value
      ? teamName
      : playCount;
  const info3 =
    selectedPlayerId || selectedPositionCode !== HAVOC_POSITION_OPTION_ANY.value
      ? playCount
      : null;
  const fileName = `${getChartTitle()} ${getTitleHavocType()} ${seasonName}`;
  return { title, secondaryTitle, info2, info3, fileName };
};

export const getBlitzTypes = (havocData) => {
  if (!havocData?.length) {
    return null;
  }
  const blitzTypes = uniqBy(
    havocData.filter((b) => b?.blitzType),
    'blitzType'
  );
  const blitzTypeCounts = blitzTypes.map((b) => {
    const matchingEvents = havocData.filter(
      (t) => t?.blitzType === b.blitzType
    );
    const freq = matchingEvents.length;
    return {
      blitzType: b.blitzType,
      blitzTypeName: b.blitzTypeName,
      freq,
    };
  });
  const sortedBlitzTypes = reverse(sortBy(blitzTypeCounts, 'freq'));
  return sortedBlitzTypes;
};

export const getDefensiveFrontTypes = (havocData) => {
  if (!havocData?.length) {
    return null;
  }
  const defensiveFronts = uniqBy(
    havocData.filter((b) => b?.defensiveFront),
    'defensiveFront'
  );
  const defensiveFrontCounts = defensiveFronts.map((b) => {
    const matchingEvents = havocData.filter(
      (t) => t?.defensiveFront === b.defensiveFront
    );
    const freq = matchingEvents.length;
    return {
      defensiveFront: b.defensiveFront,
      defensiveFrontName: b.defensiveFrontName,
      freq,
    };
  });
  const sortedDefensiveFronts = reverse(sortBy(defensiveFrontCounts, 'freq'));
  return sortedDefensiveFronts;
};

/* Applies havoc filters to get relevant events */
export const filterHavocData = ({
  teamHavocData,
  selectedPlayerId,
  selectedBlitzType,
  selectedDefensiveFront,
  selectedPositionCode,
}) => {
  if (isEmpty(teamHavocData)) {
    return [];
  }
  const filteredHavocData = teamHavocData?.filter(
    (h) =>
      (selectedPlayerId === 0 || h.playerId === selectedPlayerId) &&
      (selectedBlitzType === HAVOC_BLITZ_OPTION_ANY.value ||
        h.blitzType === selectedBlitzType) &&
      (selectedDefensiveFront === HAVOC_DEFENSIVE_FRONT_OPTION_ANY.value ||
        h.defensiveFront === selectedDefensiveFront) &&
      (selectedPositionCode === HAVOC_POSITION_OPTION_ANY.value ||
        h.positionCode === selectedPositionCode)
  );
  return filteredHavocData;
};

/* 
Some havoc events lack gap/alignment data ~ so can't go in the summary
this just returns all the valid events
*/
export const getZonedHavocData = (filteredHavocData) => {
  if (isEmpty(filteredHavocData)) {
    return [];
  }
  /* 
  Currently exploited gap falls back to snap gap, which is needed for defender zone
    the second condition SHOULD matter though, once exploited gap DS logic is sorted
   */
  return filteredHavocData?.filter((h) => h?.defenderZone && h?.exploitedGap);
};

/* Returns an object in form needed by HavocSummaryBar */
export const getHavocTotals = (teamHavocData) => {
  if (isEmpty(teamHavocData)) {
    return { havocPlays: 0, havocEvents: 0 };
  }
  const allHavocEvents = teamHavocData.length;
  const allHavocPlays = uniqBy(teamHavocData, 'playUUID').length;
  const zonedHavocEvents = getZonedHavocData(teamHavocData);
  const zonedHavocPlays = uniqBy(zonedHavocEvents, 'playUUID').length;
  return {
    allHavocEvents,
    allHavocPlays,
    zonedHavocPlays,
    zonedHavocEvents: zonedHavocEvents?.length,
  };
};
