import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { API_STAT_UNITS } from '../../utils/constants/api';
import {
  ALIGNMENT_POSITIONS,
  ROSTER_POSITIONS,
} from '../../utils/constants/positions';
import { formatValue } from '../../utils/helpers/stats.dataManipulation';
import { ALL_POSITION_CODES } from './PositionFilter.constants';
import PositionChips from './PositionChips';
import PositionModal from './PositionModal';

/*
****************
This component can be plugged into a page to enable positional filtering
It takes an array of position keys that are the currently selected ones
  Selected positions are rendered as chips
  A modal allows for fuller selection
****************
*/
const PositionFilter = ({
  selectedPositions,
  setSelectedPositions,
  isRosterPositions,
  positionUsages,
  asColumn,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dialogPositions, setDialogPositions] = useState([]);

  const rawDefinitions = isRosterPositions
    ? Object.values(ROSTER_POSITIONS)
    : Object.values(ALIGNMENT_POSITIONS);
  /* If usages provided, update the names to detail this */
  const positionDefinitions = rawDefinitions.map((positionDef) => {
    const usageInfo = positionUsages?.find(
      (p) => p.apiCode === positionDef.apiCode
    );
    const label = usageInfo
      ? `${positionDef.code} (${usageInfo.plays})`
      : positionDef.code;
    const tooltip = usageInfo
      ? `${positionDef.name}\n${usageInfo.plays} plays\n${formatValue(
          usageInfo.percentage,
          API_STAT_UNITS.PERCENTAGE
        )} plays`
      : positionDef.name;
    return {
      ...positionDef,
      label,
      tooltip,
      isSelected: selectedPositions.includes(positionDef.apiCode),
      isSelectedDialog: dialogPositions?.includes(positionDef.apiCode),
    };
  });

  /* 
  When modal opens, set dialogue to current state 
  When modal closes, update current state
    In the interim, only adjust the dialogue state
  */
  const openModal = () => {
    setIsOpen(true);
    setDialogPositions(selectedPositions);
  };

  const onModalClose = (positions) => {
    setSelectedPositions(positions);
    setIsOpen(false);
  };

  return (
    <>
      <PositionChips
        positionDefinitions={positionDefinitions}
        setSelectedPositions={setSelectedPositions}
        openModal={openModal}
        title="Positions"
      />
      <PositionModal
        isOpen={isOpen}
        positionDefinitions={positionDefinitions}
        setDialogPositions={setDialogPositions}
        onConfirm={onModalClose}
        asColumn={asColumn}
        isRosterPositions={isRosterPositions}
      />
    </>
  );
};

PositionFilter.propTypes = {
  // array of positions that are currently selected
  selectedPositions: PropTypes.arrayOf(PropTypes.oneOf(ALL_POSITION_CODES))
    .isRequired,
  // function to execute when positions added / removed
  // executes with the new array of positions as sole parameter
  setSelectedPositions: PropTypes.func.isRequired,
  // whether the modal selections should be roster positions or alignment positions
  isRosterPositions: PropTypes.bool.isRequired,
  // optional set of data which specifies a usage value for subset of positions
  positionUsages: PropTypes.arrayOf(
    PropTypes.shape({
      apiCode: PropTypes.oneOf(ALL_POSITION_CODES),
      plays: PropTypes.number,
      percentage: PropTypes.number,
    })
  ),
  asColumn: PropTypes.bool,
};

PositionFilter.defaultProps = {
  positionUsages: null,
  asColumn: true,
};

export default PositionFilter;
