import React from 'react';
import { SelectedPlayProvider } from './SelectedPlayContext';
import PlayByPlay from './PlayByPlay';

// wrap page in provider to allow for context to be used in the page itself
const PlayByPlayPageWrapper = () => (
  <SelectedPlayProvider>
    <PlayByPlay />
  </SelectedPlayProvider>
);

export default PlayByPlayPageWrapper;
