import {
  API_ALIGNMENT_POSITION_KEYS,
  API_OL_GAPS,
  API_DEFENDER_ZONES,
} from '../../utils/constants/api';

/* 
Defender zones are a havoc concept combining the position of the defender at snap
    with their alignment position for the play
The names are inverted left/right because the gap nomenclature comes from the OL, 
    whereas the defender zones are Defense orientated
*/
export const DEFENDER_ZONE_AXES = {
  DL: { y: 0, text: 'DL' },
  LB: { y: 0.45, text: 'LB' },
  DB: { y: 1, text: 'DB' },
};

export const HAVOC_DEFENDER_ZONES = {
  [API_DEFENDER_ZONES.BACK_CENTRAL]: {
    key: API_DEFENDER_ZONES.BACK_CENTRAL,
    name: 'DB Central',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.SAFETY,
      API_ALIGNMENT_POSITION_KEYS.NICKELBACK,
      API_ALIGNMENT_POSITION_KEYS.CORNERBACK,
    ],
    snapGaps: [
      API_OL_GAPS.RIGHT_B,
      API_OL_GAPS.RIGHT_A,
      API_OL_GAPS.LEFT_B,
      API_OL_GAPS.LEFT_A,
    ],
    dotPosition: { x: 0.5, y: DEFENDER_ZONE_AXES.DB.y, trunkX: 0.55 },
  },
  [API_DEFENDER_ZONES.BACK_LEFT]: {
    key: API_DEFENDER_ZONES.BACK_LEFT,
    name: 'DB Wide Right',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.SAFETY,
      API_ALIGNMENT_POSITION_KEYS.NICKELBACK,
      API_ALIGNMENT_POSITION_KEYS.CORNERBACK,
    ],
    snapGaps: [
      API_OL_GAPS.LEFT_D,
      API_OL_GAPS.LEFT_C_CLOSED,
      API_OL_GAPS.LEFT_C_OPEN,
    ],
    dotPosition: { x: 0.9, y: DEFENDER_ZONE_AXES.DB.y, trunkX: 0.9 },
  },
  [API_DEFENDER_ZONES.BACK_RIGHT]: {
    key: API_DEFENDER_ZONES.BACK_RIGHT,
    name: 'DB Wide Left',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.SAFETY,
      API_ALIGNMENT_POSITION_KEYS.NICKELBACK,
      API_ALIGNMENT_POSITION_KEYS.CORNERBACK,
    ],
    snapGaps: [
      API_OL_GAPS.RIGHT_D,
      API_OL_GAPS.RIGHT_C_CLOSED,
      API_OL_GAPS.RIGHT_C_OPEN,
    ],
    dotPosition: { x: 0.1, y: DEFENDER_ZONE_AXES.DB.y, trunkX: 0.1 },
  },
  [API_DEFENDER_ZONES.FRONT_LEFT_CENTRAL]: {
    key: API_DEFENDER_ZONES.FRONT_LEFT_CENTRAL,
    name: 'DL Central Right',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE,
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END,
    ],
    snapGaps: [API_OL_GAPS.LEFT_B, API_OL_GAPS.LEFT_A],
    dotPosition: { x: 0.65, y: DEFENDER_ZONE_AXES.DL.y, trunkX: 0.65 },
  },
  [API_DEFENDER_ZONES.FRONT_LEFT_WIDE]: {
    key: API_DEFENDER_ZONES.FRONT_LEFT_WIDE,
    name: 'DL Wide Right',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE,
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END,
    ],
    snapGaps: [
      API_OL_GAPS.LEFT_D,
      API_OL_GAPS.LEFT_C_CLOSED,
      API_OL_GAPS.LEFT_C_OPEN,
    ],
    dotPosition: { x: 0.95, y: DEFENDER_ZONE_AXES.DL.y, trunkX: 0.95 },
  },
  [API_DEFENDER_ZONES.FRONT_RIGHT_CENTRAL]: {
    key: API_DEFENDER_ZONES.FRONT_RIGHT_CENTRAL,
    name: 'DL Central Left',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE,
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END,
    ],
    snapGaps: [API_OL_GAPS.RIGHT_B, API_OL_GAPS.RIGHT_A],
    dotPosition: { x: 0.35, y: DEFENDER_ZONE_AXES.DL.y, trunkX: 0.35 },
  },
  [API_DEFENDER_ZONES.FRONT_RIGHT_WIDE]: {
    key: API_DEFENDER_ZONES.FRONT_RIGHT_WIDE,
    name: 'DL Wide Left',
    alignmentPositions: [
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_TACKLE,
      API_ALIGNMENT_POSITION_KEYS.DEFENSIVE_END,
    ],
    snapGaps: [
      API_OL_GAPS.RIGHT_D,
      API_OL_GAPS.RIGHT_C_CLOSED,
      API_OL_GAPS.RIGHT_C_OPEN,
    ],
    dotPosition: { x: 0.05, y: DEFENDER_ZONE_AXES.DL.y, trunkX: 0.05 },
  },
  [API_DEFENDER_ZONES.MID_CENTRAL]: {
    key: API_DEFENDER_ZONES.MID_CENTRAL,
    name: 'LB Central',
    alignmentPositions: [API_ALIGNMENT_POSITION_KEYS.LINEBACKER],
    snapGaps: [
      API_OL_GAPS.RIGHT_B,
      API_OL_GAPS.RIGHT_A,
      API_OL_GAPS.LEFT_B,
      API_OL_GAPS.LEFT_A,
    ],
    dotPosition: { x: 0.5, y: DEFENDER_ZONE_AXES.LB.y, trunkX: 0.45 },
  },
  [API_DEFENDER_ZONES.MID_LEFT]: {
    key: API_DEFENDER_ZONES.MID_LEFT,
    name: 'LB Wide Right',
    alignmentPositions: [API_ALIGNMENT_POSITION_KEYS.LINEBACKER],
    snapGaps: [
      API_OL_GAPS.LEFT_D,
      API_OL_GAPS.LEFT_C_CLOSED,
      API_OL_GAPS.LEFT_C_OPEN,
    ],
    dotPosition: { x: 0.785, y: DEFENDER_ZONE_AXES.LB.y, trunkX: 0.785 },
  },
  [API_DEFENDER_ZONES.MID_RIGHT]: {
    key: API_DEFENDER_ZONES.MID_RIGHT,
    name: 'LB Wide Left',
    alignmentPositions: [API_ALIGNMENT_POSITION_KEYS.LINEBACKER],
    snapGaps: [
      API_OL_GAPS.RIGHT_D,
      API_OL_GAPS.RIGHT_C_CLOSED,
      API_OL_GAPS.RIGHT_C_OPEN,
    ],
    dotPosition: { x: 0.225, y: DEFENDER_ZONE_AXES.LB.y, trunkX: 0.225 },
  },
};

export const HAVOC_SANKEY_CLASSES = {
  BACKGROUND: 'havoc-sankey-background',
  IN_MARGINS: 'havoc-sankey-in-margins',
  POSITION_SYMBOLS: 'havoc-sankey-pos-symbols',
  DEFENDER_ZONE_AXES: 'havoc-sankey-defender-zone-axes',
  DEFENDER_ZONES_GHOSTS: 'havoc-sankey-defender-zones-ghosts',
  DEFENDER_ZONES: 'havoc-sankey-defender-zones',
  SANKEY_LINES: 'havoc-sankey-sankey-lines',
};

export const HAVOC_SANKEY_SIZES = {
  SYMBOLS_MID_Y: 18,
  SYMBOLS_BASELINE: 22,
  PIPE_Y_INFLECTION_MIN: 70, // 38, // symbols bottom + 16px
  PIPE_Y_INFLECTION_MAX: 136, // defender zone y - max radius of bubble - 16px
  BUBBLE_RANGE: [5, 30], // so max radius = 30
  DEFENDER_ZONE_Y: 150, // after symbols and pipes, where top dotted line is
  DEFENDER_ZONE_HEIGHT: 120, // top to bottom dotted lines
  DEFENDER_ZONE_BOTTOM_MARGIN: 52, // 30px radius + 22px line height
};

export const POSITIONS_FOR_GAPS = {
  RIGHT_TE: {
    CODE: 'RIGHT_TE',
    POSITION: API_ALIGNMENT_POSITION_KEYS.TIGHT_END,
    GAP1: API_OL_GAPS.RIGHT_C_OPEN,
    GAP2: API_OL_GAPS.RIGHT_C_CLOSED,
  },
  RIGHT_TACKLE: {
    CODE: 'RIGHT_TACKLE',
    POSITION: API_ALIGNMENT_POSITION_KEYS.RIGHT_TACKLE,
    GAP1: API_OL_GAPS.RIGHT_C_CLOSED,
    GAP2: API_OL_GAPS.RIGHT_B,
  },
  RIGHT_GUARD: {
    CODE: 'RIGHT_GUARD',
    POSITION: API_ALIGNMENT_POSITION_KEYS.RIGHT_GUARD,
    GAP1: API_OL_GAPS.RIGHT_B,
    GAP2: API_OL_GAPS.RIGHT_A,
  },
  CENTER: {
    CODE: 'CENTER',
    POSITION: API_ALIGNMENT_POSITION_KEYS.CENTER,
    GAP1: API_OL_GAPS.RIGHT_A,
    GAP2: API_OL_GAPS.LEFT_A,
  },
  LEFT_GUARD: {
    CODE: 'LEFT_GUARD',
    POSITION: API_ALIGNMENT_POSITION_KEYS.LEFT_GUARD,
    GAP1: API_OL_GAPS.LEFT_A,
    GAP2: API_OL_GAPS.LEFT_B,
  },
  LEFT_TACKLE: {
    CODE: 'LEFT_TACKLE',
    POSITION: API_ALIGNMENT_POSITION_KEYS.LEFT_TACKLE,
    GAP1: API_OL_GAPS.LEFT_B,
    GAP2: API_OL_GAPS.LEFT_C_CLOSED,
  },
  LEFT_TE: {
    CODE: 'LEFT_TE',
    POSITION: API_ALIGNMENT_POSITION_KEYS.TIGHT_END,
    GAP1: API_OL_GAPS.LEFT_C_CLOSED,
    GAP2: API_OL_GAPS.LEFT_C_OPEN,
  },
};
