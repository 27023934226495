import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { scaleLinear } from 'd3';
import { csValue } from '../../utils/helpers/colorScales';
import { TableEPAWrapper } from './TableChartEPA_Bar.styles';
import { getPalette } from '../../utils/visualisations/visPalettes';

// an inline bar for table for EPA data
const TableChartEPA_Bar = ({ value, max = 2 }) => {
  const { colours, isDark } = useTheme();
  const visPalette = getPalette(isDark);

  // Set chart dimensions
  const width = 100;
  const cellHeight = 32;
  const origin = width / 2;
  const barHeight = 20;

  // Scale the bar and colour value function
  const scale = (value / max) * (width / 2);
  const epaScaler = scaleLinear().domain([2, -2]).range([0, 1]).clamp(true);
  const colour = csValue(epaScaler(value));

  return (
    <TableEPAWrapper className="hover-svg">
      <svg width={width} height={cellHeight}>
        <rect
          x={origin}
          y={(cellHeight - barHeight) / 2}
          width={Math.abs(scale)}
          height={barHeight}
          fill={colour}
          transform={value >= 0 ? '' : `translate(${scale}, 0)`}
        />
        <line
          x1={origin}
          y1="0"
          x2={origin}
          y2={cellHeight}
          stroke={visPalette.axis}
          strokeWidth="1"
        />
        <line
          x1={0}
          y1="0"
          x2={0}
          y2={cellHeight}
          stroke={colours.canvas.primary.main}
          strokeWidth="1"
        />
        <line
          x1={width}
          y1="0"
          x2={width}
          y2={cellHeight}
          stroke={colours.canvas.primary.main}
          strokeWidth="1"
        />
      </svg>
      <p>{value}</p>
    </TableEPAWrapper>
  );
};

TableChartEPA_Bar.propTypes = {
  value: PropTypes.string.isRequired, // EPA value from -2 to 2, minus is better
  max: PropTypes.number.isRequired, // The maximum value for the EPA
};

export default TableChartEPA_Bar;
