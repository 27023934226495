import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3';
import { useTheme } from 'styled-components';
import ScatterChart from '../../../../../visualisations/ScatterChart/ScatterChart';
import {
  runGapAverageTotalPropType,
  runGapAxesPropType,
} from './RunGapScatter.propTypes';
import {
  RUN_TENDENCY_AVERAGE_LINE_SETUP,
  RUN_TENDENCY_AXES,
  RUN_TENDENCY_BUBBLE_SIZES,
} from '../RunTendencies.constants';
import { runGapTooltip } from '../RunTendencies.dataManipulation';
import { runDatumPropTypes } from '../RunTendencies.propTypes';

/*
This sets up all the custom settings (i.e. scales) for the scatter chart
    and converts from runGap data formatting to generic scatter formatting
 */
const RunGapScatter = ({
  chartId,
  runGapData,
  runGapDataTeam,
  teamAverageTotals,
  leagueAverageTotals,
  yAxisKey,
  rAxisKey,
  colorAxisKey,
  handleBubbleClick,
  selectedBubble,
}) => {
  const { isDark, colours } = useTheme();
  const visPalette = colours.visualisations;
  const layoutOverride = {
    CANVAS: { HEIGHT: 200 },
    AXES: {
      GUIDES: {
        VERTICAL: null,
      },
      AREA: { top: 0, right: 8, bottom: 30, left: 35 },
      /* Increase vertical canvas padding to 21 as max radius is 20 + stroke */
      PADDING: { top: 21, right: 10, bottom: 21, left: 10 },
    },
  };

  const yOverrides = RUN_TENDENCY_AXES[yAxisKey].gap;
  const rOverrides = RUN_TENDENCY_AXES[rAxisKey].gap;

  const setupOverride = {
    Y: yOverrides,
    R: {
      ...rOverrides,
      NICE: false,
      RANGE: [
        RUN_TENDENCY_BUBBLE_SIZES.minRadius,
        RUN_TENDENCY_BUBBLE_SIZES.maxRadius,
      ], // note: range max > axes padding means cropping may occur
    },
  };
  /* 
  Don't want to use whole success possibility, as extremes irrelevant
  So re-map success value before using color scale
  */
  const coloringFunction = RUN_TENDENCY_AXES[colorAxisKey].colorFunction;
  const colorDomain = RUN_TENDENCY_AXES[colorAxisKey].gap.DOMAIN;
  const colorRange = [0, 1];
  const transformer = scaleLinear()
    .domain(colorDomain)
    .range(colorRange)
    .clamp(true);
  const bubbleColorScale = (rValue) => {
    const transformedValue = transformer(rValue);
    return coloringFunction(transformedValue, isDark);
  };

  const scatterData = runGapData.map((gap) => ({
    id: gap.id,
    xValue: gap.id,
    yValue: gap[yAxisKey],
    rValue: gap[rAxisKey],
    fill: bubbleColorScale(gap[colorAxisKey]),
    stroke: 'transparent',
    title: runGapTooltip(gap, `Filtered Data: ${gap.id}`),
  }));

  const scatterDataTeam = runGapDataTeam.map((gap) => ({
    id: gap.id,
    xValue: gap.id,
    yValue: gap[yAxisKey],
    rValue: gap[rAxisKey],
    fill: 'transparent',
    stroke: bubbleColorScale(gap[colorAxisKey]),
    title: runGapTooltip(gap, `Team Data: ${gap.id}`),
  }));

  const teamLine = {
    id: 'teamAverage',
    xValue: null,
    yValue: teamAverageTotals?.[yAxisKey],
    stroke: RUN_TENDENCY_AVERAGE_LINE_SETUP.TEAM.COLOR(visPalette),
    strokeDashArray: RUN_TENDENCY_AVERAGE_LINE_SETUP.TEAM.STROKE_DASH,
  };
  const leagueLine = {
    id: 'leagueAverage',
    xValue: null,
    yValue: leagueAverageTotals?.[yAxisKey],
    stroke: RUN_TENDENCY_AVERAGE_LINE_SETUP.LEAGUE.COLOR(visPalette),
    strokeDashArray: RUN_TENDENCY_AVERAGE_LINE_SETUP.LEAGUE.STROKE_DASH,
  };
  /* Plays and Play% don't make sense in terms of flat averages (or gap averages) */
  const averageLines = [
    RUN_TENDENCY_AXES.plays.value,
    RUN_TENDENCY_AXES.playPercentage.value,
  ].includes(yAxisKey)
    ? null
    : [teamLine, leagueLine];

  return (
    <ScatterChart
      chartId={chartId}
      scatterData={scatterData}
      secondSeriesData={scatterDataTeam}
      averageLines={averageLines}
      layoutOverride={layoutOverride}
      setupOverride={setupOverride}
      handleBubbleClick={handleBubbleClick}
      selectedBubble={selectedBubble}
    />
  );
};

RunGapScatter.propTypes = {
  chartId: PropTypes.string,
  runGapData: PropTypes.arrayOf(runDatumPropTypes),
  runGapDataTeam: PropTypes.arrayOf(runDatumPropTypes),
  teamAverageTotals: runGapAverageTotalPropType,
  leagueAverageTotals: runGapAverageTotalPropType,
  yAxisKey: runGapAxesPropType.isRequired,
  rAxisKey: runGapAxesPropType.isRequired,
  colorAxisKey: runGapAxesPropType.isRequired,
  handleBubbleClick: PropTypes.func,
  selectedBubble: PropTypes.string,
};

RunGapScatter.defaultProps = {
  chartId: 'run-gap-scatter',
  runGapData: undefined,
  runGapDataTeam: undefined,
  teamAverageTotals: undefined,
  leagueAverageTotals: undefined,
  handleBubbleClick: () => {},
  selectedBubble: null,
};

export default RunGapScatter;
