import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import Dimmer from '../../../../components/Dimmer/Dimmer';

const PlayByPlayAccordionFeedback = ({ isLoading, hasError, playsData }) => {
  const feedbackContent = () => {
    if (isLoading) {
      return <Loader content="Loading" />;
    }
    if (hasError) {
      return <p>There was an error loading the data</p>;
    }
    if (!playsData || playsData?.length === 0) {
      return <p>No Data available</p>;
    }
    return null;
  };

  return (
    feedbackContent() && (
      <div>
        <Dimmer active>{feedbackContent()}</Dimmer>
      </div>
    )
  );
};

PlayByPlayAccordionFeedback.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  playsData: PropTypes.arrayOf(
    PropTypes.shape({
      game: PropTypes.shape({
        awayTeam: PropTypes.shape({ name: PropTypes.string }),
        homeTeam: PropTypes.shape({ name: PropTypes.string }),
      }),
    })
  ),
};

PlayByPlayAccordionFeedback.defaultProps = {
  playsData: undefined,
};

export default PlayByPlayAccordionFeedback;
