import React, { useEffect, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { Message, Loader } from 'semantic-ui-react';
import { Grid, Dropdown, Toggle } from '@statsbomb/kitbag-components';
import { useWindowWidth } from '@react-hook/window-size';
import { useTheme } from 'styled-components';
import useQueryString from '../../../utils/hooks/useQueryString';
import { mf_PlayerDetails, mf_TeamDetails } from '../../../apollo';
import AccordionTile from '../../../components/Accordion/AccordionTile';
import Tile from '../../../components/Tile/Tile';
import {
  FixedAside,
  KitbagPageGridHolder,
  SidebarRightLayout,
} from '../../../components/Layout/Layout.styles';
import {
  ROUTE_TREE_ANY_ALIGNMENT,
  ROUTE_TREE_ANY_ALIGNMENT_CODES,
  ROUTE_TREE_FILTER_DEFAULTS,
} from './PlayerRoute.constants';
import KeyButton from '../../../components/buttons/DisplayKey/DisplayKey';
import {
  getRouteTotals,
  getTableHeaders,
  objectifyRoutes,
  addRouteProportionalities,
  tablefyRoutes,
  rescaleMetricConfig,
  getFilterMetrics,
  addVisulisationInfo,
  formatRouteVideoData,
} from './PlayerRoute.dataManipulation';
import RouteTree from '../../../visualisations/RouteTree/RouteTree';
import { ROTATIONS } from '../../../utils/constants/charting';
import TableTile from '../../../components/TableTile/TableTile';
import {
  useGetAlignmentPlaysDrop,
  useGetRoutePlayerStats,
  useGetRouteStatDefinitions,
} from './PlayerRoute.hooks';
import Dimmer from '../../../components/Dimmer/Dimmer';
import RouteTreeKey from '../../../visualisations/RouteTree/RouteTreeKey/RouteTreeKey';
import ExportModal from '../../../components/ExportModal/ExportModal';
import { useExportSeasonInfo } from '../../../utils/hooks/useExportSeasonInfo';
import { RouteTreeHolder } from './PlayerRoute.styles';
import PageHeader from '../../../components/PageHeader/PageHeader';
import { MemoizedVideoTile } from '../../../components/VideoTile/VideoTile';
import { ROUTE_TYPE_CONFIG } from '../../../visualisations/RouteTree/RouteTree.constants';

const PlayerRoute = () => {
  // global state
  const teamDetails = useReactiveVar(mf_TeamDetails);
  const teamName = teamDetails?.name;
  const playerDetails = useReactiveVar(mf_PlayerDetails);
  const playerName = playerDetails?.name;
  // page filters
  const [displayKey, setDisplayKey] = useState(true);
  const [alignmentPosition, setAlignmentPosition] = useQueryString(
    'alignmentPosition',
    ROUTE_TREE_ANY_ALIGNMENT
  );
  const [treeBranchAxis, setTreeBranchAxis] = useQueryString(
    'treeBranchAxis',
    ROUTE_TREE_FILTER_DEFAULTS.BRANCH
  );
  const [treeFruitColorAxis, setTreeFruitColorAxis] = useQueryString(
    'treeFruitColorAxis',
    ROUTE_TREE_FILTER_DEFAULTS.FRUIT
  );
  const [branchRescaled, setBranchRescaled] = useQueryString(
    'treeBranchAxisRescaled',
    false
  );
  const [fruitRescaled, setFruitRescaled] = useQueryString(
    'treeFruitColorAxisRescaled',
    false
  );
  // todo: make this usable
  // eslint-disable-next-line no-unused-vars
  const [fieldOrientation, setFieldOrientation] = useQueryString(
    'fieldOrientation',
    ROTATIONS.VERTICAL_UP
  );
  // video integration
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState();
  const [videoOpen, setVideoOpen] = useState(false);
  const windowWidth = useWindowWidth();
  const [sidebarWidthCalc, setSidebarWidthCalc] = useState();
  const theme = useTheme();
  const visPalette = theme.colours.visualisations;

  // chart data
  const apiPositionCodes =
    alignmentPosition === ROUTE_TREE_ANY_ALIGNMENT
      ? ROUTE_TREE_ANY_ALIGNMENT_CODES
      : [alignmentPosition];

  const { loading, error, routesData } =
    useGetRoutePlayerStats(apiPositionCodes);
  const isError = !!error;

  const {
    loading: loadingAlignmentOptions,
    error: alignmentOptionsHasError,
    data: alignmentOptionsData,
  } = useGetAlignmentPlaysDrop();

  /* Format routes data into a summary object with all relevant totals */
  const routeTotals = getRouteTotals(routesData);
  const formattedRoutes = addRouteProportionalities(routesData, routeTotals);
  const routePlayIds = routesData.map((item) => ({
    [item.routeType]: { playIds: item.playIds },
  }));

  /* Route Types Table */
  const { loading: loadingDefs, statDefinitions } =
    useGetRouteStatDefinitions();
  const tableHeaders = getTableHeaders(statDefinitions);
  const tableRoutes = tablefyRoutes(formattedRoutes, statDefinitions);

  /* Filters */
  const filterMetrics = getFilterMetrics(statDefinitions);

  /* Config Domain Adjustments (if needed) for Route Tree/ Key */
  const branchConfigRaw = statDefinitions.find(
    (def) => def.key === treeBranchAxis
  );
  const fruitConfigRaw = statDefinitions.find(
    (def) => def.key === treeFruitColorAxis
  );
  const branchConfig = branchRescaled
    ? rescaleMetricConfig(formattedRoutes, treeBranchAxis, branchConfigRaw)
    : branchConfigRaw;
  const fruitConfig = fruitRescaled
    ? rescaleMetricConfig(formattedRoutes, treeFruitColorAxis, fruitConfigRaw)
    : fruitConfigRaw;

  /* Route Tree Visualisation */
  const showRoutes = !loading && !isError && formattedRoutes?.length > 0;

  const scaledRoutes = addVisulisationInfo(
    formattedRoutes,
    branchConfig,
    fruitConfig
  );
  const routesSummary = showRoutes ? objectifyRoutes(scaledRoutes) : {};
  const { competitionSeason, seasonName } = useExportSeasonInfo({
    asObject: true,
  });

  /* Export */
  const exportDetails = {
    title:
      `${playerName}: ` +
      `${routeTotals.totalRoutes} Routes Run ` +
      `(${routeTotals.totalTargets} targets)`,
    secondaryTitle: 'Route Summary',
    info1: competitionSeason,
    info2: `${teamName}`,
    info3: `${routeTotals.totalPlays} pass plays`,
    fileName: `${playerName} Route Summary ${seasonName}`,
  };

  const onRouteTreeFruitClick = (datum) => {
    // if no datum, clear selected route and close video
    if (!datum) {
      setSelectedRoute({
        count: 0,
        name: null,
      });
      setVideoOpen(false);
      setSelectedEvents(null);
    } else {
      const routeName = datum.routeType;
      const playIds = routePlayIds.find((route) => route[routeName])?.[
        routeName
      ]?.playIds;
      // if no plays, clear selected route and close video
      if (playIds.length === 0) {
        setSelectedRoute({
          count: 0,
          name: null,
        });
        setVideoOpen(false);
        setSelectedEvents(null);
      } else {
        // tidy route name string to remove underscores and capitalise the first letter
        setSelectedRoute({
          count: playIds.length,
          type: routeName,
        });
        setVideoOpen(true);
        setSelectedEvents(playIds);
      }
    }
  };

  const onVideoClose = () => {
    setVideoOpen(false);
    setSelectedEvents(null);
    setSelectedRoute({
      count: 0,
      name: null,
    });
  };

  useEffect(() => {
    // set sidebar:
    // 50% if video tile is visible with video Auth
    // 70% if item selected (video tile visible) without video Auth
    // 80% if video tile is not visible
    setSidebarWidthCalc(windowWidth - (videoOpen ? 0.5 : 0.8) * windowWidth);
  }, [windowWidth, videoOpen]);

  return (
    <div className="team-run-tendencies-page">
      <KitbagPageGridHolder>
        <Grid container={false} page>
          <Grid item xs={12}>
            <SidebarRightLayout
              $sidebarWidth={`${sidebarWidthCalc}px`}
              $inlineSize={`${videoOpen ? 40 : 16}%`}
              $gap="0.5rem"
              $padding="0 0.25rem 0 0"
            >
              <div>
                <PageHeader
                  href="/player/overview/:leagues/:seasons/:teams/:players"
                  rootPage="Player"
                  activePage="Routes Run"
                />
                <Tile border="0" margin="0.5rem 0 0 0">
                  <Tile.Header style={{ padding: '1rem 1rem 0 1rem' }}>
                    <h1>
                      {`${routeTotals.totalRoutes} Routes Run ` +
                        `(${routeTotals.totalPlays} pass plays, ${routeTotals.totalTargets} targets)`}
                    </h1>
                  </Tile.Header>
                  <Tile.Body style={{ padding: '0 1rem 1rem 1rem' }}>
                    <Grid>
                      <Grid item xs={videoOpen ? 12 : 5}>
                        <div
                          style={{
                            display: 'flex',
                            gap: '0.5rem',
                            justifyContent: 'flex-end',
                            padding: '0 0 1rem 0',
                          }}
                        >
                          <KeyButton
                            showKey={displayKey}
                            handleShowKey={() => setDisplayKey(!displayKey)}
                          />
                          <ExportModal
                            title={exportDetails.title}
                            secondaryTitle={exportDetails.secondaryTitle}
                            info1={exportDetails.info1}
                            info2={exportDetails.info2}
                            info3={exportDetails.info3}
                            fileName={exportDetails.fileName}
                            isDisabled={loading || !showRoutes}
                          >
                            {showRoutes && (
                              <RouteTree
                                routesSummary={routesSummary}
                                orientation={fieldOrientation}
                                isAnimated={false}
                              />
                            )}
                            {displayKey && (
                              <RouteTreeKey
                                branchConfig={branchConfig}
                                fruitConfig={fruitConfig}
                              />
                            )}
                          </ExportModal>
                        </div>
                        <RouteTreeHolder>
                          {isError && !loading && (
                            <Message negative>
                              There has been an error. Please contact support.
                            </Message>
                          )}
                          {loading && (
                            <Dimmer active style={{ minHeight: '30vh' }}>
                              <Loader content="Loading Data" />
                            </Dimmer>
                          )}
                          {showRoutes && (
                            <RouteTree
                              routesSummary={routesSummary}
                              orientation={fieldOrientation}
                              handleRouteTreeFruitClick={onRouteTreeFruitClick}
                              selectedRouteType={selectedRoute?.type}
                            />
                          )}
                        </RouteTreeHolder>
                        <RouteTreeHolder>
                          {branchConfig && fruitConfig && displayKey && (
                            <RouteTreeKey
                              branchConfig={branchConfig}
                              fruitConfig={fruitConfig}
                            />
                          )}
                        </RouteTreeHolder>
                      </Grid>
                      <Grid item xs={videoOpen ? 12 : 7}>
                        {loadingDefs && (
                          <Dimmer active style={{ minHeight: '30vh' }}>
                            <Loader content="Loading Data" />
                          </Dimmer>
                        )}
                        {!loadingDefs && tableRoutes?.length > 0 && (
                          <Tile border="0" margin="0">
                            <Tile.Body
                              $padding={videoOpen ? '0' : '0 0 0 1rem'}
                            >
                              <TableTile
                                data={tableRoutes}
                                tileTitle="Route Details"
                                columnHeaders={tableHeaders}
                                showColumnHeader
                                fileName={`${teamName} ${playerName} Routes ${seasonName}.csv`}
                                error={false}
                                loading={false}
                                margin="-0.5rem 0 0 0"
                              />
                            </Tile.Body>
                          </Tile>
                        )}
                      </Grid>
                    </Grid>
                  </Tile.Body>
                </Tile>
              </div>

              <div>
                <FixedAside
                  $width={`${sidebarWidthCalc + 16}px`}
                  style={{ marginLeft: 0, paddingLeft: 0 }}
                >
                  {videoOpen && (
                    <MemoizedVideoTile
                      title={playerName}
                      subTitle={`${selectedRoute.count} ${
                        ROUTE_TYPE_CONFIG[selectedRoute.type]?.name
                      } Route${selectedRoute.count !== 1 ? 's' : ''} Run`}
                      data={formatRouteVideoData(
                        selectedEvents,
                        playerDetails,
                        visPalette
                      )}
                      handleClose={onVideoClose}
                    />
                  )}
                  {!videoOpen && (
                    <>
                      <AccordionTile
                        id="axes-settings-accordion"
                        isExpandedDefault
                        isMount
                        header={
                          <Tile.AccordionHeader>
                            Route Tree Settings
                          </Tile.AccordionHeader>
                        }
                        body={
                          <Tile.AccordionBody>
                            {!alignmentOptionsHasError && (
                              <Dropdown
                                id="route-tree-position-dropdown"
                                options={alignmentOptionsData}
                                label="Player's Alignment Position"
                                menuPosition="static"
                                onChange={(selectedOption) =>
                                  setAlignmentPosition(selectedOption.value)
                                }
                                value={alignmentOptionsData?.find(
                                  (f) => f.value === alignmentPosition
                                )}
                                isLoading={loadingAlignmentOptions}
                              />
                            )}

                            {filterMetrics?.length > 0 && (
                              <>
                                <Dropdown
                                  id="route-tree-branch-dropdown"
                                  options={filterMetrics}
                                  label="Tree Branch Axis"
                                  menuPosition="static"
                                  onChange={(selectedOption) =>
                                    setTreeBranchAxis(selectedOption.value)
                                  }
                                  value={filterMetrics.find(
                                    (f) => f.value === treeBranchAxis
                                  )}
                                />
                                <Toggle
                                  id="branch-rescale-toggle"
                                  label={
                                    branchRescaled
                                      ? `Player Range`
                                      : `Standard Range`
                                  }
                                  onChange={() => {
                                    setBranchRescaled(!branchRescaled);
                                  }}
                                  checked={branchRescaled}
                                />
                                <Dropdown
                                  id="route-tree-fruit-dropdown"
                                  options={filterMetrics}
                                  label="Tree Fruit Axis"
                                  menuPosition="static"
                                  onChange={(selectedOption) =>
                                    setTreeFruitColorAxis(selectedOption.value)
                                  }
                                  value={filterMetrics.find(
                                    (f) => f.value === treeFruitColorAxis
                                  )}
                                />
                                <Toggle
                                  id="fruit-rescale-toggle"
                                  label={
                                    fruitRescaled
                                      ? `Player Range`
                                      : `Standard Range`
                                  }
                                  onChange={() => {
                                    setFruitRescaled(!fruitRescaled);
                                  }}
                                  checked={fruitRescaled}
                                />
                              </>
                            )}
                            {/* This will come back in but temporarily cut for release */}
                            {/* <Dropdown
                          id="route-tree-orientation-dropdown"
                          options={ROTATION_OPTIONS}
                          label="Field Orientation"
                          menuPosition="static"
                          onChange={(selectedOption) =>
                            setFieldOrientation(selectedOption.value)
                          }
                          value={ROTATION_OPTIONS.find(
                            (f) => f.value === fieldOrientation
                          )}
                        /> */}
                          </Tile.AccordionBody>
                        }
                      />
                    </>
                  )}
                </FixedAside>
              </div>
            </SidebarRightLayout>
          </Grid>
        </Grid>
      </KitbagPageGridHolder>
    </div>
  );
};

export default PlayerRoute;
