import React, { useEffect } from 'react';
import { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { select } from 'd3';
import { merge, cloneDeep, isFunction } from 'lodash';
import { useD3 } from '../../utils/hooks/useD3';
import { DEFAULT_VISUALISATION_MARGIN } from '../../utils/constants/charting';
import { marginPropType } from '../../utils/constants/propTypes';
import { ClickableSVG } from '../visualisation.styles';
import {
  addChartCoreLayout,
  applyCoreLayoutTransforms,
  getChartViewbox,
  addExtendedAxes,
  addAxes,
  drawGuides,
} from '../BasicChart/BasicChart.drawing';
import {
  CHART_LAYOUT,
  CHART_LAYOUT_CLASSES,
  CHART_SCALE_DISPLAY_TYPE,
} from '../BasicChart/BasicChart.constants';
import { BAR_AXES_SETUP, BAR_CLASSES } from './BarChart.constants';
import { drawBars, drawInteractionBars, drawLines } from './BarChart.drawing';
import { getRange, getScale } from '../BasicChart/BasicChart.dataManipulation';
import { barDatumPropTypes, linePropTypes } from './BarChart.propTypes';
import { basicChartLayoutPropType } from '../BasicChart/BasicChart.propTypes';

const BarChart = ({
  chartId,
  barData,
  barDataGhost,
  linesData,
  margin,
  layoutOverride,
  setupOverride,
  selectedBars,
  handleBarClick,
}) => {
  const { colours, isDark } = useTheme();
  const visPalette = colours.visualisations;
  /* Need deep merging of objects hence not using spreading for overrides */
  const layout = merge(cloneDeep(CHART_LAYOUT), layoutOverride);
  const setup = merge(cloneDeep(BAR_AXES_SETUP), setupOverride);
  const viewBox = getChartViewbox(layout, margin);

  const safeBarData = barData; // makeScatterDataSafe(scatterData, visPalette);

  const xRange = getRange(setup.X, layout.CANVAS.WIDTH);
  const xScaleBar = getScale(
    setup.X,
    xRange,
    safeBarData,
    'xValue',
    CHART_SCALE_DISPLAY_TYPE.BAR
  );
  const xScaleLine = getScale(setup.X, xRange, safeBarData, 'xValue');

  const yRange = getRange(setup.Y, layout.CANVAS.HEIGHT);
  const yScale = getScale(setup.Y, yRange, safeBarData, 'yValue');

  /* 
  Permanent elements 
    Create the structure of all parts, then useEffect() based on relevant 
    parameters to update those parts/their children as required
  */
  const ref = useD3((svg) => {
    svg.attr('id', chartId);
    svg.selectAll('*').remove();

    /* Add all elements from basic chart setup */
    addChartCoreLayout(svg, margin);

    /* Add Bar specific data aspects to the canvas */
    const canvasG = svg.select(`.${CHART_LAYOUT_CLASSES.CANVAS}`);

    canvasG
      .append('g')
      .attr('class', BAR_CLASSES.BARS)
      .attr('data-testid', BAR_CLASSES.BARS);
    canvasG
      .append('g')
      .attr('class', BAR_CLASSES.GHOST_BARS)
      .attr('data-testid', BAR_CLASSES.GHOST_BARS);
    canvasG
      .append('g')
      .attr('class', BAR_CLASSES.AVERAGE_LINES)
      .attr('data-testid', BAR_CLASSES.AVERAGE_LINES);
    canvasG
      .append('g')
      .attr('class', BAR_CLASSES.INTERACTION_BARS)
      .attr('data-testid', BAR_CLASSES.INTERACTION_BARS);

    if (isFunction(handleBarClick)) {
      svg
        .select(`.${CHART_LAYOUT_CLASSES.BACKGROUND}`)
        .on('click', () => handleBarClick(null));
    }
  }, []);

  /* Shell elements - affected by theme, size and overrides */
  useEffect(() => {
    const svg = select(ref.current);
    svg.attr('viewBox', viewBox);

    svg
      .select(`.${CHART_LAYOUT_CLASSES.BACKGROUND}`)
      .attr('fill', visPalette.background.main);

    /* Adjust all axes by relevant transforms */
    applyCoreLayoutTransforms(svg, layout);

    /* If bars are longer than ticks area, add those lines */
    addExtendedAxes(svg, layout, visPalette);
  }, [layoutOverride, colours, isDark]);

  /* Changes dependant upon the data */
  useEffect(() => {
    const svg = select(ref.current);

    /* For any axes with labels, render them */
    addAxes(svg, visPalette, layout, setup, xScaleBar, yScale);

    /* If drawing guide lines, add them here */
    drawGuides(svg, layout, setup, xScaleBar, yScale, visPalette);

    /* Draw any league average lines */
    const linesG = svg.select(`.${BAR_CLASSES.AVERAGE_LINES}`);
    drawLines(linesG, linesData, xScaleLine, yScale);

    /* Draw Bar Chart data */
    const canvasG = svg.select(`.${BAR_CLASSES.BARS}`);
    drawBars(
      canvasG,
      barData,
      xScaleBar,
      yScale,
      setup.Y?.Y_ORIGIN,
      selectedBars
    );
    /* Draw ghost bars if needed using same logic */
    const canvasGhostG = svg.select(`.${BAR_CLASSES.GHOST_BARS}`);
    if (barDataGhost) {
      /* override any styling to ghost settings */
      const ghostData = barDataGhost.map((d) => ({
        ...d,
        fill: 'none',
        stroke: visPalette.contrast,
      }));
      drawBars(canvasGhostG, ghostData, xScaleBar, yScale, setup.Y?.Y_ORIGIN);
    } else {
      canvasGhostG.selectAll('*').remove();
    }

    /* Add tooltip data */
    const hoverAndClickG = svg.select(`.${BAR_CLASSES.INTERACTION_BARS}`);
    drawInteractionBars(
      hoverAndClickG,
      barData,
      xScaleBar,
      layout,
      handleBarClick,
      yScale,
      setup.Y?.Y_ORIGIN
    );
  }, [barData, linesData, colours, isDark, layoutOverride, setupOverride]);

  return <ClickableSVG ref={ref} />;
};

BarChart.propTypes = {
  chartId: PropTypes.string,
  barData: PropTypes.arrayOf(barDatumPropTypes).isRequired,
  barDataGhost: PropTypes.arrayOf(barDatumPropTypes),
  linesData: PropTypes.arrayOf(linePropTypes),
  margin: marginPropType,
  layoutOverride: basicChartLayoutPropType,
  setupOverride: PropTypes.shape({}),
  selectedBars: PropTypes.arrayOf(PropTypes.string),
  handleBarClick: PropTypes.func,
};

BarChart.defaultProps = {
  chartId: 'bar-chart',
  barDataGhost: undefined,
  linesData: [],
  margin: DEFAULT_VISUALISATION_MARGIN,
  layoutOverride: undefined,
  setupOverride: undefined,
  selectedBars: null,
  handleBarClick: undefined,
};

export default BarChart;
