import React from 'react';
import PropTypes from 'prop-types';
import SortableTable from './SortableTable';
import { SORT_DIRECTIONS } from '../../../utils/constants/sortDirections';
import {
  sortableTableStickyColumnsPropTypes,
  tableRowsPropTypes,
} from '../Tables.propTypes';

const SelectableSortableTable = ({
  name,
  headers,
  data,
  handleSort,
  sortDirection,
  sortKey,
  handleToggle,
  highlightedColumn,
  stickyColumns,
}) => {
  const indeterminateRows = data?.some((r) => r.checked);
  const allRows = data?.every((r) => r.checked);
  const isNestedTable = data?.some((obj) => obj.hasOwnProperty('children'));
  const selectedRowIndexes = data
    ?.map((item, index) => (item.checked ? index + 1 : -1))
    .filter((index) => index !== -1);
  let flatIndex = 0;
  const selectedRowIndexesNested = [];
  if (isNestedTable) {
    data?.forEach((parent) => {
      flatIndex += 1;
      if (parent.checked) {
        selectedRowIndexesNested.push(flatIndex);
      }
      parent?.children?.forEach((child) => {
        flatIndex += 1;
        if (child.checked) {
          selectedRowIndexesNested.push(flatIndex);
        }
      });
    });
  }

  // non-nested tables return an Integer for row index
  // nested tables return a string ex 0-1, 0-3 ro indicate the parent row and the child row
  const toggleRow = (index) => {
    const tableClone = structuredClone(data);

    const toggleChecked = (item, checked) => ({
      ...item,
      checked: !item.checked || checked,
    });

    if (isNestedTable) {
      // click sub
      if (!Number.isInteger(index) && index.includes('-')) {
        const [parentIndex, childIndex] = index.split('-').map(Number);
        tableClone[parentIndex].children[childIndex] = toggleChecked(
          tableClone[parentIndex].children[childIndex]
        );
      } else {
        // click parent
        const parent = tableClone[index];
        parent.checked = !parent.checked;
        parent.children = parent.children.map((child) =>
          toggleChecked(child, parent.checked)
        );
      }
    } else {
      tableClone[index] = toggleChecked(tableClone[index]);
    }

    handleToggle(tableClone);
  };

  const toggleAll = () => {
    const toggleChecked = (item) => ({
      ...item,
      checked: !indeterminateRows,
      children:
        item.children?.map((child) => ({
          ...child,
          checked: !indeterminateRows,
        })) || [],
    });

    const tableRows = data.map(toggleChecked);

    handleToggle(tableRows);
  };

  return (
    <SortableTable
      name={name}
      headers={headers}
      rows={data}
      selectedRows={
        isNestedTable ? selectedRowIndexesNested : selectedRowIndexes
      }
      handleSort={handleSort}
      sortDirection={sortDirection}
      sortBy={sortKey}
      stickyColumns={stickyColumns}
      rowHeight="2rem"
      withBorder
      onChange={toggleRow}
      onChangeAll={toggleAll}
      allIndeterminate={indeterminateRows}
      allChecked={allRows}
      highlightedColumn={highlightedColumn}
    />
  );
};

SelectableSortableTable.propTypes = {
  name: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.string,
      align: PropTypes.string,
    })
  ),
  data: tableRowsPropTypes,
  handleSort: PropTypes.func.isRequired,
  sortDirection: PropTypes.oneOf(Object.values(SORT_DIRECTIONS)),
  sortKey: PropTypes.string,
  handleToggle: PropTypes.func.isRequired,
  highlightedColumn: PropTypes.number,
  stickyColumns: sortableTableStickyColumnsPropTypes,
};

SelectableSortableTable.defaultProps = {
  sortDirection: SORT_DIRECTIONS.DEFAULT,
  headers: [],
  data: [],
  sortKey: '',
  highlightedColumn: null,
  stickyColumns: null,
};

export default SelectableSortableTable;
