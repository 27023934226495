export const sideNavItems = [
  {
    id: 'player',
    name: 'Player',
    icon: 'PlayerFootball',
    position: 'top',
    sections: ['player'],
    links: [
      {
        name: 'Overview',
        href: '/player/overview/:leagues/:seasons/:teams/:players',
        id: 'player/overview',
      },
      {
        name: 'Pass Placement',
        href: '/player/placement/:leagues/:seasons/:teams/:players',
        id: 'player/placement',
      },
      {
        name: 'Passing Chart',
        href: '/player/passing/:leagues/:seasons/:teams/:players',
        id: 'player/passing',
      },
      {
        name: 'Snap Distribution',
        href: '/player/snap/:leagues/:seasons/:teams/:players',
        id: 'player/snap',
      },
      {
        name: 'Radar',
        href: '/player/radar/:leagues/:seasons/:teams/:players',
        id: 'player/radar',
      },
      {
        name: 'Routes Run',
        href: '/player/route/:leagues/:seasons/:teams/:players',
        id: 'player/route',
      },
      {
        name: 'Comparison',
        href: '/player/comparison/:leagues/:seasons/:teams/:players',
        id: 'player/comparison',
      },
    ],
  },
  {
    id: 'team',
    name: 'Team',
    icon: 'TeamFootball',
    position: 'top',
    sections: ['team'],
    links: [
      {
        name: 'Overview',
        href: '/team/overview/:leagues/:seasons/:teams',
        id: 'team/overview',
      },
      {
        name: 'Box Score',
        href: '/team/box/:leagues/:seasons/:teams',
        id: 'team/box',
      },
      {
        name: 'Passing Chart',
        href: '/team/passing/:leagues/:seasons/:teams',
        id: 'team/passing',
      },
      {
        name: 'Line Pressures',
        href: '/team/linePressure/:leagues/:seasons/:teams',
        id: 'team/linePressure',
      },
      {
        name: 'Run Tendencies',
        href: '/team/runTendencies/:leagues/:seasons/:teams',
        id: 'team/runTendencies',
      },
      {
        name: 'Tackling Map',
        href: '/team/tackles/:leagues/:seasons/:teams',
        id: 'team/tackles',
      },
      {
        name: 'Havoc Events',
        href: '/team/havocSummary/:leagues/:seasons/:teams',
        id: 'team/havocSummary',
      },
      {
        name: 'Depth Chart',
        href: '/team/depth/:leagues/:seasons/:teams',
        id: 'team/depth',
      },
      {
        name: 'Formation Summary',
        href: '/team/summary/:leagues/:seasons/:teams',
        id: 'team/summary',
      },
      {
        name: 'Defensive Scheme',
        href: '/team/defensiveScheme/:leagues/:seasons/:teams',
        id: 'team/defensiveScheme',
      },
      {
        name: 'Formation Detail',
        href: '/team/detail/:leagues/:seasons/:teams',
        id: 'team/detail',
        userGroup: 'statsbomb',
      },
      {
        name: 'Player +/-',
        href: '/team/impact/:leagues/:seasons/:teams',
        id: 'team/impact',
        userGroup: 'statsbomb',
      },
      {
        name: 'Radar',
        href: '/team/radar/:leagues/:seasons/:teams',
        id: 'team/radar',
      },
      {
        name: 'Schedule',
        href: '/team/schedule/:leagues/:seasons/:teams',
        id: 'team/schedule',
      },
    ],
  },
  {
    id: 'playfinder',
    name: 'Play Finder',
    icon: 'IqTactics',
    position: 'top',
    sections: ['playfinder'],
    links: [
      {
        name: 'Play Finder',
        href: '/playfinder/wizard',
        id: 'playfinder/wizard',
      },
    ],
  },
  {
    id: 'league',
    name: 'League',
    icon: 'League',
    position: 'top',
    sections: ['league'],
    links: [
      {
        name: 'Player Stats',
        href: '/league/stats/players/:leagues/:seasons',
        id: 'league/stats/players',
      },
      {
        name: 'Team Stats',
        href: '/league/stats/teams/:leagues/:seasons',
        id: 'league/stats/teams',
      },
    ],
  },
  {
    id: 'game',
    name: 'Game',
    icon: 'MatchFootball',
    position: 'top',
    sections: ['game'],
    links: [
      {
        name: 'Play Animation',
        href: '/game/animation/:leagues/:seasons/:teams/:games/:drives/:plays',
        id: 'game/animation',
      },
      {
        name: 'Snap Formations',
        href: '/game/formations/:leagues/:seasons/:teams/:games/:drives',
        id: 'game/formations',
      },
      {
        name: 'Play Event Tiles',
        href: '/game/event/:leagues/:seasons/:teams/:games/:drives/:plays',
        id: 'game/event',
      },
      {
        name: 'Play By Play Feed',
        href: '/game/byplay/:leagues/:seasons/:teams/:games',
        id: 'game/byplay',
      },
    ],
  },
  {
    id: 'scout',
    name: 'Scout',
    icon: 'IqScout',
    position: 'top',
    sections: ['scout'],
    links: [
      {
        name: 'Player Scout',
        href: '/scout/wizard/:leagues/:seasons',
        id: 'scout/players',
      },
    ],
  },
];

export const sideNavFooterItems = [
  {
    id: 'admin',
    name: 'Admin',
    icon: 'Profile',
    position: 'bottom',
    sections: ['admin'],
    links: [
      {
        name: 'Admin',
        href: '/admin',
        id: 'admin',
      },
      {
        name: 'Logout',
        href: '/',
      },
    ],
  },
  {
    id: 'data-access',
    name: 'Data Access',
    icon: 'Api',
    position: 'bottom',
    sections: ['data-access'],
    links: [
      {
        name: 'Data-Access',
        href: '/data-access',
        id: 'data-access',
      },
    ],
  },
  {
    id: 'assist',
    name: 'Assist',
    icon: 'Support',
    position: 'bottom',
    sections: ['assist'],
    links: [
      {
        name: 'Assist',
        href: 'https://statsbombhelp-amf.zendesk.com/hc/en-gb/',
        id: 'assist',
      },
      {
        name: 'Release Notes',
        href: 'https://statsbombhelp-amf.zendesk.com/hc/en-gb/categories/13962054083474-Release-Notes',
        id: 'release-notes',
      },
      {
        name: 'Feedback',
        // eslint-disable-next-line no-script-url, max-len
        href: 'javascript: zE("webWidget", "open"); zE("webWidget", "updateSettings", { webWidget: { contactForm: { suppress: false }}}); zE("webWidget:on", "close", function() {  zE("webWidget", "updateSettings", { webWidget: { contactForm: { suppress: true }}}) });',
        id: 'feedback',
      },
    ],
  },
];
export const isSectionSelected = (sectionPath, section) =>
  section?.sections.some((path) => path === sectionPath.split('/')[0]);

export const isPageSelected = (location = '', pageId) =>
  (pageId === 'admin' && !location) || location.includes(pageId);
