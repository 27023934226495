export const API_PLAY_TYPE_KEYS = {
  PASS: 'PASS',
  RUSH: 'RUSH',
  QUARTERBACK_KNEEL: 'QUARTERBACK_KNEEL',
  QUARTERBACK_SPIKE: 'QUARTERBACK_SPIKE',
  KICKOFF: 'KICKOFF',
  PUNT: 'PUNT',
  FIELD_GOAL: 'FIELD_GOAL',
  EXTRA_POINT: 'EXTRA_POINT',
  TWO_POINT_CONVERSION: 'TWO_POINT_CONVERSION',
  NO_PLAY_PENALTY: 'NO_PLAY_PENALTY',
  NO_PLAY_TIMEOUT: 'NO_PLAY_TIMEOUT',
  OFF_CAMERA: 'OFF_CAMERA',
  SAFETY_KICK: 'SAFETY_KICK',
  NO_PLAY_STOPPAGE_INJURY: 'NO_PLAY_STOPPAGE_INJURY',
  NO_PLAY_STOPPAGE_OTHER: 'NO_PLAY_STOPPAGE_OTHER',
};

export const normalPlayTypes = {
  [API_PLAY_TYPE_KEYS.PASS]: 'Pass',
  [API_PLAY_TYPE_KEYS.RUSH]: 'Run',
  [API_PLAY_TYPE_KEYS.QUARTERBACK_KNEEL]: 'QB Kneel',
  [API_PLAY_TYPE_KEYS.QUARTERBACK_SPIKE]: 'QB Spike',
};

export const specialPlayTypes = {
  [API_PLAY_TYPE_KEYS.KICKOFF]: 'Kickoff',
  [API_PLAY_TYPE_KEYS.PUNT]: 'Punt',
  [API_PLAY_TYPE_KEYS.FIELD_GOAL]: 'Field Goal',
  [API_PLAY_TYPE_KEYS.EXTRA_POINT]: 'Extra Point',
  [API_PLAY_TYPE_KEYS.TWO_POINT_CONVERSION]: '2Point Attempt',
};

const nonPlayTypes = {
  [API_PLAY_TYPE_KEYS.NO_PLAY_PENALTY]: 'No Play: Penalty',
  [API_PLAY_TYPE_KEYS.NO_PLAY_TIMEOUT]: 'No Play: Timeout',
  [API_PLAY_TYPE_KEYS.OFF_CAMERA]: 'Off Camera',
  [API_PLAY_TYPE_KEYS.SAFETY_KICK]: 'Safety Kick',
  [API_PLAY_TYPE_KEYS.NO_PLAY_STOPPAGE_INJURY]: 'No Play: Injury Stoppage',
  [API_PLAY_TYPE_KEYS.NO_PLAY_STOPPAGE_OTHER]: 'No Play: Stoppage',
};

export const qbPocketLocation = {
  IN: 'In',
  LEFT: 'Left',
  RIGHT: 'Right',
};

export const API_PLAY_TYPES = {
  ...normalPlayTypes,
  ...specialPlayTypes,
  ...nonPlayTypes,
};

export const API_PLAY_TYPES_KEYS = Object.keys({
  ...normalPlayTypes,
  ...specialPlayTypes,
  ...nonPlayTypes,
});

export const playTypeOptions = {
  ...normalPlayTypes,
  ...specialPlayTypes,
};

// play type options for context filter as keys
export const playTypeOptionsKeys = Object.keys({
  ...normalPlayTypes,
  ...specialPlayTypes,
});

export const qbPocketLocationKeys = Object.keys(qbPocketLocation);

export const API_EVENT_TYPE_DEFENDER_TOUCHED = 'DEFENDER_TOUCHED';
const API_TURNOVER_TYPE_FUMBLE = 'TURNOVER_FROM_FUMBLE';
const API_TURNOVER_TYPE_INTERCEPTION = 'TURNOVER_FROM_INTERCEPTION';

export const API_PASS_PLAY_TURNOVER_TYPES = [
  API_TURNOVER_TYPE_FUMBLE,
  API_TURNOVER_TYPE_INTERCEPTION,
];

export const API_TURNOVER_TYPES = {
  TURNOVER_FROM_FUMBLE: 'Turnover (Fumble)',
  TURNOVER_FROM_INTERCEPTION: 'Turnover (Interception)',
  TURNOVER_FROM_PUNT: 'Turnover (Punt)',
  TURNOVER_ON_DOWNS: 'Turnover on downs',
};

/*
All API Event types AO 2022-10-11
Unlike the above API_SOMETHING_TYPES, this is just a list of the keys
This is not a conversion to a "nice name", hence the name TYPE_KEYS instead of TYPES
*/
export const API_EVENT_TYPE_KEYS = {
  BATTED_BALL: 'BATTED_BALL',
  BLOCK: 'BLOCK',
  BREAK_UP: 'BREAK_UP',
  CAMERA_PAUSED: 'CAMERA_PAUSED',
  CAMERA_RESUMED: 'CAMERA_RESUMED',
  CATCH: 'CATCH',
  CHALLENGE: 'CHALLENGE',
  CONVERSION: 'CONVERSION',
  DEADBALL: 'DEADBALL',
  DEFENDER_TOUCH: 'DEFENDER_TOUCH',
  DEFENDER_TOUCHED: 'DEFENDER_TOUCHED',
  DRIVE_END: 'DRIVE_END',
  DRIVE_START: 'DRIVE_START',
  DROP_KICK: 'DROP_KICK',
  ENGAGEMENT_START: 'ENGAGEMENT_START',
  ENGAGEMENT_END: 'ENGAGEMENT_END',
  EXTRA_POINT: 'EXTRA_POINT',
  FAIR_CATCH: 'FAIR_CATCH',
  FAIR_CATCH_KICK: 'FAIR_CATCH_KICK',
  FAKE: 'FAKE',
  FAKE_HANDOFF: 'FAKE_HANDOFF',
  FAKE_LATERAL: 'FAKE_LATERAL',
  FAKE_PASS: 'FAKE_PASS',
  FELL: 'FELL',
  FIELD_GOAL: 'FIELD_GOAL',
  FREE_KICK: 'FREE_KICK',
  FROM_KICK: 'FROM_KICK',
  FROM_LATERAL: 'FROM_LATERAL',
  FROM_PASS: 'FROM_PASS',
  FROM_SNAP: 'FROM_SNAP',
  FUMBLE: 'FUMBLE',
  HANDOFF: 'HANDOFF',
  HIT: 'HIT',
  HURDLE: 'HURDLE',
  HURRIED: 'HURRIED',
  HURRIED_SNAP: 'HURRIED_SNAP',
  INITIAL_LINEUP: 'INITIAL_LINEUP',
  INJURY_STOPPAGE: 'INJURY_STOPPAGE',
  INTERCEPTED: 'INTERCEPTED',
  INTERCEPTION: 'INTERCEPTION',
  JUKE: 'JUKE',
  JUMP_CATCH: 'JUMP_CATCH',
  KICK: 'KICK',
  KICK_DEADBALL: 'KICK_DEADBALL',
  KICK_OFF: 'KICK_OFF',
  KICKOFF_RETURN: 'KICKOFF_RETURN',
  KNEEL: 'KNEEL',
  LATERAL: 'LATERAL',
  LOOSE_TOUCH: 'LOOSE_TOUCH',
  MUFFED: 'MUFFED',
  OFF_CAMERA: 'OFF_CAMERA',
  ONE_POINT_CONVERSION: 'ONE_POINT_CONVERSION',
  ONSIDE_KICK: 'ONSIDE_KICK',
  OUT: 'OUT',
  OVER_SHOULDER_CATCH: 'OVER_SHOULDER_CATCH',
  PASS: 'PASS',
  PASSER_HIT: 'PASSER_HIT',
  PATHING: 'PATHING',
  PENALTY: 'PENALTY',
  PLACE_KICK: 'PLACE_KICK',
  PLAY_END: 'PLAY_END',
  PLAY_START: 'PLAY_START',
  PLAYER_DOWN: 'PLAYER_DOWN',
  PLAYER_FORCED_OUT: 'PLAYER_FORCED_OUT',
  PLAYER_GROUNDED: 'PLAYER_GROUNDED',
  PLAYER_OUT: 'PLAYER_OUT',
  PRESSURE: 'PRESSURE',
  PROXIMITY_PRESSURE: 'PROXIMITY_PRESSURE',
  PUNT: 'PUNT',
  PUNT_RETURN: 'PUNT_RETURN',
  QUARTER_END: 'QUARTER_END',
  QUARTER_START: 'QUARTER_START',
  RECOVERY: 'RECOVERY',
  RETURN: 'RETURN',
  REVIEW: 'REVIEW',
  RUN_DISRUPTION: 'RUN_DISRUPTION',
  SACK: 'SACK',
  SACKED: 'SACKED',
  SAFETY: 'SAFETY',
  SAFETY_KICK: 'SAFETY_KICK',
  SCRIMMAGE_KICK: 'SCRIMMAGE_KICK',
  SLIDE: 'SLIDE',
  SNAP: 'SNAP',
  SPIKE: 'SPIKE',
  SPIN: 'SPIN',
  STIFF_ARM: 'STIFF_ARM',
  STOPPAGE: 'STOPPAGE',
  SUBSTITUTIONS: 'SUBSTITUTIONS',
  TACKLE: 'TACKLE',
  TACKLE_ATTEMPT: 'TACKLE_ATTEMPT',
  TACKLE_AVOIDED: 'TACKLE_AVOIDED',
  TACKLE_BROKEN: 'TACKLE_BROKEN',
  TACKLED: 'TACKLED',
  TIMEOUT: 'TIMEOUT',
  TOUCH: 'TOUCH',
  TOUCH_BACK: 'TOUCH_BACK',
  TOUCHDOWN: 'TOUCHDOWN',
  TRUCK: 'TRUCK',
  TWO_MINUTE_WARNING: 'TWO_MINUTE_WARNING',
  TWO_POINT_CONVERSION: 'TWO_POINT_CONVERSION',
  PATHING_CARRY: 'PATHING_CARRY',
  PATHING_PRE_SNAP: 'PATHING_PRE_SNAP',
};

export const API_AGGREGATE_MODES = {
  TOTAL: 'TOTAL',
  PER_GAME: 'PER_GAME',
  PER_SNAP: 'PER_SNAP',
};

export const API_PLAYER_AGGREGATE_MODES = {
  TOTAL: 'TOTAL',
  RATE: 'RATE',
};

export const API_PLAYER_GROUP_BY = {
  PLAYER: 'PLAYER',
  DOWN: 'DOWN',
  QUARTER: 'QUARTER',
  TEAM: 'TEAM',
  PLAY_POSITION: 'PLAY_POSITION',
  GAME: 'GAME',
};

export const API_STAT_UNITS = {
  COUNT: 'COUNT',
  YARDS: 'YARDS',
  POINTS: 'POINTS',
  SECONDS: 'SECONDS',
  RATE: 'RATE',
  RATIO: 'RATIO',
  PERCENTAGE: 'PERCENTAGE',
  PERCENTAGE_PROVIDED: 'PERCENTAGE_PROVIDED', // percentage provided in data, no need to multiply by 100
  EPA: 'EPA',
  MPH: 'MPH',
  MPHS: 'MPHS', // acceleration in miles-per-hour-per-second
  SHORT_DECIMAL: 'SHORT_DECIMAL', // not actually in the API but used for data formatting
  STRING: 'STRING', // not actually in the API but used for data formatting
  BOOLEAN: 'BOOLEAN', // not actually in the API but used for data formatting
};

/* 
Roster (or "General") Positions as named in the API
These are collected metadata, generic names specified [primarily] at seasonal level
https://www.notion.so/statsbomb/Positions-in-AMF-12332e8ca21d4efc8719f42653ef570e
*/
export const API_ROSTER_POSITION_KEYS = {
  /* Offensive Roster Positions */
  OFFENSIVE_LINEMAN: 'OFFENSIVE_LINEMAN',
  QUARTERBACK: 'QUARTERBACK',
  RUNNING_BACK: 'RUNNING_BACK',
  TIGHT_END: 'TIGHT_END',
  WIDE_RECEIVER: 'WIDE_RECEIVER',
  /* Defensive Roster Positions */
  DEFENSIVE_BACK: 'DEFENSIVE_BACK',
  DEFENSIVE_LINEMAN: 'DEFENSIVE_LINEMAN',
  LINEBACKER: 'LINEBACKER',
  /* Special Teams */
  LONG_SNAPPER: 'LONG_SNAPPER',
  PUNTER: 'PUNTER',
  HOLDER: 'HOLDER',
  KICKER: 'KICKER',
  KICK_RETURNER: 'KICK_RETURNER',
  PUNT_RETURNER: 'PUNT_RETURNER',
  /* Only relevant to rosters */
  RESERVE: 'RESERVE',
};

/* 
Alignment (or "Specific") Positions as named in the API
These are derived in enrichment from analysis of Snap Freeze Frame
  Some additional values may be present in the API spec;
  This list is of only the values that can be derived by enrichment
  2023-04-17: Special Teams alignment logic doesn't yet exist, so these positions are assumed
https://www.notion.so/statsbomb/Positions-in-AMF-12332e8ca21d4efc8719f42653ef570e
*/
export const API_ALIGNMENT_POSITION_KEYS = {
  /* Offensive Alignment Positions */
  LEFT_TACKLE: 'LEFT_TACKLE',
  LEFT_GUARD: 'LEFT_GUARD',
  CENTER: 'CENTER',
  RIGHT_GUARD: 'RIGHT_GUARD',
  RIGHT_TACKLE: 'RIGHT_TACKLE',
  TIGHT_END: 'TIGHT_END',
  WIDE_RECEIVER: 'WIDE_RECEIVER',
  SLOT_RECEIVER: 'SLOT_RECEIVER',
  QUARTERBACK: 'QUARTERBACK',
  RUNNING_BACK: 'RUNNING_BACK',
  FULLBACK: 'FULLBACK',
  /* Defensive Alignment Positions */
  DEFENSIVE_TACKLE: 'DEFENSIVE_TACKLE',
  DEFENSIVE_END: 'DEFENSIVE_END',
  LINEBACKER: 'LINEBACKER',
  CORNERBACK: 'CORNERBACK',
  NICKELBACK: 'NICKELBACK',
  SAFETY: 'SAFETY',
  /* Special Teams Alignment Positions (not yet enriched) */
  LONG_SNAPPER: 'LONG_SNAPPER',
  HOLDER: 'HOLDER',
  KICKER: 'KICKER',
  PUNTER: 'PUNTER',
  KICK_RETURNER: 'KICK_RETURNER',
  PUNT_RETURNER: 'PUNT_RETURNER',
};

export const API_OL_GAPS = {
  LEFT_D: 'D_LEFT',
  LEFT_C_OPEN: 'C_OPEN_LEFT',
  LEFT_C_CLOSED: 'C_CLOSED_LEFT',
  LEFT_B: 'B_LEFT',
  LEFT_A: 'A_LEFT',
  RIGHT_A: 'A_RIGHT',
  RIGHT_B: 'B_RIGHT',
  RIGHT_C_CLOSED: 'C_CLOSED_RIGHT',
  RIGHT_C_OPEN: 'C_OPEN_RIGHT',
  RIGHT_D: 'D_RIGHT',
};

export const PLAY_FINDER_WIDGET_TYPES = {
  RANGE: 'RANGE',
  SELECT_SINGLE: 'SELECT_SINGLE',
  SELECT_MULTIPLE: 'SELECT_MULTIPLE',
  BOOL: 'BOOL',
  CUSTOM: 'CUSTOM',
};

export const API_COMPETITION = {
  1409: 'NFL',
  1446: 'NCAA',
  10033: 'NCAA2',
};

export const API_DEFENDER_ZONES = {
  BACK_CENTRAL: 'BACK_CENTRAL',
  BACK_LEFT: 'BACK_LEFT',
  BACK_RIGHT: 'BACK_RIGHT',
  FRONT_LEFT_CENTRAL: 'FRONT_LEFT_CENTRAL',
  FRONT_LEFT_WIDE: 'FRONT_LEFT_WIDE',
  FRONT_RIGHT_CENTRAL: 'FRONT_RIGHT_CENTRAL',
  FRONT_RIGHT_WIDE: 'FRONT_RIGHT_WIDE',
  MID_CENTRAL: 'MID_CENTRAL',
  MID_LEFT: 'MID_LEFT',
  MID_RIGHT: 'MID_RIGHT',
};
