import { styled } from 'styled-components';

export const TableEPAWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    background-color: ${({ theme }) => theme.colours.canvas.tertiary.main};
  }

  p {
    position: relative;
    padding-left: 0.5rem;
  }
`;
