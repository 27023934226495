import { sortBy } from 'lodash';
import { API_OL_GAPS } from '../../../utils/constants/api';
import { LINE_GAPS, gapColor } from '../../../utils/constants/gaps';

export const HAVOC_KEY_WIDTH = 500;
export const HAVOC_KEY_VERTICAL_WIDTH = 250;
export const HAVOC_KEY_VERTICAL_HEIGHT = 320;

export const getGapDots = (visPalette) => {
  const gapDots = Object.values(API_OL_GAPS).map((apiKey) => {
    const gap = LINE_GAPS[apiKey];
    const fillColor = gapColor(apiKey, visPalette, false);
    const strokeColor = gapColor(apiKey, visPalette, true);
    return { ...gap, color: fillColor, stroke: strokeColor };
  });
  return sortBy(gapDots, 'index');
};

export const HAVOC_SUMMARY_KEY_SIZES = {
  VERTICAL_WIDTH: 250,
  HORIZONTAL_MARGINS: 16,
  HORIZONTAL_WIDTH: 500, // two columns
  VERTICAL_KEY_SPACE_REQUIRED: 298, // vertical width + 3 * margin
  HORIZONTAL_KEY_SPACE_REQUIRED: 532, // horizontal key 2 column limit
  SECTION: {
    WIDTH: 250,
    PADDING: 8,
    HEIGHT: { TITLE: 22, DOTS: 14, VALUES: 18, MARGIN: 8, TOTAL: 60 },
  },
};

export const HAVOC_SUMMARY_CLASSES = {
  BACKGROUND: 'hsk-background',
  BAR_COLORS: 'hsk-bar-color',
  BUBBLE_COLORS: 'hsk-bubble-color',
};
