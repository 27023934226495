import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@statsbomb/kitbag-components';
import useQueryString from '../../../utils/hooks/useQueryString';
import {
  AccordionTableRowBody,
  AccordionTableRowHeader,
  TableCellPadding,
} from './AccordionTableRow.styles';

const AccordionTableRow = ({ id, header, body, saveState, colSpan }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandedQS, setIsExpandedQS] = useQueryString(id, false);
  const expanded = saveState ? isExpandedQS : isExpanded;

  const handleClick = () => {
    if (saveState) {
      setIsExpandedQS(!isExpandedQS);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Table.Row>
      <TableCellPadding colSpan={colSpan}>
        <AccordionTableRowHeader
          aria-expanded={expanded}
          $isExpanded={expanded}
          onClick={handleClick}
          data-testid="AccordianHeaderT"
        >
          {header}
        </AccordionTableRowHeader>

        <AccordionTableRowBody
          $isExpanded={expanded}
          data-testid="AccordianBodyT"
        >
          {body}
        </AccordionTableRowBody>
      </TableCellPadding>
    </Table.Row>
  );
};

AccordionTableRow.propTypes = {
  id: PropTypes.string,
  header: PropTypes.node,
  body: PropTypes.node,
  saveState: PropTypes.bool,
  colSpan: PropTypes.number,
};

AccordionTableRow.defaultProps = {
  id: 'accordion',
  header: <></>,
  body: <></>,
  saveState: false,
  colSpan: 1,
};

export default AccordionTableRow;
