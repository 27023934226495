import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonIcon } from '@statsbomb/kitbag-components';
import { Message } from 'semantic-ui-react';
import Tile from '../../../../components/Tile/Tile';
import { SummaryStat } from '../../../../components/SummaryStat/SummaryStat';
import {
  HavocSummaryStats,
  HavocSummaryStatsColumn,
  HavocSummaryStatsColumnHeader,
} from './HavocSummaryBar.styles';
import { HAVOC_SUMMARY_TOTALS } from './HavocSummary.constants';
import PlaylistButton from '../../../../components/buttons/Playlist/Playlist';

const HavocSummaryBar = ({
  playSummary,
  havocSummary,
  havocSummarySelected,
  showVideoClicked,
  clearSelectionClicked,
  isError,
}) => {
  const [displaySummaryOpen, setDisplaySummaryOpen] = useState(true);
  const playsTotals = Object.values(HAVOC_SUMMARY_TOTALS.PLAYS).map((p) => ({
    label: p.label,
    value: playSummary[p.key],
    units: p.units,
  }));
  const havocTotals = Object.values(HAVOC_SUMMARY_TOTALS.HAVOC).map((p) => ({
    label: p.label,
    value: havocSummary[p.key],
    units: p.units,
  }));
  const havocSummaryTotals = Object.values(HAVOC_SUMMARY_TOTALS.SELECTED).map(
    (p) => ({
      label: p.label,
      value: havocSummarySelected?.[p.key],
      units: p.units,
    })
  );
  const playsTotalsWidth = havocSummarySelected ? '50%' : '67%';
  const havocTotalsWidth = havocSummarySelected ? '25%' : '33%';

  const videoPlays = havocSummarySelected
    ? havocSummarySelected.havocPlays
    : havocSummary.havocPlays;

  return (
    <Tile border="0" margin="0.5rem 0">
      <Tile.Header>
        <h2>Summary</h2>
        <div className="buttons">
          {havocSummarySelected && (
            <Button
              onClick={clearSelectionClicked}
              variant="secondary"
              shape="pill"
              size="small"
            >
              Clear selection
            </Button>
          )}
          <PlaylistButton
            handleClick={showVideoClicked}
            variant={havocSummarySelected ? 'primary' : 'secondary'}
            size="small"
            numberOfPlays={videoPlays}
          />
          <ButtonIcon
            onClick={() => setDisplaySummaryOpen(!displaySummaryOpen)}
            variant="secondary"
            shape="pill"
            size="small"
            icon={displaySummaryOpen ? 'ChevronUp' : 'ChevronDown'}
            title="ChevronUp"
          />
        </div>
      </Tile.Header>
      {isError && (
        <Message negative>
          There has been an error. Please contact support.
        </Message>
      )}
      {!isError && displaySummaryOpen && (
        <Tile.Body>
          <HavocSummaryStats>
            <HavocSummaryStatsColumn $width={playsTotalsWidth}>
              <div>
                <HavocSummaryStatsColumnHeader>
                  <h3>Plays</h3>
                </HavocSummaryStatsColumnHeader>
                {playsTotals?.map((stat) => (
                  <SummaryStat
                    label={stat.label}
                    value={stat.value}
                    key={stat.label}
                    units={stat.units}
                    width="20%"
                  />
                ))}
              </div>
            </HavocSummaryStatsColumn>
            <HavocSummaryStatsColumn $width={havocTotalsWidth}>
              <div>
                <HavocSummaryStatsColumnHeader>
                  <h3>Havoc Totals</h3>
                </HavocSummaryStatsColumnHeader>
                {havocTotals?.map((stat) => (
                  <SummaryStat
                    label={stat.label}
                    value={stat.value}
                    key={stat.label}
                    units={stat.units}
                    width="40%"
                  />
                ))}
              </div>
            </HavocSummaryStatsColumn>
            {havocSummarySelected && (
              <HavocSummaryStatsColumn $width={havocTotalsWidth}>
                <div>
                  <HavocSummaryStatsColumnHeader>
                    <h3>Selection Totals</h3>
                  </HavocSummaryStatsColumnHeader>
                  {havocSummaryTotals?.map((stat) => (
                    <SummaryStat
                      label={stat.label}
                      value={stat.value}
                      key={stat.label}
                      units={stat.units}
                      width="40%"
                    />
                  ))}
                </div>
              </HavocSummaryStatsColumn>
            )}
          </HavocSummaryStats>
        </Tile.Body>
      )}
    </Tile>
  );
};

HavocSummaryBar.propTypes = {
  playSummary: PropTypes.shape({
    playCount: PropTypes.number,
    runRate: PropTypes.number,
    runPlays: PropTypes.number,
    passPlays: PropTypes.number,
  }).isRequired,
  havocSummary: PropTypes.shape({
    havocPlays: PropTypes.number,
    havocEvents: PropTypes.number,
    havocEventsAll: PropTypes.number,
  }).isRequired,
  havocSummarySelected: PropTypes.shape({
    havocPlays: PropTypes.number,
    havocEvents: PropTypes.number,
  }),
  isError: PropTypes.bool.isRequired,
  showVideoClicked: PropTypes.func.isRequired,
  clearSelectionClicked: PropTypes.func.isRequired,
};

HavocSummaryBar.defaultProps = {
  havocSummarySelected: null,
};

HavocSummaryBar.defaultProps = {
  havocSummarySelected: null,
};

export default HavocSummaryBar;
